<template>
  <v-container fluid>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <create-user-role-dialog
      :dialog="showCreateUserRoleDialog"
      @dialog-option-selected="onCreateUserRoleOptionSelected"
    >
    </create-user-role-dialog>
    <v-row>
      <v-col>
        <v-btn color="success" @click="onNewItem">New Admin</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created any admin users yet. Create
              your first one by clicking the New Admin button.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> Administrators </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :items-per-page="15"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            v-model="selectedItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { IUserRoleListItem } from "@/models/security.model";
import { SecurityService } from "@/services/security.service";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import CreateUserRoleDialog from "@/components/security/create-user-role-dialog.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListRoles",
  mixins: [CommonMixin],
  components: {
    CreateUserRoleDialog,
  },
  data() {
    return {
      showDeleteDialog: false,
      showCreateUserRoleDialog: false,
      search: "",
      headers: [
        { text: "First Name", value: "firstName", sortable: false, width: 60 },
        { text: "Last Name", value: "lastName", sortable: false, width: 60 },
        { text: "Role", value: "role", sortable: false, width: 120 },
        {
          text: "Description",
          value: "roleDescription",
          sortable: false,
          width: 240,
        },
        { text: "Actions", value: "actions", sortable: false, width: 80 },
      ],
      items: Array<IUserRoleListItem>(),
      selectedItems: Array<IUserRoleListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
    };
  },
  methods: {
    onNewItem() {
      this.showCreateUserRoleDialog = true;
    },

    onDeleteItem(item: IUserRoleListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems() {
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Roles, Please Wait...`
      );

      const service = new SecurityService();
      service
        .getUserRoles()
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: IUserRoleListItem) {
      this.dialogMessage = new DialogMessage(
        `Remove role ${item.role} for ${item.firstName} ${item.lastName}?`,
        `Are you sure you want to un-assign this role to this user?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing role assignment, Please Wait..."
        );
        const service = new SecurityService();
        service
          .removeUserRole(item.entity.roleId, item.entity.registeredUserId)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex(
              (f) =>
                f.roleId == item.entity.roleId &&
                f.registeredUserId == item.entity.registeredUserId
            );
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onCreateUserRoleOptionSelected(item: IUserRoleListItem) {
      if (item) {
        this.items.push(item);
      }
      this.showCreateUserRoleDialog = false;
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>