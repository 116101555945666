<template>
  <v-container fluid>
    <list-groups
      v-show="showListView"
      ref="listgroups"
      :groupType="groupType"
      @new-group-requested="onNewGroup"
      @show-group="onShowGroup"
    ></list-groups>
    <edit-section-group
      v-show="showManageView"
      ref="managegroup"
      :groupType="groupType"
      @show-alert-panel="showSuccessPanel"
      @manage-group-cancel="onManageGroupCancel"
    ></edit-section-group>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ListGroups from "@/views/lists/list-groups.vue";
import EditSectionGroup from "@/views/editors/editor-group-general.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerGroups",
  mixins: [CommonMixin],
  components: {
    ListGroups,
    "edit-section-group": EditSectionGroup,
  },
  props: {
    groupType: {
      type: String,
      default: "branch",
    },
  },
  data() {
    return {
      showListView: true,
      showManageView: false,
      groupId: 0,
      ownerId: 0,
    };
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems() {
      this.groupId = 0;
      this.showListView = true;
      this.showManageView = false;
      const ref: any = this.$refs.listgroups;
      if (ref) {
        ref.getItems();
      }
    },

    onNewGroup() {
      this.groupId = 0;
      this.showListView = false;
      this.showManageView = true;
      const ref: any = this.$refs.managegroup;
      if (ref) {
        ref.getGroup(this.groupId, this.ownerId);
      }
    },

    onShowGroup(id: number) {
      this.groupId = id;
      this.showListView = false;
      this.showManageView = true;
      const ref: any = this.$refs.managegroup;
      if (ref) {
        ref.getGroup(this.groupId, this.ownerId);
      }
    },

    onManageGroupCancel() {
      this.getItems();
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>