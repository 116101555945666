import { DemographicsQueryViewModel } from "@/models/demographics.model";
import { DateTime } from "luxon";
import Vue from "vue";

export default Vue.extend({
    name: "DemographicsMixin",
    methods: {
        toQueryDescription(viewModel: DemographicsQueryViewModel): string {
            let description = '';
            let useAnd = false;
            let queryTypeIsPopulated = false;

            // Query type
            if (viewModel.selectedQueryType) {
                description += viewModel.selectedQueryType;
                queryTypeIsPopulated = true;
            }

            // Marital status
            if (viewModel.selectedMaritalStatuses &&
                viewModel.selectedMaritalStatuses.length > 0) {
                for (let item of viewModel.selectedMaritalStatuses) {
                    description += ` ${item.toLowerCase()},`;
                }
                description = this.removeTrailing(description);
            }

            // Ethnicity
            if (viewModel.selectedEthnicities &&
                viewModel.selectedEthnicities.length > 0) {
                for (let item of viewModel.selectedEthnicities) {
                    description += ` ${item.toLowerCase()},`;
                }
                description = this.removeTrailing(description);
            }

            // Nationality
            if (viewModel.selectedNationalities &&
                viewModel.selectedNationalities.length > 0) {
                for (let item of viewModel.selectedNationalities) {
                    description += ` ${item.toLowerCase()},`;
                }
                description = this.removeTrailing(description);
            }

            // Gender
            if (viewModel.selectedGenders &&
                viewModel.selectedGenders.length > 0) {
                for (let item of viewModel.selectedGenders) {
                    description += ` ${item.toLowerCase()},`;
                }
                description = this.removeTrailing(description);
            }

            // Subject
            if (viewModel.selectedSubject) {
                description += ` ${viewModel.selectedSubject}`;
            }

            // Average Type
            if (viewModel.selectedAverageType &&
                (queryTypeIsPopulated && viewModel.selectedQueryType === 'the average')) {
                description += ` ${viewModel.selectedAverageType}`;
            }

            // Ratio Type
            if (viewModel.selectedRatioType &&
                (queryTypeIsPopulated && viewModel.selectedQueryType === 'the ratio of')) {
                description += ` ${viewModel.selectedRatioType}`;
            }

            // Spread Type
            if (viewModel.selectedSpreadType &&
                (queryTypeIsPopulated && viewModel.selectedQueryType === 'the spread of')) {
                description += ` ${viewModel.selectedSpreadType}`;
            }

            // Age from / to
            if (viewModel.ageFrom && viewModel.ageTo) {
                description += ` between the ages of ${viewModel.ageFrom} and ${viewModel.ageTo}`;
            }

            // Branches
            if (viewModel.selectedBranches &&
                viewModel.selectedBranches.length > 0) {
                description += ` who attend the branch(es) in`;
                for (let id of viewModel.selectedBranches) {
                    let match = viewModel.branches.find(f => f.id === id);
                    if (match) {
                        description += ` ${match.text},`;
                    }
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Ministries
            if (viewModel.selectedMinistries &&
                viewModel.selectedMinistries.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who work in the`;
                for (let id of viewModel.selectedMinistries) {
                    let match = viewModel.ministries.find(f => f.id === id);
                    if (match) {
                        description += ` ${match.text},`;
                    }
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Groups
            if (viewModel.selectedGroups &&
                viewModel.selectedGroups.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who work in`;
                for (let id of viewModel.selectedGroups) {
                    let match = viewModel.groups.find(f => f.id === id);
                    if (match) {
                        description += ` ${match.text},`;
                    }
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Position
            if (viewModel.selectedPositions &&
                viewModel.selectedPositions.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who hold the position(s) of`;
                for (let item of viewModel.selectedPositions) {
                    description += ` ${item}, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Professions
            if (viewModel.selectedProfessions &&
                viewModel.selectedProfessions.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who works in the profession(s) of`;
                for (let item of viewModel.selectedProfessions) {
                    description += ` a ${item.toLowerCase()}, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Current Statuses
            if (viewModel.selectedCurrentStatuses &&
                viewModel.selectedCurrentStatuses.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` whose current status(es) are`;
                for (let item of viewModel.selectedCurrentStatuses) {
                    description += ` ${item.toLowerCase() }, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Attributes
            if (viewModel.selectedAttributes &&
                viewModel.selectedAttributes.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who has the attribute(s) of`;
                for (let item of viewModel.selectedAttributes) {
                    description += ` ${item.toLowerCase() }, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Region
            if (viewModel.selectedRegions &&
                viewModel.selectedRegions.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who reside in the regions of`;
                for (let item of viewModel.selectedRegions) {
                    description += ` ${item}, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Cities
            if (viewModel.selectedCities &&
                viewModel.selectedCities.length > 0) {
                if (useAnd)
                    description += ` and`;
                description += ` who reside in the cities of`;
                for (let item of viewModel.selectedCities) {
                    description += ` ${item}, `;
                }
                useAnd = true;
                description = this.removeTrailing(description);
            }

            // Date First Visited
            if (viewModel.dateOfFirstVisitStart &&
                viewModel.dateOfFirstVisitEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who first visted between`;
                description += ` ${DateTime.fromISO(viewModel.dateOfFirstVisitStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.dateOfFirstVisitEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            // Date Baptism Requested
            if (viewModel.dateRequestedBaptisedStart &&
                viewModel.dateRequestedBaptisedEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who requested baptism between`;
                description += ` ${DateTime.fromISO(viewModel.dateRequestedBaptisedStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.dateRequestedBaptisedEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            // Date Of Baptism
            if (viewModel.dateBaptisedStart &&
                viewModel.dateBaptisedEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who was baptised between`;
                description += ` ${DateTime.fromISO(viewModel.dateBaptisedStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.dateBaptisedEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            // Date Fellowshipped
            if (viewModel.dateFellowshippedStart &&
                viewModel.dateFellowshippedEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who received fellowship between`;
                description += ` ${DateTime.fromISO(viewModel.dateFellowshippedStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.dateFellowshippedEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            // Date Resigned
            if (viewModel.dateResignedStart &&
                viewModel.dateResignedEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who resigned between`;
                description += ` ${DateTime.fromISO(viewModel.dateResignedStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.dateResignedEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            // Date Passed Away
            if (viewModel.datePassedAwayStart &&
                viewModel.datePassedAwayEnd) {
                if (useAnd) {
                    description += ' and';
                }
                description += ` who passed away between`;
                description += ` ${DateTime.fromISO(viewModel.datePassedAwayStart).toLocaleString(DateTime.DATE_MED)}`;
                description += ` and ${DateTime.fromISO(viewModel.datePassedAwayEnd).toLocaleString(DateTime.DATE_MED)}`;
            }

            let summary = (description) ? 'Show me ' + description.trimEnd() + '.' : 'Show me ';

            return summary;
        },

        removeTrailing(description: string): string {
            return description.trimEnd().substring(0, description.trimEnd().length - 1);
        }
    }
});