import Axios, { AxiosResponse } from "axios";
import { environment } from "@/environment";
import { ManageValidOptionListViewModel, ManageValidOptionViewModel, PeopleFilterOptionsViewModel } from "@/models/valid-option.model";
import { BaseService } from "./base.service";
import axios from "axios";

export class ValidOptionService extends BaseService {
    constructor() {
        super();
    }

    public getValidOptions(category: string): Promise<AxiosResponse<ManageValidOptionListViewModel>> {
        const url = `${environment.apiUrl}/admin/valid-option?category=${category}`;
        return Axios.get<ManageValidOptionListViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getValidOption(id: number): Promise<AxiosResponse<ManageValidOptionViewModel>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/valid-option/${id}`;
        return Axios.get<ManageValidOptionViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveValidOption(viewModel: ManageValidOptionViewModel): Promise<AxiosResponse<ManageValidOptionViewModel>> {
        const url = `${environment.apiUrl}/admin/valid-option`;
        return Axios.post<ManageValidOptionViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public removeValidOption(id: number): Promise<AxiosResponse<boolean>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/valid-option/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPeopleFilterOptions(): Promise<AxiosResponse<PeopleFilterOptionsViewModel>> {
        const url = `${environment.apiUrl}/admin/valid-option/filters-people`;
        return axios.get<PeopleFilterOptionsViewModel>(url, { headers: this.getAuthenticationHeader() });
    }
}