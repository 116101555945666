var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: { color: "success" },
                  on: { click: _vm.onRefresh },
                },
                [_vm._v("Refresh")]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.hasSelectedItems,
                                  },
                                  on: { click: _vm.onRemoveMultipleItems },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-delete-sweep-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Remove Selected")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.hasItems,
                                  },
                                  on: { click: _vm.onRemoveAllItems },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-delete-empty-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Remove All")])]
              ),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "8" } },
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmptyView,
                      expression: "showEmptyView",
                    },
                  ],
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "4" } }, [
                        _vm._v(
                          " It seems that you there is no media at present. Check for the latest entries by clicking Refresh. "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("lottie-player", {
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: {
                              src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                              background: "transparent",
                              speed: "1",
                              loop: "",
                              autoplay: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-iterator", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showGrid,
                    expression: "showGrid",
                  },
                ],
                attrs: {
                  "item-key": "id",
                  items: _vm.items,
                  "items-per-page": _vm.itemsPerPage,
                  "footer-props": _vm.footerOptions,
                  options: _vm.options,
                  "server-items-length": _vm.totalItems,
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "v-row",
                          _vm._l(props.items, function (item) {
                            return _c(
                              "v-col",
                              {
                                key: item.id,
                                attrs: {
                                  cols: "12",
                                  sm: "6",
                                  md: "4",
                                  lg: "3",
                                },
                              },
                              [
                                _c("v-hover", {
                                  attrs: { "open-delay": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var hover = ref.hover
                                          return [
                                            _c(
                                              "v-card",
                                              {
                                                class: { "on-hover": hover },
                                                attrs: {
                                                  "max-width": "400",
                                                  elevation:
                                                    !_vm.multiSelect && hover
                                                      ? 12
                                                      : 3,
                                                  outlined:
                                                    !_vm.multiSelect && hover,
                                                },
                                              },
                                              [
                                                _c("v-img", {
                                                  class: [
                                                    !_vm.multiSelect
                                                      ? _vm.imageCss
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    "max-height": "240",
                                                    src: item.imageUrl,
                                                    "lazy-src": _vm.placeholder,
                                                    "aspect-ratio": "1",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      !_vm.multiSelect
                                                    },
                                                  },
                                                }),
                                                _c("v-card-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.limitText(
                                                          item.name,
                                                          27
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-card-subtitle",
                                                  { staticClass: "pb-0" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.contentType)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-checkbox", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.multiSelect,
                                                          expression:
                                                            "multiSelect",
                                                        },
                                                      ],
                                                      model: {
                                                        value: item.selected,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selected",
                                                      },
                                                    }),
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onEditItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Edit ")]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onRemoveItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Remove ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteSingleItemDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteSingleItemSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteMultipleItemsDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteMultipleItemsSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteAllItemsDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteAllItemsSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showRefreshConfirmationDialog,
          data: _vm.dialogMessage,
          showYesNo: true,
        },
        on: {
          "dialog-option-selected": _vm.onRefreshOptionSelected,
          "dialog-exit": _vm.onRefreshDialogExit,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }