export const UserFilterColumnName = {
    maritalStatus: "Marital Status",
    ethnicity: "Ethnicity",
    nationality: "Nationality",
    profession: "Profession",
    baptismDate: "Baptism Date",
    contactable: "Contactable",
    email: "Email",
    gender: "Gender",
    age: "Age",
    firstName: "First Name",
    lastName: "Last Name",
    image: "Image",
    fellowshipDate: "Fellowship Date",
    resignationDate: "Resignation Date",
    weddingDate: "Wedding Date",
    datePassed: "Date Passed",
    member: "Member",
    visitor: "Visitor",
    active: "Active",
    online: "Online",
    hasPassed: "Passed",
    dateOfBirth: "Date Of Birth",
    groupName: "Group"
};

export interface ISaveUserFilterColumnSelectionCommand {
    id: number;
    columns: string[];
}

export class PeopleSearchFilter {
    id: number;
    title: string;
    maritalStatus: string[];
    ethnicity: string[];
    profession: string[];
    nationality: string[];
    contactable: string | null;
    gender: string[];
    ageStart: number | null;
    ageEnd: number | null;
    dateOfBaptismStart: Date | null;
    dateOfBaptismEnd: Date | null;
    fellowshipDateStart: Date | null;
    fellowshipDateEnd: Date | null;
    resignationDateStart: Date | null;
    resignationDateEnd: Date | null;
    weddingDateStart: Date | null;
    weddingDateEnd: Date | null;
    datePassedStart: Date | null;
    datePassedEnd: Date | null;
    attributes: string[];
    currentStatus: string[];
    groupId: number | null;

    constructor() {
        this.id = 0;
        this.title = '';
        this.maritalStatus = [];
        this.ethnicity = [];
        this.profession = [];
        this.nationality = [];
        this.contactable = null;
        this.gender = [];
        this.ageStart = null;
        this.ageEnd = null;
        this.dateOfBaptismStart = null;
        this.dateOfBaptismEnd = null;
        this.fellowshipDateStart = null;
        this.fellowshipDateEnd = null;
        this.resignationDateStart = null;
        this.resignationDateEnd = null;
        this.weddingDateStart = null;
        this.weddingDateEnd = null;
        this.datePassedStart = null;
        this.datePassedEnd = null;
        this.attributes = [];
        this.currentStatus = [];
        this.groupId = null;
    }
}