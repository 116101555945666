<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col md="8">
          <editor-group-main-details
            :formData="formData"
            :groupType="groupType"
          >
          </editor-group-main-details>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="2">
          <v-btn color="primary" type="submit" :disabled="!valid" large>
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import EditorGroupMainDetails from "@/views/editors/editor-group-main-details.vue";
import { ManageGroupViewModel } from "@/models/group.model";
import { v4 as uuidv4 } from "uuid";
import { GroupService } from "@/services/group.service";
import { AlertType, GroupType, LoadingType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerGroupsBranch",
  mixins: [CommonMixin],
  components: {
    "editor-group-main-details": EditorGroupMainDetails,
  },
  data() {
    return {
      valid: true,
      branchId: 0,
      groupId: 0,
      formData: new ManageGroupViewModel(),
      groupType: "",
      groupName: "",
    };
  },
  methods: {
    load(branchId: number, groupId: number) {
      this.branchId = branchId;
      this.groupId = groupId;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Information, Please Wait..."
      );

      // Determine the correct id, owner and group
      const id: number = this.groupId ? this.groupId : this.branchId;
      const ownerId: number = this.groupId ? this.branchId : 0;
      this.groupType = this.groupId
        ? GroupType.LocalMinistry
        : GroupType.Branch;

      this.formData.ownerId = ownerId;

      const service = new GroupService();
      service
        .getGroup(id, ownerId, this.groupType)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.groupName = this.formData.name;
          for (let item of this.formData.locations) {
            item.localId = uuidv4();
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Information, Please Wait..."
      );

      const service = new GroupService();
      service
        .saveGroup(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          for (let item of this.formData.locations) {
            item.localId = uuidv4();
          }

          this.$emit(
            "show-alert-panel",
            "The data was saved successfully",
            AlertType.Success
          );

          // Raise an event to notify the caller that the group name has changed
          // This will allow them to gracefully update the name from the old one to the new one.
          let groupNameChanged: boolean = this.groupName !== this.formData.name;
          if (groupNameChanged) {
            this.$emit(
              "group-name-changed",
              this.groupName,
              this.formData.name
            );
          }

          // Store the current name for next time
          this.groupName = this.formData.name;
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>