<template>
  <v-row justify="center">
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <v-dialog v-model="dialog" max-width="20%" persistent>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-card-title class="headline">
            <div>Add New Option</div>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="onCancel()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="viewModel.option"
              label="Option"
              placeholder="Option"
              outlined
              clearable
              hide-details="auto"
              maxlength="80"
              :rules="[rules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onCancel()">Cancel</v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { ValidOptionService } from "@/services/valid-option.service";
import {
  ManageValidOptionViewModel,
  ValidOptionListItem,
} from "@/models/valid-option.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EditorValidOptions",
  mixins: [CommonMixin],
  props: {
    dialog: Boolean,
    data: ManageValidOptionViewModel,
  },
  data() {
    return {
      valid: false,
      viewModel: new ManageValidOptionViewModel(),
    };
  },
  watch: {
    data(val: ManageValidOptionViewModel) {
      this.viewModel = val;
    },
  },
  methods: {
    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Group Information, Please Wait..."
      );

      const service = new ValidOptionService();
      service
        .saveValidOption(this.viewModel)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;

          let newOption = new ValidOptionListItem();
          newOption.id = this.viewModel.id;
          newOption.category = this.viewModel.category;
          newOption.option = this.viewModel.option;

          this.$emit("valid-option-dialog-actioned", newOption);
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onCancel() {
      this.$emit("valid-option-dialog-actioned");
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>