var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.members,
      loading: _vm.isMatching,
      "search-input": _vm.matching,
      "item-text": "name",
      "item-value": "id",
      outlined: _vm.outlined,
      clearable: _vm.clearable,
      filled: _vm.filled,
      label: _vm.label,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      "menu-props": "auto",
      "hide-details": "auto",
      "return-object": "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.matching = $event
      },
      "update:search-input": function ($event) {
        _vm.matching = $event
      },
      "click:clear": _vm.onCleared,
    },
    model: {
      value: _vm.selectedItem,
      callback: function ($$v) {
        _vm.selectedItem = $$v
      },
      expression: "selectedItem",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }