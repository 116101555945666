
import Axios, { AxiosResponse } from "axios";
import { BaseService } from "./base.service";
import { environment } from "@/environment";
import {
  EventLetter,
  EventListItem,
  EventViewModel,
  IImageListItem,
  IPatchEventCommand,
  IRemoveSelectedGalleryImagesCommand,
  ISaveEventLetterCommand,
  IUpdateImageCommand,
  IUploadImagesCommand,
} from "@/models/events.model";
import { DateTime } from "luxon";
import { IPagedList } from "@/models/common.model";

export class EventsService extends BaseService {
  constructor() {
    super();
  }

  public getEvents(ownerId: number, start: DateTime, end: DateTime): Promise<AxiosResponse<EventListItem[]>> {
    const url = `${environment.apiUrl}/admin/event?ownerId=${ownerId}&startRange=${start}&endRange=${end}`;
    return Axios.get<EventListItem[]>(url, { headers: this.getAuthenticationHeader() });
  }

  public getEvent(id: number): Promise<AxiosResponse<EventViewModel>> {
    const url = `${environment.apiUrl}/admin/event/${id}`;
    return Axios.get<EventViewModel>(url, { headers: this.getAuthenticationHeader(), });
  }

  public getEventItem(id: number): Promise<AxiosResponse<EventListItem>> {
    const url = `${environment.apiUrl}/admin/event/${id}/list-item`;
    return Axios.get<EventListItem>(url, { headers: this.getAuthenticationHeader(), });
  }

  public getImageGallery(id: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<IImageListItem>>> {
    const url = `${environment.apiUrl}/admin/event/${id}/image-gallery?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
    return Axios.get<IPagedList<IImageListItem>>(url, { headers: this.getAuthenticationHeader() });
  }

  public saveEvent(entity: EventViewModel): Promise<AxiosResponse<EventViewModel>> {
    const url = `${environment.apiUrl}/admin/event`;
    return Axios.post<EventViewModel>(url, entity, { headers: this.getAuthenticationHeader() });
  }

  public saveEventItem(entity: EventListItem): Promise<AxiosResponse<EventListItem>> {
    const url = `${environment.apiUrl}/admin/event/list-item`;
    return Axios.post<EventListItem>(url, entity, { headers: this.getAuthenticationHeader(), });
  }

  public uploadImages(id: number, images: string[]): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${id}/image-gallery`;
    let request: IUploadImagesCommand = {
      id: id,
      imageUrls: images
    };
    return Axios.post<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public updateImage(id: number, imageUrl: string): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${id}/image`;
    let request: IUpdateImageCommand = {
      id: id,
      imageUrl: imageUrl
    };
    return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public cancelEvent(id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/cancel`;
    let request: IPatchEventCommand = {
      id: id
    };
    return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public unCancelEvent(id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/un-cancel`;
    let request: IPatchEventCommand = {
      id: id
    };
    return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public publishEvent(id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/publish`;
    let request: IPatchEventCommand = {
      id: id
    };
    return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public unPublishEvent(id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/un-publish`;
    let request: IPatchEventCommand = {
      id: id
    };
    return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public removeEvent(id?: number): Promise<AxiosResponse<boolean>> {
    if (!id) {
      id = 0;
    }
    const url = `${environment.apiUrl}/admin/event/${id}`;
    return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader(), });
  }

  public removeGalleryImage(eventId: number, id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${eventId}/image-gallery/${id}`;
    return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader(), });
  }

  public removeSelectedGalleryImages(id: number, images: number[]): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${id}/image-gallery`;
    let request: IRemoveSelectedGalleryImagesCommand = {
      id: id,
      imageIds: images
    };
    return Axios.put<boolean>(url, request, { headers: this.getAuthenticationHeader() });
  }

  public removeAllGalleryImages(id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${id}/image-gallery/all`;
    return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader(), });
  }

  public saveLetter(eventId: number, entity: EventLetter): Promise<AxiosResponse<EventLetter>> {
    const url = `${environment.apiUrl}/admin/event/${eventId}/letter`;
    var body: ISaveEventLetterCommand = {
      id: eventId,
      letter: entity
    };
    return Axios.post<EventLetter>(url, body, { headers: this.getAuthenticationHeader(), });
  }

  public removeLetter(eventId: number, id: number): Promise<AxiosResponse<boolean>> {
    const url = `${environment.apiUrl}/admin/event/${eventId}/letter/${id}`;
    return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader(), });
  }
}
