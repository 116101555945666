<template>
  <v-container id="main-container">
    <v-row>
      <progress-indicator
        :showProgressBar="showProgressBar"
        :showSpinner="showSpinner"
        :message="progressMessage"
      >
      </progress-indicator>
    </v-row>
    <v-row v-if="!user.authenticated && !user.authenticating">
      <div class="col-md-12 mt-5">
        <v-row>
          <section class="page-header">
            <h5 class="wg-title">Sorry we can't let you in!</h5>
            <p class="small" style="margin-bottom: 0">
              Unfortunately you have not been recognised as an administrator of
              the system. Please contact the system administrator for
              assistance.
            </p>
          </section>
        </v-row>
        <div style="height: 40px" />
        <v-row>
          <v-btn color="primary" @click="onCloseClicked">Close</v-btn>
        </v-row>
      </div>
    </v-row>
    <v-row v-if="user.authenticated && !user.authenticating">
      <div class="col-md-6 mt-5">
        <section class="page-header">
          <h5 class="wg-title">Home</h5>
          <p class="medium" style="margin-bottom: 0">
            Welcome to the COG7D Church Management System!
          </p>
        </section>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-card class="wg-card-shadow">
          <v-img max-height="512" max-width="512" :src="imageUrl"></v-img>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { mapGetters } from "vuex";
import CommonMixin from "@/mixins/common.mixin";
import { IUser } from "@/models/security.model";
import { LoadingType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "landing",
  mixins: [CommonMixin],
  components: {},
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user: {
      deep: true,
      handler(val: IUser) {
        if (val.authenticated) {
          this.hideProgressIndicator();
        } else if (!val.authenticating && !val.authenticated) {
          this.hideProgressIndicator();
        }
      },
    },
  },
  data() {
    return {
      imageUrl: require("@/assets/images/COG7-logo.png"),
    };
  },
  mounted() {
    if ((this.user && !this.user.authenticated) || this.user.authenticating) {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Authenticating user and determining permissions. Please Wait...`
      );
    }
  },
  methods: {
    onCloseClicked() {
      this.$router.replace({ name: "login" });
    },
  },
});
</script>