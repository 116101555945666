var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.onNewItem } },
                [_vm._v("New Poll")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmptyView,
                      expression: "showEmptyView",
                    },
                  ],
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "4" } }, [
                        _vm._v(
                          " It seems that you have not created a poll yet. Create your first one by clicking the New Poll. "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("lottie-player", {
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: {
                              src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                              background: "transparent",
                              speed: "1",
                              loop: "",
                              autoplay: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showGrid,
                      expression: "showGrid",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(" Polls "),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  clearable: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      "item-key": "id",
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "single-select": false,
                      "show-select": _vm.showMultiSelect,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerOptions,
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.startDate",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDate(item.startDate)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.endDate",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatDate(item.endDate)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "success", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onEditItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-pencil "),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-delete "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedItems,
                      callback: function ($$v) {
                        _vm.selectedItems = $$v
                      },
                      expression: "selectedItems",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }