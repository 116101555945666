<template>
  <v-container id="main-container">
    <h1>Hello World</h1>
  </v-container>
</template>

<script lang="ts">
import { DialogMessage, IProblemDetails } from "@/models/common.model";
import { ConferenceService } from "@/services/conference.service";
import Vue from "vue";

export default Vue.extend({
  name: "authredirect",
  data() {
    return {
      showDialog: false,
      dialogMessage: new DialogMessage("", ""),
    };
  },
  created() {
    this.storeAuthToken(this.$route.query.code.toString());
  },
  methods: {
    storeAuthToken(code: string) {
      const service = new ConferenceService();
      service
        .storeAuthorisationToken("instagram", code)
        .then(() => {
          this.$router.replace("settings");
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showErrorDialog(error: any) {
      const data = error.response.data;
      const myItem: IProblemDetails = {
        type: data.type ?? "Error",
        title: data.title ?? "Error: Something has gone wrong!",
        status: data.status,
        detail:
          data.detail ??
          "We are unable to determine the problem, please try again later.",
        instance: data.instance,
        errors: null,
      };
      this.dialogMessage = new DialogMessage(myItem.title!, myItem.detail!);
      this.showDialog = true;
    },
  },
});
</script>
