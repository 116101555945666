<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">5</span>Dates</h6>
        <p class="small" style="padding-right: 40px">
          This section is to specify any significant dates for the person.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfBaptism"
              :dateRules="validateDateOfBaptism"
              :disabled="!canViewSensitiveData"
              label="Baptism Date"
              @date-changed="baptismDateChanged"
            ></date-picker>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfFellowship"
              :dateRules="validateDateOfFellowship"
              :disabled="!canViewSensitiveData"
              label="Fellowship Date"
            ></date-picker>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfResignation"
              :dateRules="validateDateOfResignation"
              :disabled="!canViewSensitiveData"
              label="Resignation Date"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfWedding"
              :dateRules="validateDateOfWedding"
              :disabled="!canViewSensitiveData"
              label="Wedding Date"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfPassing"
              :dateRules="validateDateOfPassing"
              :disabled="!canViewSensitiveData"
              label="Date Passed"
            ></date-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import { DateTime } from "luxon";
import { ManageUserViewModel } from "@/models/security.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "PeopleDatesSection",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
  },
  props: {
    id: Number,
    ownerId: Number,
    canViewSensitiveData: Boolean,
    formData: {
      type: Object,
      default: () => new ManageUserViewModel(),
      required: true,
    },
  },
  computed: {
    validateDateOfBaptism(): string[] {
      let err: string[] = [];
      if (this.formData.dateOfBaptism) {
        let dateOfBaptism = DateTime.fromISO(
          this.formData.dateOfBaptism.toString()
        );
        if (dateOfBaptism > DateTime.now()) {
          err.push("Date of baptism cannot be in the future.");
        }

        if (this.formData.dateOfBirth) {
          let dob = DateTime.fromISO(this.formData.dateOfBirth.toString());
          if (dateOfBaptism < dob) {
            err.push("Baptism date cannot be before the date of birth.");
          }
        }

        if (this.formData.dateOfFellowship) {
          let dof = DateTime.fromISO(this.formData.dateOfFellowship.toString());
          if (dateOfBaptism > dof) {
            err.push("Baptism date cannot be after the fellowship date.");
          }
        }

        if (this.formData.dateOfPassing) {
          let dop = DateTime.fromISO(this.formData.dateOfPassing.toString());
          if (dateOfBaptism > dop) {
            err.push(
              "Baptism date cannot be after the date the person passed away."
            );
          }
        }

        if (this.formData.dateOfResignation) {
          let dor = DateTime.fromISO(
            this.formData.dateOfResignation.toString()
          );
          if (dateOfBaptism > dor) {
            err.push(
              "Baptism date cannot be after the date the person resigned."
            );
          }
        }
      }
      return err;
    },
    validateDateOfFellowship(): string[] {
      let err: string[] = [];
      if (this.formData.dateOfFellowship) {
        let dateOfFellowship = DateTime.fromISO(
          this.formData.dateOfFellowship.toString()
        );
        if (dateOfFellowship > DateTime.now()) {
          err.push("Date of fellowship cannot be in the future.");
        }

        if (this.formData.dateOfBirth) {
          let dob = DateTime.fromISO(this.formData.dateOfBirth.toString());
          if (dateOfFellowship < dob) {
            err.push("Baptism date cannot be before the date of birth.");
          }
        }

        if (this.formData.dateOfBaptism) {
          let dateOfBaptism = DateTime.fromISO(
            this.formData.dateOfBaptism.toString()
          );
          if (dateOfFellowship < dateOfBaptism) {
            err.push("Fellowship date cannot be before the baptism date.");
          }
        }

        if (this.formData.dateOfPassing) {
          let dop = DateTime.fromISO(this.formData.dateOfPassing.toString());
          if (dateOfFellowship > dop) {
            err.push(
              "Fellowship date cannot be after the date the person passed away."
            );
          }
        }
      }
      return err;
    },
    validateDateOfPassing(): string[] {
      let err: string[] = [];
      if (this.formData.dateOfPassing) {
        let dateOfPassing = DateTime.fromISO(
          this.formData.dateOfPassing.toString()
        );
        if (dateOfPassing > DateTime.now()) {
          err.push("Date of passing cannot be in the future.");
        }

        if (this.formData.dateOfBirth) {
          let dob = DateTime.fromISO(this.formData.dateOfBirth.toString());
          if (dateOfPassing < dob) {
            err.push("Date of passing cannot be before the date of birth.");
          }
        }

        if (this.formData.dateOfBaptism) {
          let dateOfBaptism = DateTime.fromISO(
            this.formData.dateOfBaptism.toString()
          );
          if (dateOfPassing < dateOfBaptism) {
            err.push("Date of passing cannot be before the baptism date.");
          }
        }

        if (this.formData.dateOfFellowship) {
          let dateOfFellowship = DateTime.fromISO(
            this.formData.dateOfFellowship.toString()
          );
          if (dateOfPassing < dateOfFellowship) {
            err.push("Date of passing cannot be before the fellowship date.");
          }
        }
      }
      return err;
    },
    validateDateOfWedding(): string[] {
      let err: string[] = [];
      if (this.formData.dateOfWedding) {
        let dateOfWedding = DateTime.fromISO(
          this.formData.dateOfWedding.toString()
        );
        if (dateOfWedding > DateTime.now()) {
          err.push("Date of wedding cannot be in the future.");
        }

        if (this.formData.dateOfBirth) {
          let dob = DateTime.fromISO(this.formData.dateOfBirth.toString());
          if (dateOfWedding < dob) {
            err.push("Date of wedding cannot be before the date of birth.");
          }
        }

        if (this.formData.dateOfPassing) {
          let dateOfPassing = DateTime.fromISO(
            this.formData.dateOfPassing.toString()
          );
          if (dateOfWedding > dateOfPassing) {
            err.push(
              "Date of wedding cannot be after the date the person passed away."
            );
          }
        }
      }
      return err;
    },
    validateDateOfResignation(): string[] {
      let err: string[] = [];
      if (this.formData.dateOfResignation) {
        let dateOfResignation = DateTime.fromISO(
          this.formData.dateOfResignation.toString()
        );
        if (dateOfResignation > DateTime.now()) {
          err.push("Resignation date cannot be in the future.");
        }

        if (this.formData.dateOfBirth) {
          let dob = DateTime.fromISO(this.formData.dateOfBirth.toString());
          if (dateOfResignation < dob) {
            err.push("Resignation date cannot be before the date of birth.");
          }
        }

        if (this.formData.dateOfFellowship) {
          let dof = DateTime.fromISO(this.formData.dateOfFellowship.toString());
          if (dateOfResignation < dof) {
            err.push("Resignation date cannot be before the fellowship date.");
          }
        }

        if (this.formData.dateOfPassing) {
          let dop = DateTime.fromISO(this.formData.dateOfPassing.toString());
          if (dateOfResignation > dop) {
            err.push(
              "Resignation date cannot be after the date the person passed away."
            );
          }
        }

        if (this.formData.dateOfBaptism) {
          let dateOfBaptism = DateTime.fromISO(
            this.formData.dateOfBaptism.toString()
          );
          if (dateOfResignation < dateOfBaptism) {
            err.push("Resignation date cannot be before the baptism date.");
          }
        }
      }
      return err;
    },
  },
  methods: {
    baptismDateChanged() {
      if (!this.formData.dateOfFellowship) {
        this.formData.dateOfFellowship = this.formData.dateOfBaptism;
      }
    },
  },
});
</script>