<template>
  <v-dialog v-model="dialog" max-width="800" height="480" persistent>
    <v-form ref="recurrence-form" v-model="valid" @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          Event Recurrence
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col md="2">
              <v-radio-group
                v-model="intervalOption"
                @change="onIntervalChanged"
              >
                <v-radio
                  v-for="interval in intervals"
                  :key="interval.id"
                  :label="interval.title"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="9">
              <!-- Daily -->
              <div v-if="intervalOption == 0">
                <v-list>
                  <v-radio-group v-model="dayOption">
                    <v-list-item v-for="item in dayOptions" :key="item.id">
                      <v-list-item-content>
                        <v-row v-if="item.id == 0">
                          <v-col md="4">
                            <v-radio :label="item.title"></v-radio>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.id == 1">
                          <v-col md="2">
                            <v-radio :label="item.title"></v-radio>
                          </v-col>
                          <v-col md="3">
                            <v-text-field
                              outlined
                              dense
                              v-model="data.recurrenceInterval"
                              :disabled="dayOption == 0"
                              :rules="dayIntervalRules"
                            ></v-text-field>
                          </v-col>
                          <v-col md="3"> day(s) </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-radio-group>
                </v-list>
              </div>

              <!-- Weekly -->
              <div v-if="intervalOption == 1">
                <v-row>
                  <v-col md="3"> Recur every </v-col>
                  <v-col md="3">
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      v-model="data.recurrenceInterval"
                      :rules="weekRules"
                    ></v-text-field>
                  </v-col>
                  <v-col> week(s) on: </v-col>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-checkbox
                          v-model="data.recurrenceMonday"
                          label="Monday"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="data.recurrenceThursday"
                          label="Thursday"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="data.recurrenceSunday"
                          label="Sunday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-checkbox
                          v-model="data.recurrenceTuesday"
                          label="Tuesday"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="data.recurrenceFriday"
                          label="Friday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-checkbox
                          v-model="data.recurrenceWednesday"
                          label="Wednesday"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="data.recurrenceSaturday"
                          label="Saturday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </div>

              <!-- Monthly -->
              <div v-if="intervalOption == 2">
                <v-list>
                  <v-radio-group
                    v-model="monthOption"
                    @change="onMonthIntervalChanged"
                  >
                    <v-list-item v-for="item in monthOptions" :key="item.id">
                      <v-list-item-content>
                        <v-row v-if="item.id == 0">
                          <v-col md="2">
                            <v-radio :label="item.title"></v-radio>
                          </v-col>
                          <v-col md="2">
                            <v-text-field
                              outlined
                              dense
                              v-model="data.recurrenceDayOfMonth"
                              :disabled="monthOption == 1"
                              :rules="monthDayRules"
                            ></v-text-field>
                          </v-col>
                          <v-col md="2"> of every </v-col>
                          <v-col md="2">
                            <v-text-field
                              outlined
                              dense
                              v-model="data.recurrenceMonthOfYear"
                              :disabled="monthOption == 1"
                            ></v-text-field>
                          </v-col>
                          <v-col md="2"> month(s) </v-col>
                        </v-row>

                        <v-row v-if="item.id == 1">
                          <v-col md="2">
                            <v-radio :label="item.title"></v-radio>
                          </v-col>
                          <v-col md="4">
                            <v-select
                              outlined
                              v-model="instance"
                              :items="instances"
                              return-object
                              menu-props="auto"
                              hide-details
                              dense
                              :disabled="monthOption == 0"
                              :rules="monthInstanceRules"
                            ></v-select>
                            <div style="height: 12px" />
                            <v-select
                              outlined
                              v-model="day"
                              :items="days"
                              return-object
                              menu-props="auto"
                              hide-details
                              dense
                              :disabled="monthOption == 0"
                            ></v-select>
                          </v-col>
                          <v-col md="2"> of every </v-col>
                          <v-col md="2">
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              v-model="data.recurrenceMonthOfYear"
                              :disabled="monthOption == 0"
                            ></v-text-field>
                          </v-col>
                          <v-col md="2"> month(s) </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-radio-group>
                </v-list>
              </div>

              <!-- Yearly -->
              <div v-if="intervalOption == 3">
                <v-row>
                  <v-col md="3">Recur every</v-col>
                  <v-col md="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="data.recurrenceInterval"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2"> year(s) </v-col>
                </v-row>
                <v-row>
                  <v-list>
                    <v-radio-group
                      v-model="yearOption"
                      @change="onYearIntervalChanged"
                    >
                      <v-list-item v-for="item in yearOptions" :key="item.id">
                        <v-list-item-content>
                          <v-row v-if="item.id == 0">
                            <v-col md="3">
                              <v-radio :label="item.title"></v-radio>
                            </v-col>
                            <v-col md="3">
                              <v-select
                                outlined
                                v-model="month"
                                :items="months"
                                return-object
                                menu-props="auto"
                                hide-details
                                dense
                                :disabled="yearOption == 1"
                              ></v-select>
                            </v-col>
                            <v-col md="2">
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="data.recurrenceDayOfMonth"
                                :disabled="yearOption == 1"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="item.id == 1">
                            <v-col md="3">
                              <v-radio :label="item.title"></v-radio>
                            </v-col>
                            <v-col md="3">
                              <v-select
                                outlined
                                v-model="instance"
                                :items="instances"
                                return-object
                                menu-props="auto"
                                hide-details
                                dense
                                :disabled="yearOption == 0"
                              ></v-select>
                            </v-col>
                            <v-col md="3">
                              <v-select
                                outlined
                                v-model="day"
                                :items="days"
                                return-object
                                menu-props="auto"
                                hide-details
                                dense
                                :disabled="yearOption == 0"
                              ></v-select>
                            </v-col>
                            <v-col md="3">
                              <v-select
                                outlined
                                v-model="month"
                                :items="months"
                                return-object
                                menu-props="auto"
                                hide-details
                                dense
                                :disabled="yearOption == 0"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-radio-group>
                  </v-list>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="9">
              <date-time-picker
                :dateTime.sync="startDateTimeDisplay"
                :showDateHeader="false"
                :dateRules="validateEventDate"
                :timeRules="validateEventTime"
                dateLabel="Start Date"
                @date-time-changed="startDateChanged"
              >
              </date-time-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="9">
              <date-time-picker
                :dateTime.sync="endDateTimeDisplay"
                :showDateHeader="false"
                dateLabel="End Date"
                @date-time-changed="endDateChanged"
              >
              </date-time-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
          <v-btn color="primary" text @click="onRemove"> Remove </v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { RecurrenceType } from "@/models/common.model";

import CommonMixin from "@/mixins/common.mixin";
import DateTimePicker from "@/components/common/date-time-picker.vue";

import { DateTime } from "luxon";
import { EventViewModel } from "@/models/events.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventRecurrenceDialog",
  mixins: [CommonMixin],
  components: {
    "date-time-picker": DateTimePicker,
  },
  props: {
    dialog: Boolean,
    data: Object,
  },
  data() {
    return {
      valid: true,
      intervalOption: 0,
      intervals: [
        { id: 0, title: "Daily" },
        { id: 1, title: "Weekly" },
        { id: 2, title: "Monthly" },
        { id: 3, title: "Yearly" },
      ],
      dayOption: 0,
      dayOptions: [
        { id: 0, title: "Every weekday" },
        { id: 1, title: "Every" },
      ],
      monthOption: 0,
      monthOptions: [
        { id: 0, title: "Day" },
        { id: 1, title: "The" },
      ],
      yearOption: 0,
      yearOptions: [
        { id: 0, title: "On:" },
        { id: 1, title: "On the:" },
      ],
      instance: "first",
      instances: ["first", "second", "third", "fourth"],
      day: "Monday",
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      month: "January",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      startDateTimeDisplay: "",
      endDateTimeDisplay: "",
    };
  },

  computed: {
    validateEventDate() {
      let rules = [];

      let start = DateTime.fromISO(this.startDateTimeDisplay);
      let end = DateTime.fromISO(this.endDateTimeDisplay);

      const diff: number = start.diff(end, "days").days;
      if (diff > 0) {
        rules.push("Start date cannot be greater than the end date");
      }

      return rules;
    },
    validateEventTime() {
      let rules = [];
      let start = DateTime.fromISO(this.startDateTimeDisplay);
      let end = DateTime.fromISO(this.endDateTimeDisplay);

      const diff: number = start.diff(end, "minutes").minutes;
      if (diff > 0) {
        rules.push("Start time cannot be greater than the end time");
      }

      return rules;
    },
    dayIntervalRules() {
      let intRules = [];
      if (this.dayOption === 1) {
        intRules.push(
          this.data.recurrenceInterval > 0 || "Must be 1 or more days"
        );
      }
      return intRules;
    },
    weekRules() {
      let intRules = [];
      intRules.push(
        this.data.recurrenceInterval > 0 || "Must be 1 or more weeks"
      );

      intRules.push(
        this.data.recurrenceSunday ||
          this.data.recurrenceMonday ||
          this.data.recurrenceTuesday ||
          this.data.recurrenceWednesday ||
          this.data.recurrenceThursday ||
          this.data.recurrenceFriday ||
          this.data.recurrenceSaturday ||
          "At least one day must be selected"
      );

      return intRules;
    },
    monthDayRules() {
      let intRules = [];
      if (this.monthOption === 0) {
        intRules.push(
          (this.data.recurrenceDayOfMonth >= 1 &&
            this.data.recurrenceDayOfMonth <= 31) ||
            "Day must be between 1 and 31"
        );
        intRules.push(
          (this.data.recurrenceMonthOfYear >= 1 &&
            this.data.recurrenceMonthOfYear <= 12) ||
            "Month must be between 1 and 12"
        );
      }
      return intRules;
    },
    monthInstanceRules() {
      let intRules = [];
      if (this.monthOption == 1) {
        intRules.push(
          (this.data.recurrenceDayOfMonth >= 1 &&
            this.data.recurrenceDayOfMonth <= 31) ||
            "Day must be between 1 and 31"
        );
      }
      return intRules;
    },
  },

  watch: {
    data(val: EventViewModel) {
      if (val.recurrenceStartDate) {
        this.startDateTimeDisplay = val.recurrenceStartDate.toString();
      } else {
        this.startDateTimeDisplay = val.startDateTime.toString();
        val.recurrenceStartDate = val.startDateTime;
      }
      if (val.recurrenceEndDate) {
        this.endDateTimeDisplay = val.recurrenceEndDate.toString();
      }

      if (!val.isRecurring) {
        // Default to daily on entry so that when we save it goes down the right path
        val.recurrenceType = RecurrenceType.Daily;
      } else {
        switch (val.recurrenceType) {
          case RecurrenceType.Daily:
            this.intervalOption = 0;
            this.dayOption =
              val.recurrenceInterval === 1 &&
              val.recurrenceSaturday === false &&
              val.recurrenceSunday === false
                ? 0
                : 1;
            break;
          case RecurrenceType.Weekly:
            this.intervalOption = 1;
            break;
          case RecurrenceType.Monthly:
            this.intervalOption = 2;
            this.monthOption = 0;
            break;
          case RecurrenceType.MonthNth:
            this.intervalOption = 2;
            this.monthOption = 1;
            this.setRecurrenceInstanceview(val);
            this.setDayInstanceview(val);
            break;
          case RecurrenceType.Yearly:
            this.intervalOption = 3;
            this.yearOption = 1;
            break;
          case RecurrenceType.YearNth:
            this.intervalOption = 3;
            this.yearOption = 2;
            this.setRecurrenceInstanceview(val);
            this.setDayInstanceview(val);
            this.setMonthInstanceview(val);
            break;
        }
      }
    },
  },

  methods: {
    startDateChanged(val: any) {
      this.data.recurrenceStartDate = val;
    },

    endDateChanged(val: any) {
      this.data.recurrenceEndDate = val;
    },

    resetRecurrenceForIntervalChange() {
      let vm = this.data as EventViewModel;

      vm.recurrenceInterval = 0;
      vm.recurrenceInstance = 0;
      vm.recurrenceDayOfMonth = 0;
      vm.recurrenceMonthOfYear = 0;
      vm.recurrenceSunday = false;
      vm.recurrenceMonday = false;
      vm.recurrenceTuesday = false;
      vm.recurrenceWednesday = false;
      vm.recurrenceThursday = false;
      vm.recurrenceFriday = false;
      vm.recurrenceSaturday = false;
      vm.recurrenceSunday = false;
    },

    onIntervalChanged(value: number) {
      this.resetRecurrenceForIntervalChange();
      switch (value) {
        case 0:
          this.data.recurrenceType = RecurrenceType.Daily;
          break;
        case 1:
          this.data.recurrenceType = RecurrenceType.Weekly;
          break;
        case 2:
          this.data.recurrenceType = RecurrenceType.Monthly;
          break;
        case 3:
          this.data.recurrenceType = RecurrenceType.Yearly;
          break;
      }
    },

    onMonthIntervalChanged(value: number) {
      this.resetRecurrenceForIntervalChange();
      this.data.recurrenceType =
        value == 1 ? RecurrenceType.MonthNth : RecurrenceType.Monthly;
    },

    onYearIntervalChanged(value: number) {
      this.resetRecurrenceForIntervalChange();
      this.data.recurrenceType =
        value == 1 ? RecurrenceType.YearNth : RecurrenceType.Yearly;
    },

    onCancel() {
      this.$emit("dialog-option-selected", false);
    },

    onRemove() {
      this.resetRecurrenceForIntervalChange();
      this.intervalOption = 0;
      this.dayOption = 0;
      this.data.isRecurring = false;
      this.data.recurrenceStartDate = undefined;
      this.data.recurrenceEndDate = undefined;

      this.startDateTimeDisplay = "";
      this.endDateTimeDisplay = "";

      this.$emit("dialog-option-selected", true);
    },

    submitForm() {
      let vm = this.data as EventViewModel;

      vm.isRecurring = true;

      // Bind values that are not bound to a control for the recurrence type
      switch (vm.recurrenceType) {
        case RecurrenceType.Daily:
          if (this.dayOption === 0) {
            vm.recurrenceInterval = 1;
            vm.recurrenceSunday = true;
            vm.recurrenceMonday = true;
            vm.recurrenceTuesday = true;
            vm.recurrenceWednesday = true;
            vm.recurrenceThursday = true;
            vm.recurrenceFriday = true;
            vm.recurrenceSaturday = false;
            vm.recurrenceSunday = false;
          }
          break;
        case RecurrenceType.MonthNth:
          if (this.monthOption === 1) {
            this.setRecurrenceInstancevm(vm);
            this.setDayInstancevm(vm);
          }
          break;
        case RecurrenceType.Yearly:
          this.setMonthInstancevm(vm);
          break;
        case RecurrenceType.YearNth:
          this.setRecurrenceInstancevm(vm);
          this.setDayInstancevm(vm);
          this.setMonthInstancevm(vm);
          break;
      }

      this.$emit("dialog-option-selected", true);
    },

    setRecurrenceInstancevm(vm: EventViewModel) {
      switch (this.instance) {
        case "first":
          vm.recurrenceInstance = 1;
          break;
        case "second":
          vm.recurrenceInstance = 2;
          break;
        case "third":
          vm.recurrenceInstance = 3;
          break;
        case "fourth":
          vm.recurrenceInstance = 4;
          break;
      }
    },

    setRecurrenceInstanceview(val: EventViewModel) {
      switch (val.recurrenceInstance) {
        case 1:
          this.instance = "first";
          break;
        case 2:
          this.instance = "second";
          break;
        case 3:
          this.instance = "third";
          break;
        case 4:
          this.instance = "fourth";
          break;
      }
    },

    setDayInstancevm(vm: EventViewModel) {
      switch (this.day) {
        case "Monday":
          vm.recurrenceMonday = true;
          break;
        case "Tuesday":
          vm.recurrenceTuesday = true;
          break;
        case "Wednesday":
          vm.recurrenceWednesday = true;
          break;
        case "Thursday":
          vm.recurrenceThursday = true;
          break;
        case "Friday":
          vm.recurrenceFriday = true;
          break;
        case "Saturday":
          vm.recurrenceSaturday = true;
          break;
        case "Sunday":
          vm.recurrenceSunday = true;
          break;
      }
    },

    setDayInstanceview(val: EventViewModel) {
      if (val.recurrenceMonday) this.day = "Monday";
      else if (val.recurrenceTuesday) this.day = "Tuesday";
      else if (val.recurrenceWednesday) this.day = "Wednesday";
      else if (val.recurrenceThursday) this.day = "Thursday";
      else if (val.recurrenceFriday) this.day = "Friday";
      else if (val.recurrenceSaturday) this.day = "Saturday";
      else if (val.recurrenceSunday) this.day = "Sunday";
    },

    setMonthInstancevm(vm: EventViewModel) {
      switch (this.month) {
        case "January":
          vm.recurrenceMonthOfYear = 1;
          break;
        case "February":
          vm.recurrenceMonthOfYear = 2;
          break;
        case "March":
          vm.recurrenceMonthOfYear = 3;
          break;
        case "April":
          vm.recurrenceMonthOfYear = 4;
          break;
        case "May":
          vm.recurrenceMonthOfYear = 5;
          break;
        case "June":
          vm.recurrenceMonthOfYear = 6;
          break;
        case "July":
          vm.recurrenceMonthOfYear = 7;
          break;
        case "August":
          vm.recurrenceMonthOfYear = 8;
          break;
        case "September":
          vm.recurrenceMonthOfYear = 9;
          break;
        case "October":
          vm.recurrenceMonthOfYear = 10;
          break;
        case "November":
          vm.recurrenceMonthOfYear = 11;
          break;
        case "December":
          vm.recurrenceMonthOfYear = 12;
          break;
      }
    },

    setMonthInstanceview(val: EventViewModel) {
      switch (val.recurrenceMonthOfYear) {
        case 1:
          this.month = "January";
          break;
        case 2:
          this.month = "February";
          break;
        case 3:
          this.month = "March";
          break;
        case 4:
          this.month = "April";
          break;
        case 5:
          this.month = "May";
          break;
        case 6:
          this.month = "June";
          break;
        case 7:
          this.month = "July";
          break;
        case 8:
          this.month = "August";
          break;
        case 9:
          this.month = "September";
          break;
        case 10:
          this.month = "October";
          break;
        case 11:
          this.month = "November";
          break;
        case 12:
          this.month = "December";
          break;
      }
    },
  },
});
</script>
