<template>
  <v-menu
    v-model="showDialog"
    :close-on-click="false"
    :close-on-content-click="false"
    :activator="selectedElement"
    :nudge-left="nudgeLeft"
    :max-width="maxEventInfoDialogWidth"
    rounded="lg"
    transition="slide-x-transition"
    offset-x
    left
  >
    <v-card :width="maxEventInfoDialogWidth" min-height="180px" elevation="5">
      <v-toolbar flat dense elevation="1" color="#E5E3E3">
        <!--
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <span>Options</span>
        </v-tooltip>
        -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="showEditEventScreen()">
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
          <span>Edit event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="cancelEvent()"
              v-show="!eventIsCancelled"
            >
              <v-icon> mdi-calendar-remove </v-icon>
            </v-btn>
          </template>
          <span>Cancel event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="unCancelEvent()"
              v-show="eventIsCancelled"
            >
              <v-icon> mdi-calendar </v-icon>
            </v-btn>
          </template>
          <span>Un-Cancel event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="publishEvent()"
              v-show="!isPublished"
            >
              <v-icon> mdi-publish </v-icon>
            </v-btn>
          </template>
          <span>Publish event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="unPublishEvent()"
              v-show="isPublished"
            >
              <v-icon> mdi-publish-off </v-icon>
            </v-btn>
          </template>
          <span>Un-Publish event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="notifyEvent()"
              v-show="isPublished"
            >
              <v-icon> mdi-bell-ring </v-icon>
            </v-btn>
          </template>
          <span>Notify event</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="deleteEvent()">
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </template>
          <span>Delete event</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="closeEventInfoDialog()"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-title>
        <span v-html="name"></span>
      </v-card-title>
      <v-card-subtitle>
        <span v-html="rangeString"></span>
      </v-card-subtitle>
      <v-card-text>
        <span v-html="summary"></span>
        <span
          ><a :href="url" target="_blank">{{ location }}</a></span
        >
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { ICalendarEvent } from "@/models/events.model";
import Vue from "vue";
export default Vue.extend({
  name: "EventInfoPopup",
  props: {
    selectedEventInfoDialog: Boolean,
    nudgeLeft: Number,
    selectedElement: HTMLElement,
    selectedEvent: Object,
    maxEventInfoDialogWidth: Number,
  },
  data: () => ({
    showDialog: false,
    name: "",
    rangeString: "",
    summary: "",
    location: "",
    url: "",
    isCancelled: false,
    isPublished: false,
  }),
  computed: {
    eventIsCancelled(): boolean {
      return this.isCancelled;
    },
  },
  watch: {
    selectedEventInfoDialog(val) {
      this.showDialog = val;
    },
    selectedEvent: {
      deep: true,
      handler(val: ICalendarEvent) {
        this.name = "";
        this.rangeString = "";
        this.summary = "";
        this.location = "";
        this.url = "";
        this.isCancelled = false;
        this.isPublished = false;

        if (val) {
          this.isCancelled = val.tag.isCancelled;
          this.isPublished = val.tag.isPublished;
          this.name = val.name;
          this.rangeString = val.rangeString;
          this.summary = val.summary;
          if (val.tag && val.tag.location) {
            this.location = val.tag.location.address;
            this.url = val.tag.location.url;
          }
        }
      },
    },
  },
  methods: {
    showEditEventScreen() {
      this.$emit("showEditEventScreen");
    },

    deleteEvent() {
      this.$emit("deleteEvent");
    },

    cancelEvent() {
      this.$emit("cancelEvent");
    },

    unCancelEvent() {
      this.$emit("unCancelEvent");
    },

    publishEvent() {
      this.$emit("publishEvent");
    },

    unPublishEvent() {
      this.$emit("unPublishEvent");
    },

    notifyEvent() {
      this.$emit("notifyEvent");
    },

    closeEventInfoDialog() {
      this.$emit("closeEventInfoDialog");
    },
  },
});
</script>