import Axios, { AxiosResponse } from "axios";

import { environment } from "@/environment";
import { BaseService } from "@/services/base.service";
import {
    Contributor, ContributorContent, GetPageViewModel,
    IGetContributorFromUrlCommand, IRefreshContributorMediaCommand, IRemoveSelectedContributorMediaCommand, IReorderSectionCommand,
    ISaveContributorContentCommand,
    IUpdateBannerCommand, IUpdateContributorImageCommand, LayoutListItem, ManageContributorViewModel,
    ManagePageViewModel, ManageSectionViewModel
} from "@/models/page.model";
import { IPagedList } from "@/models/common.model";

export class PageService extends BaseService {
    constructor() {
        super();
    }

    public getPages(): Promise<AxiosResponse<LayoutListItem[]>> {
        const url = `${environment.apiUrl}/admin/page`;
        return Axios.get<LayoutListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPage(pageId: number): Promise<AxiosResponse<GetPageViewModel>> {
        const url = `${environment.apiUrl}/admin/page/layout/${pageId}`;
        return Axios.get<GetPageViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPageManager(pageId: number): Promise<AxiosResponse<ManagePageViewModel>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}`;
        return Axios.get<ManagePageViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getContributors(pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<Contributor>>> {
        const url = `${environment.apiUrl}/admin/page/contributors?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<Contributor>>(url, { headers: this.getAuthenticationHeader() });
    }

    public getSimplifiedContributorsList(): Promise<AxiosResponse<LayoutListItem[]>> {
        const url = `${environment.apiUrl}/admin/page/contributors/list`;
        return Axios.get<LayoutListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getContributor(id: number): Promise<AxiosResponse<ManageContributorViewModel>> {
        const url = `${environment.apiUrl}/admin/page/contributors/${id}`;
        return Axios.get<ManageContributorViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getContributorContent(contributorId: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<ContributorContent>>> {
        const url = `${environment.apiUrl}/admin/page/contributors/${contributorId}/content?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<ContributorContent>>(url, { headers: this.getAuthenticationHeader() });
    }

    public getContributorFromUrl(contentType: string, contentUrl: string): Promise<AxiosResponse<ManageContributorViewModel>> {
        const url = `${environment.apiUrl}/admin/page/contributor/fromUrl`;
        let request: IGetContributorFromUrlCommand = {
            contentType: contentType,
            contentUrl: contentUrl
        };
        return Axios.post<ManageContributorViewModel>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public getContributorContentItem(contributorId: number, id: number): Promise<AxiosResponse<ContributorContent>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${contributorId}/content/${id}`;
        return Axios.get<ContributorContent>(url, { headers: this.getAuthenticationHeader() });
    }

    public updateContributorImage(id: number, imageUrl: string): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${id}/image`;
        let request: IUpdateContributorImageCommand = {
            id: id,
            imageUrl: imageUrl
        };

        return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public refreshContributorMedia(id: number, clearDown: boolean): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${id}/content`;
        let request: IRefreshContributorMediaCommand = {
            id: id,
            clearDown: clearDown
        };
        return Axios.post<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public saveContributorMedia(contributorId: number, id: number, name: string, description: string, tags: string[], publicationStart: Date | null, publicationEnd: Date | null, updatedOn: Date | null): Promise<AxiosResponse<ContributorContent>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${contributorId}/content/${id}`;
        let request: ISaveContributorContentCommand = {
            contributorId: contributorId,
            description: description,
            id: id,
            name: name,
            publicationEnd: publicationEnd,
            publicationStart: publicationStart,
            tags: tags,
            updatedOn: updatedOn
        };
        return Axios.post<ContributorContent>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public getSectionManager(pageId: number, sectionId: number): Promise<AxiosResponse<ManageSectionViewModel>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}/sections/${sectionId}`;
        return Axios.get<ManageSectionViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public savePageManager(viewModel: ManagePageViewModel): Promise<AxiosResponse<ManagePageViewModel>> {
        const url = `${environment.apiUrl}/admin/page`;
        return Axios.post<ManagePageViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public updateBanner(pageId: number, items: ContributorContent[]): Promise<AxiosResponse<ContributorContent[]>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}/banner`;
        let request: IUpdateBannerCommand = {
            id: pageId,
            items: items
        };
        return Axios.patch<ContributorContent[]>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public removePage(pageId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public reorderSection(pageId: number, sectionId: number, newPosition: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}/sections/${sectionId}/reorder/${newPosition}`;
        let request: IReorderSectionCommand = {
            pageId: pageId,
            sectionId: sectionId,
            newPosition: newPosition
        };
        return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public saveContributor(viewModel: ManageContributorViewModel) {
        const url = `${environment.apiUrl}/admin/page/contributor`;
        return Axios.post<ManageContributorViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public saveSection(pageId: number, viewModel: ManageSectionViewModel): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}/section`;
        return Axios.post<boolean>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public removeSelectedContributorMedia(contributorId: number, mediaIds: number[]): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${contributorId}/content-remove`;
        let request: IRemoveSelectedContributorMediaCommand = {
            id: contributorId,
            mediaIds: mediaIds
        }
        return Axios.post<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public removeSection(pageId: number, sectionId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/${pageId}/section/${sectionId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeAllContributorMedia(contributorId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/page/contributor/${contributorId}/content`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }
}