<template>
  <v-container fluid>
    <v-form ref="manageUserForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="3">
                <v-text-field
                  outlined
                  filled
                  v-model="displayName"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      @click="onCancel"
                      v-bind="attrs"
                      v-on="on"
                      large
                    >
                      <v-icon>mdi-format-list-bulleted-square</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
                <v-btn color="primary" type="submit" :disabled="!valid" large>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="5">
          <v-tabs v-model="tab" @change="onTabClick">
            <v-tab>General</v-tab>
            <v-tab>Media</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <contribution-general-section
                ref="contributiongeneralsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </contribution-general-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <contribution-social-media-section
                ref="contributionsocialmediasection"
                :id="id"
              >
              </contribution-social-media-section>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import { AlertType, GroupType, LoadingType } from "@/models/common.model";
import { ManageContributorViewModel } from "@/models/page.model";
import { PageService } from "@/services/page.service";
import ContributionGeneralSection from "@/views/editors/editor-contribution-general-2.vue";
import ContributionSocialMediaSection from "@/views/editors/editor-contribution-list-media.vue";
import store from "@/store";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostPeopleMedia",
  mixins: [CommonMixin],
  components: {
    "action-alert": ActionAlert,
    "contribution-general-section": ContributionGeneralSection,
    "contribution-social-media-section": ContributionSocialMediaSection,
  },
  computed: {
    displayName(): string {
      let name: string = "";
      if (this.formData) {
        name = this.formData.name;
      }
      return name;
    },
  },

  data() {
    return {
      tab: 0,
      valid: true,
      formData: new ManageContributorViewModel(),
      id: 0,
      userId: 0,
      ownerId: 0,
      branchId: 0,
      groupId: 0,
      groupType: "",
    };
  },

  created() {
    if (this.$route.query.branchId) {
      this.branchId = parseInt(this.$route.query.branchId.toString());
    }
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId.toString());
    }
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id.toString());
    }
    if (this.$route.query.userId) {
      this.userId = parseInt(this.$route.query.userId.toString());
    }
    if (this.$route.query.groupType) {
      this.groupType = this.$route.query.groupType.toString();
    }

    this.ownerId =
      this.groupType === GroupType.Branch ||
      this.groupType == GroupType.NationalMinistry
        ? this.branchId
        : this.groupId;

    let title = this.getNavTitle();
    if (!title.endsWith("(Media)")) {
      this.updateNavTitle(`${this.getNavTitle()} (Media)`);
    }
    this.getItem();
  },

  methods: {
    onTabClick(index: any) {
      this.setTabIndex(index);
    },

    resetData() {
      this.tab = this.getTabIndex();
      this.formData = new ManageContributorViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem() {
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Details, Please Wait..."
      );

      const service = new PageService();
      service
        .getContributor(this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Media Details, Please Wait..."
      );

      const service = new PageService();
      service
        .saveContributor(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.showSuccessPanel(
            "The details were saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      this.setTabIndex(0);
      this.$router.go(-1);
    },

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>  