var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showProgressBar,
            expression: "showProgressBar",
          },
        ],
      },
      [
        _c("v-progress-linear", {
          attrs: {
            color: "primary",
            indeterminate: "",
            rounded: "",
            height: "5",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", width: "300" },
            model: {
              value: _vm.showSpinner,
              callback: function ($$v) {
                _vm.showSpinner = $$v
              },
              expression: "showSpinner",
            },
          },
          [
            _c(
              "v-card",
              { attrs: { color: "primary", dark: "" } },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "10px",
                          "padding-bottom": "20px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                    ),
                    _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: { indeterminate: "", color: "white" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }