import { BaseListItem, ISocialMediaReference } from "./common.model";
import store from "@/store/index";
import { GroupType } from "./common.model";
import { EventLocation } from "./events.model";
import { MemberSearchSuggestion } from "./group.model";

export interface ISaveUserRoleCommand {
    id: number;
    roleId: number;
}

export class ManageRoleViewModel {
    id: number | undefined;
    name: string | undefined;
    description: string | undefined;
    items: IRolePermissionItem[];
    selected: string[];

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.items = [];
        this.selected = [];
    }
}

export class RoleListItem {
    id: number;
    name: string;
    description: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
    }
}

export interface IRolePermissionItem {
    id: number;
    name: string;
    children: IRolePermissionItem[];
}

export interface IRoleListItem {
    id: number;
    name: string;
    description: string;
}

export interface IUserRoleListItem {
    roleId: number;
    registeredUserId: number;
    firstName: string;
    lastName: string;
    role: string;
    roleDescription: string;
}

export interface IManageUserRoleViewModel {
    registeredUserId: number | null;
    firstName: string;
    lastName: string;
    roleId: number | null;
    roleName: string;
    roleDescription: string;
    roles: BaseListItem[];
}

export interface IUserProfileViewModel {
    applicationId: number,
    id: number;
    name: string;
    imageUrl: string | null;
    permissions: string[];
}

export interface IUser {
    authenticated: boolean,
    authenticating: boolean,
    profile: UserProfile
}

export interface IUserProfile {
    applicationId: number,
    displayName: string;
    email: string;
    photoURL: string;
    uid: string;
    token: string;
    permissions: string[];
}

export class UserProfile implements IUserProfile {
    applicationId: number;
    displayName: string;
    email: string;
    photoURL: string;
    uid: string;
    token: string;
    permissions: string[];

    constructor() {
        this.applicationId = 0;
        this.displayName = '';
        this.email = '';
        this.photoURL = '';
        this.uid = '';
        this.token = '';
        this.permissions = [];
    }
}

export interface IUserListItem {
    id: number;
    knownAs: string;
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    imageUrl: string;
    gender: string;
    dateOfBirth: Date | null;
    contactable: boolean | null;
    maritalStatus: string;
    ethnicity: string;
    profession: string;
    nationality: string;
    dateOfBaptism: Date | null;
    fellowshipDate: Date | null;
    resignationDate: Date | null;
    weddingDate: Date | null;
    datePassed: Date | null;
    age: number | null;
    isMember: boolean | null;    
    isVisitor: boolean | null;
    isActive: boolean | null;
    isOnline: boolean | null;
    hasPassed: boolean | null;
    isContactableByEmail: boolean | null;
    isContactableBySms: boolean | null;
    isContactableByApp: boolean | null;    
    ownerGroupId: number;    
    ownerGroupName: string;
    name: string;
}

export interface IManageUserViewModel {
    ownerId: number | null;
    id: number | null;
    ourReference: string;
    title: string;
    knownAs: string;
    firstName: string;
    middleName: string;
    lastName: string;
    profile: string;
    dateOfBirth: string | null;
    gender: string;
    ethnicity: string;
    nationality: string;
    maritalStatus: string;
    primaryEmail: string;
    secondaryEmail: string;
    tertiaryEmail: string;
    primaryContactNumber: string;
    secondaryContactNumber: string;
    coverImageUrl: string;
    profileImageUrl: string;
    location: EventLocation;
    genders: string[];
    ethnicities: string[];
    nationalities: string[];
    maritalStatuses: string[];
    gdprPermissionGranted: boolean | null;
    allowContactByPhone: boolean | null;
    allowContactByPost: boolean | null;
    allowContactByEmail: boolean | null;
    allowContactBySms: boolean | null;
    allowContactByApp: boolean | null;
    dateOfBaptism: string | null;
    dateOfFellowship: string | null;
    dateOfResignation: string | null;
    dateOfWedding: string | null;
    dateOfPassing: string | null;
    dateOfFirstVisit: string | null;
    isMember: boolean | null;
    father: MemberSearchSuggestion | null;
    mother: MemberSearchSuggestion | null;
    emergencyContact1: MemberSearchSuggestion | null;
    emergencyContactName1IsMember: boolean;
    emergencyContact2: MemberSearchSuggestion | null;
    emergencyContactName2IsMember: boolean;
    emergencyContactName1: string;
    emergencyContactNumber1: string;
    emergencyContactName2: string;
    emergencyContactNumber2: string;
    skills: string[];
    skill: string;
    externalPlatforms: string[];
    socials: ISocialMediaReference[];
    updatedOn: Date | null;
    attributes: string[];
    selectedAttributes: string[];
    currentStatuses: string[];
    selectedCurrentStatuses: string[];

    // For local use only
    tmpEmails: ManageUserContact[];
    tmpContactNumbers: ManageUserContact[];
}

export class ManageUserViewModel implements IManageUserViewModel {
    ownerId: number | null;
    id: number | null;
    ourReference: string;
    title: string;
    knownAs: string;
    firstName: string;
    middleName: string;
    lastName: string;
    profile: string;
    dateOfBirth: string | null;
    gender: string;
    ethnicity: string;
    nationality: string;
    maritalStatus: string;
    primaryEmail: string;
    secondaryEmail: string;
    tertiaryEmail: string;
    primaryContactNumber: string;
    secondaryContactNumber: string;
    coverImageUrl: string;
    profileImageUrl: string;
    location: EventLocation;
    genders: string[];
    ethnicities: string[];
    nationalities: string[];
    maritalStatuses: string[];
    gdprPermissionGranted: boolean | null;
    allowContactByPhone: boolean | null;
    allowContactByPost: boolean | null;
    allowContactByEmail: boolean | null;
    allowContactBySms: boolean | null;
    allowContactByApp: boolean | null;
    dateOfBaptism: string | null;
    dateOfFellowship: string | null;
    dateOfResignation: string | null;
    dateOfWedding: string | null;
    dateOfPassing: string | null;
    dateOfFirstVisit: string | null;
    isMember: boolean | null;
    father: MemberSearchSuggestion | null;
    mother: MemberSearchSuggestion | null;
    emergencyContact1: MemberSearchSuggestion | null;
    emergencyContact2: MemberSearchSuggestion | null;
    emergencyContactName1: string;
    emergencyContactName1IsMember: boolean;
    emergencyContactNumber1: string;
    emergencyContactName2IsMember: boolean;
    emergencyContactName2: string;
    emergencyContactNumber2: string;
    skills: string[];
    skill: string;
    externalPlatforms: string[];
    socials: ISocialMediaReference[];
    updatedOn: Date | null;
    attributes: string[];
    selectedAttributes: string[];
    currentStatuses: string[];
    selectedCurrentStatuses: string[];

    // For local use only
    tmpEmails: ManageUserContact[];
    tmpContactNumbers: ManageUserContact[];

    constructor() {
        this.ownerId = null;
        this.id = null;
        this.ourReference = '';
        this.title = '';
        this.knownAs = '';
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.profile = '';
        this.dateOfBirth = null;
        this.gender = '';
        this.ethnicity = '';
        this.nationality = '';
        this.maritalStatus = '';
        this.primaryEmail = '';
        this.secondaryEmail = '';
        this.tertiaryEmail = '';
        this.primaryContactNumber = '';
        this.secondaryContactNumber = '';
        this.coverImageUrl = '';
        this.profileImageUrl = '';
        this.genders = [];
        this.ethnicities = [];
        this.nationalities = [];
        this.maritalStatuses = [];
        this.location = new EventLocation();
        this.gdprPermissionGranted = null;
        this.allowContactByPhone = null;
        this.allowContactByPost = null;
        this.allowContactByEmail = null;
        this.allowContactBySms = null;
        this.allowContactByApp = null;
        this.dateOfBaptism = null;
        this.dateOfFellowship = null;
        this.dateOfResignation = null;
        this.dateOfWedding = null;
        this.dateOfPassing = null;
        this.dateOfFirstVisit = null;
        this.isMember = null;
        this.father = null;
        this.mother = null;
        this.emergencyContact1 = null;
        this.emergencyContactName1IsMember = false;
        this.emergencyContact2 = null;
        this.emergencyContactName2IsMember = false;
        this.emergencyContactName1 = '';
        this.emergencyContactNumber1 = '';
        this.emergencyContactName2 = '';
        this.emergencyContactNumber2 = '';
        this.skills = [];
        this.skill = '';
        this.externalPlatforms = [];
        this.socials = [];
        this.updatedOn = null;
        this.attributes = [];
        this.selectedAttributes = [];
        this.currentStatuses = [];
        this.selectedCurrentStatuses = [];
        
        this.tmpEmails = [];
        this.tmpContactNumbers = [];
    }
}

export class ManageUserContact {
    id: number;
    label: string;
    value: string;

    constructor(id: number, label: string, value: string) {
        this.id = id;
        this.label = label;
        this.value = value;
    }
}

export enum PermissionType {
    List = 0,
    Get = 1,
    Create = 2,
    Edit = 3,
    Remove = 4,
    Sensitive = 5,
    ManageGroups = 6
}

export enum PermissionArea {
    None = 0,
    General = 1,
    Membership = 2,
    Positions = 3,
    Events = 4,
    Reports = 5,
    Security = 6,
    Overview = 7,
    Polls = 8,
    Media = 9,
    Socials = 10,
    Messaging = 11, 
    QuarterlyReturn = 12,
    Galleries = 13,
    ServiceTimes = 14
}

export class PermissionsManager {
    static isSuperAdministrator(): boolean {
        let user = store.state.user;
        let permission = `Application.${user.profile.applicationId}.SystemAdministrator.FullAccess`;
        let match = user.profile.permissions.includes(permission);
        return match;
    }
    static hasPermission(ownerId: number | null, groupId: number | null, groupType: string, permissionType: PermissionType, area: PermissionArea): boolean {

        let user = store.state.user;

        // If the user is a super administrator then they can do anything
        let isSuperAdmin = user.profile.permissions.includes(`Application.${user.profile.applicationId}.SystemAdministrator.FullAccess`);
        if (isSuperAdmin) {
            return true;
        }

        // Make sure you don't have a branch and ministry id, it is one or the other
        let branchId: number | null = (groupType === GroupType.Branch || groupType === GroupType.LocalMinistry) ? ownerId : null;
        let ministryId: number | null = (groupType === GroupType.NationalMinistry) ? null : ownerId;

        // Make sure the branch or ministry id is not the same as the group id as that would
        // generate an incorrect permission string including both branch/ministry id and group id
        if (groupId === branchId || groupId === ministryId) {
            groupId = null;
        }

        let permission: string = `Application.${user.profile.applicationId}`;

        if (user.profile.applicationId && (branchId != null || ministryId != null)) {

            if (branchId != null && branchId !== 0) {
                permission += `.Branch.${branchId}`;
            } else if (ministryId != null && ministryId !== 0) {
                permission += `.Ministry.${ministryId}`;
            }

            if (groupId != null && groupId !== 0) {
                permission += `.Group.${groupId}`;
            }

            switch (area) {
                case PermissionArea.Membership:
                    permission += `.Membership`;
                    break;
                case PermissionArea.Positions:
                    permission += `.Positions`;
                    break;
                case PermissionArea.Events:
                    permission += `.Events`;
                    break;
                case PermissionArea.Reports:
                    permission += `.Reports`;
                    break;
                case PermissionArea.Security:
                    permission += `.Security`;
                    break;
                case PermissionArea.Overview:
                    permission += `.Overview`;
                    break;
                case PermissionArea.Polls:
                    permission += `.Polls`;
                    break;
                case PermissionArea.Media:
                    permission += `.Media`;
                    break;
                case PermissionArea.Socials:
                    permission += `.Socials`;
                    break;
                case PermissionArea.Messaging:
                    permission += `.Messaging`;
                    break;
                case PermissionArea.QuarterlyReturn:
                    permission += `.QuarterlyReturn`;
                    break;    
                case PermissionArea.Galleries:
                    permission += `.Galleries`;
                    break;    
                case PermissionArea.ServiceTimes:
                    permission += `.ServiceTimes`;
                    break;                           
                default:
                    permission += `.General`;
                    break;
            }

            switch (permissionType) {
                case PermissionType.List:
                    permission += '.List';
                    break;
                case PermissionType.Get:
                    permission += '.Get';
                    break;
                case PermissionType.Create:
                    permission += '.Create';
                    break;
                case PermissionType.Edit:
                    permission += '.Edit';
                    break;
                case PermissionType.Remove:
                    permission += '.Remove';
                    break;
                case PermissionType.Sensitive:
                    permission += '.SensitiveData';
                    break;
                case PermissionType.ManageGroups:
                    permission += '.ManageGroups';
                    break;
            }
        }

        let isPermitted: boolean = user.profile.permissions.includes(permission);

        return isPermitted;
    }
}