export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCTCUMMkWudfjdvsTbGCbJ5F9be8SfjtSw",
    authDomain: "tnl-church.firebaseapp.com",
    projectId: "tnl-church",
  },
  applicationId: 1,
  apiUrl: process.env.VUE_APP_API_URL,
  baseUrl: "https://localhost:8080/",
};
