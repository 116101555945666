<template>
  <v-container fluid>
    <v-form ref="manageUserForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="4">
                <v-text-field
                  outlined
                  filled
                  v-model="displayName"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      icon
                      @click="onCancel"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      icon
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshScreen"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  large
                  class="ma-1"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="!isCancelled"
                  class="ma-1"
                  color="primary"
                  :disabled="!valid"
                  @click="cancelEvent"
                  large
                >
                  Cancel
                </v-btn>
                <v-btn
                  v-if="isCancelled"
                  class="ma-1"
                  color="primary"
                  :disabled="!valid"
                  @click="unCancelEvent"
                  large
                >
                  Un-Cancel
                </v-btn>
                <v-btn
                  v-if="!isPublished"
                  class="ma-1"
                  color="primary"
                  :disabled="!valid"
                  @click="publishEvent"
                  large
                >
                  Publish
                </v-btn>
                <v-btn
                  v-if="isPublished"
                  class="ma-1"
                  color="primary"
                  :disabled="!valid"
                  @click="unPublishEvent"
                  large
                >
                  Un-Publish
                </v-btn>
                <v-btn
                  v-if="isPublished"
                  class="ma-1"
                  color="primary"
                  :disabled="!valid"
                  @click="onShowNotificationDialog"
                  large
                >
                  Notify
                </v-btn>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="tab" @change="onTabClick">
            <v-tab>General</v-tab>
            <v-tab>Locations</v-tab>
            <v-tab>Links</v-tab>
            <v-tab>Gallery</v-tab>
            <v-tab>Promotion</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <events-general-section
                ref="eventsgeneralsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </events-general-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <events-locations-section
                ref="eventslocationssection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </events-locations-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <events-links-section
                ref="eventslinkssection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </events-links-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <events-gallery-section
                ref="eventsgallerysection"
                :id="id"
                :ownerId="ownerId"
              >
              </events-gallery-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <events-promotion-section
                ref="eventspromotionsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </events-promotion-section>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showCancelDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onCancelDialogSelected"
    ></confirmation-dialog>
    <notification-sender-dialog
      :dialog="showNotificationDialog"
      :message="notificationMessage"
      :title="notificationTitle"
      :category="notificationCategory"
      :entityId="id"
      @dialog-closed="onNotificationDialogClosed"
    >
    </notification-sender-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import {
  AlertType,
  GroupType,
  LoadingType,
  NotificationType,
} from "@/models/common.model";
import { EventViewModel } from "@/models/events.model";
import { EventsService } from "@/services/events.service";
import { v4 as uuidv4 } from "uuid";
import EventsGeneralSection from "@/views/editors/editor-events-general.vue";
import EventsLocationsSection from "@/views/editors/editor-events-locations.vue";
import EventsGallerySection from "@/views/editors/editor-events-gallery.vue";
import EventsLinksSection from "@/views/editors/editor-events-links.vue";
import EventsPromotionSection from "@/views/editors/editor-events-promotion.vue";
import NotificationSenderDialog from "@/components/notifications/notification-sender-dialog.vue";
import { DialogMessage, DialogResponse } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostEventsManager",
  mixins: [CommonMixin],
  components: {
    ActionAlert,
    "events-general-section": EventsGeneralSection,
    "events-locations-section": EventsLocationsSection,
    "events-gallery-section": EventsGallerySection,
    "events-links-section": EventsLinksSection,
    "events-promotion-section": EventsPromotionSection,
    "notification-sender-dialog": NotificationSenderDialog,
  },
  data() {
    return {
      tab: null,
      valid: true,
      formData: new EventViewModel(),
      canViewSensitiveData: false,
      id: 0,
      ownerId: 0,
      branchId: 0,
      groupId: 0,
      groupType: "",
      showCancelDialog: false,
      showNotificationDialog: false,
      notificationTitle: "",
      notificationMessage: "",
      notificationCategory: NotificationType.event,
    };
  },
  computed: {
    displayName(): string {
      let name: string = "";
      if (this.formData) {
        if (this.formData.isCancelled) {
          name = `(CANCELLED) - ${this.formData.title}`;
        } else if (!this.formData.isPublished) {
          name = `(UN-PUBLISHED) - ${this.formData.title}`;
        } else {
          name = this.formData.title;
        }
      }
      return name;
    },
    isCancelled(): boolean {
      return this.formData && this.formData.isCancelled;
    },
    isPublished(): boolean {
      return this.formData && this.formData.isPublished;
    },
  },
  created() {
    if (this.$route.query.branchId) {
      this.branchId = parseInt(this.$route.query.branchId.toString());
    }
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId.toString());
    }
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id.toString());
    }
    if (this.$route.query.groupType) {
      this.groupType = this.$route.query.groupType.toString();
    }

    this.ownerId =
      this.groupType === GroupType.Branch ||
      this.groupType == GroupType.NationalMinistry
        ? this.branchId
        : this.groupId;

    let title = this.getNavTitle();
    if (!title.endsWith("(Event)")) {
      this.updateNavTitle(`${this.getNavTitle()} (Event)`);
    }

    this.getItem();
  },
  methods: {
    refreshScreen() {
      this.resetData();
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Event Details, Please Wait..."
      );

      const service = new EventsService();
      service
        .getEvent(this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.setLocalIds(response.data);
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
      this.onTabClick(this.tab);
    },

    resetData() {
      this.formData = new EventViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem() {
      this.tab = null;
      this.resetData();
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Event Details, Please Wait..."
      );

      const service = new EventsService();
      service
        .getEvent(this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.setLocalIds(response.data);
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Event, Please Wait..."
      );

      // Remove any locations where the title has not been set
      this.formData.locations = this.formData.locations.filter(
        (f) => f.title == null || (f.title !== null && f.title.length !== 0)
      );
      this.formData.onlineLocations = this.formData.onlineLocations.filter(
        (f) => f.linkUrl.length !== 0
      );

      const service = new EventsService();
      service
        .saveEvent(this.formData)
        .then((response) => {
          this.setLocalIds(response.data);
          this.formData = response.data;
          this.showAlertPanel(
            "The event was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    setLocalIds(model: EventViewModel) {
      // Set this temporary client-side id that can be used to
      // uniquely name a location control
      for (let location of model.locations) {
        location.localId = uuidv4();
      }

      for (let location of model.onlineLocations) {
        location.localId = uuidv4();
      }
    },

    onCancel() {
      if (this.groupType == GroupType.NationalMinistry) {
        this.$router.replace({
          name: "host-ministries",
          query: {
            ministryId: this.branchId.toString(),
            groupId: this.groupId.toString(),
            groupType: this.groupType,
            tab: "events",
          },
        });
      } else {
        this.$router.replace({
          name: "host-groups",
          query: {
            branchId: this.branchId.toString(),
            groupId: this.groupId.toString(),
            groupType: this.groupType,
            tab: "events",
          },
        });
      }
    },

    onTabClick(index: any) {
      switch (index) {
        case 0:
          this.showGeneralView();
          break;
        case 1:
          this.showLocationsView();
          break;
        case 2:
          this.showLinksView();
          break;
        case 3:
          this.showGalleryView();
          break;
        case 4:
          this.showPromotionView();
          break;
      }
      this.tab = index;
    },

    showGeneralView() {},

    showLocationsView() {},

    showLinksView() {
      const ref: any = this.$refs.eventslinkssection;
      if (ref) {
        ref.initialise();
      }
    },

    showGalleryView() {
      const ref: any = this.$refs.eventsgallerysection;
      if (ref) {
        ref.getItems();
      }
    },

    showPromotionView() {},

    cancelEvent() {
      this.showCancelConfirmationDialog();
    },

    showCancelConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Cancel ${this.formData.title}?`,
        "Are you sure you want to CANCEL this event? Cancelling an event will result in it being listed as Cancelled and un-available."
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.errors = [];
      this.showCancelDialog = true;
    },

    onCancelDialogSelected(item: DialogResponse) {
      this.showCancelDialog = false;

      if (item.option == true) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Cancelling Event, Please Wait..."
        );

        const service = new EventsService();
        service
          .cancelEvent(this.formData.id!)
          .then(() => {
            this.formData.isCancelled = true;
            this.showAlertPanel("The event was cancelled.", AlertType.Success);
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    unCancelEvent() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Un-cancelling Event, Please Wait..."
      );

      const service = new EventsService();
      service
        .unCancelEvent(this.formData.id!)
        .then(() => {
          this.formData.isCancelled = false;
          this.showAlertPanel("The event was un-cancelled.", AlertType.Success);
        })
        .catch((error) => this.showErrorDialog(error));
    },

    publishEvent() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Publishing Event, Please Wait..."
      );

      const service = new EventsService();
      service
        .publishEvent(this.formData.id!)
        .then(() => {
          this.formData.isPublished = true;
          this.showAlertPanel("The event was published.", AlertType.Success);
        })
        .catch((error) => this.showErrorDialog(error));
    },

    unPublishEvent() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Un-publishing Event, Please Wait..."
      );

      const service = new EventsService();
      service
        .unPublishEvent(this.formData.id!)
        .then(() => {
          this.formData.isPublished = false;
          this.showAlertPanel("The event was un-published.", AlertType.Success);
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onShowNotificationDialog() {
      this.notificationTitle = this.formData.title;
      this.notificationMessage = this.formData.description;
      this.showNotificationDialog = true;
    },

    onNotificationDialogClosed() {
      this.showNotificationDialog = false;
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>