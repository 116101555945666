var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "70%" },
          model: {
            value: _vm.showQueryManagerDialog,
            callback: function ($$v) {
              _vm.showQueryManagerDialog = $$v
            },
            expression: "showQueryManagerDialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "manageForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "", elevation: "1" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        flat: "",
                        dense: "",
                        elevation: "1",
                        color: "#E5E3E3",
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.formData.name) + " "),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDialogCloseClick()
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Close")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "9" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  readonly: "",
                                  placeholder: "Summary",
                                  label: "Summary",
                                  "auto-grow": "",
                                  rows: "2",
                                },
                                model: {
                                  value: _vm.formData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "description", $$v)
                                  },
                                  expression: "formData.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "12" } },
                                [
                                  _c(
                                    "v-stepper",
                                    {
                                      attrs: {
                                        vertical: "",
                                        "non-linear": "",
                                        tile: false,
                                        outlined: false,
                                        elevation: "0",
                                      },
                                      model: {
                                        value: _vm.stepNo,
                                        callback: function ($$v) {
                                          _vm.stepNo = $$v
                                        },
                                        expression: "stepNo",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "1",
                                            complete: _vm.stepNo > 1,
                                          },
                                        },
                                        [
                                          _vm._v(" Pick a query type "),
                                          _c("small", [
                                            _vm._v(
                                              "What kind of query is this?"
                                            ),
                                          ]),
                                          _c(
                                            "v-stepper-content",
                                            { attrs: { step: "1" } },
                                            [
                                              _c("div", {
                                                staticStyle: { height: "10px" },
                                              }),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.formData
                                                              .queryTypes,
                                                          placeholder:
                                                            "Show me",
                                                          label: "Show me",
                                                          rules:
                                                            _vm.queryTypeRules,
                                                          "hide-details":
                                                            "auto",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.onQueryTypeChange()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedQueryType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedQueryType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedQueryType",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.formData
                                                              .subjects,
                                                          placeholder:
                                                            "Subject",
                                                          label: "Subject",
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                          "hide-details":
                                                            "auto",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedSubject,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedSubject",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedSubject",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isRatioQueryType
                                                    ? _c(
                                                        "v-col",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              items:
                                                                _vm.formData
                                                                  .ratioTypes,
                                                              placeholder: "By",
                                                              label: "By",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.formData
                                                                  .selectedRatioType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.formData,
                                                                    "selectedRatioType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "formData.selectedRatioType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isSpreadQueryType
                                                    ? _c(
                                                        "v-col",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              items:
                                                                _vm.formData
                                                                  .spreadTypes,
                                                              placeholder: "By",
                                                              label: "By",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.formData
                                                                  .selectedSpreadType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.formData,
                                                                    "selectedSpreadType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "formData.selectedSpreadType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isAverageQueryType
                                                    ? _c(
                                                        "v-col",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              items:
                                                                _vm.formData
                                                                  .averageTypes,
                                                              placeholder: "By",
                                                              label: "By",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.formData
                                                                  .selectedAverageType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.formData,
                                                                    "selectedAverageType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "formData.selectedAverageType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          "item-text": "text",
                                                          "item-value": "id",
                                                          items:
                                                            _vm.formData
                                                              .branches,
                                                          label:
                                                            "Who attended the branches",
                                                          placeholder:
                                                            "Who attended the branches",
                                                          rules:
                                                            _vm.validateGroupIsSelected,
                                                          "hide-details":
                                                            "auto",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedBranches,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedBranches",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedBranches",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          "item-text": "text",
                                                          "item-value": "id",
                                                          items:
                                                            _vm.formData.groups,
                                                          label:
                                                            "Who work in the groups of",
                                                          placeholder:
                                                            "Who work in the groups of",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedGroups,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedGroups",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedGroups",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          "item-text": "text",
                                                          "item-value": "id",
                                                          items:
                                                            _vm.formData
                                                              .ministries,
                                                          label:
                                                            "Who work in the ministries of",
                                                          placeholder:
                                                            "Who work in the ministries of",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedMinistries,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedMinistries",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedMinistries",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      height: "10px",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 2
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Next ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              !_vm.valid,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 4
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Done ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "2",
                                            complete: _vm.stepNo > 2,
                                          },
                                        },
                                        [
                                          _vm._v(" Describe the audience "),
                                          _c("small", [
                                            _vm._v("Who should be included?"),
                                          ]),
                                          _c(
                                            "v-stepper-content",
                                            { attrs: { step: "2" } },
                                            [
                                              _c("div", {
                                                staticStyle: { height: "10px" },
                                              }),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules:
                                                            _vm.validateAgeFrom,
                                                          label:
                                                            "Whose age is between",
                                                          "hide-details":
                                                            "auto",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .ageFrom,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "ageFrom",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.ageFrom",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules:
                                                            _vm.validateAgeTo,
                                                          label: "and",
                                                          "hide-details":
                                                            "auto",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData.ageTo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "ageTo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.ageTo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .genders,
                                                          label:
                                                            "Whose genders are",
                                                          placeholder:
                                                            "Whose genders are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedGenders,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedGenders",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedGenders",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .maritalStatuses,
                                                          label:
                                                            "Whose martital statuses are",
                                                          placeholder:
                                                            "Whose marital statuses are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedMaritalStatuses,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedMaritalStatuses",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedMaritalStatuses",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .nationalities,
                                                          label:
                                                            "Whose nationalities are",
                                                          placeholder:
                                                            "Whose nationalities are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedNationalities,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedNationalities",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedNationalities",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .ethnicities,
                                                          label:
                                                            "Whose ethnicities are",
                                                          placeholder:
                                                            "Whose ethnicities are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedEthnicities,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedEthnicities",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedEthnicities",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .positions,
                                                          label:
                                                            "Who positions are",
                                                          placeholder:
                                                            "Whose positions are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedPositions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedPositions",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedPositions",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .professions,
                                                          label:
                                                            "Whose professions are",
                                                          placeholder:
                                                            "Whose professions are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedProfessions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedProfessions",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedProfessions",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .currentStatuses,
                                                          label:
                                                            "Who current statuses are",
                                                          placeholder:
                                                            "Whose current statuses are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedCurrentStatuses,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedCurrentStatuses",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedCurrentStatuses",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .attributes,
                                                          label:
                                                            "Whose attributes are",
                                                          placeholder:
                                                            "Whose attributes are",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedAttributes,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedAttributes",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedAttributes",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData
                                                              .regions,
                                                          label:
                                                            "Who reside in the regions",
                                                          placeholder:
                                                            "Whose reside in the regions",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedRegions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedRegions",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedRegions",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          multiple: "",
                                                          items:
                                                            _vm.formData.cities,
                                                          label:
                                                            "Who reside in the cities",
                                                          placeholder:
                                                            "Whose reside in the cities",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .selectedCities,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "selectedCities",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.selectedCities",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      height: "10px",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "6" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 1
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Back ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 3
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Next ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              !_vm.valid,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 4
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Done ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "3",
                                            complete: _vm.stepNo > 3,
                                          },
                                        },
                                        [
                                          _vm._v(" Let's get specific "),
                                          _c("small", [
                                            _vm._v("Anything specifically?"),
                                          ]),
                                          _c(
                                            "v-stepper-content",
                                            { attrs: { step: "3" } },
                                            [
                                              _c("div", {
                                                staticStyle: { height: "10px" },
                                              }),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateOfFirstVisitStart,
                                                          dateRules:
                                                            _vm.validateFirstVisitStartDate,
                                                          label:
                                                            "Who first visited between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateOfFirstVisitStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateOfFirstVisitEnd,
                                                          dateRules:
                                                            _vm.validateFirstVisitEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateOfFirstVisitEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateRequestedBaptisedStart,
                                                          dateRules:
                                                            _vm.validateRequestedBaptisedStartDate,
                                                          label:
                                                            "Who requested baptism between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateRequestedBaptisedStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateRequestedBaptisedEnd,
                                                          dateRules:
                                                            _vm.validateRequestedBaptisedEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateRequestedBaptisedEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateBaptisedStart,
                                                          dateRules:
                                                            _vm.validateBaptisedStartDate,
                                                          label:
                                                            "Who was baptised between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateBaptisedStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateBaptisedEnd,
                                                          dateRules:
                                                            _vm.validateBaptisedEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateBaptisedEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateFellowshippedStart,
                                                          dateRules:
                                                            _vm.validateFellowshipStartDate,
                                                          label:
                                                            "Who was fellowshiped between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateFellowshippedStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateFellowshippedEnd,
                                                          dateRules:
                                                            _vm.validateFellowshipEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateFellowshippedEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateResignedStart,
                                                          dateRules:
                                                            _vm.validateResignedStartDate,
                                                          label:
                                                            "Who resigned between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateResignedStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .dateResignedEnd,
                                                          dateRules:
                                                            _vm.validateResignedEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "dateResignedEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "8" } },
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .datePassedAwayStart,
                                                          dateRules:
                                                            _vm.validatePassedAwayStartDate,
                                                          label:
                                                            "Who passed away between",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "datePassedAwayStart",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("date-picker", {
                                                        attrs: {
                                                          showHeader: false,
                                                          outlined: false,
                                                          date: _vm.formData
                                                            .datePassedAwayEnd,
                                                          dateRules:
                                                            _vm.validatePassedAwayEndDate,
                                                          label: "and",
                                                        },
                                                        on: {
                                                          "update:date":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.formData,
                                                                "datePassedAwayEnd",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      height: "10px",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 2
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Back ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 4
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Next ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "4",
                                            complete: _vm.stepNo > 4,
                                          },
                                        },
                                        [
                                          _vm._v(" Run the query "),
                                          _c("small", [
                                            _vm._v(
                                              "Find out who matches your query"
                                            ),
                                          ]),
                                          _c(
                                            "v-stepper-content",
                                            { attrs: { step: "4" } },
                                            [
                                              _c("div", {
                                                staticStyle: { height: "10px" },
                                              }),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      height: "10px",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "4" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 3
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Back ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "success",
                                                            disabled:
                                                              !_vm.valid,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.runDraftQuery,
                                                          },
                                                        },
                                                        [_vm._v(" Run ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.stepNo = 5
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Next ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-step",
                                        { attrs: { step: "5" } },
                                        [
                                          _vm._v(" Save it for next time! "),
                                          _c("small", [
                                            _vm._v(
                                              "Enter a name for the query and click Save"
                                            ),
                                          ]),
                                          _c(
                                            "v-stepper-content",
                                            { attrs: { step: "5" } },
                                            [
                                              _c("div", {
                                                staticStyle: { height: "10px" },
                                              }),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Query Name",
                                                          placeholder:
                                                            "Name of query",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData.name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.name",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      height: "10px",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { md: "4" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "success",
                                                            type: "submit",
                                                            disabled:
                                                              !_vm.canSave,
                                                          },
                                                        },
                                                        [_vm._v(" Save ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onDialogCloseClick()
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("query-result-dialog", {
        attrs: { dialog: _vm.showResultDialog, data: _vm.queryResult },
        on: { "dialog-closed": _vm.closeQueryResultDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }