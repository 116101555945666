<template>
  <v-container id="main-container">
    <add-contributor-dialog
      :dialog="showContributorDialog"
      :id="manageContributorId"
      @dialog-closed-cancel="onContributorDialogCancel"
      @dialog-closed-confirm="onContributorDialogConfirm"
    >
    </add-contributor-dialog>
    <manage-page-section-dialog
      :dialog="showAddPageSectionDialog"
      :sectionId="manageSectionId"
      :pageId="managePageId"
      @dialog-closed-cancel="onPageSectionDialogCancel"
      @dialog-closed-confirm="onPageSectionDialogConfirm"
    >
    </manage-page-section-dialog>
    <manage-banner-dialog
      :dialog="showBannerDialog"
      :currentBanner="page.banner"
      @dialog-closed-cancel="onBannerDialogCancel"
      @dialog-closed-confirm="onBannerDialogConfirm"
    >
    </manage-banner-dialog>
    <manage-page-dialog
      :dialog="showPageDialog"
      :pageId="managePageId"
      @dialog-closed-cancel="onPageDialogCancel"
      @dialog-closed-confirm="onPageDialogConfirm"
    >
    </manage-page-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeletePageDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeletePageSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-row>
      <div class="col-md-12 mt-5">
        <v-row>
          <v-col lg="4">
            <section class="page-header">
              <h5 class="wg-title">Mobile App Manager</h5>
              <p class="small" style="margin-bottom: 0">
                Design the layout and look of your mobile app here
              </p>
            </section>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="auto">
            <action-alert
              :show="showAlert"
              :message="alertMessage"
              :type="alertType"
            ></action-alert>
          </v-col>
        </v-row>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-card class="wg-card-shadow">
          <div class="row">
            <div class="col-md-2 no-padding">
              <div class="inner_sidebar">
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      color="primary"
                      outlined
                      @click="addSection()"
                      :disabled="!pageSelected"
                    >
                      <v-icon> mdi-format-list-bulleted-square </v-icon>
                      <div style="padding-left: 8px">Add Section</div>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-btn color="primary" outlined @click="addContributor()">
                      <v-icon> mdi-format-list-bulleted-square </v-icon>
                      <div style="padding-left: 8px">Add Contributor</div>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      color="primary"
                      outlined
                      @click="addBannerItem()"
                      :disabled="!pageSelected"
                    >
                      <v-icon> mdi-format-list-bulleted-square </v-icon>
                      <div style="padding-left: 8px">Manage Banner</div>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
            <div class="col-md-10 content-area">
              <v-row>
                <v-col md="2">
                  <v-select
                    :items="pages"
                    v-model="selectedPageId"
                    item-text="name"
                    item-value="id"
                    label="Pages"
                    outlined
                    @change="getPage()"
                  ></v-select>
                </v-col>
                <v-col md="6">
                  <v-btn
                    v-if="selectedPageId !== 0"
                    color="primary"
                    icon
                    @click="removePage()"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="selectedPageId == 0"
                    color="primary"
                    outlined
                    @click="addPage()"
                  >
                    <v-icon> mdi-format-list-bulleted-square </v-icon>
                    <div style="padding-left: 8px">Add Page</div>
                  </v-btn>
                  <v-btn v-else color="primary" outlined @click="editPage()">
                    <v-icon> mdi-format-list-bulleted-square </v-icon>
                    <div style="padding-left: 8px">Edit Page</div>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3"> </v-col>
                <v-col md="6">
                  <v-carousel>
                    <v-carousel-item
                      v-for="banner in page.banner"
                      :key="banner.id"
                      :src="banner.imageUrl"
                    ></v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <draggable
                    v-model="page.sections"
                    group="sections"
                    @start="drag = true"
                    @end="drag = false"
                    class="list-group"
                    tag="ul"
                    v-bind="dragOptions"
                    @change="onSectionReordered"
                  >
                    <transition-group
                      type="transition"
                      :name="!drag ? 'flip-list' : null"
                    >
                      <li
                        class="list-group-item"
                        v-for="section in page.sections"
                        :key="section.id"
                      >
                        <i
                          :class="
                            section.fixed
                              ? 'fa fa-anchor'
                              : 'glyphicon glyphicon-pushpin'
                          "
                          @click="section.fixed = !section.fixed"
                          aria-hidden="true"
                        ></i>
                        <section>
                          <v-btn
                            color="primary"
                            icon
                            @click="removeSection(section.id)"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                          <v-btn
                            color="primary"
                            outlined
                            @click="editSection(section.id)"
                          >
                            <v-icon> mdi-format-list-bulleted-square </v-icon>
                            <div style="padding-left: 8px">
                              {{ section.name }}
                            </div>
                          </v-btn>
                          <vue-horizontal responsive>
                            <section
                              v-for="contributor in section.contributors"
                              :key="contributor.id"
                            >
                              <contributor-card :contributor="contributor">
                              </contributor-card>
                            </section>
                          </vue-horizontal>
                        </section>
                      </li>
                    </transition-group>
                  </draggable>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { PageService } from "@/services/page.service";
import {
  ContributorContent,
  GetPageViewModel,
  LayoutListItem,
  ManageContributorViewModel,
  ManagePageViewModel,
  ManageSectionViewModel,
} from "@/models/page.model";
import CommonMixin from "@/mixins/common.mixin";
import VueHorizontal from "vue-horizontal";
import draggable from "vuedraggable";
import ManageBannerDialog from "@/components/layout/manage-banner-dialog.vue";
import ManagePageDialog from "@/components/layout/manage-page-dialog.vue";
import AddContributorDialog from "@/components/layout/add-contributor-dialog.vue";
import ManagePageSectionDialog from "@/components/layout/manage-page-section-dialog.vue";
import ContributorCard from "@/components/layout/contributor-card.vue";
import { LoadingType } from "@/models/common.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "media-app-builder",
  mixins: [CommonMixin],
  components: {
    VueHorizontal,
    draggable,
    "manage-banner-dialog": ManageBannerDialog,
    "manage-page-dialog": ManagePageDialog,
    "manage-page-section-dialog": ManagePageSectionDialog,
    "add-contributor-dialog": AddContributorDialog,
    "contributor-card": ContributorCard,
  },
  data() {
    return {
      page: new GetPageViewModel(),
      pages: new Array<LayoutListItem>(),
      drag: false,
      showBannerDialog: false,
      showContributorDialog: false,
      showAddPageSectionDialog: false,
      showPageDialog: false,
      showDeleteDialog: false,
      showDeletePageDialog: false,
      manageContributorId: 0,
      manageSectionId: 0,
      managePageId: 0,
      selectedPageId: 0,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    pageSelected(): boolean {
      return this.managePageId !== 0;
    },
  },
  created() {
    this.getPageList();
  },
  methods: {
    resetPage() {
      this.manageContributorId = 0;
      this.manageSectionId = 0;
      this.managePageId = 0;
      this.page = new GetPageViewModel();
    },

    getPageList() {
      this.resetPage();
      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting information, Please Wait...`
      );

      var service = new PageService();
      service
        .getPages()
        .then((response) => {
          this.hideProgressIndicator();
          this.pages = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    getPage() {
      this.resetPage();
      this.showProgressIndicator(
        LoadingType.Panel,
        `Loading page, Please Wait...`
      );

      var service = new PageService();
      service
        .getPage(this.selectedPageId)
        .then((response) => {
          this.hideProgressIndicator();
          this.page = response.data;
          this.managePageId = this.page.id;
          for (let contributor of this.page.banner) {
            contributor.selected = true;
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    addPage() {
      this.managePageId = 0;
      this.showPageDialog = true;
    },

    editPage() {
      this.managePageId = this.selectedPageId;
      this.showPageDialog = true;
    },

    onPageDialogCancel() {
      this.showPageDialog = false;
    },

    onPageDialogConfirm(viewModel: ManagePageViewModel) {
      this.showPageDialog = false;
      this.showProgressIndicator(
        LoadingType.Panel,
        `Saving details, Please Wait...`
      );

      let service = new PageService();
      service
        .savePageManager(viewModel)
        .then((response) => {
          this.hideProgressIndicator();
          let match = this.pages.find((f) => f.id == response.data.id);
          if (match) {
            match.name = response.data.name;
          } else {
            this.pages.push({
              id: response.data.id,
              name: response.data.name,
            });
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    removePage() {
      this.showDeletePageConfirmationDialog(this.managePageId);
    },

    showDeletePageConfirmationDialog(item: number) {
      this.dialogMessage = new DialogMessage(
        `Remove page?`,
        `Are you sure you want to remove this page? IMPORTANT! - Removing a page can result in unexpected issue in your mobile, web and desktop platforms.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeletePageDialog = true;
    },

    onDeletePageSelected(item: DialogResponse) {
      this.showDeletePageDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing page, Please Wait..."
        );
        const service = new PageService();
        service
          .removePage(item.entity)
          .then(() => {
            this.hideProgressIndicator();
            this.selectedPageId = 0;
            this.getPageList();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    addBannerItem() {
      this.showBannerDialog = true;
    },

    onBannerDialogCancel() {
      this.showBannerDialog = false;
    },

    onBannerDialogConfirm(items: ContributorContent[]) {
      this.showBannerDialog = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Updating banner, Please Wait..."
      );

      let service = new PageService();
      service
        .updateBanner(this.managePageId, items)
        .then((response) => {
          this.hideProgressIndicator();
          this.page.banner = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    addSection() {
      this.manageSectionId = 0;
      this.showAddPageSectionDialog = true;
    },

    editSection(id: number) {
      this.manageSectionId = id;
      this.showAddPageSectionDialog = true;
    },

    onPageSectionDialogCancel() {
      this.showAddPageSectionDialog = false;
    },

    onPageSectionDialogConfirm(viewModel: ManageSectionViewModel) {
      this.showAddPageSectionDialog = false;
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving section details, Please Wait..."
      );

      var service = new PageService();
      service
        .saveSection(this.page.id, viewModel)
        .then(() => {
          this.hideProgressIndicator();
          this.getPage();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    removeSection(id: number) {
      this.manageSectionId = id;
      this.showDeleteConfirmationDialog(id);
    },

    showDeleteConfirmationDialog(item: number) {
      this.dialogMessage = new DialogMessage(
        `Remove section?`,
        `Are you sure you want to remove this section?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing section, Please Wait..."
        );
        const service = new PageService();
        service
          .removeSection(this.managePageId, item.entity)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.page.sections.findIndex(
              (f) => f.id == item.entity
            );
            if (index !== -1) {
              this.page.sections.splice(index, 1);
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onSectionReordered(action: any) {
      if (action.moved) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Re-ordering section, Please Wait..."
        );

        let service = new PageService();
        service
          .reorderSection(
            this.managePageId,
            action.moved.element.id,
            action.moved.newIndex
          )
          .then(() => {
            this.hideProgressIndicator();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    addContributor() {
      this.manageContributorId = 0;
      this.showContributorDialog = true;
    },

    onContributorDialogCancel() {
      this.showContributorDialog = false;
    },

    onContributorDialogConfirm(viewModel: ManageContributorViewModel) {
      this.showContributorDialog = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving contributor, Please Wait..."
      );

      let service = new PageService();
      service
        .saveContributor(viewModel)
        .then(() => {
          this.hideProgressIndicator();
        })
        .catch((error) => this.showErrorDialog(error));
    },
  },
});
</script>

<style scoped>
section {
  padding: 8px 8px;
}
ul {
  list-style-type: none;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>