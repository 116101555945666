import { render, staticRenderFns } from "./editor-contribution-general-2.vue?vue&type=template&id=7973f2c6"
import script from "./editor-contribution-general-2.vue?vue&type=script&lang=ts"
export * from "./editor-contribution-general-2.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VCombobox,VContainer,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7973f2c6')) {
      api.createRecord('7973f2c6', component.options)
    } else {
      api.reload('7973f2c6', component.options)
    }
    module.hot.accept("./editor-contribution-general-2.vue?vue&type=template&id=7973f2c6", function () {
      api.rerender('7973f2c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/editors/editor-contribution-general-2.vue"
export default component.exports