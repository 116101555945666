<template>
  <v-row>
    <v-col>
      <date-picker
        :date.sync="date"
        :format="format"
        :clearable="clearable"
        :outlined="outlined"
        :disabled="disabled"
        :label="dateLabel"
        :dateRules="dateRules"
        :showHeader="showDateHeader"
        :prependIcon="datePrependIcon"
        @date-changed="onDateOrTimeChanged"
      >
      </date-picker>
    </v-col>
    <v-col md="4">
      <time-picker
        :time.sync="time"
        :clearable="clearable"
        :outlined="outlined"
        :disabled="disabled"
        :label="timeLabel"
        :timeRules="timeRules"
        :prependIcon="timePrependIcon"
        @time-changed="onDateOrTimeChanged"
      >
      </time-picker>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import TimePicker from "@/components/common/time-picker.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "DTPicker",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
    "time-picker": TimePicker,
  },
  props: {
    dateTime: {
      type: String,
      default: "",
      required: true,
    },
    format: {
      type: String,
      default: "cccc d LLLL",
      required: false,
    },
    outlined: {
      type: Boolean,
      default: true,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    dateLabel: {
      type: String,
      default: "Date",
      required: false,
    },
    timeLabel: {
      type: String,
      default: "Time",
      required: false,
    },
    dateRules: {
      type: Array,
      required: false,
    },
    timeRules: {
      type: Array,
      required: false,
    },
    showDateHeader: {
      type: Boolean,
      default: true,
      required: false,
    },
    datePrependIcon: {
      type: String,
      default: "",
      required: false,
    },
    timePrependIcon: {
      type: String,
      default: "",
      required: false,
    },
  },
  watch: {
    dateTime: {
      immediate: true,
      handler(val: string) {
        this.time = val ? DateTime.fromISO(val).toFormat("T") : "";
        this.date = val ? DateTime.fromISO(val).toISODate() : "";
      },
    },
  },
  data() {
    return {
      date: "",
      time: "",
    };
  },
  methods: {
    onDateOrTimeChanged() {
      let val: string = "";

      if (this.date && this.time) {
        let d: DateTime = DateTime.fromISO(this.date);
        let t: DateTime = DateTime.fromISO(this.time);
        let dt: DateTime = DateTime.fromISO(
          `${d.toISODate()}T${t.toUTC().toISOTime()}`
        ).toUTC();

        val = dt.toISO();
      }

      this.$emit("update:dateTime", val);
      this.$emit("date-time-changed", val);
    },
  },
});
</script>