var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "40%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("contributor-content-selector-dialog", {
        attrs: { dialog: _vm.showContentDialog, multiSelect: false },
        on: { "dialog-option-selected": _vm.onContentDialogClosed },
      }),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("div", [_vm._v("Manage Banner")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCancel()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-item-group",
            { attrs: { multiple: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.slots, function (slot) {
                      return _c(
                        "v-col",
                        { key: slot.id, attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-item", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var active = ref.active
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mx-auto",
                                          attrs: {
                                            "max-width": "400",
                                            color: active
                                              ? "primary"
                                              : "grey lighten-1",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onManageSlot(slot)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass:
                                              "white--text align-end",
                                            attrs: {
                                              height: "200px",
                                              src: slot.imageUrl,
                                            },
                                          }),
                                          _c(
                                            "v-card-subtitle",
                                            { staticClass: "pb-0" },
                                            [_vm._v(_vm._s(slot.contentType))]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "text--primary" },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(slot.name)),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onClear(slot)
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Clear ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onCancel()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.hasChanges },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }