<template>
  <v-container fluid>
    <list-valid-options
      v-show="showListView"
      ref="listitems"
      @new-item-requested="onNewItem"
      @show-item="onShowItem"
    ></list-valid-options>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ListValidOptions from "@/views/lists/list-valid-options.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerValidOptions",
  mixins: [CommonMixin],
  components: {
    ListValidOptions,
  },
  data() {
    return {
      showListView: true,
      showManageView: false,
      id: 0,
    };
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems() {
      this.showListView = true;
      this.showManageView = false;

      const ref: any = this.$refs.listitems;
      if (ref) {
        ref.getItems();
      }
    },

    onNewItem() {},

    onShowItem(id: number) {},

    onManageItemCancel() {
      this.getItems();
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>