var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "section",
              { staticClass: "page-header" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "auto" } },
                      [
                        _c("v-select", {
                          attrs: {
                            filled: "",
                            outlined: "",
                            items: _vm.viewModel.groups,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Branches",
                            "no-data-text": "No branches available",
                            "hide-details": "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelectedBranch()
                            },
                          },
                          model: {
                            value: _vm.selectedBranchId,
                            callback: function ($$v) {
                              _vm.selectedBranchId = $$v
                            },
                            expression: "selectedBranchId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "auto" } },
                      [
                        _c("v-select", {
                          attrs: {
                            filled: "",
                            outlined: "",
                            items: _vm.groups,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Groups",
                            "no-data-text": "No groups available",
                            "hide-details": "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelectedGroup()
                            },
                          },
                          model: {
                            value: _vm.selectedGroupId,
                            callback: function ($$v) {
                              _vm.selectedGroupId = $$v
                            },
                            expression: "selectedGroupId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              disabled: !_vm.canManageGroups,
                                              icon: "",
                                              outlined: "",
                                              color: "primary",
                                            },
                                            on: { click: _vm.onNewGroup },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-account-multiple-plus"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [_c("span", [_vm._v("New Group")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              disabled: !_vm.canRemoveGroup,
                                              icon: "",
                                              outlined: "",
                                              color: "primary",
                                            },
                                            on: { click: _vm.onRemoveGroup },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-account-multiple-remove"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [_c("span", [_vm._v("Remove Group")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              icon: "",
                                              outlined: "",
                                              color: "primary",
                                            },
                                            on: { click: _vm.refreshScreen },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-refresh")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [_c("span", [_vm._v("Refresh")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              icon: "",
                                              outlined: "",
                                              color: "primary",
                                              target: "_blank",
                                              href: "https://tnlascog7dprod.blob.core.windows.net/opendatablobstorage/Branches-General-Tab.mp4",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "mdi-lightbulb-question-outline"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("span", [
                              _vm._v("Click here for a short help video"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      !_vm.hasGroups
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(
                              " It seems that no branches have been set up yet or you do not have access. Please contact your system administrator and ask them to set up your branch. "
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("lottie-player", {
                                staticStyle: {
                                  width: "400px",
                                  height: "400px",
                                },
                                attrs: {
                                  src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                                  background: "transparent",
                                  speed: "1",
                                  loop: "",
                                  autoplay: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasGroups,
              expression: "hasGroups",
            },
          ],
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  on: { change: _vm.onTabClick },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", { attrs: { disabled: !_vm.allowGeneralTab } }, [
                    _vm._v("General"),
                  ]),
                  _c("v-tab", [_vm._v("People")]),
                  _c("v-tab", [_vm._v("Positions")]),
                  _c("v-tab", [_vm._v("Events")]),
                  _c("v-tab", { attrs: { disabled: _vm.isMinistry } }, [
                    _vm._v("Services"),
                  ]),
                  _c("v-tab", [_vm._v("Messaging")]),
                  _c("v-tab", { attrs: { disabled: _vm.isMinistry } }, [
                    _vm._v("Returns"),
                  ]),
                  _c("v-tab", [_vm._v("Media")]),
                  _c("v-tab", [_vm._v("Galleries")]),
                  _c("v-tab", { attrs: { disabled: "" } }, [_vm._v("Polls")]),
                  _c("v-tab", { attrs: { disabled: "" } }, [_vm._v("Reports")]),
                  _c("v-tab", { attrs: { disabled: "" } }, [
                    _vm._v("Security"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasGroups,
              expression: "hasGroups",
            },
          ],
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("editor-group-general", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowGeneralTab,
                            expression: "allowGeneralTab",
                          },
                        ],
                        ref: "EditorGroupGeneral",
                        on: {
                          "show-alert-panel": _vm.showSuccessPanel,
                          "group-name-changed": _vm.groupNameChanged,
                        },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowGeneralTab,
                            expression: "!allowGeneralTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-people", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowPeopleTab,
                            expression: "allowPeopleTab",
                          },
                        ],
                        ref: "ListManagerPeople",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowPeopleTab,
                            expression: "!allowPeopleTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-positions", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowPositionsTab,
                            expression: "allowPositionsTab",
                          },
                        ],
                        ref: "ListManagerPositions",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowPositionsTab,
                            expression: "!allowPositionsTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-events", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowEventsTab,
                            expression: "allowEventsTab",
                          },
                        ],
                        ref: "ListManagerEvents",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowEventsTab,
                            expression: "!allowEventsTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-service-times", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowServiceTimesTab,
                            expression: "allowServiceTimesTab",
                          },
                        ],
                        ref: "ListManagerServiceTimes",
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowServiceTimesTab,
                            expression: "!allowServiceTimesTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-demographics-queries", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowMessagingTab,
                            expression: "allowMessagingTab",
                          },
                        ],
                        ref: "ListManagerDemographicsQueries",
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowMessagingTab,
                            expression: "!allowMessagingTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-quarterly-return", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowQuarterlyReturnTab,
                            expression: "allowQuarterlyReturnTab",
                          },
                        ],
                        ref: "ListManagerQuarterlyReturn",
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowQuarterlyReturnTab,
                            expression: "!allowQuarterlyReturnTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-groups-media", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowMediaTab,
                            expression: "allowMediaTab",
                          },
                        ],
                        ref: "ListMediaGroupsMedia",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowMediaTab,
                            expression: "!allowMediaTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-galleries", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowGalleriesTab,
                            expression: "allowGalleriesTab",
                          },
                        ],
                        ref: "ListManagerGalleries",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowGalleriesTab,
                            expression: "!allowGalleriesTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-polls", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowPollsTab,
                            expression: "allowPollsTab",
                          },
                        ],
                        ref: "ListManagerPolls",
                        on: { "show-alert-panel": _vm.showSuccessPanel },
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowPollsTab,
                            expression: "!allowPollsTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c("list-manager-reports", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowReportsTab,
                            expression: "allowReportsTab",
                          },
                        ],
                        ref: "ListManagerReports",
                      }),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowReportsTab,
                            expression: "!allowReportsTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { transition: "false", eager: "" } },
                    [
                      _c(
                        "h2",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.allowSecurityTab,
                              expression: "allowSecurityTab",
                            },
                          ],
                        },
                        [_vm._v("Security")]
                      ),
                      _c("no-permission-card", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.allowSecurityTab,
                            expression: "!allowSecurityTab",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c("action-alert", {
                attrs: {
                  show: _vm.showAlert,
                  message: _vm.alertMessage,
                  type: _vm.alertType,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("new-group-dialog", {
        attrs: {
          dialog: _vm.showNewGroupDialog,
          branchId: _vm.selectedBranchId,
        },
        on: { "new-group-dialog-actioned": _vm.onNewGroupDialogAction },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteGroupDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteGroupSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }