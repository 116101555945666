<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Poll</h6>
        <p class="small" style="padding-right: 40px">
          This section details the poll created by this group.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.startDate"
              :dateRules="validateStartDate"
              label="Display From"
            ></date-picker>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.endDate"
              :dateRules="validateEndDate"
              label="Display To"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="formData.title"
              hide-details="auto"
              :rules="[rules.required]"
              label="Question"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="formData.option1"
              :rules="[rules.required]"
              hide-details="auto"
              label="Option 1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="formData.option2"
              :rules="[rules.required]"
              hide-details="auto"
              label="Option 2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="formData.option3"
              hide-details="auto"
              label="Option 3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="formData.option4"
              hide-details="auto"
              label="Option 4"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              :date.sync="formData.option5"
              label="Option 5"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import { DateTime } from "luxon";
import { ManagePollViewModel } from "@/models/group.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "PollsGeneralSection",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ManagePollViewModel(),
      required: true,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    validateStartDate(): string[] {
      let err: string[] = [];

      if (!this.formData.startDate) {
        err.push("You must provide a start date.");
      }

      return err;
    },

    validateEndDate(): string[] {
      let err: string[] = [];

      if (this.formData.endDate) {
        const ed: DateTime = DateTime.fromISO(this.formData.endDate.toString());
        if (ed.diffNow("hours").hours < 1) {
          err.push("The end date cannot be in the past.");
        }

        if (this.formData.startDate) {
          const sd: DateTime = DateTime.fromISO(
            this.formData.startDate.toString()
          );
          if (sd.diff(ed, "hours").hours > 0) {
            err.push("The end date cannot be before the start date.");
          }
        }
      } else {
        err.push("You must provide an end date.");
      }

      return err;
    },
  },
  methods: {
    onMemberChanged(item: any) {
      if (item) {
        this.formData.heldById = item.id;
        this.formData.heldBy = item.name;
      }
    },
  },
});
</script>
