<template>
  <v-container fluid>
    <v-row>
      <v-col md="2">
        <h6><span class="number">1</span>About</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide a basic summary of the contribution
        </p>
        <image-selector
          :imageUrl="imageUrl"
          @uploaded-image-selected="uploadedImageSelected"
          @approved-image-selected="approvedImageSelected"
        >
        </image-selector>
      </v-col>
      <v-col md="6" offset-md="1">
        <v-row>
          <v-col lg="6">
            <v-text-field
              outlined
              clearable
              v-model="formData.name"
              hide-details="auto"
              :rules="[rules.required]"
              label="Title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4">
            <v-text-field
              outlined
              clearable
              v-model="formData.subtitle"
              hide-details="auto"
              :rules="[rules.required]"
              label="Sub title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6">
            <v-textarea
              outlined
              v-model="formData.description"
              hide-details="auto"
              :rules="[rules.required]"
              label="Description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4">
            <v-text-field
              outlined
              clearable
              v-model="formData.credit"
              hide-details="auto"
              label="Author"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6">
            <v-combobox
              outlined
              clearable
              v-model="formData.tags"
              :items="formData.allTags"
              label="Tags"
              multiple
            ></v-combobox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <h6><span class="number">2</span>Publication</h6>
        <p class="small" style="padding-right: 40px">
          This section is to determine when this content is available
        </p>
      </v-col>
      <v-col md="6" offset-md="1">
        <v-row>
          <v-col md="4">
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.publicationStart"
              :dateRules="validatePublicationStart"
              label="Publish From"
            >
            </date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.publicationEnd"
              :dateRules="validatePublicationEnd"
              label="Publish Until"
            >
            </date-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { ManageContributorViewModel } from "@/models/page.model";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import ImageSelector from "@/components/common/image-selector.vue";
import { PageService } from "@/services/page.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ContributionGeneralSection",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
    "image-selector": ImageSelector,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ManageContributorViewModel(),
      required: true,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val: ManageContributorViewModel) {
        this.imageUrl = val.imageUrl;
      },
    },
  },
  computed: {
    validatePublicationStart(): string[] {
      return this.validateStartDateRange(
        this.formData.publicationStart,
        this.formData.publicationEnd
      );
    },
    validatePublicationEnd(): string[] {
      return this.validateEndDateRange(
        this.formData.publicationStart,
        this.formData.publicationEnd
      );
    },
  },
  data() {
    return {
      valid: true,
      imageUrl: "",
      placeholder: require("@/assets/images/image-placeholder.png"),
    };
  },
  methods: {
    uploadedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.imageUrl = imageUrl;
      this.updateImage();
    },

    approvedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.imageUrl = imageUrl;
      this.updateImage();
    },

    updateImage() {
      if (this.formData.id) {
        const service = new PageService();
        service.updateContributorImage(this.id, this.formData.imageUrl);
      }
    },
  },
});
</script>