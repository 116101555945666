var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "main-container" } },
    [
      _c("add-contributor-dialog", {
        attrs: {
          dialog: _vm.showContributorDialog,
          id: _vm.manageContributorId,
        },
        on: {
          "dialog-closed-cancel": _vm.onContributorDialogCancel,
          "dialog-closed-confirm": _vm.onContributorDialogConfirm,
        },
      }),
      _c("manage-page-section-dialog", {
        attrs: {
          dialog: _vm.showAddPageSectionDialog,
          sectionId: _vm.manageSectionId,
          pageId: _vm.managePageId,
        },
        on: {
          "dialog-closed-cancel": _vm.onPageSectionDialogCancel,
          "dialog-closed-confirm": _vm.onPageSectionDialogConfirm,
        },
      }),
      _c("manage-banner-dialog", {
        attrs: { dialog: _vm.showBannerDialog, currentBanner: _vm.page.banner },
        on: {
          "dialog-closed-cancel": _vm.onBannerDialogCancel,
          "dialog-closed-confirm": _vm.onBannerDialogConfirm,
        },
      }),
      _c("manage-page-dialog", {
        attrs: { dialog: _vm.showPageDialog, pageId: _vm.managePageId },
        on: {
          "dialog-closed-cancel": _vm.onPageDialogCancel,
          "dialog-closed-confirm": _vm.onPageDialogConfirm,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeletePageDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeletePageSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("v-row", [
        _c(
          "div",
          { staticClass: "col-md-12 mt-5" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { lg: "4" } }, [
                  _c("section", { staticClass: "page-header" }, [
                    _c("h5", { staticClass: "wg-title" }, [
                      _vm._v("Mobile App Manager"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "small",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _vm._v(
                          " Design the layout and look of your mobile app here "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { md: "auto" } },
                  [
                    _c("action-alert", {
                      attrs: {
                        show: _vm.showAlert,
                        message: _vm.alertMessage,
                        type: _vm.alertType,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "divider" }, [_c("v-divider")], 1),
            _c("v-card", { staticClass: "wg-card-shadow" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 no-padding" }, [
                  _c(
                    "div",
                    { staticClass: "inner_sidebar" },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    disabled: !_vm.pageSelected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSection()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-format-list-bulleted-square "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v("Add Section")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addContributor()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-format-list-bulleted-square "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v("Add Contributor")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    disabled: !_vm.pageSelected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addBannerItem()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-format-list-bulleted-square "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v("Manage Banner")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-10 content-area" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "2" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.pages,
                                "item-text": "name",
                                "item-value": "id",
                                label: "Pages",
                                outlined: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getPage()
                                },
                              },
                              model: {
                                value: _vm.selectedPageId,
                                callback: function ($$v) {
                                  _vm.selectedPageId = $$v
                                },
                                expression: "selectedPageId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _vm.selectedPageId !== 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePage()
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-delete ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedPageId == 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPage()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        " mdi-format-list-bulleted-square "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-left": "8px" },
                                      },
                                      [_vm._v("Add Page")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editPage()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        " mdi-format-list-bulleted-square "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-left": "8px" },
                                      },
                                      [_vm._v("Edit Page")]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "3" } }),
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "v-carousel",
                              _vm._l(_vm.page.banner, function (banner) {
                                return _c("v-carousel-item", {
                                  key: banner.id,
                                  attrs: { src: banner.imageUrl },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass: "list-group",
                                  attrs: { group: "sections", tag: "ul" },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    change: _vm.onSectionReordered,
                                  },
                                  model: {
                                    value: _vm.page.sections,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.page, "sections", $$v)
                                    },
                                    expression: "page.sections",
                                  },
                                },
                                "draggable",
                                _vm.dragOptions,
                                false
                              ),
                              [
                                _c(
                                  "transition-group",
                                  {
                                    attrs: {
                                      type: "transition",
                                      name: !_vm.drag ? "flip-list" : null,
                                    },
                                  },
                                  _vm._l(_vm.page.sections, function (section) {
                                    return _c(
                                      "li",
                                      {
                                        key: section.id,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("i", {
                                          class: section.fixed
                                            ? "fa fa-anchor"
                                            : "glyphicon glyphicon-pushpin",
                                          attrs: { "aria-hidden": "true" },
                                          on: {
                                            click: function ($event) {
                                              section.fixed = !section.fixed
                                            },
                                          },
                                        }),
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeSection(
                                                      section.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" mdi-delete "),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editSection(
                                                      section.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " mdi-format-list-bulleted-square "
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "8px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(section.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "vue-horizontal",
                                              { attrs: { responsive: "" } },
                                              _vm._l(
                                                section.contributors,
                                                function (contributor) {
                                                  return _c(
                                                    "section",
                                                    { key: contributor.id },
                                                    [
                                                      _c("contributor-card", {
                                                        attrs: {
                                                          contributor:
                                                            contributor,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }