<template>
  <v-container fluid>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <editor-valid-option
      :dialog="showManageDialog"
      :data="manageValidOptionViewModel"
      @valid-option-dialog-actioned="onValidOptionDialogActioned"
    >
    </editor-valid-option>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col md="1"> Options </v-col>
              <v-col md="4">
                <v-btn
                  color="success"
                  @click="onNewItem"
                  :disabled="!viewModel.category"
                  >New Option</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-select
                  v-model="viewModel.category"
                  :items="viewModel.categories"
                  return-object
                  menu-props="auto"
                  hide-details
                  @change="getItems"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :items-per-page="10"
            :headers="headers"
            :items="viewModel.options"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            v-model="selectedItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn color="success" icon @click="onEditItem(item)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import EditValidOption from "@/views/hosts/host-valid-options.vue";
import { LoadingType } from "@/models/common.model";
import {
  ManageValidOptionListViewModel,
  ManageValidOptionViewModel,
  ValidOptionListItem,
} from "@/models/valid-option.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { ValidOptionService } from "@/services/valid-option.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListValidOptions",
  mixins: [CommonMixin],
  components: {
    "editor-valid-option": EditValidOption,
  },
  data() {
    return {
      showDeleteDialog: false,
      showManageDialog: false,
      search: "",
      headers: [
        { text: "Category", value: "category", sortable: false, width: 140 },
        { text: "Option", value: "option", sortable: true, width: 280 },
        { text: "Actions", value: "actions", sortable: false, width: 80 },
      ],
      viewModel: new ManageValidOptionListViewModel(),
      selectedItems: Array<ValidOptionListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      manageValidOptionViewModel: new ManageValidOptionViewModel(),
    };
  },

  methods: {
    onNewItem() {
      this.manageValidOptionViewModel = new ManageValidOptionViewModel();
      this.manageValidOptionViewModel.id = 0;
      this.manageValidOptionViewModel.category = this.viewModel.category;
      this.manageValidOptionViewModel.option = "";

      this.showManageDialog = true;
    },

    onEditItem(item: ValidOptionListItem) {
      this.manageValidOptionViewModel = new ManageValidOptionViewModel();
      this.manageValidOptionViewModel.id = item.id;
      this.manageValidOptionViewModel.category = item.category;
      this.manageValidOptionViewModel.option = item.option;

      this.showManageDialog = true;
    },

    onDeleteItem(item: ValidOptionListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Valid Options, Please Wait...`
      );

      const service = new ValidOptionService();
      service
        .getValidOptions(this.viewModel.category)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: ValidOptionListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.option}?`,
        `Are you sure you want to delete this option? This item will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Option, Please Wait..."
        );
        const service = new ValidOptionService();
        service
          .removeValidOption(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.viewModel.options.findIndex(
              (f) => f.id == item.entity.id
            );
            if (index !== -1) {
              this.viewModel.options.splice(index, 1);
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onValidOptionDialogActioned(item: ValidOptionListItem) {
      this.showManageDialog = false;
      if (item) {
        const match = this.viewModel.options.find((f) => f.id === item.id);
        if (match) {
          match.option = item.option;
        } else {
          this.viewModel.options.unshift(item);
        }
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>