<template>
  <div>
    <!--<v-system-bar color="deep-purple darken-3"></v-system-bar>-->
    <v-app-bar
      app
      dark
      color="primary"
      clipped-left
      clipped-right
      elevation="2"
    >
      <v-app-bar-nav-icon @click.stop="onNavButtonClick()"></v-app-bar-nav-icon>

      <!--
      <div class="d-flex align-top">
        <v-img
          alt="logo"
          contain
          src="../../assets/logo.png"
          transition="scale-transition"
        />
      </div>
-->
      <h3>{{ title }}</h3>
      <v-spacer></v-spacer>

      <user-profile v-if="user.authenticated"></user-profile>

      <div style="width: 10px" />
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import userProfile from "./user-profile.vue";

export default Vue.extend({
  name: "Navbar",
  components: {
    userProfile,
  },
  computed: {
    ...mapGetters({
      user: "user",
      title: "title",
    }),
  },
  methods: {
    onNavButtonClick() {
      this.$emit("nav-button-clicked");
    },
  },
});
</script>
