<template>
  <v-container fluid>
    <v-dialog v-model="showSenderDialog" :max-width="maxWidth" persistent eager>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            <div>Message to {{ heading }}</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <div style="height: 20px" />
            <v-row>
              <v-col>
                <v-text-field
                  v-model="notification.subject"
                  label="Subject"
                  placeholder="Subject"
                  clearable
                  :rules="validateSubject"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-select
                  v-model="selectedQuery"
                  :items="queryOptions"
                  item-text="name"
                  item-value="id"
                  return-object
                  label="Send to this group"
                  placeholder="Choose a group of people that you want to notify"
                  menu-props="auto"
                  hide-details="auto"
                  clearable
                  @change="onSelectedQueryChange"
                  :rules="validateSelectedQuery"
                ></v-select>
              </v-col>
              <v-col md="6">
                <extended-autocomplete
                  :items="allRecipients"
                  :selected.sync="selectedRecipients"
                  :input="searchRecipientsInput"
                  label="And/Or specifically"
                  placeholder="Only recipients who permit contact will be listed"
                >
                </extended-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <template>
                  <v-textarea
                    label="Message"
                    placeholder="Enter your message here."
                    counter="4000"
                    outlined
                    clearable
                    v-model="notification.message"
                    rows="8"
                    :rules="[rules.required]"
                  ></v-textarea>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <v-file-input
                  show-size
                  counter
                  multiple
                  truncate-length="5"
                  label="Attachments"
                  placeholder="Add file attachments here"
                  v-model="attachments"
                  :rules="[rules.attachmentSize]"
                  accept=".txt,.mp4,.mp3,.gif,.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                >
                </v-file-input>
              </v-col>
              <v-col md="4" rowspan="2">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="15"
                      :value="reach"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ reach }} %
                    </v-progress-circular>
                  </template>
                  <span
                    >This is the percentage of your audience that will be
                    reached.<br />This number may not reach 100% if some have
                    opted out of being contacted<br />
                    or have not provided contact information.</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <v-text-field
                  prepend-icon="mdi-link"
                  v-model="notification.contentUrl"
                  label="Content Url"
                  placeholder="A link to additional content like a web site or online form."
                  clearable
                  hide-details="auto"
                  :rules="validateContentUrl"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.app"
                  label="Send by mobile app"
                  hide-details="auto"
                  :rules="validateSendBy"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.email"
                  label="Send by email"
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.sms"
                  label="Send by text"
                  hide-details="auto"
                ></v-switch>
              </v-col>
              <v-col md="6" v-show="notification.sms === true && incursCost">
                <b>Texts are charged. Please check the cost before sending.</b>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
            <v-btn
              color="primary"
              :disabled="!incursCost"
              @click="calculateCost"
            >
              Get Cost
            </v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">
              Send Message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  IDemographicsNotificationRecipient,
  IDemographicsQueryItem,
  IDemographicsQueryResultViewModel,
  IRunDemographicsQueryViewModel,
  NotificationMessage,
} from "@/models/demographics.model";
import { DemographicsService } from "@/services/demographics.service";
import { MediaService } from "@/services/media.service";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";
import { LoadingType, NotificationType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "NotificationSenderDialog",
  mixins: [CommonMixin],
  components: {
    "extended-autocomplete": ExtendedAutoComplete,
  },
  props: {
    dialog: Boolean,
    title: String,
    message: String,
    url: {
      type: String,
      default: "",
      required: false,
    },
    entityId: {
      type: Number,
      default: null,
      required: false,
    },
    category: {
      type: String,
      default: NotificationType.general,
      required: false,
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler(val: boolean) {
        this.showSenderDialog = val;
        if (val === true) {
          this.notification = new NotificationMessage();
          this.notification.subject = this.title;
          this.notification.message = this.message;

          this.resetView();
          const ref: any = this.$refs.manageForm;
          if (ref) {
            ref.validate();
          }

          this.getSendToOptions();
        }
      },
    },
    notification: {
      deep: true,
      handler(val: NotificationMessage) {
        this.calculateReach();
        this.willIncurCost();
      },
    },
    selectedRecipients: {
      deep: true,
      handler(val: IDemographicsNotificationRecipient[]) {
        this.calculateReach();
        this.willIncurCost();
        this.searchRecipientsInput = "";
      },
    },
    title: {
      immediate: true,
      handler(val: string) {
        if (this.notification) {
          this.notification.subject = val;
        }
      },
    },
    message: {
      immediate: true,
      handler(val: string) {
        if (this.notification) {
          this.notification.message = val;
        }
      },
    },
  },
  computed: {
    validateContentUrl(): string[] {
      let err: string[] = [];

      if (this.notification && this.notification.contentUrl) {
        if (this.notification.attachmentUrls.length > 0) {
          err.push(
            "You cannot have an attachment and content link at this present time."
          );
        } else if (!this.isUrl(this.notification.contentUrl)) {
          err.push("You must enter a valid url.");
        }
      }
      return err;
    },
    validateSubject(): string[] {
      let err: string[] = [];
      if (
        !this.notification.subject ||
        (this.notification.subject && this.notification.subject.length === 0)
      ) {
        err.push("You must enter a subject for the message");
      }
      return err;
    },
    validateSelectedQuery(): string[] {
      let err: string[] = [];
      if (!this.selectedQuery) {
        err.push("You must select a group that you want to notify.");
      }
      return err;
    },
    validateSendBy(): string[] {
      let err: string[] = [];
      if (
        this.notification.email === false &&
        this.notification.sms === false &&
        this.notification.app === false
      ) {
        err.push("You must select at least one means of notification.");
      }
      return err;
    },
  },
  data() {
    return {
      valid: true,
      showSenderDialog: false,
      viewModel: {} as IRunDemographicsQueryViewModel,
      allRecipients: Array<IDemographicsNotificationRecipient>(),
      selectedRecipients: Array<IDemographicsNotificationRecipient>(),
      notification: new NotificationMessage(),
      queryOptions: Array<IDemographicsQueryItem>(),
      selectedQuery: {} as IDemographicsQueryItem | null,
      id: 0,
      maxWidth: "40%",
      attachments: Array<File>(),
      reach: 0,
      incursCost: false,
      searchRecipientsInput: "",
      heading: "",
    };
  },
  methods: {
    resetView() {
      this.queryOptions = [];
      this.selectedRecipients = [];
      this.allRecipients = [];
      this.attachments = [];
      this.selectedQuery = null;

      if (this.notification.attachmentUrls.length > 0) {
        for (let attachmentUrl of this.notification.attachmentUrls) {
          URL.revokeObjectURL(attachmentUrl);
        }
      }
    },

    getSendToOptions() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Queries, Please Wait...`
      );

      const service = new DemographicsService();
      service
        .getQueries()
        .then((response) => {
          this.hideProgressIndicator();
          this.queryOptions = response.data;
          this.selectedQuery = null;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onSelectedQueryChange() {
      if (this.selectedQuery !== null) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Getting group recipients, Please Wait..."
        );

        const service = new DemographicsService();
        service
          .runQuery(this.selectedQuery?.id)
          .then((response) => {
            this.hideProgressIndicator();
            var query = response.data;
            this.loadQuery(query);
          })
          .catch((error) => {
            this.showErrorDialog(error);
          });
      }
    },

    loadQuery(val: IRunDemographicsQueryViewModel) {
      this.viewModel = val;
      this.allRecipients = [];
      this.selectedRecipients = [];
      this.attachments = [];
      this.heading = val.title;

      val.attendees.forEach((a) => {
        const isNotifyable: boolean =
          a.gdprPermissionGranted === true &&
          (a.allowContactByEmail === true ||
            a.allowContactBySms === true ||
            a.allowContactByApp == true ||
            a.allowContactByPhone == true);
        if (isNotifyable) {
          this.allRecipients.push({
            id: a.id,
            name: `${a.firstName} ${a.lastName}`,
            isNotifyable: true,
          });
        }
      });
    },

    willIncurCost() {
      let eligible: IDemographicsQueryResultViewModel[] = [];

      if (this.viewModel) {
        for (let recipient of this.selectedRecipients) {
          const match = this.viewModel.attendees.find(
            (f) => f.id === recipient.id
          );
          if (match) {
            if (
              this.notification.sms === true &&
              match.allowContactBySms === true
            ) {
              eligible.push(match);
            }
          }
        }
      }

      this.incursCost = eligible.length > 0;
    },

    calculateCost() {
      if (this.viewModel) {
        this.showProgressIndicator(
          LoadingType.Panel,
          `Calculating cost to send message, Please Wait...`
        );

        this.notification.recipients = [];

        this.notification.queryId = this.viewModel.id;
        this.selectedRecipients.forEach((r) => {
          this.notification.recipients.push(r.id);
        });

        const service = new DemographicsService();
        service
          .getSmsMessageCost(this.viewModel.id, this.notification)
          .then((response) => {
            this.hideProgressIndicator();
            this.showConfirmationDialog(
              "Cost to send message",
              `It is estimated that it will cost approximately ${response.data} to send this message.`
            );
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    calculateReach() {
      let result = 0;
      if (
        this.viewModel &&
        this.viewModel.attendees &&
        this.selectedRecipients &&
        this.viewModel.attendees.length > 0 &&
        this.selectedRecipients.length > 0
      ) {
        let eligible: IDemographicsQueryResultViewModel[] = [];
        for (let recipient of this.selectedRecipients) {
          const match = this.viewModel.attendees.find(
            (f) => f.id === recipient.id
          );
          if (match) {
            if (
              this.notification.email === true &&
              match.allowContactByEmail === true
            ) {
              eligible.push(match);
            } else if (
              this.notification.sms === true &&
              match.allowContactBySms === true
            ) {
              eligible.push(match);
            } else if (
              this.notification.app === true &&
              match.allowContactByApp === true
            ) {
              eligible.push(match);
            }
          }
        }

        result = Math.round(
          (eligible.length / this.viewModel.attendees.length) * 100
        );
      }

      this.reach = result;
    },

    async submitForm() {
      if (this.viewModel) {
        this.notification.recipients = [];
        this.notification.attachmentUrls = [];

        this.notification.queryId = this.viewModel.id;
        this.notification.entityId = this.entityId;
        this.notification.category = this.category;

        this.selectedRecipients.forEach((r) => {
          this.notification.recipients.push(r.id);
        });

        for (let fileAttachment of this.attachments) {
          if (fileAttachment.size > 0) {
            this.showProgressIndicator(
              LoadingType.Panel,
              `Compressing attachment ${fileAttachment.name}, Please Wait...`
            );

            try {
              const service = new MediaService();
              const result = await service.uploadFile(
                fileAttachment,
                fileAttachment.name
              );
              if (result.status === 200) {
                this.notification.attachmentUrls.push(result.data);
              }
            } catch (error) {
              this.showErrorDialog(error);
              return;
            }
          }
        }

        this.showProgressIndicator(
          LoadingType.Panel,
          `Sending message, Please Wait...`
        );

        const service = new DemographicsService();
        service
          .sendMessage(this.notification)
          .then((response) => {
            this.hideProgressIndicator();
            if (response.data === true) {
              this.showConfirmationDialog(
                "Send Message",
                "The message was sent."
              );
              this.$emit("dialog-closed");
            } else {
              this.showConfirmationDialog(
                "Send Message",
                "The message failed to send for an un-known reason. Please try again later."
              );
              this.$emit("dialog-closed");
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onCancel() {
      this.$emit("dialog-closed");
    },
  },
});
</script>

<style scoped src="../../assets/css/wg.css">
.v-progress-circular {
  margin: 1rem;
}
</style>