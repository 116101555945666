<template>
  <v-row>
    <v-container fluid>
      <confirmation-dialog
        :dialog="showDialog"
        :data="dialogMessage"
        @dialog-option-selected="onDialogOptionSelected"
      ></confirmation-dialog>
      <progress-indicator
        :showProgressBar="showProgressBar"
        :showSpinner="showSpinner"
        :message="progressMessage"
      >
      </progress-indicator>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-row>
          <v-col lg="3">
            <h6><span class="number">1</span>General</h6>
            <p class="small" style="padding-right: 40px">
              Use this section to name and briefly describe the purpose of this
              role.
            </p>
          </v-col>
          <v-col lg="7" offset-lg="1">
            <!-- Name -->
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="formData.name"
                  label="Name"
                  placeholder="Name"
                  outlined
                  clearable
                  hide-details="auto"
                  maxlength="80"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description -->
            <v-row>
              <v-col md="10">
                <v-textarea
                  v-model="formData.description"
                  label="Description"
                  placeholder="Description"
                  counter="400"
                  outlined
                  clearable
                  hide-details="auto"
                  maxlength="400"
                  rows="4"
                  :rules="validateRolesAndDescription"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3">
            <h6><span class="number">2</span>Permissions</h6>
            <p class="small" style="padding-right: 40px">
              Use this section to specify what activities this role has
              permission to perform.
            </p>
          </v-col>
          <v-col lg="7" offset-lg="1">
            <v-treeview
              hoverable
              selectable
              :items="items"
              v-model="selected"
            ></v-treeview>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  @click="onCancel"
                  v-bind="attrs"
                  v-on="on"
                  large
                >
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </template>
              <span>Back to list</span>
            </v-tooltip>
            <v-btn color="primary" type="submit" :disabled="!valid" large>
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-row>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import dateTimePicker from "@/components/common/date-time-picker.vue";
import {
  IRolePermissionItem,
  ManageRoleViewModel,
} from "@/models/security.model";
import { SecurityService } from "@/services/security.service";
import { AlertType, LoadingType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EditorSectionRoles",
  mixins: [CommonMixin],
  components: {
    dateTimePicker,
  },
  data() {
    return {
      valid: true,
      id: 0,
      formData: new ManageRoleViewModel(),
      selected: Array<string>(),
      items: Array<IRolePermissionItem>(),
    };
  },
  computed: {
    validateRolesAndDescription(): string[] {
      let err: string[] = [];

      if (!this.formData.description) {
        err.push("You must enter a description");
      }

      // Added to the description validation as the treeview does not have an errors property of it's own
      if (this.selected.length == 0) {
        err.push("You must select at least one permission.");
      }

      return err;
    },
  },
  methods: {
    resetData() {
      this.items = [];
      this.selected = [];
      this.formData = new ManageRoleViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem(id: number) {
      this.id = id;
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Role Information, Please Wait..."
      );

      const service = new SecurityService();
      service
        .getRole(this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.items = response.data.items;
          this.selected = response.data.selected;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Information, Please Wait..."
      );

      this.formData.selected = this.selected;

      const service = new SecurityService();
      service
        .saveRole(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.$emit(
            "show-alert-panel",
            "The data was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onCancel() {
      this.$emit("manage-item-cancel");
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>