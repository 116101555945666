import { GroupType } from "@/models/common.model";
import Vue from "vue";

export default Vue.extend({
    name: "GroupMixin",
    methods: {
        toFriendlyGroupName(groupType: string): string {
            let name: string = '';
            switch (groupType) {
                case GroupType.Branch:
                    name = 'Branch';
                    break;
                case GroupType.LocalMinistry:
                    name = 'Group';
                    break;
                case GroupType.NationalMinistry:
                    name = 'Ministry';
                    break;
            }
            return name;
        }
    }
});