var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "3" } }, [
                _vm._v(
                  " It seems that you do not have permission to access this information. Please ask your administrator for access. "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "3" } },
                [
                  _c("lottie-player", {
                    staticStyle: { width: "400px", height: "400px" },
                    attrs: {
                      src: "https://assets10.lottiefiles.com/packages/lf20_xmnyrzjz.json",
                      background: "transparent",
                      speed: "1",
                      loop: "",
                      autoplay: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }