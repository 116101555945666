<template>
    <v-container fluid>
        <v-form ref="manageUserForm" v-model="valid" @submit.prevent="submitForm">
            <v-row>
                <v-col>
                    <section class="page-header">
                        <v-row>
                            <v-col md="3">
                                <v-text-field outlined filled v-model="displayName" hide-details="auto">
                                </v-text-field>
                            </v-col>
                            <v-col md="4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined color="primary" icon @click="onCancel" v-bind="attrs" v-on="on">
                                            <v-icon>mdi-arrow-left-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Back to list</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="ma-2" icon outlined color="primary" v-bind="attrs" v-on="on"
                                            @click="refreshScreen"><v-icon>mdi-refresh</v-icon></v-btn>
                                    </template>
                                    <span>Refresh</span>
                                </v-tooltip>
                                <v-btn color="primary" type="submit" :disabled="!valid" large>
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </section>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="5">
                    <v-tabs v-model="tab">
                        <v-tab>General</v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-tabs-items v-model="tab">
                        <v-tab-item transition="false" eager>
                            <!--
                            <position-general-section ref="positiongeneralsection" :id="id" :ownerId="ownerId"
                                :formData="formData" :groupType="groupType">
                            </position-general-section>
                            -->
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-form>
        <v-row>
            <v-col md="auto">
                <action-alert :show="showAlert" :message="alertMessage" :type="alertType"></action-alert>
            </v-col>
        </v-row>
        <progress-indicator :showProgressBar="showProgressBar" :showSpinner="showSpinner" :message="progressMessage">
        </progress-indicator>
        <confirmation-dialog :dialog="showDialog" :data="dialogMessage" :errors="dialogErrors"
            @dialog-option-selected="onDialogOptionSelected"></confirmation-dialog>
    </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import { ManageServiceTimeViewModel } from "@/models/group.model";
import { GroupService } from "@/services/group.service";
import { AlertType, GroupType, LoadingType } from "@/models/common.model";

export default (
    Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
    name: "HostServiceTimes",
    mixins: [CommonMixin],
    components: {
        'action-alert': ActionAlert,
    },
    computed: {
        displayName(): string {
            let name: string = "";
            if (this.formData.title) {
                name = this.formData.title;
            }
            return name;
        },
    },
    data() {
        return {
            tab: null,
            valid: true,
            formData: new ManageServiceTimeViewModel(),
            canViewSensitiveData: false,
            id: 0,
            ownerId: 0,
            branchId: 0,
            groupId: 0,
            groupType: "",
        };
    },
    created() {
        if (this.$route.query.branchId) {
            this.branchId = parseInt(this.$route.query.branchId.toString());
        }
        if (this.$route.query.groupId) {
            this.groupId = parseInt(this.$route.query.groupId.toString());
        }
        if (this.$route.query.id) {
            this.id = parseInt(this.$route.query.id.toString());
        }
        if (this.$route.query.groupType) {
            this.groupType = this.$route.query.groupType.toString();
        }

        this.ownerId =
            this.groupType === GroupType.Branch ||
                this.groupType == GroupType.NationalMinistry
                ? this.branchId
                : this.groupId;

        let title = this.getNavTitle();
        if (!title.endsWith("(Service Times)")) {
            this.updateNavTitle(`${this.getNavTitle()} (Service Times)`);
        }

        this.getItem();
    },
    methods: {
        refreshScreen() {
            this.getItem();
        },
        getItem() {
            this.tab = null;

            this.showProgressIndicator(
                LoadingType.Panel,
                "Getting Service Time Information, Please Wait..."
            );

            const service = new GroupService();
            service
                .getServiceTime(this.ownerId, this.id)
                .then((response) => {
                    this.hideProgressIndicator();
                    this.formData = response.data;
                })
                .catch((error) => this.showErrorDialog(error));
        },
        submitForm() {
            this.showProgressIndicator(
                LoadingType.Panel,
                "Saving Information, Please Wait..."
            );

            const service = new GroupService();
            service
                .saveServiceTime(this.ownerId, this.formData)
                .then((response) => {
                    this.hideProgressIndicator();
                    this.formData = response.data;
                    this.showSuccessPanel(
                        "The service time was saved successfully",
                        AlertType.Success
                    );
                })
                .catch((error) => {
                    this.showErrorDialog(error);
                });
        },

        onCancel() {
            if (this.groupType == GroupType.NationalMinistry) {
                this.$router.replace({
                    name: "host-service-times",
                    query: {
                        ministryId: this.branchId.toString(),
                        groupId: this.groupId.toString(),
                        groupType: this.groupType,
                        tab: "positions",
                    },
                });
            } else {
                this.$router.replace({
                    name: "host-service-times",
                    query: {
                        branchId: this.branchId.toString(),
                        groupId: this.groupId.toString(),
                        groupType: this.groupType,
                        tab: "service-times",
                    },
                });
            }
        },

        showSuccessPanel(message: string, type: string) {
            this.showAlertPanel(message, type);
        },
    },
});
</script>