<template>
  <v-container fluid>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-row>
      <v-col>
        <v-btn color="success" @click="onNewGroup">New {{ groupName }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created a {{ groupName }} yet. Create
              your first one by clicking the New {{ groupName }} button.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4">
                {{ groupName }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :items-per-page="15"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            v-model="selectedItems"
          >
            <template v-slot:[`item.imageUrl`]="{ item }">
              <div class="container">
                <a v-if="item.mapUrl" :href="item.mapUrl" target="_blank">
                  <img :src="item.imageUrl" />
                </a>
                <img v-else :src="item.imageUrl" />
              </div>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <a :href="getMailLink(item.email)">{{ item.email }}</a>
            </template>
            <template v-slot:[`item.webSiteUrl`]="{ item }">
              <a :href="item.webSiteUrl" target="_blank">{{
                item.webSiteUrl
              }}</a>
            </template>
            <template v-slot:[`item.contactNumber`]="{ item }">
              <a :href="getPhoneLink(item.contactNumber)" target="_blank">{{
                item.contactNumber
              }}</a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn icon color="success" @click="onEditItem(item)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn icon color="primary" @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import GroupMixin from "@/mixins/group.mixin";
import { LoadingType } from "@/models/common.model";
import { IGroupListItem } from "@/models/group.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { DataOptions } from "vuetify";

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof CommonMixin> & InstanceType<typeof GroupMixin>
  >
).extend({
  name: "ListGroups",
  mixins: [CommonMixin, GroupMixin],
  props: {
    groupType: {
      type: String,
      default: "branch",
    },
  },

  data() {
    return {
      showDeleteDialog: false,
      search: "",
      headers: [
        { text: "Image", value: "imageUrl", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email", sortable: false },
        { text: "Web Site", value: "webSiteUrl", sortable: false },
        { text: "Contact No", value: "contactNumber", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      groupName: this.toFriendlyGroupName(this.groupType),
      items: Array<IGroupListItem>(),
      selectedItems: Array<IGroupListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
    };
  },

  methods: {
    onNewGroup() {
      this.$emit("new-group-requested");
    },

    onEditItem(item: IGroupListItem) {
      this.$emit("show-group", item.id);
    },

    onDeleteItem(item: IGroupListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems() {
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting ${this.groupName}, Please Wait...`
      );

      const service = new GroupService();
      service
        .getAllGroups(this.groupType)
        .then((response) => {
          this.hideProgressIndicator();
          for (let item of response.data) {
            if (!item.imageUrl) {
              item.imageUrl = require("@/assets/images/COG7-logo.png");
            }
          }
          this.items = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: IGroupListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.name}?`,
        `Are you sure you want to delete this ${this.groupName.toLowerCase()}? This item will no longer be listed ANYWHERE in this system until it is restored from the recycle bin. It will be PERMANENTLY removed after 30 days.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Event, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeGroup(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>