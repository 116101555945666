<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <v-card>
      <v-card-title class="headline">
        <div>Content Selector</div>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="onCancel()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-col md="6">
          <v-autocomplete
            :items="contributors"
            item-text="name"
            label="Contributors"
            outlined
            v-model="contributor"
            return-object
            @change="onContributorChange()"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-data-iterator
            :items="content"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <v-card
                      :elevation="!multiSelect && hover ? 12 : 3"
                      :outlined="!multiSelect && hover"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-img
                        :src="item.imageUrl"
                        :lazy-src="placeholder"
                        :class="[!multiSelect ? imageCss : '']"
                        @click="!multiSelect && onImageSelected(item)"
                      >
                        <v-app-bar flat color="rgba(0,0,0,0)">
                          <v-checkbox
                            v-show="multiSelect"
                            v-model="item.selected"
                          ></v-checkbox>
                        </v-app-bar>
                      </v-img>

                      <v-card-subtitle class="pb-0">{{
                        item.name
                      }}</v-card-subtitle>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
        <v-btn color="primary" @click="onConfirm" :disabled="!hasSelectedItems">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import {
  Contributor,
  ContributorContent,
  LayoutListItem,
} from "@/models/page.model";
import { DataOptions } from "vuetify";
import { PageService } from "@/services/page.service";

export default Vue.extend({
  name: "ContributorContentSelectorDialog",
  data() {
    return {
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      contributors: new Array<LayoutListItem>(),
      contributor: new LayoutListItem(),
      content: new Array<ContributorContent>(),
    };
  },
  props: {
    dialog: Boolean,
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: {
      handler(val: boolean) {
        if (val === true) {
          this.onLaunch();
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.onGridOptionsChange();
      },
      deep: true,
    },
  },
  computed: {
    hasSelectedItems(): boolean {
      const set = this.content as ContributorContent[];
      if (set) {
        const items = set.filter((f) => f.selected);
        return items.length > 0;
      } else {
        return false;
      }
    },
  },
  methods: {
    onLaunch() {
      this.getContributors();
    },

    onGridOptionsChange() {
      this.onSearch();
    },

    onContributorChange() {
      this.onSearch();
    },

    getContributors() {
      this.contributors = [];
      this.content = [];
      this.contributor = new Contributor();

      let service = new PageService();
      service.getSimplifiedContributorsList().then((response) => {
        this.contributors = response.data;
      });
    },

    onSearch() {
      this.content = [];
      this.totalItems = 0;

      if (this.contributor && this.contributor.id > 0) {
        const { page, itemsPerPage }: DataOptions = this.options;

        // If the search is being called from a dialog property watch
        // we may not have the data options. In this case take it from
        // the default values we have set up in the data section.
        let pageNumber = page ? page : 1;
        let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

        let service = new PageService();
        service
          .getContributorContent(this.contributor.id, pageNumber, perPage)
          .then((response) => {
            this.content = response.data.items;
            this.totalItems = response.data.totalCount;
          });
      }
    },

    onImageSelected(item: ContributorContent) {
      this.$emit("dialog-option-selected", [item]);
    },

    onConfirm() {
      const set = this.contributors as ContributorContent[];
      let items: ContributorContent[] = [];
      if (set) {
        items = set.filter((f) => f.selected) as ContributorContent[];
      }
      this.$emit("dialog-option-selected", items);
    },

    onCancel() {
      this.$emit("dialog-option-selected", []);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>