var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "35%", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("div", [_vm._v("Add Contributor")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onCancel()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("div", { staticStyle: { height: "20px" } }),
                      _c(
                        "v-stepper",
                        {
                          attrs: { vertical: "" },
                          model: {
                            value: _vm.stepNo,
                            callback: function ($$v) {
                              _vm.stepNo = $$v
                            },
                            expression: "stepNo",
                          },
                        },
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.canProceedToNextStep(1),
                                step: "1",
                              },
                            },
                            [
                              _vm._v(" What type of contribution is this? "),
                              _c("small", [
                                _vm._v(
                                  "Contributions contains content of a particular type such as podcasts, videos, and blog entries"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c("div", { staticStyle: { height: "4px" } }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.contributor.contentTypes,
                                  label: "Contribution Type",
                                  outlined: "",
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.contributor.contentType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contributor,
                                      "contentType",
                                      $$v
                                    )
                                  },
                                  expression: "contributor.contentType",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep()
                                    },
                                  },
                                },
                                [_vm._v(" Continue ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.canProceedToNextStep(2),
                                step: "2",
                              },
                            },
                            [
                              _vm._v(" Where is the content held? "),
                              _c("small", [
                                _vm._v(
                                  "Please specify the url of where this contribution will pull in it's content"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c("div", { staticStyle: { height: "4px" } }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Source Url",
                                  placeholder: "Source Url",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.contributor.contentUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contributor, "contentUrl", $$v)
                                  },
                                  expression: "contributor.contentUrl",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.hasContentUrl(),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep()
                                    },
                                  },
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prevStep()
                                    },
                                  },
                                },
                                [_vm._v(" Back ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.canProceedToNextStep(3),
                                step: "3",
                              },
                            },
                            [
                              _vm._v(" Edit the details "),
                              _c("small", [
                                _vm._v(
                                  "Please confirm the details of this contribution and make any adjustments here."
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c("div", { staticStyle: { height: "4px" } }),
                              _c("v-row", [
                                _c("div", { staticClass: "container" }, [
                                  _c("img", {
                                    attrs: { src: _vm.contributor.imageUrl },
                                  }),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Title",
                                          placeholder: "Title",
                                          outlined: "",
                                          clearable: "",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.contributor.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contributor,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contributor.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "Description",
                                          placeholder: "Description",
                                          counter: "2000",
                                          outlined: "",
                                          clearable: "",
                                          rules: [_vm.rules.required],
                                          rows: "4",
                                        },
                                        model: {
                                          value: _vm.contributor.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contributor,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "contributor.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.valid,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep()
                                    },
                                  },
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prevStep()
                                    },
                                  },
                                },
                                [_vm._v(" Back ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.canProceedToNextStep(4),
                                step: "4",
                              },
                            },
                            [
                              _vm._v(" Confirm the details "),
                              _c("small", [
                                _vm._v(
                                  "Confirm the deatils. This will save the contribution."
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "4" } },
                            [
                              _c("v-row", [
                                _c("div", { staticClass: "container" }, [
                                  _c("img", {
                                    attrs: { src: _vm.contributor.imageUrl },
                                  }),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Title",
                                          placeholder: "Title",
                                          outlined: "",
                                          clearable: "",
                                          readonly: "",
                                          disabled: "",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.contributor.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contributor,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contributor.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    type: "submit",
                                    disabled: !_vm.valid,
                                  },
                                },
                                [_vm._v(" Complete ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prevStep()
                                    },
                                  },
                                },
                                [_vm._v(" Back ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onCancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }