<template>
  <v-card elevation="4">
    <v-system-bar :color="systemBarColor"></v-system-bar>
    <v-card-title>
      <div>{{ title }}</div>
    </v-card-title>
    <v-card-text>
      <p>Held at</p>
      <div class="text--primary">
        <a :href="mapUrl" target="_blank">{{ address }}</a>
      </div>
      <p></p>
      <div v-if="isOnline" class="text--primary">
        <a :href="linkUrl" target="_blank">{{ linkUrl }}</a>
      </div>
      <p v-if="isOnline"></p>
      <div class="text--primary" v-if="isOnline && dialInNumber">
        Dial In: {{ dialInNumber }} / {{ dialInCode }}
      </div>
      <div class="text--primary" v-if="isOnline && meetingId">
        Meeting: {{ meetingId }} / {{ meetingCode }}
      </div>
      <p v-if="isOnline"></p>
      <div class="text--primary">
        {{ start }}
      </div>
      <div class="text--primary">
        {{ end }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onNew()">New</v-btn>
      <v-btn color="primary" text @click="onRemove()">Remove</v-btn>
      <v-btn color="primary" text @click="onEdit()">Edit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { EventLocation } from "@/models/events.model";
import { DateTime } from "luxon";
import Vue from "vue";

export default Vue.extend({
  name: "EventLocationCard",
  props: {
    location: {
      type: Object,
      default: () => new EventLocation(),
      required: true,
    },
  },

  watch: {
    location: {
      deep: true,
      immediate: true,
      handler(val: EventLocation) {
        this.isOnline = val.isOnline;
        this.platform = val.platform;
        this.linkUrl = val.linkUrl;
        this.dialInNumber = val.dialInNumber;
        this.dialInCode = val.dialInCode;
        this.meetingCode = val.meetingCode;
        this.meetingId = val.meetingId;
        this.title = val.title;
        this.address = val.address;
        this.mapUrl = val.url;
        this.start = val.startDateTime
          ? DateTime.fromISO(val.startDateTime.toString()).toFormat(
              "cccc d LLLL, T"
            )
          : "";
        this.end = val.endDateTime
          ? DateTime.fromISO(val.endDateTime.toString()).toFormat(
              "cccc d LLLL, T"
            )
          : "";

        this.systemBarColor = val.id !== 0 ? "success" : "grey";
      },
    },
  },

  data() {
    return {
      platform: "",
      linkUrl: "",
      dialInNumber: "",
      dialInCode: "",
      meetingCode: "",
      meetingId: "",
      isOnline: false,
      title: "",
      address: "",
      start: "",
      end: "",
      mapUrl: "",
      systemBarColor: "grey",
    };
  },

  methods: {
    onNew() {
      this.$emit("on-new-location");
    },
    onEdit() {
      this.$emit("on-edit-location", this.location);
    },
    onRemove() {
      this.$emit("on-remove-location", this.location);
    },
  },
});
</script>