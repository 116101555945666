var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "40%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("div", [_vm._v("Contributors")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCancel()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-iterator", {
                attrs: {
                  items: _vm.contributors,
                  "items-per-page": _vm.itemsPerPage,
                  "footer-props": _vm.footerOptions,
                  options: _vm.options,
                  "server-items-length": _vm.totalItems,
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "v-row",
                          _vm._l(props.items, function (item) {
                            return _c(
                              "v-col",
                              {
                                key: item.id,
                                attrs: {
                                  cols: "12",
                                  sm: "6",
                                  md: "4",
                                  lg: "3",
                                },
                              },
                              [
                                _c("v-hover", {
                                  attrs: { "open-delay": "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var hover = ref.hover
                                          return [
                                            _c(
                                              "v-card",
                                              {
                                                class: { "on-hover": hover },
                                                attrs: {
                                                  elevation:
                                                    !_vm.multiSelect && hover
                                                      ? 12
                                                      : 3,
                                                  outlined:
                                                    !_vm.multiSelect && hover,
                                                },
                                              },
                                              [
                                                _c("v-img", {
                                                  class: [
                                                    !_vm.multiSelect
                                                      ? _vm.imageCss
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    src: item.imageUrl,
                                                    "lazy-src": _vm.placeholder,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      !_vm.multiSelect &&
                                                        _vm.onImageSelected(
                                                          item
                                                        )
                                                    },
                                                  },
                                                }),
                                                _c("v-card-title", [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]),
                                                _c(
                                                  "v-card-subtitle",
                                                  { staticClass: "pb-0" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.subtitle)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c("v-checkbox", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.multiSelect,
                                                          expression:
                                                            "multiSelect",
                                                        },
                                                      ],
                                                      model: {
                                                        value: item.selected,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selected",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.hasSelectedItems },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }