<template>
  <v-dialog v-model="dialog" max-width="70%" max-height="70%" persistent>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-card>
      <v-form ref="uploadForm" v-model="valid" @submit.prevent="submitForm">
        <v-container fluid>
          <v-row>
            <v-col md="6">
              <div style="height: 20px"></div>

              <v-text-field
                v-model="image.src"
                outlined
                clearable
                prepend-icon="mdi-image-outline"
                label="Upload Image"
                placeholder="Please select or paste an image to upload"
                hint="Location of the image to upload"
                :rules="validateImageUrl"
                @change="onSelectedImageChange"
                @click:prepend="$refs.file.click()"
              ></v-text-field>
              <input
                hidden
                type="file"
                ref="file"
                @change="loadImage($event)"
                accept="image/*"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="8">
              <v-card elevation="10">
                <cropper
                  ref="cropper"
                  class="cropper"
                  :src="image.src"
                  :debounce="false"
                  :auto-zoom="false"
                  :imageRestriction="fitName"
                  :stencil-props="{
                    aspectRatio: 16 / 9,
                  }"
                  @change="onChange"
                />
              </v-card>
            </v-col>
            <v-col md="3">
              <v-row>
                <v-col>
                  <preview
                    :width="375"
                    :height="250"
                    :image="result.image"
                    :coordinates="result.coordinates"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid"> OK </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { MediaService } from "@/services/media.service";
import { v4 as uuidv4 } from "uuid";
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ImageUploader",
  mixins: [CommonMixin],
  components: {
    Cropper,
    Preview,
  },
  props: {
    dialog: Boolean,
  },

  data() {
    return {
      valid: true,
      selectedFileName: "",
      image: {
        src: "https://images.unsplash.com/photo-1593642532744-d377ab507dc8?ixid=MnwxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwzMHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60",
        type: null,
        name: "",
      },
      result: {
        coordinates: null,
        image: null,
      },
      fitName: "fit-area",
    };
  },

  watch: {
    dialog(val: boolean) {
      if (val) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
      }
    },
  },

  computed: {
    validateImageUrl(): string[] {
      let err: string[] = [];
      if (!this.image.src) {
        err.push("You must provide an image to upload");
      }
      return err;
    },
  },

  methods: {
    onCancel() {
      this.$emit("dialog-option-selected", "");
    },

    submitForm() {
      const cropper = this.$refs.cropper as any;
      const { canvas } = cropper.getResult();

      if (canvas) {
        canvas.toBlob((blob: any) => {
          if (!this.selectedFileName) {
            this.selectedFileName = `${uuidv4()}.png`;
          } else {
            let newFileName =
              this.selectedFileName.substr(
                0,
                this.selectedFileName.lastIndexOf(".")
              ) || this.selectedFileName;
            this.selectedFileName = `${newFileName}.png`;
          }

          this.showProgressIndicator(
            LoadingType.Panel,
            "Uploading Image, Please Wait..."
          );

          const service = new MediaService();
          service
            .uploadFile(blob, this.selectedFileName)
            .then((response) => {
              this.hideProgressIndicator();
              const url = response.data;
              this.$emit("dialog-option-selected", url);
            })
            .catch((error) => this.showErrorDialog(error));
        });
      }
    },

    onSelectedImageChange() {
      if (!this.image.src) {
        this.selectedFileName = "";
        this.image.type = null;
        this.image.name = "";
      }
    },

    onChange({ coordinates, image }: { coordinates: any; image: any }) {
      this.result = {
        coordinates,
        image,
      };
    },

    loadImage(event: any) {
      const { files } = event.target;
      if (files && files[0]) {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }

        // Create the blob link to the file to optimize performance
        const blobUrl = URL.createObjectURL(files[0]);

        // Define a callback function to run, when FileReader finishes its job
        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
        // Determine the image type to preserve it during the extracting the image from canvas
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blobUrl,
            type: this.getMimeType(e.target!.result, files[0].type),
            name: files[0].name,
          };

          this.selectedFileName = this.image.name;
        };

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },

    getMimeType(file: any, fallback: string): any {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = "";
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    },
  },

  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
});
</script>

<style scoped>
.cropper {
  height: 600px;
  background: white;
}
</style>
