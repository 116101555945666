var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "manageUserForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "section",
                  { staticClass: "page-header" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                filled: "",
                                "hide-details": "auto",
                              },
                              model: {
                                value: _vm.displayName,
                                callback: function ($$v) {
                                  _vm.displayName = $$v
                                },
                                expression: "displayName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "4" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  color: "primary",
                                                  icon: "",
                                                },
                                                on: { click: _vm.onCancel },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-left-circle"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_c("span", [_vm._v("Back to list")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  icon: "",
                                                  outlined: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: _vm.refreshScreen,
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-refresh"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_c("span", [_vm._v("Refresh")])]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  type: "submit",
                                  disabled: !_vm.valid,
                                  large: "",
                                },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "5" } },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [_c("v-tab", [_vm._v("General")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tab-item", {
                        attrs: { transition: "false", eager: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c("action-alert", {
                attrs: {
                  show: _vm.showAlert,
                  message: _vm.alertMessage,
                  type: _vm.alertType,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDialog,
          data: _vm.dialogMessage,
          errors: _vm.dialogErrors,
        },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }