<template>
  <v-container id="main-container">
    <v-row>
      <div class="col-md-12 mt-5">
        <section class="page-header">
          <h5 class="wg-title">Settings</h5>
          <p class="small" style="margin-bottom: 0">
            Manage your settings here
          </p>
        </section>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-card class="wg-card-shadow">
          <div class="row">
            <div class="col-md-3 no-padding">
              <div class="inner_sidebar">
                <p>Side Content</p>
              </div>
            </div>

            <div class="col-md-9 content-area">
              <p>Main Content</p>
              <v-btn
                v-show="selectedMenuItem == 3"
                class="primary"
                @click="connectToInstagram"
              >
                Instagram
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ConferenceService } from "@/services/conference.service";
import Vue from "vue";

export default Vue.extend({
  name: "settings",
  components: {},
  data() {},
  methods: {
    connectToInstagram() {
      var service = new ConferenceService();
      window.location.href = service.connectToInstagram();
    },
  },
});
</script>