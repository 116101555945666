<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid">
      <v-row>
        <v-col md="3">
          <v-btn class="ma-2" color="success" @click="onNewItem"
            >New Contribution</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-show="showEmptyView" elevation="0">
            <v-row>
              <v-col md="4">
                It seems that you have not added a contribution. Create your
                first one by clicking the New Contribution button.
              </v-col>
              <v-col md="3">
                <lottie-player
                  src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                  background="transparent"
                  speed="1"
                  style="width: 400px; height: 400px"
                  loop
                  autoplay
                ></lottie-player>
              </v-col>
            </v-row>
          </v-card>
          <v-data-iterator
            v-show="showGrid"
            item-key="id"
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col md="auto" v-for="item in props.items" :key="item.id">
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <v-card
                      max-width="400"
                      :elevation="!multiSelect && hover ? 12 : 3"
                      :outlined="!multiSelect && hover"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-system-bar :color="getBarColor(item)"></v-system-bar>
                      <v-img
                        max-height="240"
                        :src="item.imageUrl"
                        :lazy-src="placeholder"
                        :class="[!multiSelect ? imageCss : '']"
                        @click="!multiSelect"
                        aspect-ratio="1"
                      >
                      </v-img>

                      <v-card-title>
                        {{ limitText(item.name, 27) }}
                      </v-card-title>
                      <v-card-subtitle class="pb-0">{{
                        item.subtitle
                      }}</v-card-subtitle>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click.stop="onEditItem(item)"
                        >
                          Edit
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click.stop="onRemoveItem(item)"
                        >
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-form>
    <add-contributor-dialog
      :dialog="showContributorDialog"
      :id="manageContributorId"
      @dialog-closed-cancel="onContributorDialogCancel"
      @dialog-closed-confirm="onContributorDialogConfirm"
    >
    </add-contributor-dialog>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  DialogMessage,
  DialogResponse,
  LoadingType,
} from "@/models/common.model";
import AddContributorDialog from "@/components/layout/add-contributor-dialog.vue";
import { Contributor, ManageContributorViewModel } from "@/models/page.model";
import { RegisteredUserService } from "@/services/registered-user.service";
import { DataOptions } from "vuetify";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListPeopleMedia",
  mixins: [CommonMixin],
  components: {
    "add-contributor-dialog": AddContributorDialog,
  },
  watch: {
    options: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
  },

  computed: {
    hasItems(): boolean {
      return this.items.length > 0;
    },
    hasSelectedItems(): boolean {
      const items = this.items.filter((f) => f.selected);
      return items.length > 0;
    },
  },
  data() {
    return {
      valid: true,
      multiSelect: true,
      showContributorDialog: false,
      showDeleteDialog: false,
      showGrid: false,
      showEmptyView: false,
      userId: 0,
      manageContributorId: 0,
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "items per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      items: Array<Contributor>(),
    };
  },
  methods: {
    onNewItem() {
      this.showContributorDialog = true;
    },

    onContributorDialogCancel() {
      this.showContributorDialog = false;
    },

    onContributorDialogConfirm(viewModel: ManageContributorViewModel) {
      this.showContributorDialog = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving contributor, Please Wait..."
      );

      let service = new RegisteredUserService();
      service
        .saveContributor(this.userId, viewModel)
        .then(() => {
          this.hideProgressIndicator();
          this.onSearch();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    getItems(userId: number) {
      this.userId = userId;
      this.onSearch();
    },

    onSearch() {
      this.items = [];
      if (this.userId === 0) {
        return;
      }

      const { page, itemsPerPage }: DataOptions = this.options;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting contributions, Please Wait..."
      );

      // If the search is being called from a dialog property watch
      // we may not have the data options. In this case take it from
      // the default values we have set up in the data section.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      let service = new RegisteredUserService();

      service
        .getContributions(this.userId, pageNumber, perPage)
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data.items;
          this.totalItems = response.data.totalCount;

          this.showGrid = response.data.items.length > 0;
          this.showEmptyView = response.data.items.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onEditItem(item: Contributor) {
      this.$emit("edit-item-requested", item.id);
    },

    onRemoveItem(item: Contributor) {
      this.showDeleteConfirmationDialog(item);
    },

    showDeleteConfirmationDialog(item: Contributor) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.name}?`,
        `Are you sure you want to delete this ${item.name}? This item will no longer be listed ANYWHERE in this system.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;

      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Contribution, Please Wait..."
        );

        const service = new RegisteredUserService();
        service
          .removeContributor(this.userId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
    getBarColor(item: Contributor): string {
      return item.isPublished ? "success" : "grey";
    },
  },
});
</script>