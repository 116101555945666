import { IUserListItem } from "./security.model";

export const LoadingType = {
  ProgressBar: 0,
  Panel: 1,
};

export const AlertType = {
  Success: "success",
  Information: "info",
  Warning: "warning",
  Error: "error"
};

export enum CalendarEventStatus {
  None,
  ExistingEventSelected,
  NewEventSelected,
}

export const RecurrenceType = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  MonthNth: 4,
  Yearly: 5,
  YearNth: 6,
};

export const ApprovedImages = {
  Platform: {
    Mobile: "mobile",
    Web: "web",
  },
  Category: {
    Events: "media.event",
    Groups: "media.group"
  },
};

export const GroupType = {
  Branch: "Branch",
  NationalMinistry: "NationalMinistry",
  LocalMinistry: "LocalMinistry"
}

export class DialogMessage {
  title: string;
  message: string;
  showCancel: boolean;
  entity?: any;
  errors?: ValidationError[];

  constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
    this.showCancel = false;
    this.entity = undefined;
    this.errors = [];
  }
}

export class DialogResponse {
  option: boolean;
  entity?: any;

  constructor(option: boolean, entity?: any) {
    this.option = option;
    this.entity = entity;
  }
}

export class EntityItem {
  id: number;
  name: string;

  constructor() {
    this.id = 0;
    this.name = "";
  }
}

export interface ISelectableItem {
  id?: number;
  text: string;
  selected: boolean;
  imageUrl: string;
}

export class SelectableItem implements ISelectableItem {
  id?: number;
  text: string;
  selected: boolean;
  imageUrl: string;

  constructor(text: string, selected = false) {
    this.text = text;
    this.selected = selected;
    this.imageUrl = "";
  }
}

export interface ICustomItem {
  id: string;
  text: string;
}

export interface IMenuItem {
  id: number;
  title: string;
  route: string;
  icon?: string;
  enabled: boolean;
  items?: IMenuItem[];
}

export class MenuItem implements IMenuItem {
  id: number;
  title: string;
  route: string;
  icon?: string;
  enabled: boolean;
  items?: IMenuItem[];

  constructor() {
    this.id = 0;
    this.title = '';
    this.icon = '';
    this.route = '';
    this.enabled = false;
    this.items = [];
  }
}

export interface IBaseListItem {
  id: number;
  name: string;
}

export class BaseListItem implements IBaseListItem {
  id: number;
  name: string;

  constructor() {
    this.id = 0;
    this.name = '';
  }
}

export interface ISideOption {
  id: number;
  title: string;
  icon: string;
  name: string;
}

export interface IPagedList<T> {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  itemsPerPage: number;
  items: T[];
}

export interface IValidationError {
  isWarning: boolean;
  message: string;
}

export class ValidationError implements IValidationError {
  id: number;
  key: string;
  isWarning: boolean;
  message: string;

  constructor(id: number, key: string, isWarning: boolean, message: string) {
    this.id = id;
    this.key = key;
    this.isWarning = isWarning;
    this.message = message;
  }
}

export interface IProblemDetails {
  type: string | null;
  title: string | null;
  status: number | null;
  detail: string | null;
  instance: string | null;
  errors: { [key: string]: ValidationError[]; } | null;
}

export const NotificationType = {
  general: 'general',
  event: 'event'
}

export interface ISocialMediaReference {
  platform: string;
  contentUrl: string;
}

export class SocialMediaReference implements ISocialMediaReference {
  id: number | null;
  platform: string;
  contentUrl: string;

  constructor() {
    this.id = 0;
    this.platform = '';
    this.contentUrl = '';
  }
}

export class MultiItemSelectionItem {
  option: string;
  constructor(o: string) {
    this.option = o;
  }
}

export interface IParsedData {
  data: Array<any>
  errors: Array<any>
  meta: any
}

export interface RecurrenceSchedule {
  frequency: string;
  interval: number;
  byDay: RecurrenceScheduleWeekDay[];
  byMonth: number[];
  byMonthDay: number[];
  until: string | null;
}

export interface RecurrenceScheduleWeekDay {
  offset: number;
  dayOfWeek: DayOfWeek;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export const UserFilterName = {
  All: "All"
}

export const StateKeyName = {
  SetPeopleSearchFilterId: "setPeopleSearchFilterId",
  SetMinistryPeopleSearchFilterId: "setMinistryPeopleSearchFilterId"
}

export interface ISendMessageViewModel {
  subject: string;
  recipients: IUserListItem[];
  message: string;
  attachmentUrls: string[];
  email: boolean;
  sms: boolean;
  app: boolean;  
}