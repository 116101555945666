<template>
  <vue-google-autocomplete
    :id="id"
    :address="address"
    :country="country"
    :clearable="clearable"
    :outlined="outlined"
    :placeholder="placeholder"
    :rules="mapRules"
    :hideDetails="hideDetails"
    :disabled="disabled"
    :readonly="readonly"
    v-on:placechanged="getAddressData"
    @cleared="onClear"
  >
  </vue-google-autocomplete>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import VueGoogleAutocomplete from "@/components/common/google-autocomplete.vue";
import { EventLocation, IPlaceResultData } from "@/models/events.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "MapsAutocomplete",
  mixins: [CommonMixin],
  components: {
    "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: false,
    },
    country: {
      type: Array,
      default: () => {
        return ["GB"];
      },
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Address",
    },
    mapRules: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClear() {
      this.$emit("addressChanged", new EventLocation());
    },
    getAddressData: function (addressData: any, placeResultData: any, id: any) {
      const gLocation = placeResultData as IPlaceResultData;

      var internalLocation = new EventLocation();

      internalLocation.localId = this.id;
      internalLocation.address = gLocation.formatted_address;
      internalLocation.houseNameOrNumber = addressData.street_number
        ? addressData.street_number
        : addressData.name;
      internalLocation.street = addressData.route;
      internalLocation.city = this.getAddressPart(gLocation, "postal_town");
      internalLocation.country = addressData.administrative_area_level_1;
      internalLocation.postCode = addressData.postal_code;
      internalLocation.region = addressData.administrative_area_level_2;
      internalLocation.url = gLocation.url;
      internalLocation.longitude = addressData.longitude;
      internalLocation.latitude = addressData.latitude;

      this.$emit("addressChanged", internalLocation);
    },

    getAddressPart(location: IPlaceResultData, name: string): string {
      let part = "";

      for (let component of location.address_components) {
        for (let type of component.types) {
          if (type === name) {
            part = component.long_name;
          }
        }
      }

      return part;
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>