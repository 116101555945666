<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Admins</v-tab>
      <v-tab>Roles</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <div style="height: 20px"></div>
      <v-tab-item transition="false">
        <panel-user-role-manager
          ref="userrolemanager"
          @show-alert-panel="showSuccessPanel"
        >
        </panel-user-role-manager>
      </v-tab-item>
      <v-tab-item transition="false">
        <panel-role-manager
          ref="rolemanager"
          @show-alert-panel="showSuccessPanel"
        >
        </panel-role-manager>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import PanelRoleManager from "@/views/list-managers/list-manager-roles.vue";
import PanelUserRoleManager from "@/views/list-managers/list-manager-user-roles.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerSecurity",
  mixins: [CommonMixin],
  components: {
    PanelRoleManager,
    PanelUserRoleManager,
  },
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    tab(val) {
      this.getItems();
    },
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },
    getItems() {
      const ref: any = this.$refs.rolemanager;
      if (ref) {
        ref.getItems();
      }
      const ref1: any = this.$refs.userrolemanager;
      if (ref1) {
        ref1.getItems();
      }
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>