var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "3", xl: "2" } },
            [
              _c("h6", [
                _c("span", { staticClass: "number" }, [_vm._v("1")]),
                _vm._v("Event Details"),
              ]),
              _c(
                "p",
                {
                  staticClass: "small",
                  staticStyle: { "padding-right": "40px" },
                },
                [
                  _vm._v(
                    " Name your event and tell event-goers why they should come. Add details that highlight what makes it unique. "
                  ),
                ]
              ),
              _c("image-selector", {
                attrs: { imageUrl: _vm.imageUrl },
                on: {
                  "uploaded-image-selected": _vm.uploadedImageSelected,
                  "approved-image-selected": _vm.approvedImageSelected,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Title",
                          placeholder: "Title",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.viewModel.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "title", $$v)
                          },
                          expression: "viewModel.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.viewModel.eventTypes,
                          outlined: "",
                          label: "Type Of Event",
                          placeholder: "type of the event",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.viewModel.eventType,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "eventType", $$v)
                          },
                          expression: "viewModel.eventType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "auto-grow": "",
                          "hide-details": "auto",
                          label: "Description",
                          placeholder: "Description",
                          rows: "5",
                          clearable: "",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.viewModel.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "description", $$v)
                          },
                          expression: "viewModel.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Subtitle",
                          placeholder: "Subtitle",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                        },
                        model: {
                          value: _vm.viewModel.subtitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "subtitle", $$v)
                          },
                          expression: "viewModel.subtitle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-time-picker", {
                        attrs: {
                          dateTime: _vm.startDateTimeDisplay,
                          showDateHeader: false,
                          dateRules: _vm.validateEventDate,
                          timeRules: _vm.validateEventTime,
                          dateLabel: "Start Date",
                        },
                        on: {
                          "update:dateTime": function ($event) {
                            _vm.startDateTimeDisplay = $event
                          },
                          "update:date-time": function ($event) {
                            _vm.startDateTimeDisplay = $event
                          },
                          "date-time-changed": _vm.startDateChanged,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-time-picker", {
                        attrs: {
                          dateTime: _vm.endDateTimeDisplay,
                          showDateHeader: false,
                          dateLabel: "End Date",
                        },
                        on: {
                          "update:dateTime": function ($event) {
                            _vm.endDateTimeDisplay = $event
                          },
                          "update:date-time": function ($event) {
                            _vm.endDateTimeDisplay = $event
                          },
                          "date-time-changed": _vm.endDateChanged,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.onSetRecurring },
                          },
                          [_vm._v(_vm._s(_vm.recurrenceButtonText))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.rRule.description
                ? _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(" " + _vm._s(_vm.rRule.description) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Contact Details"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Give your potential attendees a way to contact you and ask questions. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            _vm._l(_vm.viewModel.contacts, function (contact) {
              return _c(
                "v-row",
                { key: contact.id },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          placeholder: "Email",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                          rules: [_vm.rules.email],
                        },
                        model: {
                          value: contact.email,
                          callback: function ($$v) {
                            _vm.$set(contact, "email", $$v)
                          },
                          expression: "contact.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Contact No",
                          placeholder: "Contact No",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                          rules: [_vm.rules.phone],
                        },
                        model: {
                          value: contact.contactNumber,
                          callback: function ($$v) {
                            _vm.$set(contact, "contactNumber", $$v)
                          },
                          expression: "contact.contactNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-1",
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                              icon: "",
                                              "hide-details": "auto",
                                            },
                                            on: { click: _vm.addContact },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("add contact")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-1",
                                            attrs: {
                                              color: "red",
                                              text: "",
                                              icon: "",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeContact(
                                                  contact
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone-remove "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("remove contact")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("recurrence-dialog", {
        attrs: {
          defaultStart: _vm.rDefaultStartDate,
          recurrence: _vm.rRule,
          dialog: _vm.showRecurrenceDialog,
        },
        on: {
          "dialog-cancelled": _vm.onRecurrenceDialogCancelled,
          "remove-recurrence": _vm.onRemoveRecurrence,
          "recurrence-updated": _vm.onRecurrenceUpdated,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }