var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("6")]),
              _vm._v("GDPR"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details the current GDPR settings that the member has agreed to. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "GDPR Permission Granted",
                          "hide-details": "auto",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.gdprPermissionChanged()
                          },
                        },
                        model: {
                          value: _vm.formData.gdprPermissionGranted,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "gdprPermissionGranted", $$v)
                          },
                          expression: "formData.gdprPermissionGranted",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Allow contact by email",
                          disabled: !_vm.formData.gdprPermissionGranted,
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.allowContactByEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowContactByEmail", $$v)
                          },
                          expression: "formData.allowContactByEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Allow contact by SMS text",
                          disabled: !_vm.formData.gdprPermissionGranted,
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.allowContactBySms,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowContactBySms", $$v)
                          },
                          expression: "formData.allowContactBySms",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Allow contact by the app",
                          disabled: !_vm.formData.gdprPermissionGranted,
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.allowContactByApp,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowContactByApp", $$v)
                          },
                          expression: "formData.allowContactByApp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Allow contact by phone",
                          disabled: !_vm.formData.gdprPermissionGranted,
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.allowContactByPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowContactByPhone", $$v)
                          },
                          expression: "formData.allowContactByPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Allow contact by post",
                          disabled: !_vm.formData.gdprPermissionGranted,
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.allowContactByPost,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowContactByPost", $$v)
                          },
                          expression: "formData.allowContactByPost",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }