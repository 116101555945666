var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("confirmation-dialog", {
            attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
            on: { "dialog-option-selected": _vm.onDialogOptionSelected },
          }),
          _c("progress-indicator", {
            attrs: {
              showProgressBar: _vm.showProgressBar,
              showSpinner: _vm.showSpinner,
              message: _vm.progressMessage,
            },
          }),
          _c(
            "v-form",
            {
              ref: "manageForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { lg: "3" } }, [
                    _c("h6", [
                      _c("span", { staticClass: "number" }, [_vm._v("1")]),
                      _vm._v("General"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "small",
                        staticStyle: { "padding-right": "40px" },
                      },
                      [
                        _vm._v(
                          " Use this section to name and briefly describe the purpose of this role. "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { lg: "7", "offset-lg": "1" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  placeholder: "Name",
                                  outlined: "",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "80",
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "10" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Description",
                                  placeholder: "Description",
                                  counter: "400",
                                  outlined: "",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "400",
                                  rows: "4",
                                  rules: _vm.validateRolesAndDescription,
                                },
                                model: {
                                  value: _vm.formData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "description", $$v)
                                  },
                                  expression: "formData.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { lg: "3" } }, [
                    _c("h6", [
                      _c("span", { staticClass: "number" }, [_vm._v("2")]),
                      _vm._v("Permissions"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "small",
                        staticStyle: { "padding-right": "40px" },
                      },
                      [
                        _vm._v(
                          " Use this section to specify what activities this role has permission to perform. "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { lg: "7", "offset-lg": "1" } },
                    [
                      _c("v-treeview", {
                        attrs: {
                          hoverable: "",
                          selectable: "",
                          items: _vm.items,
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "4" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            large: "",
                                          },
                                          on: { click: _vm.onCancel },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "mdi-format-list-bulleted-square"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Back to list")])]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled: !_vm.valid,
                            large: "",
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }