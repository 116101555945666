var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "manageForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "3", xl: "2" } },
                [
                  _c("v-file-input", {
                    attrs: {
                      "show-size": "",
                      counter: "",
                      multiple: "",
                      "truncate-length": "5",
                      label: "Images",
                      placeholder: "Add images here",
                      rules: [_vm.rules.attachmentSize],
                      accept: ".png,.jpg",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (ref) {
                          var index = ref.index
                          var text = ref.text
                          return [
                            index < 2
                              ? _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      color: "primary accent-4",
                                      dark: "",
                                      label: "",
                                      small: "",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(text) + " ")]
                                )
                              : index === 2
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-overline grey--text text--darken-3 mx-2",
                                  },
                                  [
                                    _vm._v(
                                      " +" +
                                        _vm._s(
                                          _vm.selectedFilesForUpload.length - 2
                                        ) +
                                        " File(s) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedFilesForUpload,
                      callback: function ($$v) {
                        _vm.selectedFilesForUpload = $$v
                      },
                      expression: "selectedFilesForUpload",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "success", disabled: !_vm.canUpload },
                      on: { click: _vm.onAddImages },
                    },
                    [_vm._v("Add Images")]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        icon: "",
                                        outlined: "",
                                        color: "primary",
                                        disabled: !_vm.hasSelectedImages,
                                      },
                                      on: { click: _vm.onRemoveMultipleItems },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-delete-sweep-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Remove Selected")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        icon: "",
                                        outlined: "",
                                        color: "primary",
                                        disabled: !_vm.hasImages,
                                      },
                                      on: { click: _vm.onRemoveAllItems },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-delete-empty-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Remove All")])]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showEmptyView,
                          expression: "showEmptyView",
                        },
                      ],
                      attrs: { elevation: "0" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(
                              " It seems that you do not have any images for this event. Add some by clicking the Add Images button or entering the Instagram hash tag. "
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("lottie-player", {
                                staticStyle: {
                                  width: "400px",
                                  height: "400px",
                                },
                                attrs: {
                                  src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                                  background: "transparent",
                                  speed: "1",
                                  loop: "",
                                  autoplay: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-iterator", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showGrid,
                        expression: "showGrid",
                      },
                    ],
                    attrs: {
                      "item-key": "id",
                      items: _vm.images,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerOptions,
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "v-row",
                              _vm._l(props.items, function (item) {
                                return _c(
                                  "v-col",
                                  { key: item.id, attrs: { md: "auto" } },
                                  [
                                    _c("v-hover", {
                                      attrs: { "open-delay": "100" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var hover = ref.hover
                                              return [
                                                _c(
                                                  "v-card",
                                                  {
                                                    class: {
                                                      "on-hover": hover,
                                                    },
                                                    attrs: {
                                                      elevation:
                                                        !_vm.multiSelect &&
                                                        hover
                                                          ? 12
                                                          : 3,
                                                      outlined:
                                                        !_vm.multiSelect &&
                                                        hover,
                                                    },
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      class: [
                                                        !_vm.multiSelect
                                                          ? _vm.imageCss
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        src: item.imageUrl,
                                                        "lazy-src":
                                                          _vm.placeholder,
                                                        "aspect-ratio": "1",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          !_vm.multiSelect
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "v-card-actions",
                                                      [
                                                        _c("v-checkbox", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.multiSelect,
                                                              expression:
                                                                "multiSelect",
                                                            },
                                                          ],
                                                          model: {
                                                            value:
                                                              item.selected,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "selected",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.selected",
                                                          },
                                                        }),
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              text: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onRemoveSingleItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Remove ")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteSingleItemDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteSingleItemSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteMultipleItemsDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteMultipleItemsSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDeleteAllItemsDialog,
          data: _vm.dialogMessage,
        },
        on: { "dialog-option-selected": _vm.onDeleteAllItemsSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }