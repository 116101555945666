var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "manageForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "2" } }, [
                _c("h6", [
                  _c("span", { staticClass: "number" }, [_vm._v("1")]),
                  _vm._v("About"),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "small",
                    staticStyle: { "padding-right": "40px" },
                  },
                  [
                    _vm._v(
                      " This section is to provide basic summary about the conference. "
                    ),
                  ]
                ),
              ]),
              _c(
                "v-col",
                { attrs: { md: "6", "offset-lg": "1" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name",
                              placeholder: "Name",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "80",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Overview",
                              placeholder:
                                "Enter a short overview of the conference.",
                              counter: "4000",
                              outlined: "",
                              clearable: "",
                              rows: "8",
                            },
                            model: {
                              value: _vm.formData.overview,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "overview", $$v)
                              },
                              expression: "formData.overview",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Charity Number",
                              placeholder: "Charity Number",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.formData.charityNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "charityNumber", $$v)
                              },
                              expression: "formData.charityNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              showHeader: false,
                              date: _vm.formData.dateRegistered,
                              dateRules: _vm.validateDateRegistered,
                              label: "Date Registered",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.formData,
                                  "dateRegistered",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "2" } }, [
                _c("h6", [
                  _c("span", { staticClass: "number" }, [_vm._v("2")]),
                  _vm._v("Contact"),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "small",
                    staticStyle: { "padding-right": "40px" },
                  },
                  [
                    _vm._v(
                      " This section is to provide the main contact points for the conference. "
                    ),
                  ]
                ),
              ]),
              _c(
                "v-col",
                { attrs: { md: "6", "offset-lg": "1" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Email",
                              placeholder: "Email",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "256",
                              rules: [_vm.rules.required, _vm.rules.email],
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "email", $$v)
                              },
                              expression: "formData.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Contact Number",
                              placeholder: "Contact Number",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "20",
                              rules: [_vm.rules.phone],
                            },
                            model: {
                              value: _vm.formData.contactNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "contactNumber", $$v)
                              },
                              expression: "formData.contactNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Web Site Url",
                              placeholder: "Web Site Url",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "120",
                              rules: [_vm.rules.url],
                            },
                            model: {
                              value: _vm.formData.webSiteUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "webSiteUrl", $$v)
                              },
                              expression: "formData.webSiteUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }