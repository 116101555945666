<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Quarterly Return</h6>
        <p class="small" style="padding-right: 40px">
          This section details which quarterly return is being managed.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col md="4">
            <v-select
              :items="years"
              v-model="formData.year"
              label="Year"
              placeholder="Select the year"
              menu-props="auto"
              hide-details="auto"
              :rules="[rules.required]"
              outlined
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-select
              :items="quarters"
              v-model="formData.quarter"
              label="Quarter"
              placeholder="Select the quarter"
              menu-props="auto"
              hide-details="auto"
              :rules="[rules.required]"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Current Assets</h6>
        <p class="small" style="padding-right: 40px">
          This section details the quarterly return current assets.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.currentAccountsTotalBalance"
              label="Balance (Current Accounts)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.depositAccountsTotalBalance"
              label="Balance (Deposit Accounts)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.creditUnionAccountBalance"
              label="Balance (Credit Union)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.otherAccountsBalance"
              label="Balance (Other Accounts)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <div style="height: 20px"></div>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Fixed Assets</h6>
        <p class="small" style="padding-right: 40px">
          This section details the quarterly return fixed asset valuations.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.buildingValuationInsuranceValue"
              label="Building (Insurance Value)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.buildingValuationMarketValue"
              label="Building (Market Value)"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.otherAssetsValuation"
              label="Other Assets"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.otherLongTermInvestments"
              label="Other Long Term Investments"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>        
      </v-col>
    </v-row>
    <v-row>
      <div style="height: 20px"></div>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Income</h6>
        <p class="small" style="padding-right: 40px">
          This section details the quarterly return income.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.totalTithesAndOffering"
              label="Tithes & Offering"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.totalGiftAidReceipts"
              label="Gift Aid Receipts"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.totalLoansRecovered"
              label="Loans Recovered"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.totalOtherIncome"
              label="Other Income"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>        
      </v-col>
    </v-row> 
    <v-row>
      <div style="height: 20px"></div>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Expenditure</h6>
        <p class="small" style="padding-right: 40px">
          This section details the quarterly return expenditure.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.totalRunningExpenses"
              label="Normal Running Expenses"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.extraordinaryProjectsExpenditure"
              label="Extraordinary Projects"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.totalConferenceContributions"
              label="Conference Contributions"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.conventionFees"
              label="Convention Fees"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>   
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.loansIssued"
              label="Loans Issued"
              menu-props="auto"
              hide-details="auto"
              type="number"
              outlined
              prefix="£"
            ></v-text-field>
          </v-col>
        </v-row>             
      </v-col>
    </v-row>        
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ManageQuarterlyReturnViewModel } from "@/models/cog7d.model";
import { DateTime } from "luxon";
  
export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "QuarterlyReturnGeneralSection",
  mixins: [CommonMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ManageQuarterlyReturnViewModel(),
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      years: [] as number[],
      quarters: [] as number[]
    };
  },
  created() {
    let currentYear = DateTime.now().year;
    this.years.push(currentYear);
    for (let year = currentYear; year > currentYear - 30; year--) {
      this.years.push(year);
    }
    for (let quarter = 1; quarter < 5; quarter++) {
      this.quarters.push(quarter);
    }
  },
});
</script>