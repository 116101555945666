var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Poll"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [_vm._v(" This section details the poll created by this group. ")]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.startDate,
                          dateRules: _vm.validateStartDate,
                          label: "Display From",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(_vm.formData, "startDate", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.endDate,
                          dateRules: _vm.validateEndDate,
                          label: "Display To",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(_vm.formData, "endDate", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                          label: "Question",
                        },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "title", $$v)
                          },
                          expression: "formData.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          rules: [_vm.rules.required],
                          "hide-details": "auto",
                          label: "Option 1",
                        },
                        model: {
                          value: _vm.formData.option1,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "option1", $$v)
                          },
                          expression: "formData.option1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          rules: [_vm.rules.required],
                          "hide-details": "auto",
                          label: "Option 2",
                        },
                        model: {
                          value: _vm.formData.option2,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "option2", $$v)
                          },
                          expression: "formData.option2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          "hide-details": "auto",
                          label: "Option 3",
                        },
                        model: {
                          value: _vm.formData.option3,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "option3", $$v)
                          },
                          expression: "formData.option3",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          "hide-details": "auto",
                          label: "Option 4",
                        },
                        model: {
                          value: _vm.formData.option4,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "option4", $$v)
                          },
                          expression: "formData.option4",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          date: _vm.formData.option5,
                          label: "Option 5",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(_vm.formData, "option5", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }