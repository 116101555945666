var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "400" } },
    [
      _c("v-img", {
        staticClass: "white--text align-end",
        attrs: { height: "200px", src: _vm.contributor.imageUrl },
      }),
      _c("v-card-title", [_vm._v(" " + _vm._s(_vm.contributor.name) + " ")]),
      _c("v-card-subtitle", { staticClass: "pb-0" }, [
        _vm._v(_vm._s(_vm.contributor.subtitle)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }