import { DateTime } from "luxon";
import { BaseListItem, ISocialMediaReference } from "./common.model";
import { EventLocation } from "./events.model";

export class BranchSelectionViewModel {
    groups: BranchSelectionItem[];

    constructor() {
        this.groups = [];
    }
}

export class BranchSelectionItem extends BaseListItem {
    groups: BaseListItem[];

    constructor() {
        super();
        this.groups = [];
    }
}

export class MinistrySelectionViewModel {
    groups: MinistrySelectionItem[];

    constructor() {
        this.groups = [];
    }
}

export class MinistrySelectionItem extends BaseListItem {
    groups: BaseListItem[];

    constructor() {
        super();
        this.groups = [];
    }
}

export class GroupSelectionViewModel {
    groups: BaseListItem[];

    constructor() {
        this.groups = [];
    }
}

export interface IGroupListItem {
    id: number;
    name: string;
    imageUrl: string;
    email: string;
    webSiteUrl: string;
    contactNumber: string;
    dateEstablished: Date | null;
    mapUrl: string;
}

export class GroupListItem implements IGroupListItem {
    id: number;
    name: string;
    imageUrl: string;
    email: string;
    webSiteUrl: string;
    contactNumber: string;
    dateEstablished: Date | null;
    mapUrl: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.imageUrl = '';
        this.email = '';
        this.webSiteUrl = '';
        this.contactNumber = '';
        this.dateEstablished = null;
        this.mapUrl = '';
    }
}

export interface IManageGroupViewModel {
    applicationId: number;
    conferenceId: number;
    id: number;
    ownerId: number | null;
    name: string;
    groupType: string;
    charityNumber: string;
    contactNumber: string;
    webSiteUrl: string;
    imageUrl: string;
    email: string;
    overview: string;
    visionAndMission: string;
    strapline: string;
    servicesOverview: string;
    services1: string;
    services2: string;
    services3: string;
    services4: string;
    services5: string;
    feedbackUrl: string;
    dateEstablished: Date | null;
    locations: EventLocation[];
    addressTypes: string[];
    mapUrl: string;
    ourReference: string;
    externalPlatforms: string[];
    socials: ISocialMediaReference[];
    updatedOn: Date | null;
}

export class ManageGroupViewModel implements IManageGroupViewModel {
    applicationId: number;
    conferenceId: number;
    id: number;
    ownerId: number | null;
    name: string;
    groupType: string;
    charityNumber: string;
    contactNumber: string;
    webSiteUrl: string;
    imageUrl: string;
    email: string;
    overview: string;
    visionAndMission: string;
    strapline: string;
    servicesOverview: string;
    services1: string;
    services2: string;
    services3: string;
    services4: string;
    services5: string;
    feedbackUrl: string;
    dateEstablished: Date | null;
    locations: EventLocation[];
    addressTypes: string[];
    mapUrl: string;
    ourReference: string;
    externalPlatforms: string[];
    socials: ISocialMediaReference[];
    updatedOn: Date | null;

    constructor() {
        this.applicationId = 0;
        this.conferenceId = 0;
        this.id = 0;
        this.ownerId = null;
        this.name = '';
        this.charityNumber = '';
        this.contactNumber = '';
        this.webSiteUrl = '';
        this.email = '';
        this.overview = '';
        this.visionAndMission = '';
        this.strapline = '';
        this.servicesOverview = '';
        this.dateEstablished = null;
        this.groupType = '';
        this.locations = [];
        this.addressTypes = [];
        this.services1 = '';
        this.services2 = '';
        this.services3 = '';
        this.services4 = '';
        this.services5 = '';
        this.feedbackUrl = '';
        this.mapUrl = '';
        this.imageUrl = '';
        this.ourReference = '';
        this.externalPlatforms = [];
        this.socials = [];
        this.updatedOn = null;
    }
}

export interface IRemoveGroupMembersCommand {
    id: number;
    memberIds: number[];
}

export class PollListItem {
    id: number;
    title: string;
    startDate: Date | null;
    endDate: Date | null;

    constructor() {
        this.id = 0;
        this.title = '';
        this.startDate = null;
        this.endDate = null;
    }
}

export class ManagePollViewModel {
    id: number | null;
    title: string;
    startDate: string;
    endDate: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    option5: string;
    updatedOn: Date | null;

    constructor() {
        this.id = 0;
        this.title = '';
        this.startDate = '';
        this.endDate = '';
        this.option1 = '';
        this.option2 = '';
        this.option3 = '';
        this.option4 = '';
        this.option5 = '';
        this.updatedOn = null;
    }
}

export class PollOptionViewModel {
    id: number;
    title: string;
    numberOfVotes: number;
    votedForThisOption: boolean;

    constructor() {
        this.id = 0;
        this.title = '';
        this.numberOfVotes = 0;
        this.votedForThisOption = false;
    }
}

export class PollViewModel {
    id: number;
    title: string;
    startDate: Date;
    endDate: Date;
    options: PollOptionViewModel[];
    hasVoted: boolean;

    constructor() {
        this.id = 0;
        this.title = '';
        this.startDate = DateTime.now().toJSDate();
        this.endDate = DateTime.now().plus({ days: 7 }).toJSDate();
        this.options = [];
        this.hasVoted = false;
    }
}

export class PositionListItem extends BaseListItem {
    title: string;
    dateStarted: string;
    dateCompleted: string | null;
    expirationDate: string | null;
    heldBy: string;
    heldById: number;
    isMainPosition: boolean;

    constructor() {
        super();
        this.title = '';
        this.heldBy = '';
        this.heldById = 0;
        this.dateStarted = '';
        this.dateCompleted = null;
        this.expirationDate = null;
        this.isMainPosition = false;
    }
}

export class ManagePositionViewModel {
    ownerId: number;
    id: number;
    heldBy: string;
    heldById: number;
    title: string;
    isMainPosition: boolean;
    isPublic: boolean;
    dateStarted: string;
    dateCompleted: string | null;
    reasonForCompletion: string;
    profile: string;
    positions: string[];
    expirationDate: string | null;
    updatedOn: Date | null;

    constructor() {
        this.ownerId = 0;
        this.id = 0;
        this.heldBy = '';
        this.heldById = 0;
        this.title = '';
        this.isMainPosition = false;
        this.isPublic = false;
        this.dateStarted = '';
        this.dateCompleted = null;
        this.reasonForCompletion = '';
        this.profile = '';
        this.positions = [];
        this.expirationDate = null;
        this.updatedOn = null;
    }
}

export class MemberSearchSuggestion {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this.name = '';
    }
}

export interface IImportExportMember {
    ourRef: string;
    isMember: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
    dateOfBaptism: string;
    dateOfFellowship: string;
    dateOfPassing: string;
    address: string;
    primaryContactNo: string;
    secondaryContactNo: string;
    primaryEmail: string;
    secondaryEmail: string;
    ethnicity: string;
    nationality: string;
    maritalStatus: string;
    currentStatus: string;
}

export interface IImportViewModel {
    groupId: number;
    overwriteExisting: boolean;
    members: IImportExportMember[];
}

export interface IUpdateImageCommand {
    id: number;
    imageUrl: string;
}

export interface IUpdateMemberImageCommand {
    groupId: number;
    memberId: number;
    imageUrl: string;
}

export interface IContributorMediaItem {
    id: number;
    contributorId: number;
    name: string;
    imageUrl: string;
    contentUrl: string;
    description: string;
    contentType: string;
    fileSize: number | null;
    duration: number | null;
    publishedOn: string | null;
    publicationStart: string | null;
    publicationEnd: string | null;
}

export interface IUploadMediaItem {
    name: string;
    imageUrl: string;
    contentUrl: string;
}

export class UploadMediaItem {
    name: string;
    imageUrl: string;
    contentUrl: string;

    constructor() {
        this.name = '';
        this.imageUrl = '';
        this.contentUrl = '';
    }
}