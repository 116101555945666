<template>
  <div>
    <v-autocomplete
      v-if="isMemberSelection"
      :items="members"
      :loading="isMatching"
      :search-input.sync="matching"
      item-text="name"
      item-value="id"
      v-model="selectedItem"
      outlined
      clearable
      :label="label"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :readonly="isReadOnly"
      menu-props="auto"
      hide-details="auto"
      return-object
    >
      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                :key="`icon-isMemberSelection`"
                :color="isMemberSelection ? 'success' : 'primary'"
                :disabled="isDisabled"
                :readonly="isReadOnly"
                @click="memberIconClicked"
                v-bind="attrs"
                v-on="on"
                v-text="
                  isMemberSelection
                    ? 'mdi-account-search'
                    : 'mdi-account-question'
                "
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
          <span>Click to enter an unlisted person</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-text-field
      v-if="!isMemberSelection"
      v-model="selectedName"
      :label="label"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :readonly="isReadOnly"
      outlined
      clearable
      hide-details="auto"
      maxlength="200"
    >
      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                :key="`icon-isNotMemberSelection`"
                :color="isMemberSelection ? 'success' : 'primary'"
                :disabled="isDisabled"
                :readonly="isReadOnly"
                @click="memberIconClicked"
                v-bind="attrs"
                v-on="on"
                v-text="
                  isMemberSelection
                    ? 'mdi-account-searcg'
                    : 'mdi-account-question'
                "
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
          <span>Click to select an existing person</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import CommonMixin from "@/mixins/common.mixin";
import Vue, { VueConstructor } from "vue";
import { MemberSearchSuggestion } from "@/models/group.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "MemberCombobox",
  mixins: [CommonMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    ownerId: {
      type: Number,
      default: 0,
      required: false,
    },
    label: {
      type: String,
      default: "Member/Visitor",
      required: false,
    },
    placeholder: {
      type: String,
      default: "Member or Visitor",
      required: false,
    },
    member: {
      type: Object,
      default: () => new MemberSearchSuggestion(),
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
    isMember: Boolean,
  },
  data() {
    return {
      isMatching: false,
      selectedItem: {} as MemberSearchSuggestion,
      members: new Array<MemberSearchSuggestion>(),
      matching: null,
      isMemberSelection: false,
      selectedName: "",
      isDisabled: false,
      isReadOnly: false,
    };
  },
  watch: {
    member: {
      immediate: true,
      handler(newVal, oldVal) {
        this.selectedItem = newVal;
        if (newVal) {
          this.members.push(newVal);
        } else {
          this.members = [];
        }
      },
    },
    name: {
      immediate: true,
      handler(newVal, oldVal) {
        this.selectedName = newVal;
      },
    },
    isMember: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isMemberSelection = newVal;
      },
    },
    disabled: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isDisabled = newVal;
      },
    },
    readonly: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isReadOnly = newVal;
      },
    },
    selectedName(val: string) {
      this.$emit("update:name", val);
      this.$emit("nameChanged", val);
    },
    selectedItem(val: MemberSearchSuggestion) {
      this.$emit("update:member", val);
      this.$emit("memberChanged", val);
    },
    matching(val: string) {
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(this.ownerId, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  methods: {
    memberIconClicked() {
      this.isMemberSelection = !this.isMemberSelection;
      this.$emit("update:isMember", this.isMemberSelection);
      this.$emit("isMemberChanged");
    },
  },
});
</script>
