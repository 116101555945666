<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="35%" persistent>
      <v-card>
        <v-card-title class="headline">
          <div>Add Contributor</div>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="onCancel()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <div style="height: 20px"></div>
            <v-stepper v-model="stepNo" vertical>
              <!-- Step 1 -->
              <v-stepper-step :complete="canProceedToNextStep(1)" step="1">
                What type of contribution is this?
                <small
                  >Contributions contains content of a particular type such as
                  podcasts, videos, and blog entries</small
                >
              </v-stepper-step>
              <v-stepper-content step="1">
                <div style="height: 4px"></div>
                <v-select
                  :items="contributor.contentTypes"
                  label="Contribution Type"
                  outlined
                  v-model="contributor.contentType"
                  return-object
                ></v-select>
                <v-btn color="primary" @click="nextStep()"> Continue </v-btn>
              </v-stepper-content>

              <!-- Step 2 -->
              <v-stepper-step :complete="canProceedToNextStep(2)" step="2">
                Where is the content held?
                <small
                  >Please specify the url of where this contribution will pull
                  in it's content</small
                >
              </v-stepper-step>
              <v-stepper-content step="2">
                <div style="height: 4px"></div>
                <v-text-field
                  label="Source Url"
                  placeholder="Source Url"
                  outlined
                  clearable
                  v-model="contributor.contentUrl"
                ></v-text-field>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                  :disabled="!hasContentUrl()"
                >
                  Continue
                </v-btn>
                <v-btn @click="prevStep()" text> Back </v-btn>
              </v-stepper-content>

              <!-- Step 3 -->
              <v-stepper-step :complete="canProceedToNextStep(3)" step="3">
                Edit the details
                <small
                  >Please confirm the details of this contribution and make any
                  adjustments here.</small
                >
              </v-stepper-step>
              <v-stepper-content step="3">
                <div style="height: 4px"></div>
                <v-row>
                  <div class="container">
                    <img :src="contributor.imageUrl" />
                  </div>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-text-field
                      label="Title"
                      placeholder="Title"
                      outlined
                      clearable
                      v-model="contributor.name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-textarea
                      label="Description"
                      placeholder="Description"
                      counter="2000"
                      outlined
                      clearable
                      v-model="contributor.description"
                      :rules="[rules.required]"
                      rows="4"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-btn color="primary" @click="nextStep()" :disabled="!valid">
                  Continue
                </v-btn>
                <v-btn @click="prevStep()" text> Back </v-btn>
              </v-stepper-content>

              <!-- Step 4 -->
              <v-stepper-step :complete="canProceedToNextStep(4)" step="4">
                Confirm the details
                <small
                  >Confirm the deatils. This will save the contribution.</small
                >
              </v-stepper-step>
              <v-stepper-content step="4">
                <v-row>
                  <div class="container">
                    <img :src="contributor.imageUrl" />
                  </div>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-text-field
                      label="Title"
                      placeholder="Title"
                      outlined
                      clearable
                      readonly
                      disabled
                      v-model="contributor.name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn color="primary" type="submit" :disabled="!valid">
                  Complete
                </v-btn>
                <v-btn @click="prevStep()" text> Back </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import { ManageContributorViewModel } from "@/models/page.model";
import ConfirmationDialog from "@/components/common/confirmation-dialog.vue";
import { PageService } from "@/services/page.service";

export default Vue.extend({
  name: "AddContributorDialog",
  components: { ConfirmationDialog },
  props: {
    dialog: Boolean,
    id: Number,
  },

  watch: {
    dialog: {
      handler(val: Boolean) {
        if (val) {
          this.getContributor();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      tab: null,
      valid: true,
      contributor: new ManageContributorViewModel(),
      rules: {
        required: (value: any) => !!value || "You must provide a value.",
      },
      stepNo: 1,
    };
  },

  methods: {
    getContributor() {
      this.stepNo = 1;
      let service = new PageService();
      service.getContributor(this.id).then((response) => {
        this.contributor = response.data;
      });
    },

    getContributorFromUrl() {
      let service = new PageService();
      service
        .getContributorFromUrl(
          this.contributor.contentType,
          this.contributor.contentUrl
        )
        .then((response) => {
          this.contributor = response.data;
        });
    },

    submitForm() {
      this.$emit("dialog-closed-confirm", this.contributor);
    },

    onCancel() {
      this.$emit("dialog-closed-cancel");
    },

    hasContentUrl(): Boolean {
      if (
        this.contributor.contentUrl &&
        this.contributor.contentUrl.length > 0 &&
        this.isValidUrl(this.contributor.contentUrl)
      )
        return true;
      else return false;
    },

    isValidUrl(str: string): Boolean {
      const selectedType = this.contributor.contentType.toLowerCase();
      if (selectedType === "podcast") {
        var pattern = new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator
        return !!pattern.test(str);
      } else {
        return true;
      }
    },

    canProceedToNextStep(stepToComplete: number) {
      return this.stepNo > stepToComplete;
    },

    nextStep() {
      if (this.stepNo === 1) {
        const selectedType = this.contributor.contentType.toLowerCase();
        switch (selectedType) {
          case "downloads":
          case "events":
          case "profile":
          case "custom":
            this.stepNo = 3;
            break;
          default:
            this.stepNo = 2;
            break;
        }
      } else {
        if (this.stepNo === 2) {
          this.getContributorFromUrl();
        }
        this.stepNo++;
      }
    },

    prevStep() {
      if (this.stepNo === 3) {
        const selectedType = this.contributor.contentType.toLowerCase();
        switch (selectedType) {
          case "downloads":
          case "events":
          case "profile":
          case "custom":
            this.stepNo = 1;
            break;
          default:
            this.stepNo = 2;
            break;
        }
      } else {
        this.stepNo--;
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>