<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <contributor-content-selector-dialog
      :dialog="showContentDialog"
      :multiSelect="false"
      @dialog-option-selected="onContentDialogClosed"
    >
    </contributor-content-selector-dialog>
    <v-card>
      <v-card-title class="headline">
        <div>Manage Banner</div>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="onCancel()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-item-group multiple>
        <v-container fluid>
          <v-row>
            <v-col v-for="slot in slots" :key="slot.id" cols="12" md="3">
              <v-item v-slot="{ active }">
                <v-card
                  class="mx-auto"
                  max-width="400"
                  :color="active ? 'primary' : 'grey lighten-1'"
                  @click.stop="onManageSlot(slot)"
                >
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="slot.imageUrl"
                  >
                  </v-img>

                  <v-card-subtitle class="pb-0">{{
                    slot.contentType
                  }}</v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>{{ slot.name }}</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.stop="onClear(slot)">
                      Clear
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCancel()"> Cancel </v-btn>
        <v-btn color="primary" @click="onConfirm" :disabled="!hasChanges">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import ContributorContentSelectorDialog from "@/components/layout/contributor-content-selector-dialog.vue";
import { ContributorContent } from "@/models/page.model";

export default Vue.extend({
  name: "ManageBannerDialog",
  components: {
    "contributor-content-selector-dialog": ContributorContentSelectorDialog,
  },
  data() {
    return {
      slots: new Array<ContributorContent>(),
      showContentDialog: false,
      selectedSlot: {} as ContributorContent,
      hasChanges: false,
    };
  },
  props: {
    dialog: Boolean,
    currentBanner: Array,
  },
  watch: {
    dialog: {
      handler(val: boolean) {
        if (val === true) {
          this.onLaunch();
        }
      },
      deep: true,
    },
  },
  methods: {
    onLaunch() {
      this.hasChanges = false;
      let bannerItems: ContributorContent[] = [];

      for (let element of this.currentBanner) {
        let item: ContributorContent = element as ContributorContent;
        bannerItems.push({
          id: item.id,
          name: item.name,
          credit: item.credit,
          imageUrl: item.imageUrl,
          contentUrl: item.contentUrl,
          description: item.description,
          contentType: item.contentType,
          duration: item.duration,
          size: item.size,
          genres: item.genres,
          tags: item.tags,
          publicationStart: item.publicationStart,
          publicationEnd: item.publicationEnd,
          updatedOn: item.updatedOn,
          show: false,
        });
      }

      for (let i = bannerItems.length; i < 4; i++) {
        bannerItems.push({
          id: -i,
          name: `Spare Banner Slot`,
          credit: "Author",
          imageUrl: require("@/assets/images/banner-slot.png"),
          contentUrl: "",
          description: "Click to select an item",
          contentType: "None",
          duration: null,
          size: null,
          genres: [],
          tags: [],
          publicationStart: null,
          publicationEnd: null,
          updatedOn: null,
          show: false,
        });
      }

      this.slots = bannerItems;
    },

    onManageSlot(slot: ContributorContent) {
      this.selectedSlot = slot;
      this.showContentDialog = true;
    },

    onCancel() {
      this.$emit("dialog-closed-cancel", []);
    },

    onConfirm() {
      let selectedSlots = this.slots.filter((f) => f.id > 0);
      this.$emit("dialog-closed-confirm", selectedSlots);
    },

    onContentDialogClosed(items: ContributorContent[]) {
      if (items && items.length > 0) {
        let item = items[0];

        let alreadySet = this.slots.find((f) => f.id == item.id);
        if (!alreadySet) {
          let match = this.slots.find((f) => f.id == this.selectedSlot.id);
          if (match) {
            match.id = item.id;
            match.name = item.name;
            match.imageUrl = item.imageUrl;
            match.contentUrl = item.contentUrl;
            match.description = item.description;
            match.contentType = item.contentType;
            match.duration = item.duration;
            match.size = item.size;
            match.genres = item.genres;
          }
          this.hasChanges = true;
        }
      }
      this.showContentDialog = false;
    },

    onClear(slot: ContributorContent) {
      slot.id = 0;
      slot.name = `Spare Banner Slot`;
      slot.imageUrl = require("@/assets/images/banner-slot.png");
      slot.contentUrl = "";
      slot.description = "Click to select an item";
      slot.contentType = "None";
      slot.duration = null;
      slot.size = null;
      slot.genres = [];

      this.resetAndRenumberIds();
      this.hasChanges = true;
    },

    resetAndRenumberIds() {
      for (let i = 0; i < this.slots.length; i++) {
        let item = this.slots[i];
        if (item.id <= 0) {
          item.id = 0;
        }
      }

      for (let i = 0; i < this.slots.length; i++) {
        let item = this.slots[i];
        if (item.id === 0) {
          item.id = -i;
        }
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>