<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <v-card>
      <v-container fluid>
        <v-data-iterator
          :items="items"
          :items-per-page.sync="itemsPerPage"
          :footer-props="footerOptions"
          :search="search"
        >
          <template v-slot:header>
            <v-col md="4 px-0">
              <v-toolbar class="elevation-0">
                <v-text-field
                  v-model="search"
                  clearable
                  solo
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
              </v-toolbar>
            </v-col>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    :elevation="!multiSelect && hover ? 12 : 3"
                    :outlined="!multiSelect && hover"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-img
                      :src="item.imageUrl"
                      :lazy-src="placeholder"
                      :class="[!multiSelect ? imageCss : '']"
                      @click="!multiSelect && onImageSelected(item)"
                    >
                      <v-app-bar flat color="rgba(0,0,0,0)">
                        <v-checkbox
                          v-show="multiSelect"
                          v-model="item.selected"
                        ></v-checkbox>
                      </v-app-bar>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
        <v-btn color="primary" @click="onConfirm" :disabled="!hasSelectedItems">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { ApprovedImage, IApprovedImage } from "@/models/conference.model";
import Vue from "vue";

export default Vue.extend({
  name: "ApprovedImageSelector",
  data() {
    return {
      itemsPerPage: 12,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      search: "",
      imageCss: "imageCursor",
    };
  },
  props: {
    dialog: Boolean,
    items: Array,
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasSelectedItems(): boolean {
      const set = this.items as ApprovedImage[];
      if (set) {
        const items = set.filter((f) => f.selected);
        return items.length > 0;
      } else {
        return false;
      }
    },
  },
  methods: {
    onCancel() {
      this.$emit("dialog-option-selected", []);
    },

    onConfirm() {
      const set = this.items as ApprovedImage[];
      let items: IApprovedImage[] = [];
      if (set) {
        items = set.filter((f) => f.selected) as IApprovedImage[];
      }
      this.$emit("dialog-option-selected", items);
    },

    onImageSelected(item: IApprovedImage) {
      this.$emit("dialog-option-selected", [item]);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
