var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "4" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.onNewItem } },
                [_vm._v("New Query")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmptyView,
                      expression: "showEmptyView",
                    },
                  ],
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "4" } }, [
                        _vm._v(
                          " It seems that you have not created any queries. Create your first one by clicking the New Query. "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("lottie-player", {
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: {
                              src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                              background: "transparent",
                              speed: "1",
                              loop: "",
                              autoplay: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showGrid,
                      expression: "showGrid",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(" My Queries "),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  clearable: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      "calculate-widths": true,
                      "items-per-page": 10,
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "single-select": false,
                      "item-key": "id",
                      "show-select": _vm.showMultiSelect,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.actions",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "success",
                                                              icon: "",
                                                              disabled:
                                                                !item.isNotifyable,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onNotifyItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-bullhorn-outline "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Send a message to this group"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !item.isOwner,
                                                              color: "success",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onEditItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-pencil "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Edit this Query"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !item.isOwner,
                                                              color: "primary",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onRunItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(" mdi-play "),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Run this Query")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !item.isOwner,
                                                              color: "primary",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onManageUsers(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-account-plus "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Manage access to this Query"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !item.isOwner,
                                                              color: "error",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onDeleteItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-delete "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Remove this Query"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedItems,
                      callback: function ($$v) {
                        _vm.selectedItems = $$v
                      },
                      expression: "selectedItems",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("demographicsQuery-permissions", {
        ref: "demographicpermissionsdialog",
        attrs: { dialog: _vm.showDemographicsQueryDialog },
        on: { "ok-clicked": _vm.demographicsDialogConfirmed },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("query-result-dialog", {
        attrs: { dialog: _vm.showResultDialog, data: _vm.queryResult },
        on: { "dialog-closed": _vm.closeQueryResultDialog },
      }),
      _c("query-result-message-sender-dialog", {
        attrs: { dialog: _vm.showSendDialog, data: _vm.queryResult },
        on: { "dialog-closed": _vm.onSendDialogClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }