<template>
  <v-container fluid>
    <v-dialog
      v-model="showDialog"
      :max-width="maxWidth"
      persistent
      eager
      :retain-focus="false"
    >
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          {{ title }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onClick()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <div v-show="viewModel.isAllQuery">
          <v-card-subtitle>
            <v-row>
              <v-col md="6">
                {{ viewModel.description }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-data-table
            :headers="headers"
            :items="viewModel.attendees"
            :search="search"
            :single-select="true"
            :items-per-page="10"
            :show-select="false"
            item-key="id"
          >
          </v-data-table>
        </div>
        <div v-show="isNumberResultQuery">
          <v-card-subtitle>
            <v-row>
              <v-col md="10">
                {{ viewModel.description }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <div v-show="viewModel.isTotalNumberOfQuery">
              <h1>{{ viewModel.totalNumberOf }}</h1>
            </div>
            <div v-show="viewModel.isAverageQuery">
              <h1>{{ viewModel.average }}</h1>
            </div>
            <div v-show="viewModel.isYoungestQuery">
              <h1>{{ viewModel.youngest }}</h1>
            </div>
            <div v-show="viewModel.isOldestQuery">
              <h1>{{ viewModel.oldest }}</h1>
            </div>
            <div v-show="viewModel.isRatioQuery">
              <ejs-accumulationchart
                id="chart-container"
                :legendSettings="legendSettings"
                :tooltip="tooltip"
                enableAnimation="true"
                :enableSmartLabels="enableSmartLabels"
              >
                <e-accumulation-series-collection>
                  <e-accumulation-series
                    xName="x"
                    yName="y"
                    :startAngle="0"
                    :endAngle="360"
                    :dataSource="pieData"
                    :dataLabel="dataLabel"
                  ></e-accumulation-series>
                </e-accumulation-series-collection>
              </ejs-accumulationchart>
            </div>
            <div v-show="viewModel.isSpreadQuery">
              <ejs-chart
                id="chart2-container"
                ref="chart"
                :primaryXAxis="primaryXAxis"
                :tooltip="spreadTooltip"
                :palettes="palette"
                :legendSettings="legendSettings"
              >
                <e-series-collection> </e-series-collection>
              </ejs-chart>
            </div>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onClick()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import {
  IDemographicsQueryResultViewModel,
  IRunDemographicsQueryViewModel,
  PieChartDataItem,
} from "@/models/demographics.model";
import {
  AccumulationChartPlugin,
  PieSeries,
  AccumulationDataLabel,
  ChartPlugin,
  ColumnSeries,
  Category,
  ChartComponent,
  Legend,
  Tooltip,
} from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);
Vue.use(AccumulationChartPlugin);

export default Vue.extend({
  name: "QueryResultDialog",
  props: {
    dialog: Boolean,
    data: Object,
  },
  provide: {
    chart: [ColumnSeries, Category, Legend, Tooltip],
    accumulationchart: [PieSeries, AccumulationDataLabel, Legend, Tooltip],
  },
  watch: {
    data(val: IRunDemographicsQueryViewModel) {
      this.viewModel = val;
      this.title = this.viewModel.title ? this.viewModel.title : "Results";

      this.isNumberResultQuery =
        this.viewModel.isTotalNumberOfQuery ||
        this.viewModel.isAverageQuery ||
        this.viewModel.isYoungestQuery ||
        this.viewModel.isOldestQuery ||
        this.viewModel.isRatioQuery ||
        this.viewModel.isSpreadQuery;

      if (!this.viewModel.isAllQuery) {
        this.maxWidth = "25%";
      } else {
        this.maxWidth = "40%";
      }

      if (this.viewModel.isRatioQuery) {
        this.populatePieChart();
      }

      if (this.viewModel.isSpreadQuery) {
        this.populateSeriesChart();
      }
    },
    dialog(val: boolean) {
      this.showDialog = val;
      this.showSendDialog = false;
    },
  },
  data() {
    return {
      showDialog: false,
      showSendDialog: false,
      title: "",
      search: "",
      attendees: Array<IDemographicsQueryResultViewModel>(),
      viewModel: {} as IRunDemographicsQueryViewModel,
      headers: [
        { text: "First Name", value: "firstName", sortable: true },
        { text: "Last Name", value: "lastName", sortable: true },
        { text: "Gender", value: "gender", sortable: true },
        { text: "Age", value: "age", sortable: true },
      ],
      isNumberResultQuery: false,
      maxWidth: "50%",
      pieData: Array<PieChartDataItem>(),
      legendSettings: {
        visible: true,
      },
      dataLabel: { visible: true, name: "text", position: "Outside" },
      tooltip: { enable: true },
      spreadTooltip: { enable: true },
      enableSmartLabels: true,
      palette: ["#E94649", "#F6B53F", "#6FAAB0", "#C4C24A"],
      primaryXAxis: {
        valueType: "Category",
        title: "",
      },
    };
  },
  methods: {
    onClick() {
      this.$emit("dialog-closed");
    },

    populatePieChart() {
      this.pieData = [];
      this.viewModel.ratios.forEach((element) => {
        let item = new PieChartDataItem();
        item.x = element.name;
        item.y = element.value;
        item.text = element.tooltip;
        item.groupName = element.groupName;
        this.pieData.push(item);
      });
    },

    populateSeriesChart() {
      const chart = this.$refs.chart as any;
      if (chart) {
        chart.clearSeries();

        // The series chart component wants the data organised
        // by a single rows with their values for each grouping
        // e.g.
        //  [
        //    { group: Gloucester, male: 10, female: 25 }
        //    { group: Bristol, male: 4, female: 18 }
        //  ]
        // The data does not come from the service in this format
        // and so we need to convert it to a JSON to produce this
        // format and then serialise it to an object that can be
        // used by the chart component.
        let data: string = "[";
        let groupByNames: string[] = [];

        for (let x = 0; x < this.viewModel.spreads.length; x++) {
          const spread = this.viewModel.spreads[x];
          data = data + `{ "group": "${spread.groupName}", `;

          for (let i = 0; i < spread.ratios.length; i++) {
            const element = spread.ratios[i];
            data = data + `"${element.name}": ${element.value} `;

            if (!groupByNames.includes(element.name)) {
              groupByNames.push(element.name);
            }
            if (i < spread.ratios.length - 1) {
              data = data + `,`;
            }
          }

          if (x < this.viewModel.spreads.length - 1) {
            data = data + `},`;
          }
        }
        data = data + `}]`;

        // The series groupings must also be defined to specify how the
        // series should be split. The format is as follows for the example above:
        // [
        //   { xName: "group", yName: "male" },
        //   { xName: "group", yName: "female" },
        // ]
        // We ensure that there are no duplicate yNames by building a unique list
        // of group names in the previous loop.
        let def: string = "[";
        for (let i = 0; i < groupByNames.length; i++) {
          def = def + `{ "xName": "group", "yName": "${groupByNames[i]}" }`;
          if (i < groupByNames.length - 1) {
            def = def + `,`;
          }
        }
        def = def + "]";

        // We can now serialise the JSON data into objects the chart can use
        let myData = JSON.parse(data);
        let myDef = JSON.parse(def);

        // Add each series to the chart including the full data and the series definition
        myDef.forEach((def: { xName: any; yName: any }) => {
          chart.addSeries([
            {
              type: "Column",
              dataSource: myData,
              xName: def.xName,
              yName: def.yName,
              name: def.yName,
            },
          ]);
        });
      }
    },

    onSendMessage(item: IDemographicsQueryResultViewModel) {
      this.showSendDialog = true;
    },

    onSendMessageToSelected() {
      this.showSendDialog = true;
    },

    sendDialogClosed() {
      this.showSendDialog = false;
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>