var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        "return-value": _vm.timeValue,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px",
      },
      on: {
        "update:returnValue": function ($event) {
          _vm.timeValue = $event
        },
        "update:return-value": function ($event) {
          _vm.timeValue = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        outlined: _vm.outlined,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        clearable: _vm.clearable,
                        rules: _vm.timeRules,
                        "prepend-icon": _vm.prependIcon,
                        "hide-details": "auto",
                        readonly: "",
                      },
                      on: { "click:clear": _vm.onTimeCleared },
                      model: {
                        value: _vm.timeValue,
                        callback: function ($$v) {
                          _vm.timeValue = $$v
                        },
                        expression: "timeValue",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm.menu
        ? _c("v-time-picker", {
            attrs: {
              "full-width": "",
              format: "24hr",
              scrollable: "",
              "no-title": !_vm.showHeader,
            },
            on: {
              "click:minute": function ($event) {
                return _vm.$refs.menu.save(_vm.timeValue)
              },
              change: _vm.onTimeSelected,
            },
            model: {
              value: _vm.timeValue,
              callback: function ($$v) {
                _vm.timeValue = $$v
              },
              expression: "timeValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }