<template>
  <v-navigation-drawer app v-model="drawer" clipped>
    <v-list nav>
      <v-list-item-group
        active-class="deep-indigo--text text--accent-4"
        v-model="selectedItem"
      >
        <v-list-item
          :disabled="!item.enabled"
          v-for="item in items"
          :key="item.id"
          @click.stop="onItemClick(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { IMenuItem, MenuItem } from "@/models/common.model";
import Vue from "vue";

export default Vue.extend({
  name: "FlatSideMenu",
  components: {},
  data() {
    return {
      selectedItem: 0,
      drawer: true,
      lastItem: new MenuItem(),
    };
  },
  props: {
    drawerSelected: Boolean,
    selectedMenuItem: Number,
    items: Array,
  },
  methods: {
    onItemClick(item: IMenuItem) {
      this.$emit("item-selected", item);
    },

    onGroupClick(item: IMenuItem) {
      if (this.lastItem && item.id !== this.lastItem.id) {
        this.lastItem = item;
        this.selectedItem = -1;
      }
    },

    hasChildren(item: IMenuItem) {
      const hasChildren = item.items && item.items.length !== 0;
      return hasChildren;
    },
  },

  watch: {
    drawerSelected(val) {
      this.drawer = val;
    },
    selectedMenuItem(val) {
      this.selectedItem = val;
    },
  },
});
</script>

<style scoped>
.list-item {
  height: 40px;
  margin: 8px;
}
</style>
