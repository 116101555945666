var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.allItems,
      label: _vm.label,
      placeholder: _vm.placeholder,
      multiple: "",
      clearable: "",
      "auto-select-first": "",
      "item-text": "name",
      "item-value": "id",
      "return-object": true,
      rules: _vm.validateRecipients,
      "search-input": _vm.searchInput,
      outlined: _vm.outlined,
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchInput = $event
      },
      "update:search-input": function ($event) {
        _vm.searchInput = $event
      },
      change: _vm.onChange,
    },
    scopedSlots: _vm._u([
      {
        key: "prepend-item",
        fn: function () {
          return [
            _c(
              "v-list-item",
              {
                attrs: { ripple: "" },
                on: { mousedown: _vm.selectallItemsToggle },
              },
              [
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color:
                            _vm.selectedItems.length > 0
                              ? "indigo darken-4"
                              : "",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [_c("v-list-item-title", [_vm._v(" Select All ")])],
                  1
                ),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mt-2" }),
          ]
        },
        proxy: true,
      },
      {
        key: "selection",
        fn: function (ref) {
          var item = ref.item
          var index = ref.index
          return [
            index === 0
              ? _c("div", [_c("span", [_vm._v(_vm._s(item.name) + " ")])])
              : _vm._e(),
            _c("div", { staticStyle: { width: "4px" } }),
            index === 1
              ? _c("span", { staticClass: "grey--text text-caption" }, [
                  _vm._v(
                    " (+" + _vm._s(_vm.selectedItems.length - 1) + " others) "
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedItems,
      callback: function ($$v) {
        _vm.selectedItems = $$v
      },
      expression: "selectedItems",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }