var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Location"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Help people in the area discover your event and let attendees know where to show up. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          attrs: { color: "primary" },
                          on: { change: _vm.onLocationOptionChange },
                          model: {
                            value: _vm.locationOption,
                            callback: function ($$v) {
                              _vm.locationOption = $$v
                            },
                            expression: "locationOption",
                          },
                        },
                        [
                          _c("v-btn", [_vm._v("Venue")]),
                          _c("v-btn", [_vm._v("Online")]),
                          _c("v-btn", [_vm._v("To be announced")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showNoLocationsCreated
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "auto" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { md: "4" } }, [
                                    _vm._v(
                                      " It seems that you do not have any physical locations for this event. Add one or more by clicking the Add button. "
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("lottie-player", {
                                        staticStyle: {
                                          width: "400px",
                                          height: "400px",
                                        },
                                        attrs: {
                                          src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                                          background: "transparent",
                                          speed: "1",
                                          loop: "",
                                          autoplay: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: { click: _vm.addNewLocation },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showNoOnlineLocationsCreated
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "auto" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { md: "2" } }, [
                                    _vm._v(
                                      " It seems that you do not specified any online details for this event. Add one or more by clicking the Add button. "
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("lottie-player", {
                                        staticStyle: {
                                          width: "400px",
                                          height: "400px",
                                        },
                                        attrs: {
                                          src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                                          background: "transparent",
                                          speed: "1",
                                          loop: "",
                                          autoplay: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: _vm.addNewOnlineLocation,
                                          },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.viewModel.locations, function (location) {
                return _c(
                  "v-row",
                  { key: location.localId },
                  [
                    _vm.showVenues
                      ? _c(
                          "v-col",
                          [
                            _c("event-location-card", {
                              attrs: { location: location },
                              on: {
                                "on-new-location": _vm.addNewLocation,
                                "on-edit-location": _vm.editLocation,
                                "on-remove-location": _vm.removeLocation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._l(_vm.viewModel.onlineLocations, function (location) {
                return _c(
                  "v-row",
                  { key: location.localId },
                  [
                    _vm.showOnlineVenues
                      ? _c(
                          "v-col",
                          [
                            _c("event-location-online-card", {
                              attrs: { location: location },
                              on: {
                                "on-new-location": _vm.addNewOnlineLocation,
                                "on-edit-location": _vm.editOnlineLocation,
                                "on-remove-location": _vm.removeOnlineLocation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showRemoveLocationsDialog,
          data: _vm.dialogMessage,
        },
        on: {
          "dialog-option-selected": _vm.onRemoveLocationsDialogOptionSelected,
        },
      }),
      _c("event-location-online-editor", {
        attrs: {
          dialog: _vm.showOnlineLocationEditor,
          entity: _vm.selectedOnlineLocation,
          mainEvent: _vm.viewModel,
        },
        on: {
          "on-dialog-save": _vm.onOnlineLocationEditorSave,
          "on-dialog-cancel": _vm.onOnlineLocationEditorCancel,
        },
      }),
      _c("event-location-editor", {
        attrs: {
          dialog: _vm.showLocationEditor,
          entity: _vm.selectedLocation,
          mainEvent: _vm.viewModel,
        },
        on: {
          "on-dialog-save": _vm.onLocationEditorSave,
          "on-dialog-cancel": _vm.onLocationEditorCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }