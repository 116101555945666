<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Event Details</h6>
        <p class="small" style="padding-right: 40px">
          Name your event and tell event-goers why they should come. Add details
          that highlight what makes it unique.
        </p>
        <image-selector :imageUrl="imageUrl" @uploaded-image-selected="uploadedImageSelected"
          @approved-image-selected="approvedImageSelected">
        </image-selector>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field v-model="viewModel.title" label="Title" placeholder="Title" outlined clearable
              hide-details="auto" maxlength="80" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="viewModel.eventTypes" v-model="viewModel.eventType" outlined label="Type Of Event"
              placeholder="type of the event" menu-props="auto" hide-details="auto"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea outlined auto-grow hide-details="auto" label="Description" placeholder="Description" rows="5"
              v-model="viewModel.description" clearable :rules="[rules.required]">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="viewModel.subtitle" label="Subtitle" placeholder="Subtitle" outlined clearable
              hide-details="auto" maxlength="80"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-time-picker :dateTime.sync="startDateTimeDisplay" :showDateHeader="false"
              :dateRules="validateEventDate" :timeRules="validateEventTime" dateLabel="Start Date"
              @date-time-changed="startDateChanged">
            </date-time-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-time-picker :dateTime.sync="endDateTimeDisplay" :showDateHeader="false" dateLabel="End Date"
              @date-time-changed="endDateChanged">
            </date-time-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <v-btn color="primary" @click="onSetRecurring">{{
                recurrenceButtonText
              }}</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="rRule.description">
          <v-col>
            {{ rRule.description }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Contact Details</h6>
        <p class="small" style="padding-right: 40px">
          Give your potential attendees a way to contact you and ask questions.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row v-for="contact of viewModel.contacts" :key="contact.id">
          <v-col>
            <v-text-field v-model="contact.email" label="Email" placeholder="Email" outlined clearable
              hide-details="auto" maxlength="200" :rules="[rules.email]"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="contact.contactNumber" label="Contact No" placeholder="Contact No" outlined clearable
              hide-details="auto" maxlength="200" :rules="[rules.phone]"></v-text-field>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="ma-1" text icon v-bind="attrs" v-on="on" @click="addContact"
                  hide-details="auto">
                  <v-icon> mdi-cellphone </v-icon>
                </v-btn>
              </template>
              <span>add contact</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="red" class="ma-1" text icon v-bind="attrs" v-on="on" @click="removeContact(contact)"
                  hide-details="auto">
                  <v-icon> mdi-cellphone-remove </v-icon>
                </v-btn>
              </template>
              <span>remove contact</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <recurrence-dialog :defaultStart="rDefaultStartDate" :recurrence="rRule" :dialog="showRecurrenceDialog"
      @dialog-cancelled="onRecurrenceDialogCancelled" @remove-recurrence="onRemoveRecurrence"
      @recurrence-updated="onRecurrenceUpdated">
    </recurrence-dialog>
    <confirmation-dialog :dialog="showDialog" :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"></confirmation-dialog>
    <progress-indicator :showProgressBar="showProgressBar" :showSpinner="showSpinner" :message="progressMessage">
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { EventContact, EventViewModel } from "@/models/events.model";
import RecurrenceDialog from "@/components/common/recurrence-dialog.vue";
import ApprovedImageSelector from "@/components/common/approved-image-selector.vue";
import imageUploader from "@/components/common/image-uploader.vue";
import DateTimePicker from "@/components/common/date-time-picker.vue";
import ImageSelector from "@/components/common/image-selector.vue";
import { DateTime } from "luxon";
import { IRecurrencePattern, LoadingType } from "@/models/common.model";
import { EventsService } from "@/services/events.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventsGeneralSection",
  mixins: [CommonMixin],
  components: {
    "date-time-picker": DateTimePicker,
    "image-selector": ImageSelector,
    "recurrence-dialog": RecurrenceDialog,
    ApprovedImageSelector,
    imageUploader,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(val: EventViewModel) {
        this.viewModel = val;

        this.rRule = {
          rule: this.viewModel.recurrenceRule,
          start: this.viewModel.recurrenceStart,
          description: this.viewModel.recurrenceDescription
        };

        this.rDefaultStartDate = DateTime.fromISO(this.viewModel.startDateTime.toString()).toISO();

        this.startDateTimeDisplay = this.formData.startDateTime.toString();
        this.endDateTimeDisplay = this.formData.endDateTime.toString();
        this.imageUrl = this.formData.mainImageUrl
          ? this.formData.mainImageUrl
          : this.formData.mainImageUrl ?? this.placeholder;

        this.recurrenceButtonText = (this.rRule.start)
          ? "Edit Recurrence"
          : "Make Recurring";

        if (this.viewModel.contacts.length == 0) {
          this.viewModel.contacts.push(new EventContact());
        }
      },
    },
  },

  computed: {
    validateEventDate() {
      let rules = [];

      let start = DateTime.fromISO(this.startDateTimeDisplay);
      let end = DateTime.fromISO(this.endDateTimeDisplay);

      const diff: number = start.diff(end, "days").days;
      if (diff > 0) {
        rules.push("Start date/time cannot be greater than the end date/time");
      }

      return rules;
    },

    validateEventTime() {
      let rules = [];
      let start = DateTime.fromISO(this.startDateTimeDisplay);
      let end = DateTime.fromISO(this.endDateTimeDisplay);

      const diff: number = start.diff(end, "minutes").minutes;
      if (diff > 0) {
        rules.push("Start time cannot be greater than the end time");
      }

      return rules;
    },
  },

  data() {
    return {
      viewModel: new EventViewModel(),
      showRecurrenceDialog: false,
      recurrenceButtonText: "Make Recurring",
      startDateTimeDisplay: "",
      endDateTimeDisplay: "",
      imageUrl: "",
      placeholder: require("@/assets/images/image-placeholder.png"),
      rRule: {} as IRecurrencePattern,
      rDefaultStartDate: ''
    };
  },

  methods: {
    uploadedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.viewModel.mainImageUrl = imageUrl;
      this.updateImage();
    },

    approvedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.viewModel.mainImageUrl = imageUrl;
      this.updateImage();
    },

    updateImage() {
      if (this.viewModel.id) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Updating event with image, Please Wait..."
        );

        const service = new EventsService();
        service
          .updateImage(this.viewModel.id, this.viewModel.mainImageUrl)
          .then(() => {
            this.hideProgressIndicator();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onSetRecurring() {
      this.showRecurrenceDialog = true;
    },

    onRecurrenceDialogCancelled() {
      this.showRecurrenceDialog = false;
    },

    onRemoveRecurrence() {
      this.viewModel.recurrenceStart = null;
      this.viewModel.recurrenceRule = null;
      this.viewModel.recurrenceDescription = null;
      this.showRecurrenceDialog = false;
    },

    onRecurrenceUpdated(pattern: IRecurrencePattern) {
      this.viewModel.recurrenceStart = pattern.start;
      this.viewModel.recurrenceRule = pattern.rule;
      this.viewModel.recurrenceDescription = null;
      this.showRecurrenceDialog = false;
    },

    startDateChanged(val: any) {
      this.viewModel.startDateTime = val;
    },

    endDateChanged(val: any) {
      this.viewModel.endDateTime = val;
    },

    addContact() {
      if (this.viewModel.contacts.length < 2) {
        this.viewModel.contacts.push(new EventContact());
      }
    },

    removeContact(item: EventContact) {
      if (this.viewModel.contacts.length > 0) {
        const index = this.viewModel.contacts.indexOf(item);
        this.viewModel.contacts.splice(index, 1);
      }
    },
  },

  destroyed() {
    if (this.viewModel.mainImageUrl) {
      URL.revokeObjectURL(this.viewModel.mainImageUrl);
    }
  },
});
</script>
