<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onDeleteSelected"
              :disabled="!hasSelectedItems"
              ><v-icon>mdi-delete-sweep-outline</v-icon></v-btn
            >
          </template>
          <span>Remove Selected</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onReinstateSelected"
              :disabled="!hasSelectedDeletedItems"
              ><v-icon>mdi-delete-restore</v-icon></v-btn
            >
          </template>
          <span>Restore Selected</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col md="4"> Registered Users </v-col>
              <v-spacer></v-spacer>
              <v-col md="2">
                <v-select
                  :items="groups"
                  v-model="selectedGroup"
                  placeholder="Groups"
                  label="Groups"
                  hide-details="auto"
                  @change="onSearch"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col md="4">
                <v-checkbox
                  v-model="showDeletedOnly"
                  label="Show Deleted Only"
                  hide-details
                  @change="onSearch"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-data-table
            item-key="id"
            multi-sort
            v-model="selectedItems"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            :show-select="showMultiSelect"
            :sort-by="[]"
            :sort-desc="[]"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:[`item.imageUrl`]="{ item }">
              <div class="container">
                <img :src="item.imageUrl" />
              </div>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <a :href="getMailLink(item.email)">{{ item.email }}</a>
            </template>
            <template v-slot:[`item.primaryContactNumber`]="{ item }">
              <a
                :href="getPhoneLink(item.primaryContactNumber)"
                target="_blank"
                >{{ item.contactNumber }}</a
              >
            </template>
            <template v-slot:[`item.isMember`]="{ item }">
              <v-simple-checkbox
                v-model="item.isMember"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.isDeleted`]="{ item }">
              <v-simple-checkbox
                v-model="item.isDeleted"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.isDeleted"
                    color="success"
                    icon
                    @click="onRestoreItem(item)"
                  >
                    <v-icon> mdi-delete-restore </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteAllDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteAllOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showReinstateDialog"
      :data="dialogMessage"
      @dialog-option-selected="onReinstateOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showReinstateAllDialog"
      :data="dialogMessage"
      @dialog-option-selected="onReinstateAllOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  DialogMessage,
  DialogResponse,
  GroupType,
  LoadingType,
} from "@/models/common.model";
import { IRegisteredUserDto } from "@/models/conference.model";
import { ConferenceService } from "@/services/conference.service";
import { DataOptions } from "vuetify";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListRegisteredUsers",
  mixins: [CommonMixin],

  computed: {
    hasSelectedItems(): boolean {
      return this.selectedItems.length > 0;
    },
    hasSelectedDeletedItems(): boolean {
      var selection = this.selectedItems.filter((f) => f.isDeleted);
      return selection.length > 0;
    },
  },

  watch: {
    options: {
      handler() {
        this.onGridOptionsChange();
      },
      deep: true,
    },
  },

  data() {
    return {
      showDeleteDialog: false,
      showReinstateDialog: false,
      showReinstateAllDialog: false,
      showDeleteAllDialog: false,
      showDeletedOnly: false,
      search: "",
      headers: [
        //{ text: 'Image', value: 'imageUrl', sortable: false },
        {
          text: "Deleted",
          value: "isDeleted",
          width: "100px",
          sortable: false,
        },
        { text: "Member", value: "isMember", width: "100px", sortable: false },
        { text: "Branch", value: "groupName", width: "180px", sortable: false },
        {
          text: "First Name",
          value: "firstName",
          sortable: false,
          width: "140px",
        },
        {
          text: "Last Name",
          value: "lastName",
          sortable: false,
          width: "180px",
        },
        { text: "Email", value: "email", sortable: false, width: "280px" },
        {
          text: "Contact No",
          value: "primaryContactNumber",
          sortable: false,
          width: "180px",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: Array<IRegisteredUserDto>(),
      selectedItems: Array<IRegisteredUserDto>(),
      groups: Array<String>(),
      selectedGroup: "",
      showMultiSelect: true,
      showGrid: false,
      showEmptyView: false,
      groupId: 0,
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      footerOptions: {
        "items-per-page-options": [10, 15, 20, 50, 100, 200],
        "items-per-page-text": "items per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
    };
  },

  methods: {
    onGridOptionsChange() {
      this.onSearch();
    },

    getItems(ownerId: number) {
      this.groupId = ownerId;
      this.onSearch();
    },

    onSearch() {
      this.showGrid = true;
      this.showEmptyView = false;

      const { page, itemsPerPage }: DataOptions = this.options;

      // Set default values for the paging if they are not available.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Users, Please Wait...`
      );

      const service = new ConferenceService();
      service
        .getRegisteredUsers(
          this.selectedGroup,
          this.showDeletedOnly,
          pageNumber,
          perPage
        )
        .then((response) => {
          this.hideProgressIndicator();

          this.groups = response.data.groups;
          this.items = response.data.users.items;
          this.totalItems = response.data.users.totalCount;
          this.showGrid = response.data.users.items.length > 0;
          this.showEmptyView = response.data.users.items.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onRestoreItem(item: IRegisteredUserDto) {
      this.showReinstateConfirmationDialog(item);
    },

    showReinstateConfirmationDialog(item: IRegisteredUserDto) {
      this.dialogMessage = new DialogMessage(
        `Re-instate ${item.firstName} ${item.lastName}?`,
        `Are you sure you want to re-instate ${item.firstName} ${item.lastName}? This action will RESTORE THE USER TO THE APPLICATION AND WILL BE RE-INCLUDED IN ALL FUTURE STATISTICS AND REPORTS.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showReinstateDialog = true;
    },

    onReinstateOptionSelected(item: DialogResponse) {
      this.showReinstateDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          `Re-instating ${item.entity.firstName} ${item.entity.lastName}, Please Wait...`
        );
        const service = new ConferenceService();
        service
          .reinstateRegisteredUser(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            item.entity.isDeleted = false;
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedItems = [];
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onDeleteItem(item: IRegisteredUserDto) {
      this.showDeleteConfirmationDialog(item);
    },

    showDeleteConfirmationDialog(item: IRegisteredUserDto) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.firstName} ${item.lastName}?`,
        `Are you sure you want to delete ${item.firstName} ${item.lastName}? This action will remove the person PERMANENTLY FROM THE APPLICATION.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Person, Please Wait..."
        );
        const service = new ConferenceService();
        service
          .removeRegisteredUser(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedItems = [];
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onDeleteSelected() {
      this.showDeleteAllConfirmationDialog();
    },

    showDeleteAllConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Remove the selected people?`,
        `This action WILL REMOVE THESE ${this.selectedItems.length} PEOPLE COMPLETELY FROM THE APPLICATION! THIS IS IRREVERSIBLE! Are you sure you want to remove these people?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.selectedItems;
      this.dialogMessage.errors = [];
      this.showDeleteAllDialog = true;
    },

    onDeleteAllOptionSelected(item: DialogResponse) {
      this.showDeleteAllDialog = false;
      if (item.option) {
        let memberIds: number[] = item.entity.map(
          (f: IRegisteredUserDto) => f.id
        );
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing people, Please Wait..."
        );
        const service = new ConferenceService();
        service
          .removeRegisteredUsers(memberIds)
          .then(() => {
            this.hideProgressIndicator();
            for (let memberId of memberIds) {
              let index = this.items.findIndex((f) => f.id == memberId);
              if (index !== -1) {
                this.items.splice(index, 1);
              }
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedItems = [];
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onReinstateSelected() {
      this.showReinstateAllConfirmationDialog();
    },

    showReinstateAllConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Restore the selected people?`,
        `This action WILL RESTORE THESE ${this.selectedItems.length} PEOPLE BACK TO THE APPLICATION! Are you sure you want to restore these people?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.selectedItems;
      this.dialogMessage.errors = [];
      this.showReinstateAllDialog = true;
    },

    onReinstateAllOptionSelected(item: DialogResponse) {
      this.showReinstateAllDialog = false;
      if (item.option) {
        let memberIds: number[] = item.entity.map(
          (f: IRegisteredUserDto) => f.id
        );
        this.showProgressIndicator(
          LoadingType.Panel,
          "Restoring people, Please Wait..."
        );
        const service = new ConferenceService();
        service
          .reinstateRegisteredUsers(memberIds)
          .then(() => {
            this.hideProgressIndicator();
            for (let memberId of memberIds) {
              let item = this.items.find((f) => f.id == memberId);
              if (item) {
                item.isDeleted = false;
              }
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedItems = [];
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>