<template>
  <v-autocomplete
    :items="members"
    :loading="isMatching"
    :search-input.sync="matching"
    item-text="name"
    item-value="id"
    v-model="selectedItem"
    :outlined="outlined"
    :clearable="clearable"
    :filled="filled"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    menu-props="auto"
    hide-details="auto"
    return-object
    @click:clear="onCleared"
  ></v-autocomplete>
</template>

<script lang="ts">
import CommonMixin from "@/mixins/common.mixin";
import Vue, { VueConstructor } from "vue";
import { MemberSearchSuggestion } from "@/models/group.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "MemberAutocomplete",
  mixins: [CommonMixin],
  props: {
    ownerId: {
      type: Number,
      default: 0,
      required: false,
    },
    label: {
      type: String,
      default: "Member/Visitor",
      required: false,
    },
    placeholder: {
      type: String,
      default: "Member or Visitor",
      required: false,
    },
    member: {
      type: Object,
      default: () => new MemberSearchSuggestion(),
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    outlined: {
      type: Boolean,
      default: true,
      required: false,
    },
    filled: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isMatching: false,
      selectedItem: {} as MemberSearchSuggestion,
      members: new Array<MemberSearchSuggestion>(),
      matching: null,
      hasSelectedMember: false,
    };
  },
  watch: {
    member(val: MemberSearchSuggestion) {
      this.selectedItem = val;
      if (val) {
        this.members.push(val);
      } else {
        this.members = [];
      }
    },
    selectedItem(val: MemberSearchSuggestion) {
      this.hasSelectedMember = val && val.id !== 0 ? true : false;
      this.$emit("update:memberBind", val);
      this.$emit("member-changed", val);
    },
    matching(val: string) {
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(this.ownerId, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  methods: {
    onCleared() {
      this.hasSelectedMember = false;
      this.$emit("update:memberBind", null);
      this.$emit("member-changed", null);
    },
  },
});
</script>
