<template>
  <v-container fluid>
    <list-demographic-queries
      v-show="showListView"
      ref="listitems"
      @new-item-requested="onNewItem"
      @show-item="onShowItem"
    ></list-demographic-queries>
    <query-manager-dialog
      ref="managedemographicquery"
      :dialog="showManageView"
      @dialog-closed="closeQueryManagerDialog"
    >
    </query-manager-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ListDemographicQueries from "@/views/lists/list-demographic-queries.vue";
import QueryManagerDialog from "@/components/demographics/query-manager-dialog.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerDemographicsQueries",
  mixins: [CommonMixin],
  components: {
    ListDemographicQueries,
    "query-manager-dialog": QueryManagerDialog,
  },
  data() {
    return {
      showListView: true,
      showManageView: false,
      ownerId: 0,
      id: 0,
    };
  },

  mounted() {
    this.getItems();
  },

  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems() {
      this.showListView = true;
      this.showManageView = false;
      const ref: any = this.$refs.listitems;
      if (ref) {
        ref.getItems();
      }
    },

    onNewItem() {
      this.id = 0;
      this.showManageView = true;
      const ref: any = this.$refs.managedemographicquery;
      if (ref) {
        ref.getItem(this.id);
      }
    },

    onShowItem(id: number) {
      this.id = id;
      this.showManageView = true;
      const ref: any = this.$refs.managedemographicquery;
      if (ref) {
        ref.getItem(this.id);
      }
    },

    closeQueryManagerDialog(requestClientRefresh: boolean) {
      this.showManageView = false;
      if (requestClientRefresh) {
        this.getItems();
      }
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>