var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [_c("v-tab", [_vm._v("Admins")]), _c("v-tab", [_vm._v("Roles")])],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("div", { staticStyle: { height: "20px" } }),
          _c(
            "v-tab-item",
            { attrs: { transition: "false" } },
            [
              _c("panel-user-role-manager", {
                ref: "userrolemanager",
                on: { "show-alert-panel": _vm.showSuccessPanel },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { transition: "false" } },
            [
              _c("panel-role-manager", {
                ref: "rolemanager",
                on: { "show-alert-panel": _vm.showSuccessPanel },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }