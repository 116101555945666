<template>
  <v-menu
    v-model="showDialog"
    :close-on-click="false"
    :close-on-content-click="false"
    :activator="selectedNewElement"
    :nudge-left="nudgeLeft"
    :nudge-top="80"
    :max-width="maxEventInfoDialogWidth"
    rounded="lg"
    transition="slide-x-transition"
    offset-x
    left
  >
    <v-form ref="form" v-model="valid" @submit.prevent="onSave()">
      <v-card :width="maxEventInfoDialogWidth" min-height="180px" elevation="5">
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          {{ model.title }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onCloseDialog()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-title>
          <v-row>
            <v-col md="12">
              <v-text-field
                prepend-icon="mdi-format-title"
                label="Title"
                placeholder="Title"
                counter="80"
                maxlength="80"
                v-model="model.title"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col md="9">
              <date-time-picker
                datePrependIcon="mdi-calendar"
                :dateTime.sync="startDateTime"
                :clearable="false"
                :outlined="false"
                :showDateHeader="false"
                :timeRules="validateEventTimes"
                dateLabel="Occurs On"
                timeLabel="Start Time"
                @date-time-changed="syncEndTime"
              >
              </date-time-picker>
            </v-col>
            <v-col md="3">
              <time-picker
                :clearable="false"
                :outlined="false"
                :time.sync="endTime"
                :timeRules="validateEventTimes"
                label="End Time"
              >
              </time-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <map-autocomplete
                id="location"
                :address="eventLocation"
                placeholder="Enter address"
                @addressChanged="onAddressChange"
              >
              </map-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onMoreOptions()" text>
            More Options
          </v-btn>
          <v-btn color="primary" @click="onSave()" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>


<script lang="ts">
import Vue from "vue";
import TimePicker from "@/components/common/time-picker.vue";
import DateTimePicker from "@/components/common/date-time-picker.vue";
import MapAutocomplete from "@/components/common/maps-autocomplete.vue";

import { DateTime } from "luxon";
import { EventListItem, EventLocation } from "@/models/events.model";
import { v4 as uuidv4 } from "uuid";

export default Vue.extend({
  name: "EventInfoEditPopup",
  components: {
    "date-time-picker": DateTimePicker,
    "time-picker": TimePicker,
    "map-autocomplete": MapAutocomplete,
  },

  props: {
    selectedEventInfoEditDialog: Boolean,
    nudgeLeft: Number,
    selectedNewElement: HTMLElement,
    model: EventListItem,
    maxEventInfoDialogWidth: Number,
  },

  data: () => ({
    valid: true,
    showDialog: false,
    eventLocation: "",
    startDateTime: "",
    endTime: "",
    rules: {
      required: (value: any) => !!value || "You must provide a value.",
    },
  }),

  watch: {
    selectedEventInfoEditDialog(val) {
      this.showDialog = val;
    },
    model(val) {
      this.startDateTime =
        val && val.startDateTimeDisplay ? val.startDateTimeDisplay : "";
      this.endTime =
        val && val.endDateTimeDisplay ? val.endDateTimeDisplay : "";
      this.eventLocation = val && val.location ? val.location.address : "";
    },
  },

  computed: {
    validateEventTimes(): string[] {
      let err: string[] = [];

      const etConverted = this.convertEndTime(this.startDateTime);
      const st: DateTime = DateTime.fromISO(this.startDateTime);
      const et: DateTime = DateTime.fromISO(etConverted);
      const diff: number = et.diff(st, "minutes").minutes;

      if (diff < 0) {
        err.push("The end time is before the start time.");
      }

      return err;
    },
  },

  methods: {
    onAddressChange(val: EventLocation) {
      const locationId = this.model.location ? this.model.location.id : 0;
      this.model.location = {
        id: locationId,
        title: val.address,
        houseNameOrNumber: val.houseNameOrNumber,
        street: val.street,
        city: val.city,
        country: val.country,
        postCode: val.postCode,
        region: val.region,
        url: val.url,
        longitude: val.longitude,
        latitude: val.latitude,
        isPrimaryLocation: true,
        localId: uuidv4(),
        category: "",
        startDateTime: null,
        endDateTime: null,
        address: "",
        isOnline: val.isOnline,
        platform: val.platform,
        linkUrl: val.linkUrl,
        dialInNumber: val.dialInNumber,
        dialInCode: val.dialInCode,
        meetingCode: val.meetingCode,
        meetingId: val.meetingId,
        allowDialIn: false
      };
    },

    onSave() {
      this.model.startDateTime = DateTime.fromISO(
        this.model.startDateTimeDisplay
      ).toJSDate();
      this.model.endDateTime = DateTime.fromISO(
        this.model.endDateTimeDisplay
      ).toJSDate();
      this.$emit("save-event", this.model);
    },

    onMoreOptions() {
      this.model.startDateTime = DateTime.fromISO(
        this.model.startDateTimeDisplay
      ).toJSDate();
      this.model.endDateTime = DateTime.fromISO(
        this.model.endDateTimeDisplay
      ).toJSDate();
      this.$emit("more-options", this.model);
    },

    onCloseDialog() {
      this.$emit("close-dialog");
    },

    syncEndTime(value: string) {
      this.endTime = this.convertEndTime(value);
    },

    convertEndTime(value: string): string {
      let datePart: DateTime = DateTime.fromISO(value);
      let timePart = DateTime.fromISO(this.endTime);
      let endDateTime: DateTime = DateTime.fromISO(
        `${datePart.toISODate()}T${timePart.toUTC().toISOTime()}`
      ).toUTC();

      return endDateTime.toISO();
    },
  },
});
</script>