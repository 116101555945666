var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showCancelDialog,
          data: _vm.dialogMessage,
          errors: _vm.dialogErrors,
        },
        on: { "dialog-option-selected": _vm.onCancelDialogSelected },
      }),
      _c(
        "v-row",
        [
          _c("progress-indicator", {
            attrs: {
              showProgressBar: _vm.showProgressBar,
              showSpinner: _vm.showSpinner,
              message: _vm.progressMessage,
            },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-sheet",
                    { attrs: { height: "64" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { outlined: "", color: "primary" },
                              on: { click: _vm.onNewEvent },
                            },
                            [_vm._v(" New Event ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { outlined: "", color: "grey darken-2" },
                              on: { click: _vm.onCalendarTodayButtonClicked },
                            },
                            [_vm._v(" Today ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2",
                              },
                              on: { click: _vm.onCalendarPrevIntervalClicked },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-left "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2",
                              },
                              on: { click: _vm.onCalendarNextIntervalClicked },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-right "),
                              ]),
                            ],
                            1
                          ),
                          _vm.$refs.calendar
                            ? _c("v-toolbar-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.$refs.calendar.title) + " "
                                ),
                              ])
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                right: "",
                                "menu-props": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "grey darken-2",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.interval)),
                                          ]),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v(" mdi-menu-down ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "day"
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-list-item-title", [_vm._v("Day")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "week"
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-list-item-title", [_vm._v("Week")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "working-week"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Work Week"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "4day"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("4 days"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "month"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Month"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-sheet",
                    { attrs: { height: "800px" } },
                    [
                      _c("v-calendar", {
                        ref: "calendar",
                        attrs: {
                          color: "primary",
                          type: _vm.type,
                          events: _vm.events,
                          "event-ripple": true,
                          weekdays: _vm.weekdays,
                        },
                        on: {
                          change: _vm.onDateChange,
                          "click:event": _vm.onEventClicked,
                          "click:more": _vm.onCalendarDayClicked,
                          "click:date": _vm.onCalendarDayClicked,
                          "click:time": _vm.onCalendarTimeClicked,
                          "click:day": _vm.onCalendarDayClicked,
                          "mouseenter:event": _vm.onEventMouseEnter,
                          "mousedown:event": _vm.onEventStartDrag,
                          "mousedown:time": _vm.onCalendarMouseDown,
                          "mousemove:time": _vm.onCalendarMouseMove,
                          "mouseup:time": _vm.onEventEndDrag,
                        },
                        nativeOn: {
                          mouseleave: function ($event) {
                            return _vm.onEventCancelDrag.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "event",
                            fn: function (ref) {
                              var event = ref.event
                              var timed = ref.timed
                              var eventSummary = ref.eventSummary
                              return [
                                _c(
                                  "div",
                                  { staticClass: "v-event-draggable" },
                                  [
                                    _c(
                                      { render: eventSummary },
                                      { tag: "component" }
                                    ),
                                  ],
                                  1
                                ),
                                timed
                                  ? _c("div", {
                                      staticClass: "v-event-drag-bottom",
                                      on: {
                                        mousedown: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onExtendEventDate(event)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.focus,
                          callback: function ($$v) {
                            _vm.focus = $$v
                          },
                          expression: "focus",
                        },
                      }),
                      _c("event-info-popup", {
                        attrs: {
                          selectedEventInfoDialog: _vm.showInfoPopup,
                          nudgeLeft: _vm.nudgeLeft,
                          selectedElement: _vm.selectedElement,
                          selectedEvent: _vm.selectedEvent,
                          maxEventInfoDialogWidth: _vm.maxEventInfoDialogWidth,
                        },
                        on: {
                          showEditEventScreen: _vm.showFullEditScreen,
                          deleteEvent: _vm.deleteEvent,
                          closeEventInfoDialog: _vm.closeEventInfoDialog,
                          cancelEvent: _vm.cancelEvent,
                          unCancelEvent: _vm.unCancelEvent,
                          publishEvent: _vm.publishEvent,
                          unPublishEvent: _vm.unPublishEvent,
                          notifyEvent: _vm.notifyEvent,
                        },
                      }),
                      _c("event-info-edit-popup", {
                        attrs: {
                          selectedEventInfoEditDialog: _vm.showEditPopup,
                          nudgeLeft: _vm.nudgeLeft,
                          selectedNewElement: _vm.selectedNewElement,
                          model: _vm.newEventItem,
                          maxEventInfoDialogWidth: 440,
                        },
                        on: {
                          "save-event": _vm.onEventSave,
                          "more-options": _vm.onMoreOptions,
                          "close-dialog": _vm.closeEventInfoEditDialog,
                        },
                      }),
                      _c("notification-sender-dialog", {
                        attrs: {
                          dialog: _vm.showNotificationDialog,
                          message: _vm.notificationMessage,
                          title: _vm.notificationTitle,
                          category: _vm.notificationCategory,
                          entityId: _vm.notificationEventId,
                        },
                        on: { "dialog-closed": _vm.onNotificationDialogClosed },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }