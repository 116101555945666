<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>About</h6>
        <p class="small" style="padding-right: 40px">
          This section is a detail the general information
        </p>
        <v-img
          contain
          :src="imageUrl"
          :lazy-src="placeholder"
          class="imageCursor"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              clearable
              v-model="formData.name"
              hide-details="auto"
              :rules="[rules.required]"
              label="Title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              v-model="formData.description"
              hide-details="auto"
              :rules="[rules.required]"
              label="Description"
              rows="10"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox
              outlined
              clearable
              v-model="formData.tags"
              :items="formData.allTags"
              label="Tags"
              multiple
            ></v-combobox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Publication</h6>
        <p class="small" style="padding-right: 40px">
          This section is to determine when this content is available
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.publicationStart"
              :dateRules="validatePublicationStart"
              label="Publish From"
            >
            </date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.publicationEnd"
              :dateRules="validatePublicationEnd"
              label="Publish Until"
            >
            </date-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ContributorContent } from "@/models/page.model";
import DatePicker from "@/components/common/date-picker.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ContributionSocialMediaGeneralSection",
  components: {
    "date-picker": DatePicker,
  },
  mixins: [CommonMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ContributorContent(),
      required: true,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val: ContributorContent) {
        this.imageUrl = val.imageUrl;
      },
    },
  },
  computed: {
    validatePublicationStart(): string[] {
      return this.validateStartDateRange(
        this.formData.publicationStart,
        this.formData.publicationEnd
      );
    },
    validatePublicationEnd(): string[] {
      return this.validateEndDateRange(
        this.formData.publicationStart,
        this.formData.publicationEnd
      );
    },
  },
  data() {
    return {
      valid: true,
      imageUrl: "",
      placeholder: require("@/assets/images/image-placeholder.png"),
    };
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>