var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": false,
        "close-on-content-click": false,
        activator: _vm.selectedNewElement,
        "nudge-left": _vm.nudgeLeft,
        "nudge-top": 80,
        "max-width": _vm.maxEventInfoDialogWidth,
        rounded: "lg",
        transition: "slide-x-transition",
        "offset-x": "",
        left: "",
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSave()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                width: _vm.maxEventInfoDialogWidth,
                "min-height": "180px",
                elevation: "5",
              },
            },
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.model.title) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCloseDialog()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-icon": "mdi-format-title",
                              label: "Title",
                              placeholder: "Title",
                              counter: "80",
                              maxlength: "80",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.model.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "title", $$v)
                              },
                              expression: "model.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-subtitle"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "9" } },
                        [
                          _c("date-time-picker", {
                            attrs: {
                              datePrependIcon: "mdi-calendar",
                              dateTime: _vm.startDateTime,
                              clearable: false,
                              outlined: false,
                              showDateHeader: false,
                              timeRules: _vm.validateEventTimes,
                              dateLabel: "Occurs On",
                              timeLabel: "Start Time",
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                _vm.startDateTime = $event
                              },
                              "update:date-time": function ($event) {
                                _vm.startDateTime = $event
                              },
                              "date-time-changed": _vm.syncEndTime,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("time-picker", {
                            attrs: {
                              clearable: false,
                              outlined: false,
                              time: _vm.endTime,
                              timeRules: _vm.validateEventTimes,
                              label: "End Time",
                            },
                            on: {
                              "update:time": function ($event) {
                                _vm.endTime = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "12" } },
                        [
                          _c("map-autocomplete", {
                            attrs: {
                              id: "location",
                              address: _vm.eventLocation,
                              placeholder: "Enter address",
                            },
                            on: { addressChanged: _vm.onAddressChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onMoreOptions()
                        },
                      },
                    },
                    [_vm._v(" More Options ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.valid },
                      on: {
                        click: function ($event) {
                          return _vm.onSave()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }