import Axios, { AxiosResponse } from "axios";

import { environment } from "@/environment";
import { IManageUserRoleViewModel, ISaveUserRoleCommand, IUserProfileViewModel, IUserRoleListItem, ManageRoleViewModel, RoleListItem } from "@/models/security.model";
import { BaseService } from "./base.service";
import { IManageUserViewModel, IUserListItem } from "@/models/security.model";

export class SecurityService extends BaseService {

    public getRoles(): Promise<AxiosResponse<RoleListItem[]>> {
        const url = `${environment.apiUrl}/admin/security/roles`;
        return Axios.get<RoleListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getRole(id: number): Promise<AxiosResponse<ManageRoleViewModel>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/security/roles/${id}`;
        return Axios.get<ManageRoleViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getUserRoles(): Promise<AxiosResponse<IUserRoleListItem[]>> {
        const url = `${environment.apiUrl}/admin/security/user/roles`;
        return Axios.get<IUserRoleListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getProfile(token: string): Promise<AxiosResponse<IUserProfileViewModel>> {
        // This method accepts the token from the parameter and not the store
        // as we have not confirmed that this is a user known by the system yet
        const url = `${environment.apiUrl}/admin/security/me`;
        return Axios.get<IUserProfileViewModel>(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
    }

    public getCmsUsers(): Promise<AxiosResponse<IUserListItem[]>> {
        const url = `${environment.apiUrl}/admin/security/cms-users`;
        return Axios.get<IUserListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getCmsUser(id: number): Promise<AxiosResponse<IManageUserViewModel>> {
        const url = `${environment.apiUrl}/admin/security/cms-users/${id}`;
        return Axios.get<IManageUserViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveRole(viewModel: ManageRoleViewModel): Promise<AxiosResponse<ManageRoleViewModel>> {
        const url = `${environment.apiUrl}/admin/security/role`;
        return Axios.post<ManageRoleViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public saveUserRole(roleId: number, userId: number): Promise<AxiosResponse<IUserRoleListItem>> {
        const url = `${environment.apiUrl}/admin/security/user/${userId}/role`;
        let request: ISaveUserRoleCommand = {
            id: userId,
            roleId: roleId
        };
        return Axios.post<IUserRoleListItem>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public removeUserRole(roleId: number, userId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/security/user/${userId}/roles/${roleId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeRole(id: number): Promise<AxiosResponse<boolean>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/security/roles/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeCmsUser(id: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/security/cms-users/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }
}
