<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-btn color="success" @click="onNewItem">New Service</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card v-show="showEmptyView" elevation="0">
                    <v-row>
                        <v-col md="4">
                            It seems that you have not created a service yet. Create your
                            first one by clicking the New Service button.
                        </v-col>
                        <v-col md="3">
                            <lottie-player src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                                background="transparent" speed="1" style="width: 400px; height: 400px" loop
                                autoplay></lottie-player>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-show="showGrid">
                    <v-card-title>
                        <v-row>
                            <v-col md="4"> Position </v-col>
                            <v-spacer></v-spacer>
                            <v-col md="3">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    clearable hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table :items-per-page="15" :headers="headers" :items="items" :search="search"
                        :single-select="false" item-key="id" :show-select="showMultiSelect" :sort-by="[]"
                        :sort-desc="[]" multi-sort v-model="selectedItems">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-row>
                                <v-col>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="error" icon v-bind="attrs" v-on="on"
                                                @click="onDeleteItem(item)">
                                                <v-icon> mdi-delete </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Click here to remove</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" icon v-bind="attrs" v-on="on"
                                                @click="onEditItem(item)">
                                                <v-icon> mdi-file-document-edit-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Click here to edit</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <confirmation-dialog :dialog="showDeleteDialog" :data="dialogMessage"
            @dialog-option-selected="onDeleteOptionSelected"></confirmation-dialog>
        <confirmation-dialog :dialog="showDialog" :data="dialogMessage"
            @dialog-option-selected="onDialogOptionSelected"></confirmation-dialog>
        <progress-indicator :showProgressBar="showProgressBar" :showSpinner="showSpinner" :message="progressMessage">
        </progress-indicator>
    </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { IGroupServiceTimeItem } from "@/models/group.model";

export default (
    Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
    name: "ListServiceTimes",
    mixins: [CommonMixin],
    data() {
        return {
            showDeleteDialog: false,
            search: "",
            headers: [
                { text: "Actions", value: "actions", sortable: false, width: 120 },
                { text: "Title", value: "title", sortable: true, width: 320 },
                { text: "Start Time", value: "startTime", sortable: true, width: 120 },
                { text: "End Time", value: "endTime", sortable: true, width: 120 },
                { text: "Repeats", value: "recurrence", sortable: false }
            ],
            items: Array<IGroupServiceTimeItem>(),
            selectedItems: Array<IGroupServiceTimeItem>(),
            showMultiSelect: false,
            actionWidth: 4,
            showGrid: false,
            showEmptyView: false,
            ownerId: 0,
        };
    },    
    methods: {
        onNewItem() {
            this.$emit("new-item-requested");
        },

        onEditItem(item: IGroupServiceTimeItem) {
            this.$emit("show-item", item.id);
        },

        onDeleteItem(item: IGroupServiceTimeItem) {
            this.showDeleteConfirmationDialog(item);
        },  

        getItems(ownerId: number) {
            this.ownerId = ownerId;
            this.showGrid = true;
            this.showEmptyView = false;

            this.showProgressIndicator(
                LoadingType.Panel,
                `Getting Services, Please Wait...`
            );

            const service = new GroupService();
            service
                .getServiceTimes(this.ownerId)
                .then((response) => {
                    this.hideProgressIndicator();
                    this.items = response.data;
                    this.actionWidth = this.showMultiSelect ? 5 : 4;
                    this.showGrid = response.data.length > 0;
                    this.showEmptyView = response.data.length == 0;
                })
                .catch((error) => this.showErrorDialog(error));
        },

        showDeleteConfirmationDialog(item: IGroupServiceTimeItem) {
            this.dialogMessage = new DialogMessage(
                `Delete ${item.title} service?`,
                `Are you sure you want to delete the service ${item.title}? This item will removed from the records and no longer available.`
            );
            this.dialogMessage.showCancel = true;
            this.dialogMessage.entity = item;
            this.dialogMessage.errors = [];
            this.showDeleteDialog = true;
        },

        onDeleteOptionSelected(item: DialogResponse) {
            this.showDeleteDialog = false;
            if (item.option) {
                this.showProgressIndicator(
                    LoadingType.Panel,
                    "Removing service time, Please Wait..."
                );
                const service = new GroupService();
                service
                    .removeServiceTime(this.ownerId, item.entity.id)
                    .then(() => {
                        this.hideProgressIndicator();
                        let index = this.items.findIndex((f) => f.id == item.entity.id);
                        if (index !== -1) {
                            this.items.splice(index, 1);
                        }
                        this.showGrid = this.items.length > 0;
                        this.showEmptyView = this.items.length == 0;
                    })
                    .catch((error) => this.showErrorDialog(error));
            }
        },        
    }    
});

</script>