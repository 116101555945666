var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "main-container" } },
    [
      _c("v-row", [
        _c(
          "div",
          { staticClass: "col-md-12 mt-5" },
          [
            _c("section", { staticClass: "page-header" }, [
              _c("h5", { staticClass: "wg-title" }, [_vm._v("Settings")]),
              _c(
                "p",
                { staticClass: "small", staticStyle: { "margin-bottom": "0" } },
                [_vm._v(" Manage your settings here ")]
              ),
            ]),
            _c("div", { staticClass: "divider" }, [_c("v-divider")], 1),
            _c("v-card", { staticClass: "wg-card-shadow" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3 no-padding" }, [
                  _c("div", { staticClass: "inner_sidebar" }, [
                    _c("p", [_vm._v("Side Content")]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-9 content-area" },
                  [
                    _c("p", [_vm._v("Main Content")]),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedMenuItem == 3,
                            expression: "selectedMenuItem == 3",
                          },
                        ],
                        staticClass: "primary",
                        on: { click: _vm.connectToInstagram },
                      },
                      [_vm._v(" Instagram ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }