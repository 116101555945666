<template>
  <v-container fluid>
    <v-form ref="manageUserForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="auto">
                <member-autocomplete
                  :ownerId="ownerId"
                  :member="person"
                  :memberBind.sync="person"
                  :clearable="true"
                  :filled="true"
                  placeholder="Type the name"
                  @member-changed="onMemberChange"
                ></member-autocomplete>
              </v-col>
              <v-col md="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      icon
                      @click="onCancel"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      icon
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshScreen"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
                <v-btn color="primary" type="submit" :disabled="!valid" large>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="tab">
            <v-tab>General</v-tab>
            <v-tab>Contact</v-tab>
            <v-tab>Dates</v-tab>
            <v-tab>GDPR</v-tab>
            <v-tab>Media</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <people-general-section
                ref="peoplegeneralsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
                :canViewSensitiveData="canViewSensitiveData"
              >
              </people-general-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <people-contact-section
                ref="peoplecontactsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
                :canViewSensitiveData="canViewSensitiveData"
              >
              </people-contact-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <people-dates-section
                ref="peopledatessection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
                :canViewSensitiveData="canViewSensitiveData"
              >
              </people-dates-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <people-gdpr-section
                ref="peoplegdprsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
                :canViewSensitiveData="canViewSensitiveData"
              >
              </people-gdpr-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <people-media-section
                ref="peoplemediasection"
                @show-alert-panel="showSuccessPanel"
              >
              </people-media-section>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import MemberAutoComplete from "@/components/common/member-autocomplete.vue";
import { MemberSearchSuggestion } from "@/models/group.model";
import {
  ManageUserContact,
  ManageUserViewModel,
} from "@/models/security.model";
import { AlertType, GroupType, LoadingType } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { v4 as uuidv4 } from "uuid";
import {
  PermissionArea,
  PermissionsManager,
  PermissionType,
} from "@/models/security.model";
import PeopleGeneralSection from "@/views/editors/editor-people-general.vue";
import PeopleContactSection from "@/views/editors/editor-people-contact.vue";
import PeopleDatesSection from "@/views/editors/editor-people-dates.vue";
import PeopleGDPRSection from "@/views/editors/editor-people-gdpr.vue";
import PeopleMediaSection from "@/views/list-managers/list-manager-people-media.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostPeopleManager",
  mixins: [CommonMixin],
  components: {
    ActionAlert,
    "member-autocomplete": MemberAutoComplete,
    "people-general-section": PeopleGeneralSection,
    "people-contact-section": PeopleContactSection,
    "people-dates-section": PeopleDatesSection,
    "people-gdpr-section": PeopleGDPRSection,
    "people-media-section": PeopleMediaSection,
  },
  data() {
    return {
      tab: 0,
      valid: true,
      formData: new ManageUserViewModel(),
      canViewSensitiveData: false,
      id: 0,
      ownerId: 0,
      branchId: 0,
      groupId: 0,
      groupType: "",
      tabIndex: 0,
      person: {} as MemberSearchSuggestion,
    };
  },
  created() {
    if (this.$route.query.branchId) {
      this.branchId = parseInt(this.$route.query.branchId.toString());
    }
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId.toString());
    }
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id.toString());
    }
    if (this.$route.query.groupType) {
      this.groupType = this.$route.query.groupType.toString();
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.getTabIndex(this.$route.query.tab.toString());
    }

    this.ownerId =
      this.groupType === GroupType.Branch ||
      this.groupType == GroupType.NationalMinistry
        ? this.branchId
        : this.groupId;

    let title = this.getNavTitle();
    if (!title.endsWith("(People)")) {
      this.updateNavTitle(`${this.getNavTitle()} (People)`);
    }
    this.getItem();
  },
  methods: {
    refreshScreen() {
      this.getItem();
    },
    getTabIndex(name: string): number {
      let index = 0;

      switch (name) {
        case "general":
          index = 0;
          break;
        case "contact":
          index = 1;
          break;
        case "dates":
          index = 2;
          break;
        case "gdpr":
          index = 3;
          break;
        case "media":
          index = 4;
          break;
      }

      return index;
    },

    resetData() {
      this.tab = this.tabIndex;
      this.formData = new ManageUserViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem() {
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting User Details, Please Wait..."
      );

      this.canViewSensitiveData = PermissionsManager.hasPermission(
        this.ownerId,
        null,
        this.groupType,
        PermissionType.Sensitive,
        PermissionArea.Membership
      );

      const service = new GroupService();
      service
        .getGroupMember(this.ownerId, this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.populateForm();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    populateForm() {
      this.formData.location.localId = uuidv4();

      this.formData.tmpContactNumbers = [];
      this.formData.tmpContactNumbers.push(
        new ManageUserContact(
          1,
          "Primary Contact Number",
          this.formData.primaryContactNumber
        )
      );
      if (this.formData.secondaryContactNumber) {
        this.formData.tmpContactNumbers.push(
          new ManageUserContact(
            2,
            "Secondary Contact Number",
            this.formData.secondaryContactNumber
          )
        );
      }

      this.formData.tmpEmails = [];
      this.formData.tmpEmails.push(
        new ManageUserContact(1, "Primary E-mail", this.formData.primaryEmail)
      );
      if (this.formData.secondaryEmail) {
        this.formData.tmpEmails.push(
          new ManageUserContact(
            2,
            "Secondary E-mail",
            this.formData.secondaryEmail
          )
        );
      }
      if (this.formData.tertiaryEmail) {
        this.formData.tmpEmails.push(
          new ManageUserContact(
            3,
            "Tertiary E-mail",
            this.formData.tertiaryEmail
          )
        );
      }

      const ref: any = this.$refs.peoplecontactsection;
      if (ref) {
        ref.populateForm();
      }

      const ref1: any = this.$refs.peoplemediasection;
      if (ref1) {
        ref1.getItems(this.id, this.branchId, this.groupId, this.groupType);
      }
    },

    onMemberChange(item: MemberSearchSuggestion) {
      this.id = item ? item.id : 0;
      this.getItem();
    },

    submitForm() {
      this.formData.ownerId = this.ownerId;

      this.formData.primaryContactNumber = "";
      this.formData.secondaryContactNumber = "";

      this.formData.primaryEmail = "";
      this.formData.secondaryEmail = "";
      this.formData.tertiaryEmail = "";

      for (let i = 0; i < this.formData.tmpEmails.length; i++) {
        let item = this.formData.tmpEmails[i];
        switch (i) {
          case 0:
            this.formData.primaryEmail = item.value;
            break;
          case 1:
            this.formData.secondaryEmail = item.value;
            break;
          case 2:
            this.formData.tertiaryEmail = item.value;
            break;
        }
      }

      for (let i = 0; i < this.formData.tmpContactNumbers.length; i++) {
        let item = this.formData.tmpContactNumbers[i];
        switch (i) {
          case 0:
            this.formData.primaryContactNumber = item.value;
            break;
          case 1:
            this.formData.secondaryContactNumber = item.value;
            break;
        }
      }

      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving User Details, Please Wait..."
      );

      const service = new GroupService();
      service
        .saveGroupMember(this.groupId, this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.populateForm();
          this.showSuccessPanel(
            "The person was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      this.$router.replace({
        name: "host-groups",
        query: {
          branchId: this.branchId.toString(),
          groupId: this.groupId.toString(),
          groupType: this.groupType,
          tab: "people",
        },
      });
    },

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>