<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="timeValue"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="timeValue"
        :outlined="outlined"
        :label="label"
        :disabled="disabled"
        :clearable="clearable"
        :rules="timeRules"
        :prepend-icon="prependIcon"
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="onTimeCleared"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="timeValue"
      full-width
      format="24hr"
      scrollable
      :no-title="!showHeader"
      @click:minute="$refs.menu.save(timeValue)"
      @change="onTimeSelected"
    ></v-time-picker>
  </v-menu>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "TimePicker",
  mixins: [CommonMixin],
  props: {
    time: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Time",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    outlined: {
      type: Boolean,
      default: true,
      required: false,
    },
    timeRules: {
      type: Array,
      required: false,
    },
    prependIcon: {
      type: String,
      default: "",
      required: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  watch: {
    time: {
      immediate: true,
      handler(val: string) {
        this.timeValue = val ? DateTime.fromISO(val).toFormat("T") : "";
      },
    },
  },
  data() {
    return {
      timeValue: "",
      menu: false,
    };
  },
  methods: {
    onTimeSelected(val: string) {
      let t = DateTime.fromISO(val).toISOTime();
      this.$emit("update:time", t);
      this.$emit("time-changed", t);
    },
    onTimeCleared() {
      this.$emit("update:time", "");
      this.$emit("time-changed", "");
    },
  },
});
</script>