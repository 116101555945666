<template>
  <div>
    <div v-show="showProgressBar">
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="5"
      ></v-progress-linear>
    </div>
    <div class="text-center">
      <v-dialog v-model="showSpinner" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <div style="padding-top: 10px; padding-bottom: 20px">
              {{ message }}
            </div>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "ProgressIndicator",
  props: {
    showProgressBar: Boolean,
    showSpinner: Boolean,
    message: String,
  },
});
</script>
