var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "" },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { "active-class": "deep-indigo--text text--accent-4" },
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-list-item",
                {
                  key: item.id,
                  attrs: { disabled: !item.enabled },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onItemClick(item)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }