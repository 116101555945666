<template>
  <v-container fluid>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-dialog
      v-model="showMyDialog"
      :max-width="maxWidth"
      persistent
      :retain-focus="false"
      eager
    >
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          <div>Permitted Users</div>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onOk()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <div style="height: 20px" />
          <div>Select the users that will be permitted to run this query.</div>
          <div style="height: 20px" />
          <v-row>
            <v-col md="3">
              <v-autocomplete
                :items="members"
                :loading="isMatching"
                :search-input.sync="matching"
                item-text="name"
                item-value="id"
                v-model="selectedMember"
                outlined
                clearable
                label="Member/Visitor"
                placeholder="Member or Visitor"
                menu-props="auto"
                hide-details="auto"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col md="2">
              <v-btn
                color="success"
                @click="onNewItem"
                :disabled="!hasSelectedMember"
                >Add to List</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="11">
              <v-card>
                <v-card-title>
                  <v-row>
                    <v-col md="2"></v-col>
                    <v-spacer></v-spacer>
                    <v-col md="3">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        clearable
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :items-per-page="10"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :single-select="false"
                  item-key="id"
                  :show-select="showMultiSelect"
                  v-model="selectedItems"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-row>
                      <v-col md="12">
                        <v-btn color="primary" icon @click="onDeleteItem(item)">
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onOk()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { DemographicsQueryRunner } from "@/models/demographics.model";
import { DemographicsService } from "@/services/demographics.service";
import {
  DialogMessage,
  DialogResponse,
  LoadingType,
} from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { MemberSearchSuggestion } from "@/models/group.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "QueryPermissionsDialog",
  mixins: [CommonMixin],
  data() {
    return {
      showDialog: false,
      showMyDialog: false,
      showDeleteDialog: false,
      maxWidth: "40%",
      search: "",
      headers: [
        { text: "Name", value: "name", width: 240 },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: Array<DemographicsQueryRunner>(),
      selectedItems: Array<DemographicsQueryRunner>(),
      showMultiSelect: false,
      isMatching: false,
      selectedMember: new MemberSearchSuggestion(),
      members: new Array<MemberSearchSuggestion>(),
      matching: null,
      hasSelectedMember: false,
      demographicsQueryId: 0,
    };
  },
  props: {
    dialog: Boolean,
  },
  watch: {
    dialog(val: boolean) {
      this.showMyDialog = val;
    },
    selectedMember(val: MemberSearchSuggestion) {
      this.hasSelectedMember = val && val.id !== 0 ? true : false;
    },
    matching(val: string) {
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(0, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  methods: {
    onOk() {
      this.$emit("ok-clicked");
    },

    onNewItem() {
      if (this.selectedMember) {
        const match = this.items.find((f) => f.id == this.selectedMember.id);
        if (!match) {
          this.showProgressIndicator(
            LoadingType.Panel,
            "Adding user, Please Wait..."
          );
          const service = new DemographicsService();
          service
            .addAuthorizedQueryRunner(
              this.demographicsQueryId,
              this.selectedMember.id
            )
            .then((response) => {
              this.hideProgressIndicator();
              let item = response.data;
              this.items.push(item);
              this.selectedMember = new MemberSearchSuggestion();
            })
            .catch((error) => this.showErrorDialog(error));
        }
      }
    },

    onDeleteItem(item: DemographicsQueryRunner) {
      this.showDeleteConfirmationDialog(item);
    },

    showDeleteConfirmationDialog(item: DemographicsQueryRunner) {
      this.dialogMessage = new DialogMessage(
        `Remove ${item.name} permission?`,
        `Are you sure you want to remove this users access to this query? If so the user will no longer be able to run it.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing user, Please Wait..."
        );
        const service = new DemographicsService();
        service
          .removeAuthorisedQueryRunner(this.demographicsQueryId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.selectedMember = new MemberSearchSuggestion();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    getItems(id: number) {
      this.demographicsQueryId = id;
      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Users, Please Wait...`
      );

      const service = new DemographicsService();
      service
        .getAuthorizedQueryRunners(this.demographicsQueryId)
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },
  },
});
</script>