<template>
  <div class="container">
    <div class="split-screen">
      <div class="left-screen">
        <section class="copy"></section>
      </div>

      <div class="right-screen">
        <form>
          <section>
            <v-img max-height="512" max-width="512" :src="imageUrl"></v-img>
          </section>
          <section class="social-container">
            <!--
            <v-btn elevation="0" text outlined v-on:click="onFacebookAuth()"
              >Continue with Facebook</v-btn
            >
            -->
            <v-btn outlined v-on:click="onGoogleAuth()"
              >Login with Google</v-btn
            >
            <div style="height: 12px" />
            <v-btn outlined v-on:click="onMicrosoftAuth()"
              >Login with Microsoft</v-btn
            >
            <!--<v-btn elevation="0" text outlined>Continue with Apple</v-btn>-->
          </section>
          <!--
          <v-text-field
            label="E-mail"
            placeholder="E-mail"
            v-model="email"
            :rules="[rules.required]"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            label="Password"
            placeholder="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.passwordMinLength]"
            counter
            maxlength="12"
            @click:append="showPassword = !showPassword"
            outlined
            dense
          ></v-text-field>
          <v-checkbox
            v-model="rememberPassword"
            label="Remember me"
            color="primary"
            hide-details
          ></v-checkbox>
          <button class="login-btn" v-on:click="onEmailPasswordAuth()">
            Log in
          </button>
          -->
          <section class="copy legal">
            <p>
              <span class="small"
                >By continuing, you agree to accept our <br />
                <a href="#">Privacy Policy</a> &amp;
                <a href="#">Terms of Condition</a>
              </span>
            </p>
          </section>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import firebase from "firebase/app";
import CommonMixin from "@/mixins/common.mixin";
import store from "@/store/index";
import { environment } from "@/environment";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "login",
  mixins: [CommonMixin],
  data() {
    return {
      error: "",
      password: "",
      email: "",
      showPassword: false,
      rememberPassword: false,
      rules: {
        required: (value: any) => !!value || "You must provide a value.",
        passwordMinLength: (value: any) =>
          value.length >= 8 || "Must be at least 8 characters",
      },
      imageUrl: require("@/assets/images/COG7-logo.png"),
      url: environment.apiUrl,
    };
  },
  methods: {
    onFacebookAuth() {
      store.commit("setTitle", "");
      store.commit("setAuthenticating", true);
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      firebase.auth().signInWithRedirect(provider);
      firebase
        .auth()
        .getRedirectResult()
        .then(() => {
          this.$router.replace({ name: "/" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },

    onGoogleAuth() {
      store.commit("setTitle", "");
      store.commit("setAuthenticating", true);
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      firebase.auth().signInWithPopup(provider);
      firebase
        .auth()
        .getRedirectResult()
        .then(() => {
          this.$router.replace({ name: "/" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },

    onMicrosoftAuth() {
      store.commit("setTitle", "");
      store.commit("setAuthenticating", true);
      const provider = new firebase.auth.OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        prompt: "select_account",
      });
      provider.addScope("User.Read");
      firebase.auth().signInWithPopup(provider);
      firebase
        .auth()
        .getRedirectResult()
        .then(() => {
          this.$router.replace({ name: "/" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },

    onInternalAuth() {
      /*
            const provider = new firebase.auth.OAuthProvider("microsoft.com");
            provider.setCustomParameters({
                tenant: environment.tenentId,
            });

            provider.addScope("User.Read");
            firebase.auth().signInWithRedirect(provider);
            firebase
                .auth()
                .getRedirectResult()
                .then(() => {
                    this.$router.replace({ name: "/" });
                })
                .catch((err) => {
                    this.showErrorDialog(err);
                    this.error = err.message;
                });
            */
    },

    onEmailPasswordAuth() {
      store.commit("setTitle", "");
      store.commit("setAuthenticating", true);
      firebase.auth().signInWithEmailAndPassword(this.email, this.password);
      firebase
        .auth()
        .getRedirectResult()
        .then(() => {
          this.$router.replace({ name: "/" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/css/tnl.scss";

:root {
  font-size: 100%;
  font-size: 16px;
  line-height: 1.5;
  color: $primary-blue;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 2.25rem;
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
a {
  text-decoration: none;
  color: var(--primary-blue);
}
a:hover {
  text-decoration: underline;
}
.small {
  font-size: 80%;
  text-align: center;
}
.split-screen {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}
.left-screen {
  height: 200px;
  padding-right: 140px;
  justify-content: flex-end;
}

.left-screen,
.right-screen {
  display: flex;
}
.left-screen .copy {
  width: 560px;
  color: $primary-blue;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.left p {
  font-weight: 400;
}
.right-screen .copy {
  text-align: center;
}
.right-screen .copy p {
  margin: 1.5em 0;
  font-size: 0.875rem;
}
.right-screen form {
  width: 360px;
}
form input[type="text"],
form input[type="email"],
form input[type="password"] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 1em;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}
label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  text-align: le;
}
.login-btn {
  display: block;
  width: 100%;
  background: #1f75cb;
  color: #fff;
  font-weight: 700;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.login-btn:hover {
  background: #2c4893;
  cursor: pointer;
}
.social-container {
  display: flex;
  flex-direction: column;
}
.social-container p {
  display: flex;
  justify-content: center;
}
.social-container a {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.social-container a.last {
  margin-bottom: 0;
}
@media screen and (min-width: 900px) {
  .split-screen {
    flex-direction: row;
    height: 100vh;
  }
  .left-screen {
    display: flex;
    width: 25%;
    height: auto;
  }
  .right-screen {
    display: flex;
    width: 50%;
    height: auto;
  }
}

@media screen and (max-width: 899px) {
  .split-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  .left-screen {
    display: none;
  }
}
</style>
