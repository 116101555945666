var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "40%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.dialogData.title) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onExit()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "top-buffer" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.currentStatus,
                              label: "Current Status",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.currentStatus
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.currentStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "currentStatus", $$v)
                              },
                              expression: "selection.currentStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.gender,
                              label: "Gender",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.gender.length -
                                                    1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "gender", $$v)
                              },
                              expression: "selection.gender",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.validateAgeStart,
                              type: "number",
                              outlined: "",
                              label: "Age (from)",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selection.ageStart,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "ageStart", _vm._n($$v))
                              },
                              expression: "selection.ageStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.validateAgeEnd,
                              type: "number",
                              outlined: "",
                              label: "Age (to)",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selection.ageEnd,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "ageEnd", _vm._n($$v))
                              },
                              expression: "selection.ageEnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.filters.contactable,
                              label: "Contactable Only",
                              placeholder: "Select an option",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              outlined: "",
                            },
                            model: {
                              value: _vm.selection.contactable,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "contactable", $$v)
                              },
                              expression: "selection.contactable",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.attributes,
                              label: "Attributes",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.attributes
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.attributes,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "attributes", $$v)
                              },
                              expression: "selection.attributes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.maritalStatus,
                              label: "Marital Status",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.maritalStatus
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.maritalStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "maritalStatus", $$v)
                              },
                              expression: "selection.maritalStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.profession,
                              label: "Profession",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.profession
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.profession,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "profession", $$v)
                              },
                              expression: "selection.profession",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.ethnicity,
                              label: "Ethnicity",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.ethnicity
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.ethnicity,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "ethnicity", $$v)
                              },
                              expression: "selection.ethnicity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filters.nationality,
                              label: "Nationality",
                              placeholder: "Select one or more options",
                              multiple: "",
                              clearable: "",
                              "auto-select-first": "",
                              outlined: "",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + " "),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", {
                                      staticStyle: { width: "4px" },
                                    }),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  _vm.selection.nationality
                                                    .length - 1
                                                ) +
                                                " others) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selection.nationality,
                              callback: function ($$v) {
                                _vm.$set(_vm.selection, "nationality", $$v)
                              },
                              expression: "selection.nationality",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.dateOfBaptismStart,
                              dateRules: _vm.validateDateOfBaptism,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Baptism Date (Start)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "dateOfBaptismStart",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.dateOfBaptismEnd,
                              dateRules: _vm.validateDateOfBaptism,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Baptism Date (End)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "dateOfBaptismEnd",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.fellowshipDateStart,
                              dateRules: _vm.validateDateOfFellowship,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Fellowship Date (Start)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "fellowshipDateStart",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.fellowshipDateEnd,
                              dateRules: _vm.validateDateOfFellowship,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Fellowship Date (End)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "fellowshipDateEnd",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.resignationDateStart,
                              dateRules: _vm.validateDateOfResignation,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Resignation Date (Start)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "resignationDateStart",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.resignationDateEnd,
                              dateRules: _vm.validateDateOfResignation,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Resignation Date (End)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "resignationDateEnd",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.datePassedStart,
                              dateRules: _vm.validateDateOfPassing,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Date Passed (Start)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "datePassedStart",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              date: _vm.selection.datePassedEnd,
                              dateRules: _vm.validateDateOfPassing,
                              disabled: !_vm.canViewSensitiveData,
                              label: "Date Passed (End)",
                            },
                            on: {
                              "update:date": function ($event) {
                                return _vm.$set(
                                  _vm.selection,
                                  "datePassedEnd",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onCancel()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onReset()
                        },
                      },
                    },
                    [_vm._v(" Reset ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" Apply ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }