var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMemberSelection
        ? _c("v-autocomplete", {
            attrs: {
              items: _vm.members,
              loading: _vm.isMatching,
              "search-input": _vm.matching,
              "item-text": "name",
              "item-value": "id",
              outlined: "",
              clearable: "",
              label: _vm.label,
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled,
              readonly: _vm.isReadOnly,
              "menu-props": "auto",
              "hide-details": "auto",
              "return-object": "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.matching = $event
              },
              "update:search-input": function ($event) {
                _vm.matching = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append-outer",
                  fn: function () {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-slide-x-reverse-transition",
                                      { attrs: { mode: "out-in" } },
                                      [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                key: "icon-isMemberSelection",
                                                attrs: {
                                                  color: _vm.isMemberSelection
                                                    ? "success"
                                                    : "primary",
                                                  disabled: _vm.isDisabled,
                                                  readonly: _vm.isReadOnly,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.isMemberSelection
                                                      ? "mdi-account-search"
                                                      : "mdi-account-question"
                                                  ),
                                                },
                                                on: {
                                                  click: _vm.memberIconClicked,
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1456011225
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v("Click to enter an unlisted person"),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1724242190
            ),
            model: {
              value: _vm.selectedItem,
              callback: function ($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem",
            },
          })
        : _vm._e(),
      !_vm.isMemberSelection
        ? _c("v-text-field", {
            attrs: {
              label: _vm.label,
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled,
              readonly: _vm.isReadOnly,
              outlined: "",
              clearable: "",
              "hide-details": "auto",
              maxlength: "200",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append-outer",
                  fn: function () {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-slide-x-reverse-transition",
                                      { attrs: { mode: "out-in" } },
                                      [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                key: "icon-isNotMemberSelection",
                                                attrs: {
                                                  color: _vm.isMemberSelection
                                                    ? "success"
                                                    : "primary",
                                                  disabled: _vm.isDisabled,
                                                  readonly: _vm.isReadOnly,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.isMemberSelection
                                                      ? "mdi-account-searcg"
                                                      : "mdi-account-question"
                                                  ),
                                                },
                                                on: {
                                                  click: _vm.memberIconClicked,
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            204861123
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v("Click to select an existing person"),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3185493893
            ),
            model: {
              value: _vm.selectedName,
              callback: function ($$v) {
                _vm.selectedName = $$v
              },
              expression: "selectedName",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }