import Axios, { AxiosResponse } from "axios";
import { environment } from "@/environment";
import { ManageQuarterlyReturnViewModel, QuarterlyReturnDto } from "@/models/cog7d.model";
import { BaseService } from "./base.service";
import { IPagedList } from "@/models/common.model";

export class COG7DService extends BaseService {
    constructor() {
        super();
    }

    public getQuarterlyReturns(groupId: number, year: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<QuarterlyReturnDto>>> {
        let url = `${environment.apiUrl}/admin/cog7d/group/${groupId}/quarterly-returns?year=${year}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<QuarterlyReturnDto>>(url, { headers: this.getAuthenticationHeader() });
    }

    public getQuarterlyReturn(groupId: number, id: number): Promise<AxiosResponse<ManageQuarterlyReturnViewModel>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/cog7d/group/${groupId}/quarterly-return/${id}`;
        return Axios.get<ManageQuarterlyReturnViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveQuarterlyReturn(groupId: number, viewModel: ManageQuarterlyReturnViewModel): Promise<AxiosResponse<ManageQuarterlyReturnViewModel>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        const url = `${environment.apiUrl}/admin/cog7d/group/${groupId}/quarterly-return`;
        return Axios.post<ManageQuarterlyReturnViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public removeQuarterlyReturn(groupId: number, id: number): Promise<AxiosResponse<boolean>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/cog7d/group/${groupId}/quarterly-return/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public exportReturns(groupId: number, year: number,): Promise<AxiosResponse<Blob>> {
        const url = `${environment.apiUrl}/admin/cog7d/group/${groupId}/quarterly-returns/export?year=${year}`;
        return Axios.get(url, {
            responseType: 'blob', headers: this.getBlobHeader()
        });
    }
}