import { render, staticRenderFns } from "./editor-contribution-list-media.vue?vue&type=template&id=aa0971d6&scoped=true"
import script from "./editor-contribution-list-media.vue?vue&type=script&lang=ts"
export * from "./editor-contribution-list-media.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/wg.css?vue&type=style&index=0&id=aa0971d6&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0971d6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VCheckbox,VCol,VContainer,VDataIterator,VHover,VIcon,VImg,VRow,VSpacer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa0971d6')) {
      api.createRecord('aa0971d6', component.options)
    } else {
      api.reload('aa0971d6', component.options)
    }
    module.hot.accept("./editor-contribution-list-media.vue?vue&type=template&id=aa0971d6&scoped=true", function () {
      api.rerender('aa0971d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/editors/editor-contribution-list-media.vue"
export default component.exports