import { IPagedList } from "./common.model";

export interface IManageConferenceViewModel {
    id: number;
    name: string;
    charityNumber: string;
    contactNumber: string;
    webSiteUrl: string;
    email: string;
    overview: string;
    dateRegistered: Date | null;
}

export class ManageConferenceViewModel implements IManageConferenceViewModel {
    id: number;
    name: string;
    charityNumber: string;
    contactNumber: string;
    webSiteUrl: string;
    email: string;
    overview: string;
    dateRegistered: Date | null;

    constructor() {
        this.id = 0;
        this.name = '';
        this.charityNumber = '';
        this.contactNumber = '';
        this.webSiteUrl = '';
        this.email = '';
        this.overview = '';
        this.dateRegistered = null;
    }
}

export class StoreAuthorizationTokenCommand {
    platform: string;
    token: string;

    constructor(platform: string, token: string) {
        this.platform = platform;
        this.token = token;
    }
}

export interface IApprovedImage {
    id: number;
    name?: string;
    tags?: string;
    image?: string;
    imageUrl: string;
    selected?: boolean;
}

export class ApprovedImage implements IApprovedImage {
    id: number;
    name?: string;
    tags?: string;
    image?: string;
    imageUrl: string;
    selected?: boolean;

    constructor() {
        this.id = 0;
        this.name = "";
        this.tags = "";
        this.image = "";
        this.imageUrl = "";
        this.selected = false;
    }
}

export interface IRegisteredUserDto {
    id: number;
    groupId: number | null;
    isMember: boolean | null;
    groupName: string;
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    imageUrl: string;
    isDeleted: boolean;
}

export interface IRemoveRegisteredUsersCommand {
    ids: number[];
}

export interface IReinstateRegisteredUsersCommand {
    ids: number[];
}

export interface IManageRegisteredUsersViewModel {
    groups: string[];
    users: IPagedList<IRegisteredUserDto>;
}