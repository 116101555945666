import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";

import Login from "@/views/system/login.vue";
import AuthRedirect from "@/views/system/authredirect.vue";
import landing from "@/views/system/landing.vue";
import Settings from '@/views/system/settings.vue';

import ListEvents from '@/views/lists/list-events.vue';

import HostMobileAppBuilder from "@/views/hosts/host-mobile-app-builder.vue";
import HostGroupManager from "@/views/hosts/host-groups.vue";
import HostCommunicationManager from "@/views/hosts/host-communications.vue";
import HostPeopleManager from "@/views/hosts/host-people.vue";
import HostPositionManager from "@/views/hosts/host-positions.vue";
import HostPollsManager from "@/views/hosts/host-polls.vue";
import HostEventsManager from "@/views/hosts/host-events.vue";
import HostGroupsMedia from "@/views/hosts/host-groups-media.vue";
import HostPeopleMedia from "@/views/hosts/host-people-media.vue";
import HostMediaItem from "@/views/hosts/host-media-item.vue";
import HostConferenceManager from "@/views/hosts/host-conference.vue";
import HostMinistryManger from "@/views/hosts/host-ministries.vue";
import HostQuarterlyReturn from "@/views/hosts/host-quarterly-return.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/authredirect",
      name: "authredirect",
      component: AuthRedirect,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/landing",
      name: "landing",
      component: landing,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/",
      name: "landing",
      component: landing,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/list-events',
      name: 'list-events',
      component: ListEvents,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-groups',
      name: 'host-groups',
      component: HostGroupManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-ministries',
      name: 'host-ministries',
      component: HostMinistryManger,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-conference',
      name: 'host-conference',
      component: HostConferenceManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-communications',
      name: 'host-communications',
      component: HostCommunicationManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-mobile-app-builder',
      name: 'host-mobile-app-builder',
      component: HostMobileAppBuilder,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-people',
      name: 'host-people',
      component: HostPeopleManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-positions',
      name: 'host-positions',
      component: HostPositionManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-polls',
      name: 'host-polls',
      component: HostPollsManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-events',
      name: 'host-events',
      component: HostEventsManager,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-groups-media',
      name: 'host-groups-media',
      component: HostGroupsMedia,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-people-media',
      name: 'host-people-media',
      component: HostPeopleMedia,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-media-item',
      name: 'host-media-item',
      component: HostMediaItem,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/host-quarterly-return',
      name: 'host-quarterly-return',
      component: HostQuarterlyReturn,
      meta: {
        authRequired: true
      }
    }    
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router;
