import { render, staticRenderFns } from "./image-uploader.vue?vue&type=template&id=4a07a538&scoped=true"
import script from "./image-uploader.vue?vue&type=script&lang=ts"
export * from "./image-uploader.vue?vue&type=script&lang=ts"
import style0 from "./image-uploader.vue?vue&type=style&index=0&id=4a07a538&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a07a538",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VContainer,VDialog,VForm,VRow,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a07a538')) {
      api.createRecord('4a07a538', component.options)
    } else {
      api.reload('4a07a538', component.options)
    }
    module.hot.accept("./image-uploader.vue?vue&type=template&id=4a07a538&scoped=true", function () {
      api.rerender('4a07a538', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/image-uploader.vue"
export default component.exports