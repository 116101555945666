<template>
  <v-container fluid>
    <v-row>
      <v-col md="2">
        <v-btn color="success" @click="onNewItem"
          >New Quarterly Return Record</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="auto">
        <v-select
          :items="years"
          v-model="selectedYear"
          label="Year"
          placeholder="Select the year"
          menu-props="auto"
          hide-details="auto"
          @change="onFilterOptionsChange"
          outlined
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created a quarterly return record yet.
              Create your first one by clicking the New Record.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> Quarterly Returns - {{ heading }}</v-col>
              <v-spacer />
              <v-col md="1">
                <v-btn color="success" @click="onDownloadButtonClicked">Export To Excel</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model="selectedItems"
            item-key="id"
            :headers="headers"
            :items="items"
            :single-select="false"
            :show-select="showMultiSelect"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:[`item.quarter`]="{ item }">
              {{ formatNumber(item.quarter, 2) }}
            </template>

            <!-- Current Assets -->
            <template v-slot:[`item.currentAccountsTotalBalance`]="{ item }">
              {{ formatCurrency(item.currentAccountsTotalBalance) }}
            </template>
            <template v-slot:[`item.depositAccountsTotalBalance`]="{ item }">
              {{ formatCurrency(item.depositAccountsTotalBalance) }}
            </template>
            <template v-slot:[`item.creditUnionAccountBalance`]="{ item }">
              {{ formatCurrency(item.creditUnionAccountBalance) }}
            </template>
            <template v-slot:[`item.otherAccountsBalance`]="{ item }">
              {{ formatCurrency(item.otherAccountsBalance) }}
            </template>

            <!-- Fixed Assets -->
            <template
              v-slot:[`item.buildingValuationInsuranceValue`]="{ item }"
            >
              {{ formatCurrency(item.buildingValuationInsuranceValue) }}
            </template>
            <template v-slot:[`item.buildingValuationMarketValue`]="{ item }">
              {{ formatCurrency(item.buildingValuationMarketValue) }}
            </template>
            <template v-slot:[`item.otherAssetsValuation`]="{ item }">
              {{ formatCurrency(item.otherAssetsValuation) }}
            </template>
            <template v-slot:[`item.otherLongTermInvestments`]="{ item }">
              {{ formatCurrency(item.otherLongTermInvestments) }}
            </template>

            <!-- Income -->
            <template v-slot:[`item.totalTithesAndOffering`]="{ item }">
              {{ formatCurrency(item.totalTithesAndOffering) }}
            </template>
            <template v-slot:[`item.totalOtherIncome`]="{ item }">
              {{ formatCurrency(item.totalOtherIncome) }}
            </template>
            <template v-slot:[`item.totalGiftAidReceipts`]="{ item }">
              {{ formatCurrency(item.totalGiftAidReceipts) }}
            </template>
            <template v-slot:[`item.totalLoansRecovered`]="{ item }">
              {{ formatCurrency(item.totalLoansRecovered) }}
            </template>

            <!-- Expenditure -->
            <template v-slot:[`item.totalRunningExpenses`]="{ item }">
              {{ formatCurrency(item.totalRunningExpenses) }}
            </template>
            <template
              v-slot:[`item.extraordinaryProjectsExpenditure`]="{ item }"
            >
              {{ formatCurrency(item.extraordinaryProjectsExpenditure) }}
            </template>
            <template v-slot:[`item.totalConferenceContributions`]="{ item }">
              {{ formatCurrency(item.totalConferenceContributions) }}
            </template>
            <template v-slot:[`item.conventionFees`]="{ item }">
              {{ formatCurrency(item.conventionFees) }}
            </template>
            <template v-slot:[`item.loansIssued`]="{ item }">
              {{ formatCurrency(item.loansIssued) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn color="success" icon @click="onEditItem(item)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { DataOptions } from "vuetify";
import { QuarterlyReturnDto } from "@/models/cog7d.model";
import { COG7DService } from "@/services/cog7d.service";
import { DateTime } from "luxon";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListQuarterlyReturn",
  mixins: [CommonMixin],
  watch: {
    options: {
      handler() {
        this.onFilterOptionsChange();
      },
      deep: true,
    },
  },
  computed: {
    heading(): String {
      return this.selectedYear ? this.selectedYear.toString() : "All";
    },
  },
  data() {
    return {
      showDeleteDialog: false,
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      headers: [
        { text: "Year", value: "year", sortable: true },
        { text: "Quarter", value: "quarter", sortable: true },
        {
          text: "Balance (Current Accounts)",
          value: "currentAccountsTotalBalance",
          sortable: false,
        },
        {
          text: "Balance (Deposit Accounts)",
          value: "depositAccountsTotalBalance",
          sortable: false,
        },
        {
          text: "Balance (Credit Union)",
          value: "creditUnionAccountBalance",
          sortable: false,
        },
        {
          text: "Balance (Other)",
          value: "otherAccountsBalance",
          sortable: false,
        },
        {
          text: "Building Valuation (Insurance)",
          value: "buildingValuationInsuranceValue",
          sortable: false,
        },
        {
          text: "Building Valuation (Market)",
          value: "buildingValuationMarketValue",
          sortable: false,
        },
        {
          text: "Other Assets",
          value: "otherAssetsValuation",
          sortable: false,
        },
        {
          text: "Long Term Investments",
          value: "otherLongTermInvestments",
          sortable: false,
        },
        {
          text: "Tithes & Offering",
          value: "totalTithesAndOffering",
          sortable: false,
        },
        {
          text: "Gift Aid",
          value: "totalGiftAidReceipts",
          sortable: false,
        },
        {
          text: "Loans Recovered",
          value: "totalLoansRecovered",
          sortable: false,
        },
        {
          text: "Other Income",
          value: "totalOtherIncome",
          sortable: false,
        },
        {
          text: "Running Expenses",
          value: "totalRunningExpenses",
          sortable: false,
        },
        {
          text: "Extraordinary Projects",
          value: "extraordinaryProjectsExpenditure",
          sortable: false,
        },
        {
          text: "Conference Contributions",
          value: "totalConferenceContributions",
          sortable: false,
        },
        {
          text: "Convention Fees",
          value: "conventionFees",
          sortable: false,
        },
        {
          text: "Loans Issued",
          value: "loansIssued",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      items: Array<QuarterlyReturnDto>(),
      selectedItems: Array<QuarterlyReturnDto>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      ownerId: 0,
      years: [] as number[],
      selectedYear: 0,
      exportFileName: "",
    };
  },
  created() {
    let currentYear = DateTime.now().year;
    this.years.push(currentYear);
    for (let year = currentYear; year > currentYear - 30; year--) {
      this.years.push(year);
    }
    this.selectedYear = currentYear;
  },
  methods: {
    onNewItem() {
      this.$emit("new-item-requested");
    },

    onEditItem(item: QuarterlyReturnDto) {
      this.$emit("show-item", item.id);
    },

    onDeleteItem(item: QuarterlyReturnDto) {
      this.showDeleteConfirmationDialog(item);
    },

    onFilterOptionsChange() {
      if (this.ownerId) {
        this.getItems(this.ownerId);
      }
    },

    getItems(ownerId: number) {
      const { page, itemsPerPage }: DataOptions = this.options;

      // If the search is being called from a dialog property watch
      // we may not have the data options. In this case take it from
      // the default values we have set up in the data section.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      this.ownerId = ownerId;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Returns, Please Wait...`
      );

      const service = new COG7DService();
      service
        .getQuarterlyReturns(
          this.ownerId,
          this.selectedYear,
          pageNumber,
          perPage
        )
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data.items;
          this.totalItems = response.data.totalCount;

          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.items.length > 0;
          this.showEmptyView = response.data.items.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: QuarterlyReturnDto) {
      this.dialogMessage = new DialogMessage(
        `Delete quarterly return for: ${item.year}/${item.quarter}?`,
        `Are you sure you want to delete the return for ${item.year}/${item.quarter}? This item will removed from the records and no longer available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing quarterly return, Please Wait..."
        );

        const service = new COG7DService();
        service
          .removeQuarterlyReturn(this.ownerId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onDownloadButtonClicked() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Exporting, Please Wait...`
      );

      const service = new COG7DService();
      service
        .exportReturns(this.ownerId, this.selectedYear)
        .then((response: any) => {
          let fileName =
            this.selectedYear !== 0
              ? `Quarterly-Return-${this.selectedYear}`
              : `Quarterly-Return`;

          this.hideProgressIndicator();
          this.showExportedFile(response, fileName);
        })
        .catch((error) => this.showErrorDialog(error));
    },
  },
});
</script>