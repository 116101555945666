import { render, staticRenderFns } from "./list-manager-national-ministry-people.vue?vue&type=template&id=6e3c9ea6&scoped=true"
import script from "./list-manager-national-ministry-people.vue?vue&type=script&lang=ts"
export * from "./list-manager-national-ministry-people.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/wg.css?vue&type=style&index=0&id=6e3c9ea6&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3c9ea6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VDataTable,VIcon,VRow,VSelect,VSimpleCheckbox,VSpacer,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e3c9ea6')) {
      api.createRecord('6e3c9ea6', component.options)
    } else {
      api.reload('6e3c9ea6', component.options)
    }
    module.hot.accept("./list-manager-national-ministry-people.vue?vue&type=template&id=6e3c9ea6&scoped=true", function () {
      api.rerender('6e3c9ea6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/list-managers/list-manager-national-ministry-people.vue"
export default component.exports