<template>
  <v-container fluid>
    <list-people
      v-show="showListView"
      ref="listitems"
      @new-item-requested="onNewItem"
      @show-item="onShowItem"
    ></list-people>
    <list-local-group-members v-show="showLocalListView" ref="locallistitems">
    </list-local-group-members>
    <list-ministry-members v-show="showMinistryListView" ref="ministryitems">
    </list-ministry-members>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ListPeople from "@/views/lists/list-people.vue";
import ListLocalGroupMembers from "@/views/lists/list-local-group-members.vue";
import ListMinistryMembers from "@/views/lists/list-ministry-members.vue";
import NoPermissionCard from "@/components/common/no-permission-card.vue";
import { GroupType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerPeople",
  mixins: [CommonMixin],
  components: {
    ListPeople,
    ListLocalGroupMembers,
    "no-permission-card": NoPermissionCard,
    "list-ministry-members": ListMinistryMembers,
  },
  data() {
    return {
      showListView: true,
      showLocalListView: false,
      showMinistryListView: false,
      groupType: GroupType.Branch,
      branchId: 0,
      groupId: 0,
      id: 0,
    };
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems(branchId: number, groupId: number, groupType: string) {
      this.groupType = groupType;
      this.branchId = branchId;
      this.groupId = groupId;

      this.showListView = groupType === GroupType.Branch;
      this.showLocalListView = groupType === GroupType.LocalMinistry;
      this.showMinistryListView = groupType == GroupType.NationalMinistry;

      if (groupType === GroupType.Branch) {
        const ref: any = this.$refs.listitems;
        if (ref) {
          ref.getItems(this.branchId);
        }
      } else if (groupType == GroupType.NationalMinistry) {
        const ref: any = this.$refs.ministryitems;
        if (ref) {
          ref.getItems(branchId);
        }
      } else {
        const ref: any = this.$refs.locallistitems;
        if (ref) {
          ref.getItems(branchId, groupId);
        }
      }
    },

    onNewItem() {
      this.id = 0;
      this.showListView = false;
      this.showLocalListView = false;
      this.$router.push({
        name: "host-people",
        query: {
          branchId: this.branchId.toString(),
          groupId: this.groupId.toString(),
          id: this.id.toString(),
          groupType: this.groupType,
        },
      });
    },

    onShowItem(id: number) {
      this.id = id;
      this.showListView = false;
      this.showLocalListView = false;
      this.$router.push({
        name: "host-people",
        query: {
          branchId: this.branchId.toString(),
          groupId: this.groupId.toString(),
          id: this.id.toString(),
          groupType: this.groupType,
        },
      });
    },

    onManageMemberCancel() {
      this.getItems(this.branchId, this.groupId, this.groupType);
    },
  },
});
</script>
