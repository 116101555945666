<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">6</span>GDPR</h6>
        <p class="small" style="padding-right: 40px">
          This section details the current GDPR settings that the member has
          agreed to.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-switch
              v-model="formData.gdprPermissionGranted"
              label="GDPR Permission Granted"
              hide-details="auto"
              @change="gdprPermissionChanged()"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="formData.allowContactByEmail"
              label="Allow contact by email"
              :disabled="!formData.gdprPermissionGranted"
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="formData.allowContactBySms"
              label="Allow contact by SMS text"
              :disabled="!formData.gdprPermissionGranted"
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="formData.allowContactByApp"
              label="Allow contact by the app"
              :disabled="!formData.gdprPermissionGranted"
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="formData.allowContactByPhone"
              label="Allow contact by phone"
              :disabled="!formData.gdprPermissionGranted"
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="formData.allowContactByPost"
              label="Allow contact by post"
              :disabled="!formData.gdprPermissionGranted"
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ManageUserViewModel } from "@/models/security.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "PeopleGDPRSection",
  mixins: [CommonMixin],
  props: {
    id: Number,
    ownerId: Number,
    canViewSensitiveData: Boolean,
    formData: {
      type: Object,
      default: () => new ManageUserViewModel(),
      required: true,
    },
  },
  methods: {
    gdprPermissionChanged() {
      if (this.formData.gdprPermissionGranted == false) {
        this.formData.allowContactByEmail = false;
        this.formData.allowContactByPhone = false;
        this.formData.allowContactByPost = false;
        this.formData.allowContactBySms = false;
        this.formData.allowContactByApp = false;
      }
    },
  },
});
</script>