<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <contributor-selector-dialog
      :dialog="showContributorDialog"
      :multiSelect="true"
      :selectedItems="viewModel.contributors"
      @dialog-closed-cancel="onContributorDialogCancelled"
      @dialog-option-selected="onContributorDialogConfirmed"
    >
    </contributor-selector-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-card>
      <v-card-title class="headline">
        <div>Manage Page Section</div>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="onCancel()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="onSave">
        <v-container fluid>
          <v-row>
            <v-col md="4">
              <v-text-field
                v-model="viewModel.name"
                label="Title"
                placeholder="Title"
                outlined
                clearable
                hide-details="auto"
                maxlength="80"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-select
                :items="viewModel.displayTypes"
                label="Display Type"
                outlined
                v-model="viewModel.displayType"
                return-object
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col md="4">
              <v-btn
                color="primary"
                @click.stop="onAddContributors()"
                :disabled="!valid"
              >
                Add Contributors
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-data-iterator
                :items="viewModel.contributors"
                :items-per-page.sync="itemsPerPage"
                :footer-props="footerOptions"
                :options.sync="options"
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.id"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-hover v-slot="{ hover }" open-delay="100">
                        <v-card
                          :elevation="!multiSelect && hover ? 12 : 3"
                          :outlined="!multiSelect && hover"
                          :class="{ 'on-hover': hover }"
                        >
                          <v-img
                            :src="item.imageUrl"
                            :lazy-src="placeholder"
                            :class="[!multiSelect ? imageCss : '']"
                            @click="!multiSelect"
                          >
                          </v-img>

                          <v-card-title>
                            <div>
                              {{ item.name }}
                            </div>
                          </v-card-title>
                          <v-card-subtitle class="pb-0">{{
                            item.subtitle
                          }}</v-card-subtitle>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click.stop="onRemove(item)"
                            >
                              Remove
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
          <v-btn color="primary" type="submit" :disabled="!isValid">
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ContributorSelectorDialog from "@/components/layout/contributor-selector-dialog.vue";
import { Contributor, ManageSectionViewModel } from "@/models/page.model";
import { DataOptions } from "vuetify";
import { LoadingType } from "@/models/common.model";
import { PageService } from "@/services/page.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "AddPageSectionDialog",
  mixins: [CommonMixin],
  components: {
    "contributor-selector-dialog": ContributorSelectorDialog,
  },
  data() {
    return {
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      viewModel: new ManageSectionViewModel(),
      valid: true,
      showContributorDialog: false,
      multiSelect: true,
    };
  },
  props: {
    dialog: Boolean,
    pageId: Number,
    sectionId: Number,
  },
  computed: {
    isValid(): boolean {
      return this.valid && this.viewModel.contributors.length > 0;
    },
  },
  watch: {
    dialog: {
      handler(val: boolean) {
        if (val === true) {
          this.onLaunch();
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.onGridOptionsChange();
      },
      deep: true,
    },
  },
  methods: {
    onLaunch() {
      this.onSearch();
    },

    onGridOptionsChange() {
      this.onSearch();
    },

    onCancel() {
      this.$emit("dialog-closed-cancel");
    },

    onSave() {
      this.$emit("dialog-closed-confirm", this.viewModel);
    },

    onSearch() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting section details, Please Wait..."
      );

      var service = new PageService();
      service
        .getSectionManager(this.pageId, this.sectionId)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onAddContributors() {
      this.showContributorDialog = true;
    },

    onContributorDialogCancelled() {
      this.showContributorDialog = false;
    },

    onContributorDialogConfirmed(items: Contributor[]) {
      for (let contributor of items) {
        let match = this.viewModel.contributors.find(
          (c) => c.id == contributor.id
        );
        if (!match) {
          this.viewModel.contributors.push(contributor);
        }
      }
      this.showContributorDialog = false;
    },

    onRemove(item: Contributor) {
      let index = this.viewModel.contributors.findIndex((f) => f.id == item.id);
      this.viewModel.contributors.splice(index, 1);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
