var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "manageForm",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "success" },
                      on: { click: _vm.onNewItem },
                    },
                    [_vm._v("New Contribution")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showEmptyView,
                          expression: "showEmptyView",
                        },
                      ],
                      attrs: { elevation: "0" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(
                              " It seems that you have not added a contribution. Create your first one by clicking the New Contribution button. "
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { md: "3" } },
                            [
                              _c("lottie-player", {
                                staticStyle: {
                                  width: "400px",
                                  height: "400px",
                                },
                                attrs: {
                                  src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                                  background: "transparent",
                                  speed: "1",
                                  loop: "",
                                  autoplay: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-iterator", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showGrid,
                        expression: "showGrid",
                      },
                    ],
                    attrs: {
                      "item-key": "id",
                      items: _vm.items,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerOptions,
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "v-row",
                              _vm._l(props.items, function (item) {
                                return _c(
                                  "v-col",
                                  { key: item.id, attrs: { md: "auto" } },
                                  [
                                    _c("v-hover", {
                                      attrs: { "open-delay": "100" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var hover = ref.hover
                                              return [
                                                _c(
                                                  "v-card",
                                                  {
                                                    class: {
                                                      "on-hover": hover,
                                                    },
                                                    attrs: {
                                                      "max-width": "400",
                                                      elevation:
                                                        !_vm.multiSelect &&
                                                        hover
                                                          ? 12
                                                          : 3,
                                                      outlined:
                                                        !_vm.multiSelect &&
                                                        hover,
                                                    },
                                                  },
                                                  [
                                                    _c("v-system-bar", {
                                                      attrs: {
                                                        color:
                                                          _vm.getBarColor(item),
                                                      },
                                                    }),
                                                    _c("v-img", {
                                                      class: [
                                                        !_vm.multiSelect
                                                          ? _vm.imageCss
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        "max-height": "240",
                                                        src: item.imageUrl,
                                                        "lazy-src":
                                                          _vm.placeholder,
                                                        "aspect-ratio": "1",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          !_vm.multiSelect
                                                        },
                                                      },
                                                    }),
                                                    _c("v-card-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.limitText(
                                                              item.name,
                                                              27
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c(
                                                      "v-card-subtitle",
                                                      { staticClass: "pb-0" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.subtitle)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card-actions",
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              text: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onEditItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Edit ")]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              text: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onRemoveItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Remove ")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-contributor-dialog", {
        attrs: {
          dialog: _vm.showContributorDialog,
          id: _vm.manageContributorId,
        },
        on: {
          "dialog-closed-cancel": _vm.onContributorDialogCancel,
          "dialog-closed-confirm": _vm.onContributorDialogConfirm,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }