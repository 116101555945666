<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Location</h6>
        <p class="small" style="padding-right: 40px">
          Help people in the area discover your event and let attendees know
          where to show up.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-btn-toggle
              v-model="locationOption"
              color="primary"
              @change="onLocationOptionChange"
            >
              <v-btn>Venue</v-btn>
              <v-btn>Online</v-btn>
              <v-btn>To be announced</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="showNoLocationsCreated">
          <v-col md="auto">
            <v-card elevation="0">
              <v-row>
                <v-col md="4">
                  It seems that you do not have any physical locations for this
                  event. Add one or more by clicking the Add button.
                </v-col>
                <v-col md="3">
                  <lottie-player
                    src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                    background="transparent"
                    speed="1"
                    style="width: 400px; height: 400px"
                    loop
                    autoplay
                  ></lottie-player>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="addNewLocation">Add</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="showNoOnlineLocationsCreated">
          <v-col md="auto">
            <v-card elevation="0">
              <v-row>
                <v-col md="2">
                  It seems that you do not specified any online details for this
                  event. Add one or more by clicking the Add button.
                </v-col>
                <v-col md="3">
                  <lottie-player
                    src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                    background="transparent"
                    speed="1"
                    style="width: 400px; height: 400px"
                    loop
                    autoplay
                  ></lottie-player>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="addNewOnlineLocation"
                    >Add</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-for="location of viewModel.locations" :key="location.localId">
          <v-col v-if="showVenues">
            <event-location-card
              :location="location"
              @on-new-location="addNewLocation"
              @on-edit-location="editLocation"
              @on-remove-location="removeLocation"
            ></event-location-card>
          </v-col>
        </v-row>
        <v-row
          v-for="location of viewModel.onlineLocations"
          :key="location.localId"
        >
          <v-col v-if="showOnlineVenues">
            <event-location-online-card
              :location="location"
              @on-new-location="addNewOnlineLocation"
              @on-edit-location="editOnlineLocation"
              @on-remove-location="removeOnlineLocation"
            >
            </event-location-online-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showRemoveLocationsDialog"
      :data="dialogMessage"
      @dialog-option-selected="onRemoveLocationsDialogOptionSelected"
    ></confirmation-dialog>
    <event-location-online-editor
      :dialog="showOnlineLocationEditor"
      :entity="selectedOnlineLocation"
      :mainEvent="viewModel"
      @on-dialog-save="onOnlineLocationEditorSave"
      @on-dialog-cancel="onOnlineLocationEditorCancel"
    ></event-location-online-editor>
    <event-location-editor
      :dialog="showLocationEditor"
      :entity="selectedLocation"
      :mainEvent="viewModel"
      @on-dialog-save="onLocationEditorSave"
      @on-dialog-cancel="onLocationEditorCancel"
    >
    </event-location-editor>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import EventDialInDetails from "@/components/events/event-location-online-editor.vue";
import {
  EventLocation,
  EventOnlineLocation,
  EventViewModel,
} from "@/models/events.model";
import DateTimePicker from "@/components/common/date-time-picker.vue";
import EventLocationCard from "@/components/events/event-location-card.vue";
import EventLocationOnlineCard from "@/components/events/event-location-online-card.vue";
import EventLocationEditor from "@/components/events/event-location-editor.vue";
import EventLocationOnlineEditor from "@/components/events/event-location-online-editor.vue";
import { DialogMessage, DialogResponse } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventsLocationsSection",
  mixins: [CommonMixin],

  components: {
    "date-time-picker": DateTimePicker,
    "event-location-card": EventLocationCard,
    "event-location-editor": EventLocationEditor,
    "event-location-online-card": EventLocationOnlineCard,
    "event-location-online-editor": EventLocationOnlineEditor,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },

  computed: {
    showVenues(): boolean {
      return this.locationOption == 0;
    },
    showOnlineVenues(): boolean {
      return this.locationOption == 1;
    },
  },

  watch: {
    formData: {
      handler(val: EventViewModel) {
        this.viewModel = val;
        this.setLocationIndicators();
      },
    },
  },

  data() {
    return {
      viewModel: new EventViewModel(),
      locationOption: 2,
      selectedLocation: new EventLocation(),
      selectedOnlineLocation: new EventOnlineLocation(),
      showOnlineLocationEditor: false,
      showLocationEditor: false,
      showRemoveLocationsDialog: false,
      showNoLocationsCreated: false,
      showNoOnlineLocationsCreated: false,
    };
  },

  methods: {
    addNewLocation() {
      if (this.viewModel.locations.length < 3) {
        let location = new EventLocation();

        location.title = "Main Location";
        location.startDateTime = this.viewModel.startDateTime;
        location.endDateTime = this.viewModel.endDateTime;

        this.viewModel.locations.push(location);
        this.showNoLocationsCreated = false;
      }
    },

    addNewOnlineLocation() {
      if (this.viewModel.onlineLocations.length < 3) {
        let location = new EventOnlineLocation();

        location.startDateTime = this.viewModel.startDateTime;
        location.endDateTime = this.viewModel.endDateTime;

        this.viewModel.onlineLocations.push(location);
        this.showNoOnlineLocationsCreated = false;
      }
    },

    editLocation(location: EventLocation) {
      this.selectedLocation = new EventLocation();
      this.selectedLocation.id = location.id;
      this.selectedLocation.isPrimaryLocation = location.isPrimaryLocation;
      this.selectedLocation.category = location.category;
      this.selectedLocation.title = location.title;
      this.selectedLocation.houseNameOrNumber = location.houseNameOrNumber;
      this.selectedLocation.street = location.street;
      this.selectedLocation.city = location.city;
      this.selectedLocation.region = location.region;
      this.selectedLocation.country = location.country;
      this.selectedLocation.postCode = location.postCode;
      this.selectedLocation.url = location.url;
      this.selectedLocation.longitude = location.longitude;
      this.selectedLocation.latitude = location.latitude;
      this.selectedLocation.startDateTime = location.startDateTime;
      this.selectedLocation.endDateTime = location.endDateTime;
      this.selectedLocation.address = location.address;
      this.selectedLocation.localId = location.localId;
      this.selectedLocation.isOnline = location.isOnline;
      this.selectedLocation.platform = location.platform;
      this.selectedLocation.linkUrl = location.linkUrl;
      this.selectedLocation.dialInNumber = location.dialInNumber;
      this.selectedLocation.dialInCode = location.dialInCode;
      this.selectedLocation.meetingCode = location.meetingCode;
      this.selectedLocation.meetingId = location.meetingId;
      this.showLocationEditor = true;
    },

    editOnlineLocation(location: EventOnlineLocation) {
      this.selectedOnlineLocation = new EventOnlineLocation();
      this.selectedOnlineLocation.id = location.id;
      this.selectedOnlineLocation.platform = location.platform;
      this.selectedOnlineLocation.linkUrl = location.linkUrl;
      this.selectedOnlineLocation.dialInNumber = location.dialInNumber;
      this.selectedOnlineLocation.dialInCode = location.dialInCode;
      this.selectedOnlineLocation.meetingCode = location.meetingCode;
      this.selectedOnlineLocation.meetingId = location.meetingId;
      this.selectedOnlineLocation.startDateTime = location.startDateTime;
      this.selectedOnlineLocation.endDateTime = location.endDateTime;
      this.selectedOnlineLocation.localId = location.localId;
      this.showOnlineLocationEditor = true;
    },

    onLocationEditorSave(location: EventLocation) {
      let match = this.viewModel.locations.find(
        (f) => f.localId == location.localId
      );
      if (match) {
        match.id = location.id;
        match.isPrimaryLocation = location.isPrimaryLocation;
        match.category = location.category;
        match.title = location.title;
        match.houseNameOrNumber = location.houseNameOrNumber;
        match.street = location.street;
        match.city = location.city;
        match.region = location.region;
        match.country = location.country;
        match.postCode = location.postCode;
        match.url = location.url;
        match.longitude = location.longitude;
        match.latitude = location.latitude;
        match.startDateTime = location.startDateTime;
        match.endDateTime = location.endDateTime;
        match.address = location.address;
        match.localId = location.localId;
        match.isOnline = location.isOnline;
        match.platform = location.platform;
        match.linkUrl = location.linkUrl;
        match.dialInNumber = location.dialInNumber;
        match.dialInCode = location.dialInCode;
        match.meetingCode = location.meetingCode;
        match.meetingId = location.meetingId;
      }
      this.showLocationEditor = false;
    },

    onOnlineLocationEditorSave(location: EventOnlineLocation) {
      let match = this.viewModel.onlineLocations.find(
        (f) => f.localId == location.localId
      );
      if (match) {
        match.id = location.id;
        match.platform = location.platform;
        match.linkUrl = location.linkUrl;
        match.dialInNumber = location.dialInNumber;
        match.dialInCode = location.dialInCode;
        match.meetingCode = location.meetingCode;
        match.meetingId = location.meetingId;
        match.startDateTime = location.startDateTime;
        match.endDateTime = location.endDateTime;
        match.localId = location.localId;
      }
      this.showOnlineLocationEditor = false;
    },

    onLocationEditorCancel() {
      this.showLocationEditor = false;
    },

    onOnlineLocationEditorCancel() {
      this.showOnlineLocationEditor = false;
    },

    removeLocation(loc: EventLocation) {
      const index = this.viewModel.locations.indexOf(loc);
      this.viewModel.locations.splice(index, 1);

      if (this.viewModel.locations.length === 0) {
        this.viewModel.isHeldInPhysicalLocation = false;
        this.setLocationIndicators();
      }
    },

    removeOnlineLocation(loc: EventOnlineLocation) {
      const index = this.viewModel.onlineLocations.indexOf(loc);
      this.viewModel.onlineLocations.splice(index, 1);

      if (this.viewModel.onlineLocations.length === 0) {
        this.viewModel.isHeldOnline = false;
        this.setLocationIndicators();
      }
    },

    setLocationIndicators() {
      if (this.viewModel.isHeldInPhysicalLocation) {
        this.locationOption = 0;
      } else if (this.viewModel.isHeldOnline) {
        this.locationOption = 1;
      } else {
        this.locationOption = 2;
      }
    },

    onLocationOptionChange(option: number) {
      // If more than one location option has been selected then
      // make sure that it cannot be a combination of 'to be announced'
      // and another option. If TBA is the 1st item in the list then
      // the other option should remain and it removed, otherwise visa-versa
      let isToBeAnnounced: boolean = option == 2;

      // If the option to add a physical location is selected and we do not have
      // any locations at present then show the empty dialog to allow a user to create one.
      this.showNoLocationsCreated =
        option == 0 && this.viewModel.locations.length === 0;

      // If the option to add a online location is selected and we do not have
      // any locations at present then show the empty dialog to allow a user to create one.
      this.showNoOnlineLocationsCreated =
        option == 1 && this.viewModel.onlineLocations.length === 0;

      // If the user has selected the to be announced button then remove
      // all locations and online locations that have not been saved
      if (
        isToBeAnnounced &&
        (this.viewModel.locations.length !== 0 ||
          this.viewModel.onlineLocations.length !== 0)
      ) {
        this.dialogMessage = new DialogMessage(
          `Remove existing locations?`,
          "Are you sure you want to reset the event locations to TO BE ANNOUNCED? Resetting back to this state will remove any SAVED AND DRAFT online or physical locations you have set up WHEN YOU PRESS THE SAVE BUTTON."
        );
        this.dialogMessage.entity = option;
        this.dialogMessage.showCancel = true;
        this.dialogMessage.errors = [];
        this.showRemoveLocationsDialog = true;
      }
    },

    onRemoveLocationsDialogOptionSelected(val: DialogResponse) {
      if (val.option == true) {
        this.viewModel.locations = [];
        this.viewModel.onlineLocations = [];
        this.viewModel.isHeldInPhysicalLocation = false;
        this.viewModel.isHeldOnline = false;
      } else {
        this.locationOption = 0;
        this.showNoLocationsCreated = this.viewModel.locations.length === 0;
      }
      this.showRemoveLocationsDialog = false;
    },
  },
});
</script>
