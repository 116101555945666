var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            dark: "",
            color: "primary",
            "clipped-left": "",
            "clipped-right": "",
            elevation: "2",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onNavButtonClick()
              },
            },
          }),
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _c("v-spacer"),
          _vm.user.authenticated ? _c("user-profile") : _vm._e(),
          _c("div", { staticStyle: { width: "10px" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }