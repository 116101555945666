<template>
  <v-autocomplete
    :items="allItems"
    v-model="selectedItems"
    :label="label"
    :placeholder="placeholder"
    multiple
    clearable
    auto-select-first
    item-text="name"
    item-value="id"
    :return-object="true"
    :rules="validateRecipients"
    :search-input.sync="searchInput"
    @change="onChange"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown="selectallItemsToggle">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        <span>{{ item.name }} </span>
      </div>
      <div style="width: 4px"></div>
      <span v-if="index === 1" class="grey--text text-caption">
        (+{{ selectedItems.length - 1 }} others)
      </span>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import CommonMixin from "@/mixins/common.mixin";
import Vue, { VueConstructor } from "vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ExtendedAutoComplete",
  props: {
    items: Array,
    selected: Array,
    input: String,
    label: {
      type: String,
      default: "All Items",
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  watch: {
    items(val) {
      this.allItems = val;
    },
    selected(val) {
      this.selectedItems = val;
    },
    input(val) {
      this.searchInput = val;
    },
  },
  data() {
    return {
      allItems: Array(),
      selectedItems: Array(),
      searchInput: "",
    };
  },
  computed: {
    allSelected(): boolean {
      return this.selectedItems.length === this.allItems.length;
    },
    someSelected(): boolean {
      return this.selectedItems.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    validateRecipients(): string[] {
      let err: string[] = [];
      if (this.selectedItems.length === 0) {
        err.push("You must select at least one recipient of the message");
      }
      return err;
    },
  },
  methods: {
    selectallItemsToggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.allItems.slice();
        }
        this.onChange();
      });
    },
    onChange() {
      this.$emit("update:selected", this.selectedItems);
    },
  },
});
</script>