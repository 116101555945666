<template>
  <v-container id="main-container" fluid>
    <v-row>
      <div class="col-md-12 mt-5">
        <v-row>
          <v-col lg="4">
            <section class="page-header">
              <h5 class="wg-title">Communication Manager</h5>
              <p class="small" style="margin-bottom: 0">
                Manage your conference-wide communication here
              </p>
            </section>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="auto">
            <action-alert
              :show="showAlert"
              :message="alertMessage"
              :type="alertType"
            ></action-alert>
          </v-col>
        </v-row>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-card class="wg-card-shadow">
          <div class="row">
            <div class="col-md-2 no-padding">
              <div class="inner_sidebar">
                <v-list nav>
                  <v-list-item-group
                    active-class="deep-indigo--text text--accent-4"
                    v-model="selectedMenuItem"
                  >
                    <v-list-item
                      class="list-item"
                      v-for="item in items"
                      :key="item.id"
                      @click.stop="onMenuItemClick(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>

            <div class="col-md-10 content-area">
              <panel-demographics-manager
                v-show="selectedMenuItem == 0"
                ref="demographicsmanager"
                @show-alert-panel="showSuccessPanel"
              >
              </panel-demographics-manager>
            </div>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import PanelDemographicsManager from "@/views/list-managers/list-manager-demographics-queries.vue";
import ActionAlert from "@/components/common/action-alert.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostCommunicationManager",
  mixins: [CommonMixin],
  components: {
    PanelDemographicsManager,
    ActionAlert,
  },

  data() {
    return {
      selectedMenuItem: 0,
      items: [{ id: 1, title: "Queries", icon: "mdi-church", name: "queries" }],
    };
  },

  methods: {
    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },

    onMenuItemClick(item: any) {
      switch (item.name) {
        case "queries":
          this.refreshDemographicsView();
          break;
      }
    },

    refreshDemographicsView() {
      const ref: any = this.$refs.demographicsmanager;
      if (ref) {
        ref.getItems();
      }
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>