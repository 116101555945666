var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "4" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.hasSelectedItems,
                                  },
                                  on: { click: _vm.onDeleteSelected },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-delete-sweep-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Remove Selected")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.hasSelectedDeletedItems,
                                  },
                                  on: { click: _vm.onReinstateSelected },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-delete-restore")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Restore Selected")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(" Registered Users "),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { md: "2" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.groups,
                                  placeholder: "Groups",
                                  label: "Groups",
                                  "hide-details": "auto",
                                },
                                on: { change: _vm.onSearch },
                                model: {
                                  value: _vm.selectedGroup,
                                  callback: function ($$v) {
                                    _vm.selectedGroup = $$v
                                  },
                                  expression: "selectedGroup",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-subtitle",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  label: "Show Deleted Only",
                                  "hide-details": "",
                                },
                                on: { change: _vm.onSearch },
                                model: {
                                  value: _vm.showDeletedOnly,
                                  callback: function ($$v) {
                                    _vm.showDeletedOnly = $$v
                                  },
                                  expression: "showDeletedOnly",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      "item-key": "id",
                      "multi-sort": "",
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "single-select": false,
                      "show-select": _vm.showMultiSelect,
                      "sort-by": [],
                      "sort-desc": [],
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerOptions,
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.imageUrl",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("div", { staticClass: "container" }, [
                                _c("img", { attrs: { src: item.imageUrl } }),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.email",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.getMailLink(item.email) },
                                },
                                [_vm._v(_vm._s(item.email))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.primaryContactNumber",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getPhoneLink(
                                      item.primaryContactNumber
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.contactNumber))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.isMember",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("v-simple-checkbox", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.isMember,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isMember", $$v)
                                  },
                                  expression: "item.isMember",
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item.isDeleted",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("v-simple-checkbox", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.isDeleted,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isDeleted", $$v)
                                  },
                                  expression: "item.isDeleted",
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-delete "),
                                          ]),
                                        ],
                                        1
                                      ),
                                      item.isDeleted
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                icon: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onRestoreItem(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-delete-restore "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedItems,
                      callback: function ($$v) {
                        _vm.selectedItems = $$v
                      },
                      expression: "selectedItems",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteAllDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteAllOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showReinstateDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onReinstateOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showReinstateAllDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onReinstateAllOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }