<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <section class="page-header">
          <v-row>
            <v-col md="auto">
              <v-select
                filled
                outlined
                :items="viewModel.groups"
                item-text="name"
                item-value="id"
                label="Branches"
                v-model="selectedBranchId"
                no-data-text="No branches available"
                @change="onSelectedBranch()"
                hide-details
              ></v-select>
            </v-col>
            <v-col md="auto">
              <v-select
                filled
                outlined
                :items="groups"
                item-text="name"
                item-value="id"
                label="Groups"
                no-data-text="No groups available"
                v-model="selectedGroupId"
                @change="onSelectedGroup()"
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!canManageGroups"
                    class="ma-2"
                    icon
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="onNewGroup"
                    ><v-icon>mdi-account-multiple-plus</v-icon></v-btn
                  >
                </template>
                <span>New Group</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!canRemoveGroup"
                    class="ma-2"
                    icon
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="onRemoveGroup"
                    ><v-icon>mdi-account-multiple-remove</v-icon></v-btn
                  >
                </template>
                <span>Remove Group</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    icon
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refreshScreen"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                  >
                </template>
                <span>Refresh</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    icon
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    href="https://tnlascog7dprod.blob.core.windows.net/opendatablobstorage/Branches-General-Tab.mp4"
                    ><v-icon>mdi-lightbulb-question-outline</v-icon></v-btn
                  >
                </template>
                <span>Click here for a short help video</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>

    <v-row v-if="!hasGroups">
      <v-col>
        <v-card elevation="0">
          <v-row>
            <v-col md="4">
              It seems that no branches have been set up yet or you do not have
              access. Please contact your system administrator and ask them to
              set up your branch.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-show="hasGroups">
      <v-col>
        <v-tabs v-model="tab" @change="onTabClick">
          <v-tab :disabled="!allowGeneralTab">General</v-tab>
          <v-tab>People</v-tab>
          <v-tab>Positions</v-tab>
          <v-tab>Events</v-tab>
          <v-tab>Messaging</v-tab>
          <v-tab>Returns</v-tab>
          <v-tab>Media</v-tab>
          <v-tab>Polls</v-tab>          
          <v-tab disabled>Reports</v-tab>
          <v-tab disabled>Security</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="hasGroups">
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item transition="false" eager>
            <list-manager-groups-branch
              v-show="allowGeneralTab"
              ref="ListManagerGroupsBranch"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-groups-branch>
            <no-permission-card v-show="!allowGeneralTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-people
              v-show="allowPeopleTab"
              ref="ListManagerPeople"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-people>
            <no-permission-card v-show="!allowPeopleTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-positions
              v-show="allowPositionsTab"
              ref="ListManagerPositions"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-positions>
            <no-permission-card
              v-show="!allowPositionsTab"
            ></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-events
              v-show="allowEventsTab"
              ref="ListManagerEvents"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-events>
            <no-permission-card v-show="!allowEventsTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-demographics-queries
              v-show="allowMessagingTab"
              ref="ListManagerDemographicsQueries"
            >
            </list-manager-demographics-queries>
            <no-permission-card
              v-show="!allowMessagingTab"
            ></no-permission-card>
          </v-tab-item>          
          <v-tab-item transition="false" eager>
            <list-manager-quarterly-return
              v-show="allowQuarterlyReturnTab"
              ref="ListManagerQuarterlyReturn"
            >
            </list-manager-quarterly-return>
            <no-permission-card v-show="!allowQuarterlyReturnTab"></no-permission-card>
          </v-tab-item>                           
          <v-tab-item transition="false" eager>
            <list-manager-groups-media
              v-show="allowMediaTab"
              ref="ListMediaGroupsMedia"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-groups-media>
            <no-permission-card v-show="!allowMediaTab"></no-permission-card>
          </v-tab-item>           
          <v-tab-item transition="false" eager>
            <list-manager-polls
              v-show="allowPollsTab"
              ref="ListManagerPolls"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-polls>
            <no-permission-card v-show="!allowPollsTab"></no-permission-card>
          </v-tab-item>  
          <v-tab-item transition="false" eager>
            <h2 v-show="allowReportsTab">Reports</h2>
            <no-permission-card v-show="!allowReportsTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <h2 v-show="allowSecurityTab">Security</h2>
            <no-permission-card v-show="!allowSecurityTab"></no-permission-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <new-group-dialog
      :dialog="showNewGroupDialog"
      :branchId="selectedBranchId"
      @new-group-dialog-actioned="onNewGroupDialogAction"
    >
    </new-group-dialog>
    <confirmation-dialog
      :dialog="showDeleteGroupDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteGroupSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import NewGroupDialog from "@/components/groups/new-group-dialog.vue";

import ListManageGroupsBranch from "@/views/list-managers/list-manager-groups-branch.vue";
import ListManagerPeople from "@/views/list-managers/list-manager-people.vue";
import ListManagerPositions from "@/views/list-managers/list-manager-positions.vue";
import ListManagerPolls from "@/views/list-managers/list-manager-polls.vue";
import ListManagerEvents from "@/views/list-managers/list-manager-events.vue";
import ListManagerMedia from "@/views/list-managers/list-manager-groups-media.vue";
import ListManagerDemographicsQueries from "@/views/list-managers/list-manager-demographics-queries.vue";
import ListManagerQuarterlyReturn from "@/views/list-managers/list-manager-quarterly-return.vue";

import NoPermissionCard from "@/components/common/no-permission-card.vue";
import {
  BaseListItem,
  DialogMessage,
  DialogResponse,
} from "@/models/common.model";
import { GroupType, LoadingType } from "@/models/common.model";
import {
  BranchSelectionItem,
  BranchSelectionViewModel,
} from "@/models/group.model";
import {
  PermissionArea,
  PermissionsManager,
  PermissionType,
} from "@/models/security.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostGroupManager",
  mixins: [CommonMixin],
  components: {
    ActionAlert,
    "new-group-dialog": NewGroupDialog,
    "list-manager-groups-branch": ListManageGroupsBranch,
    "list-manager-people": ListManagerPeople,
    "list-manager-positions": ListManagerPositions,
    "list-manager-polls": ListManagerPolls,
    "list-manager-events": ListManagerEvents,
    "list-manager-groups-media": ListManagerMedia,
    "list-manager-demographics-queries": ListManagerDemographicsQueries,
    "list-manager-quarterly-return": ListManagerQuarterlyReturn,
    "no-permission-card": NoPermissionCard,
  },
  computed: {
    hasGroups(): boolean {
      return this.viewModel.groups.length > 0;
    },
    canRemoveGroup(): boolean {
      return this.canManageGroups == true && this.selectedGroupId > 0;
    },
  },
  data() {
    return {
      tab: 0,
      selectedBranchId: 0,
      selectedGroupId: 0,
      selectedBranch: {} as BranchSelectionItem | undefined,
      selectedGroup: {} as BaseListItem | undefined,
      viewModel: new BranchSelectionViewModel(),
      groups: new Array<BaseListItem>(),
      showMainView: false,
      showNewGroupDialog: false,
      showDeleteGroupDialog: false,
      canManageGroups: false,
      allowGeneralTab: false,
      allowPeopleTab: false,
      allowPositionsTab: false,
      allowEventsTab: false,
      allowPollsTab: false,
      allowMediaTab: false,
      allowReportsTab: false,
      allowMessagingTab: false,
      allowSecurityTab: false,
      allowQuarterlyReturnTab: false
    };
  },
  created() {
    if (
      this.$route.query.branchId &&
      this.$route.query.groupId &&
      this.$route.query.groupType &&
      this.$route.query.tab
    ) {
      let branchId = parseInt(this.$route.query.branchId.toString());
      let groupId = parseInt(this.$route.query.groupId.toString());
      let tab = this.$route.query.tab.toString();

      let tabIndex = this.getTabIndex(tab);

      this.getBranchSelections(branchId, groupId, tabIndex);
    } else {
      this.getBranchSelections(0, 0, 0);
    }
  },
  methods: {
    refreshScreen() {
      this.onTabClick(this.tab);
    },

    getTabIndex(name: string): number {
      let index = 0;

      switch (name) {
        case "general":
          index = 0;
          break;
        case "people":
          index = 1;
          break;
        case "positions":
          index = 2;
          break;
        case "events":
          index = 3;
          break;          
        case "messaging":
          index = 4;
          break;
        case "quarterly-return":
          index = 5;
          break;                             
        case "media":
          index = 6;
          break;
        case "polls":
          index = 7;
          break;            
        case "reports":
          index = 8;
          break;
        case "security":
          index = 9;
          break;         
      }

      return index;
    },

    getBranchSelections(branchId: number, groupId: number, tabIndex: number) {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Information, Please Wait..."
      );

      this.selectedBranchId = branchId;
      this.selectedGroupId = groupId;

      const service = new GroupService();
      service
        .getGroupsForSelection(GroupType.Branch)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;

          if (this.viewModel.groups.length > 0) {
            // Add a blank item so that the user can select just a branch
            this.viewModel.groups.forEach((element) => {
              element.groups.unshift(new BaseListItem());
            });

            // If we have come into this screen for the 1st time then
            // ensure that the 1st branch is considered the selected branch
            if (this.selectedBranchId == 0 && this.selectedGroupId == 0) {
              this.selectedBranchId = this.viewModel.groups[0].id;
            }

            // Get the selected branch so that we can get hold of it's groups
            let match = this.viewModel.groups.find(
              (b) => b.id == this.selectedBranchId
            );
            if (match) {
              this.selectedBranch = match;
            }

            // If we have come into this screen for the 1st then ensure that the
            // selected group Id is the 1st one if the branch has groups.
            // Coming into this screen from a sub-screen may have this already set
            if (this.selectedBranch && this.selectedBranch.groups.length > 0) {
              if (this.selectedGroupId == 0) {
                this.selectedGroup = this.selectedBranch.groups[0];
                this.selectedGroupId = this.selectedBranch.groups[0].id;
              } else {
                this.selectedGroup = this.selectedBranch.groups.find(
                  (g) => g.id == this.selectedGroupId
                );
              }
              this.groups = this.selectedBranch.groups;
            }
          }

          this.tab = tabIndex;
          this.setTabPermissions();
          this.onBranchOrGroupChange();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onSelectedBranch() {
      const branch = this.viewModel.groups.find(
        (f) => f.id === this.selectedBranchId
      );
      if (branch) {
        this.selectedBranch = branch;
      }
      this.groups = branch ? branch.groups : [];
      if (this.groups) {
        this.selectedGroupId = this.groups[0].id;
      }
      this.setTabPermissions();
      this.onBranchOrGroupChange();
    },

    onSelectedGroup() {
      if (this.selectedBranch) {
        const group = this.selectedBranch.groups.find(
          (g) => g.id == this.selectedGroupId
        );
        if (group) {
          this.selectedGroup = group;
        }
      }
      this.setTabPermissions();
      this.onBranchOrGroupChange();
    },

    onBranchOrGroupChange() {
      if (this.selectedBranch) {
        let name = `Branches: ${this.selectedBranch.name}`;
        if (this.selectedGroup && this.selectedGroup.name.length > 0) {
          name = `${name} ${this.selectedGroup.name}`;
        }
        this.updateNavTitle(name);
      }
      this.onTabClick(this.tab);
    },

    onNewGroup() {
      this.showNewGroupDialog = true;
    },

    onNewGroupDialogAction(group: BaseListItem) {
      this.showNewGroupDialog = false;
      if (group) {
        const branch = this.viewModel.groups.find(
          (f) => f.id === this.selectedBranchId
        );
        if (branch) {
          branch.groups.push(group);
          this.selectedGroupId = group.id;
          this.showAlertPanel("The group was added to the branch successfully");
          this.onBranchOrGroupChange();
        }
      }
    },

    setTabPermissions() {
      const isSuperAdmin: boolean = PermissionsManager.isSuperAdministrator();
      const groupType =
        this.selectedGroupId !== 0 ? GroupType.LocalMinistry : GroupType.Branch;
      this.canManageGroups = PermissionsManager.hasPermission(
        this.selectedBranchId,
        this.selectedGroupId,
        groupType,
        PermissionType.ManageGroups,
        PermissionArea.None
      );

      if (isSuperAdmin) {
        this.allowGeneralTab = true;
        this.allowPeopleTab = true;
        this.allowPositionsTab = true;
        this.allowEventsTab = true;
        this.allowPollsTab = true;
        this.allowMediaTab = true;
        this.allowMessagingTab = true;
        this.allowReportsTab = true;
        this.allowSecurityTab = true;
        this.allowQuarterlyReturnTab = true;
      } else {
        this.allowGeneralTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Overview
        );
        this.allowPeopleTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Membership
        );
        this.allowPositionsTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Positions
        );
        this.allowEventsTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Events
        );
        this.allowPollsTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Polls
        );
        this.allowMediaTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Media
        );
        this.allowMessagingTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Messaging
        );
        this.allowReportsTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Reports
        );
        this.allowSecurityTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.Security
        );
        this.allowQuarterlyReturnTab = PermissionsManager.hasPermission(
          this.selectedBranchId,
          this.selectedGroupId,
          groupType,
          PermissionType.List,
          PermissionArea.QuarterlyReturn
        );        
      }
    },

    onTabClick(index: any) {
      // Make sure there are no temporary event info dialogs about
      if (index !== 3) {
        this.removeTemporaryEvents();
      }

      switch (index) {
        case 0:
          this.showBranchMainView();
          break;
        case 1:
          this.showGroupMembershipManager();
          break;
        case 2:
          this.showGroupPositionManager();
          break;
        case 3:
          this.showGroupEventsManager();
          break;           
        case 4:
          this.showMessagingManager();
          break;
        case 5:
          this.showQuarterlyReturnManager();
          break;                
        case 6:
          this.showGroupContributionManager();
          break;               
        case 7:
          this.showGroupPollsManager();
          break;          
        case 8:
          this.showGroupReports();
          break;
        case 9:
          this.showSecurity();
          break;        
      }
    },

    removeTemporaryEvents() {
      if (this.allowEventsTab) {
        const ref: any = this.$refs.ListManagerEvents;
        if (ref) {
          ref.removeTemporaryEvents();
        }
      }
    },

    showBranchMainView() {
      if (this.allowGeneralTab) {
        const ref: any = this.$refs.ListManagerGroupsBranch;
        if (ref) {
          ref.load(this.selectedBranchId, this.selectedGroupId);
        }
      }
    },

    showGroupMembershipManager() {
      if (this.allowPeopleTab) {
        const ref: any = this.$refs.ListManagerPeople;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showGroupPositionManager() {
      if (this.allowPositionsTab) {
        const ref: any = this.$refs.ListManagerPositions;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showGroupEventsManager() {
      if (this.allowEventsTab) {
        const ref: any = this.$refs.ListManagerEvents;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showGroupPollsManager() {
      if (this.allowPollsTab) {
        const ref: any = this.$refs.ListManagerPolls;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showGroupContributionManager() {
      if (this.allowMediaTab) {
        const ref: any = this.$refs.ListMediaGroupsMedia;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showMessagingManager() {
      if (this.allowMessagingTab) {
        const ref: any = this.$refs.ListManagerDemographicsQueries;
        if (ref) {
          ref.getItems();
        }
      }
    },

    showGroupReports() {},

    showSecurity() {},

    showQuarterlyReturnManager() {
      if (this.allowQuarterlyReturnTab) {
        const ref: any = this.$refs.ListManagerQuarterlyReturn;
        if (ref) {
          const groupType =
            this.selectedGroupId !== 0
              ? GroupType.LocalMinistry
              : GroupType.Branch;
          ref.getItems(this.selectedBranchId, this.selectedGroupId, groupType);
        }
      }
    },

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },

    onRemoveGroup() {
      this.showDeleteConfirmationDialog(this.selectedGroup);
    },

    showDeleteConfirmationDialog(item: BaseListItem | undefined) {
      if (item) {
        this.dialogMessage = new DialogMessage(
          `Remove ${item.name} group?`,
          `WARNING! Are you sure you want to remove this group? REMOVING A GROUP WILL REMOVE ALL RELATED INFORMATION FOR THAT GROUP. THIS IS IRREVERSIBLE!!!.`
        );
        this.dialogMessage.showCancel = true;
        this.dialogMessage.entity = item;
        this.dialogMessage.errors = [];
        this.showDeleteGroupDialog = true;
      }
    },

    onDeleteGroupSelected(item: DialogResponse) {
      this.showDeleteGroupDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          `Removing ${item.entity.name} group, Please Wait...`
        );
        const service = new GroupService();
        service
          .removeGroup(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            this.getBranchSelections(this.selectedBranchId, 0, 0);
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>