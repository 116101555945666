<template>
  <v-container fluid>
    <v-dialog v-model="showQueryManagerDialog" persistent max-width="70%">
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card outlined elevation="1">
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            {{ formData.name }}
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onDialogCloseClick()"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col lg="9">
                <v-textarea
                  v-model="formData.description"
                  readonly
                  placeholder="Summary"
                  label="Summary"
                  auto-grow
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-row>
                <v-col lg="12">
                  <v-stepper
                    vertical
                    non-linear
                    :tile="false"
                    :outlined="false"
                    elevation="0"
                    v-model="stepNo"
                  >
                    <v-stepper-step step="1" :complete="stepNo > 1">
                      Pick a query type
                      <small>What kind of query is this?</small>
                      <v-stepper-content step="1">
                        <div style="height: 10px"></div>
                        <v-row>
                          <v-col>
                            <v-select
                              :items="formData.queryTypes"
                              v-model="formData.selectedQueryType"
                              placeholder="Show me"
                              label="Show me"
                              :rules="queryTypeRules"
                              hide-details="auto"
                              @change="onQueryTypeChange()"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              :items="formData.subjects"
                              v-model="formData.selectedSubject"
                              placeholder="Subject"
                              label="Subject"
                              :rules="[rules.required]"
                              hide-details="auto"
                            >
                            </v-select>
                          </v-col>
                          <v-col v-if="isRatioQueryType">
                            <v-select
                              :items="formData.ratioTypes"
                              v-model="formData.selectedRatioType"
                              placeholder="By"
                              label="By"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col v-if="isSpreadQueryType">
                            <v-select
                              :items="formData.spreadTypes"
                              v-model="formData.selectedSpreadType"
                              placeholder="By"
                              label="By"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col v-if="isAverageQueryType">
                            <v-select
                              :items="formData.averageTypes"
                              v-model="formData.selectedAverageType"
                              placeholder="By"
                              label="By"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedBranches"
                              item-text="text"
                              item-value="id"
                              :items="formData.branches"
                              label="Who attended the branches"
                              placeholder="Who attended the branches"
                              :rules="validateGroupIsSelected"
                              hide-details="auto"
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedGroups"
                              item-text="text"
                              item-value="id"
                              :items="formData.groups"
                              label="Who work in the groups of"
                              placeholder="Who work in the groups of"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedMinistries"
                              item-text="text"
                              item-value="id"
                              :items="formData.ministries"
                              label="Who work in the ministries of"
                              placeholder="Who work in the ministries of"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <div style="height: 10px"></div>
                          <v-col md="2">
                            <v-btn
                              color="primary"
                              @click="stepNo = 2"
                              class="ma-2"
                            >
                              Next
                            </v-btn>
                            <v-btn
                              color="primary"
                              :disabled="!valid"
                              @click="stepNo = 4"
                              class="ma-2"
                            >
                              Done
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-step>
                    <v-stepper-step step="2" :complete="stepNo > 2">
                      Describe the audience
                      <small>Who should be included?</small>
                      <v-stepper-content step="2">
                        <div style="height: 10px"></div>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="formData.ageFrom"
                              :rules="validateAgeFrom"
                              label="Whose age is between"
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="formData.ageTo"
                              :rules="validateAgeTo"
                              label="and"
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedGenders"
                              :items="formData.genders"
                              label="Whose genders are"
                              placeholder="Whose genders are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedMaritalStatuses"
                              :items="formData.maritalStatuses"
                              label="Whose martital statuses are"
                              placeholder="Whose marital statuses are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedNationalities"
                              :items="formData.nationalities"
                              label="Whose nationalities are"
                              placeholder="Whose nationalities are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedEthnicities"
                              :items="formData.ethnicities"
                              label="Whose ethnicities are"
                              placeholder="Whose ethnicities are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedPositions"
                              :items="formData.positions"
                              label="Who positions are"
                              placeholder="Whose positions are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedProfessions"
                              :items="formData.professions"
                              label="Whose professions are"
                              placeholder="Whose professions are"
                              hide-details
                            >
                            </v-select>
                          </v-col>                          
                        </v-row>
                      <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedCurrentStatuses"
                              :items="formData.currentStatuses"
                              label="Who current statuses are"
                              placeholder="Whose current statuses are"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedAttributes"
                              :items="formData.attributes"
                              label="Whose attributes are"
                              placeholder="Whose attributes are"
                              hide-details
                            >
                            </v-select>
                          </v-col>                          
                        </v-row>                        
                        <v-row>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedRegions"
                              :items="formData.regions"
                              label="Who reside in the regions"
                              placeholder="Whose reside in the regions"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              multiple
                              v-model="formData.selectedCities"
                              :items="formData.cities"
                              label="Who reside in the cities"
                              placeholder="Whose reside in the cities"
                              hide-details
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <div style="height: 10px"></div>
                          <v-col md="6">
                            <v-btn
                              color="primary"
                              @click="stepNo = 1"
                              class="ma-2"
                            >
                              Back
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="stepNo = 3"
                              class="ma-2"
                            >
                              Next
                            </v-btn>
                            <v-btn
                              color="primary"
                              :disabled="!valid"
                              @click="stepNo = 4"
                              class="ma-2"
                            >
                              Done
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-step>
                    <v-stepper-step step="3" :complete="stepNo > 3">
                      Let's get specific
                      <small>Anything specifically?</small>
                      <v-stepper-content step="3">
                        <div style="height: 10px"></div>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateOfFirstVisitStart"
                              :dateRules="validateFirstVisitStartDate"
                              label="Who first visited between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateOfFirstVisitEnd"
                              :dateRules="validateFirstVisitEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateRequestedBaptisedStart"
                              :dateRules="validateRequestedBaptisedStartDate"
                              label="Who requested baptism between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateRequestedBaptisedEnd"
                              :dateRules="validateRequestedBaptisedEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateBaptisedStart"
                              :dateRules="validateBaptisedStartDate"
                              label="Who was baptised between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateBaptisedEnd"
                              :dateRules="validateBaptisedEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateFellowshippedStart"
                              :dateRules="validateFellowshipStartDate"
                              label="Who was fellowshiped between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateFellowshippedEnd"
                              :dateRules="validateFellowshipEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateResignedStart"
                              :dateRules="validateResignedStartDate"
                              label="Who resigned between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.dateResignedEnd"
                              :dateRules="validateResignedEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="8">
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.datePassedAwayStart"
                              :dateRules="validatePassedAwayStartDate"
                              label="Who passed away between"
                            >
                            </date-picker>
                          </v-col>
                          <v-col>
                            <date-picker
                              :showHeader="false"
                              :outlined="false"
                              :date.sync="formData.datePassedAwayEnd"
                              :dateRules="validatePassedAwayEndDate"
                              label="and"
                            >
                            </date-picker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <div style="height: 10px"></div>
                          <v-col md="2">
                            <v-btn
                              color="primary"
                              @click="stepNo = 2"
                              class="ma-2"
                            >
                              Back
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="stepNo = 4"
                              class="ma-2"
                            >
                              Next
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-step>
                    <v-stepper-step step="4" :complete="stepNo > 4">
                      Run the query
                      <small>Find out who matches your query</small>
                      <v-stepper-content step="4">
                        <div style="height: 10px"></div>
                        <v-row>
                          <div style="height: 10px"></div>
                          <v-col md="4">
                            <v-btn
                              color="primary"
                              @click="stepNo = 3"
                              class="ma-2"
                            >
                              Back
                            </v-btn>
                            <v-btn
                              color="success"
                              @click="runDraftQuery"
                              :disabled="!valid"
                              class="ma-2"
                            >
                              Run
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="stepNo = 5"
                              class="ma-2"
                            >
                              Next
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-step>
                    <v-stepper-step step="5">
                      Save it for next time!
                      <small>Enter a name for the query and click Save</small>
                      <v-stepper-content step="5">
                        <div style="height: 10px"></div>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Query Name"
                              placeholder="Name of query"
                              v-model="formData.name"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <div style="height: 10px"></div>
                          <v-col md="4">
                            <v-btn
                              color="success"
                              type="submit"
                              :disabled="!canSave"
                              class="ma-2"
                            >
                              Save
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-step>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onDialogCloseClick()"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <query-result-dialog
      :dialog="showResultDialog"
      :data="queryResult"
      @dialog-closed="closeQueryResultDialog"
    >
    </query-result-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import queryResultDialog from "@/components/demographics/query-result-dialog.vue";
import { AlertType, LoadingType } from "@/models/common.model";
import { DemographicsService } from "@/services/demographics.service";
import {
  DemographicsQueryViewModel,
  RunDemographicsQueryViewModel,
} from "@/models/demographics.model";
import DemographicsMixin from "@/mixins/demographics.mixin";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof CommonMixin> &
      InstanceType<typeof DemographicsMixin>
  >
).extend({
  name: "QueryManagerDialog",
  mixins: [CommonMixin, DemographicsMixin],
  props: {
    dialog: Boolean,
  },
  components: {
    "date-picker": DatePicker,
    "query-result-dialog": queryResultDialog,
  },
  watch: {
    dialog(val: boolean) {
      this.showQueryManagerDialog = val;
    },
    formData: {
      deep: true,
      handler(val: DemographicsQueryViewModel) {
        this.formData.description = this.toQueryDescription(val);
      },
    },
  },
  computed: {
    queryTypeRules(): string[] {
      let err: string[] = [];

      if (!this.formData.selectedQueryType) {
        err.push("You must provide a value.");
      } else {
        switch (this.formData.selectedQueryType) {
          case "the ratio of":
            if (!this.formData.selectedRatioType) {
              err.push("You need to select a ratio type.");
            }
            break;
          case "the spread of":
            if (!this.formData.selectedSpreadType) {
              err.push("You need to select a spread type.");
            }
            break;
          case "the average":
            if (!this.formData.selectedAverageType) {
              err.push("You need to select an average type.");
            }
            break;
        }
      }

      return err;
    },
    validateAgeFrom(): string[] {
      let err: string[] = [];

      this.formData.dateFellowshippedStart;
      if (this.formData.ageFrom) {
        if (this.isNotNumber(this.formData.ageFrom.toString()))
          err.push("Age must be a number");
        else if (this.formData.ageFrom < 0)
          err.push("Age cannot be less than 0");
        else if (this.formData.ageFrom > 125)
          err.push("Age cannot be greater than 125");
      }
      if (this.formData.ageFrom && this.formData.ageTo) {
        if (this.formData.ageFrom > this.formData.ageTo) {
          err.push("Age range is not correct");
        }
      }

      return err;
    },
    validateAgeTo(): string[] {
      let err: string[] = [];

      if (this.formData.ageTo) {
        if (this.isNotNumber(this.formData.ageTo.toString()))
          err.push("Age Must be a number");
        else if (this.formData.ageTo < 0) err.push("Age cannot be less than 0");
        else if (this.formData.ageTo > 125)
          err.push("Age cannot be greater than 125");
      }

      return err;
    },
    validateGroupIsSelected(): string[] {
      let err: string[] = [];

      if (
        this.formData.selectedBranches.length === 0 &&
        this.formData.selectedMinistries.length === 0 &&
        this.formData.selectedGroups.length === 0
      ) {
        err.push("You must select a branch, ministry or group");
      }

      return err;
    },
    validateFirstVisitStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.dateOfFirstVisitStart,
        this.formData.dateOfFirstVisitEnd
      );
    },
    validateFirstVisitEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.dateOfFirstVisitStart,
        this.formData.dateOfFirstVisitEnd
      );
    },
    validateRequestedBaptisedStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.dateRequestedBaptisedStart,
        this.formData.dateRequestedBaptisedEnd
      );
    },
    validateRequestedBaptisedEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.dateRequestedBaptisedStart,
        this.formData.dateRequestedBaptisedEnd
      );
    },
    validateBaptisedStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.dateBaptisedStart,
        this.formData.dateBaptisedEnd
      );
    },
    validateBaptisedEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.dateBaptisedStart,
        this.formData.dateBaptisedEnd
      );
    },
    validateFellowshipStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.dateFellowshippedStart,
        this.formData.dateFellowshippedEnd
      );
    },
    validateFellowshipEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.dateFellowshippedStart,
        this.formData.dateFellowshippedEnd
      );
    },
    validateResignedStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.dateResignedStart,
        this.formData.dateResignedEnd
      );
    },
    validateResignedEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.dateResignedStart,
        this.formData.dateResignedEnd
      );
    },
    validatePassedAwayStartDate(): string[] {
      return this.validateStartDateRange(
        this.formData.datePassedAwayStart,
        this.formData.datePassedAwayEnd
      );
    },
    validatePassedAwayEndDate(): string[] {
      return this.validateEndDateRange(
        this.formData.datePassedAwayStart,
        this.formData.datePassedAwayEnd
      );
    },
    canSave(): boolean {
      if (this.formData.name && this.valid) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      showQueryManagerDialog: false,
      title: "",
      valid: true,
      id: 0,
      stepNo: 1,
      formData: new DemographicsQueryViewModel(),
      queryResult: new RunDemographicsQueryViewModel(),
      isAverageQueryType: false,
      isRatioQueryType: false,
      isSpreadQueryType: false,
      showResultDialog: false,
      requestClientRefresh: false
    };
  },
  methods: {
    getItem(id: number) {
      this.stepNo = 1;
      this.id = id;
      this.formData = new DemographicsQueryViewModel();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Query Information, Please Wait..."
      );

      const service = new DemographicsService();
      service
        .getQuery(this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Query, Please Wait..."
      );

      const service = new DemographicsService();
      service
        .saveQuery(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.requestClientRefresh = true;
          this.id = response.data.id!;
          this.getItem(this.id);          
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onQueryTypeChange() {
      this.isAverageQueryType = false;
      this.isRatioQueryType = false;
      this.isSpreadQueryType = false;

      switch (this.formData.selectedQueryType) {
        case "the ratio of":
          this.isRatioQueryType = true;
          break;
        case "the spread of":
          this.isSpreadQueryType = true;
          break;
        case "the average":
          this.isAverageQueryType = true;
          break;
      }
    },

    runDraftQuery() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Running Query, Please Wait..."
      );

      const service = new DemographicsService();
      service
        .runDraftQuery(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.queryResult = response.data;
          this.queryResult.description = this.toQueryDescription(this.formData);
          this.showResultDialog = true;
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    closeQueryResultDialog() {
      this.showResultDialog = false;
    },

    onDialogCloseClick() {
      this.$emit("dialog-closed", this.requestClientRefresh);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>