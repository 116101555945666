var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Position"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details the position for a member within this group. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Foremost Position Held",
                          value: "",
                          dense: "",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.isMainPosition,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isMainPosition", $$v)
                          },
                          expression: "formData.isMainPosition",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Publicly Visible",
                          value: "",
                          dense: "",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.isPublic,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isPublic", $$v)
                          },
                          expression: "formData.isPublic",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _vm.isNationalMinistry
                        ? _c("v-select", {
                            attrs: {
                              disabled: !_vm.canEditMemberNameAndPosition,
                              items: _vm.members,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              clearable: "",
                              label: "Member",
                              placeholder: "Select a member",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              "return-object": "",
                            },
                            on: { change: _vm.onMemberChanged },
                            model: {
                              value: _vm.selectedMember,
                              callback: function ($$v) {
                                _vm.selectedMember = $$v
                              },
                              expression: "selectedMember",
                            },
                          })
                        : _vm._e(),
                      !_vm.isNationalMinistry
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: !_vm.canEditMemberNameAndPosition,
                              items: _vm.members,
                              loading: _vm.isMatching,
                              "search-input": _vm.matching,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              clearable: "",
                              label: "Member",
                              placeholder: "Type the name",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              "error-messages": _vm.validateMemberSelection,
                              "return-object": "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.matching = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.matching = $event
                              },
                              change: _vm.onMemberChanged,
                            },
                            model: {
                              value: _vm.selectedMember,
                              callback: function ($$v) {
                                _vm.selectedMember = $$v
                              },
                              expression: "selectedMember",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: !_vm.canEditMemberNameAndPosition,
                          items: _vm.formData.positions,
                          outlined: "",
                          clearable: "",
                          label: "Position",
                          placeholder: "Position",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "title", $$v)
                          },
                          expression: "formData.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Profile",
                          placeholder:
                            "Provide a profile of this member in this position.",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          rows: "4",
                          "hide-details": "auto",
                          maxlength: "4000",
                        },
                        model: {
                          value: _vm.formData.profile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "profile", $$v)
                          },
                          expression: "formData.profile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateStarted,
                          dateRules: _vm.validateDateStarted,
                          label: "Date Started",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(_vm.formData, "dateStarted", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.expirationDate,
                          dateRules: _vm.validateDateExpires,
                          label: "Due To Expire On",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "expirationDate",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateCompleted,
                          dateRules: _vm.validateDateCompleted,
                          label: "Date Completed",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateCompleted",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Reason for Completion",
                          placeholder:
                            "Provide a reason for the completion of this position.",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          rows: "3",
                          "hide-details": "auto",
                          maxlength: "4000",
                          "error-messages": _vm.validateReasonForCompletion,
                        },
                        model: {
                          value: _vm.formData.reasonForCompletion,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "reasonForCompletion", $$v)
                          },
                          expression: "formData.reasonForCompletion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }