export interface QuarterlyReturn {
    id: number;
    groupId: number;
    year: number;
    quarter: number;
    currentAccountsTotalBalance: number;
    depositAccountsTotalBalance: number;
    creditUnionAccountBalance: number;
    otherAccountsBalance: number;
    totalConferenceContributions: number;
    totalExtraordinaryExpenses: number;
    buildingValuationInsuranceValue: number;
    buildingValuationMarketValue: number;
    otherAssetsValuation: number;
    otherLongTermInvestments: number;
    totalTithesAndOffering: number;
    totalGiftAidReceipts: number;
    totalLoansRecovered: number;
    totalOtherIncome: number;
    totalRunningExpenses: number;
    extraordinaryProjectsExpenditure: number;
    conventionFees: number;
    loansIssued: number;
}


export class QuarterlyReturnDto {
    id: number;
    year: number;
    quarter: number;
    currentAccountsTotalBalance: number;
    depositAccountsTotalBalance: number;
    creditUnionAccountBalance: number;
    otherAccountsBalance: number;
    totalConferenceContributions: number;
    totalExtraordinaryExpenses: number;
    buildingValuationInsuranceValue: number;
    buildingValuationMarketValue: number;
    otherAssetsValuation: number;
    otherLongTermInvestments: number;
    totalTithesAndOffering: number;
    totalGiftAidReceipts: number;
    totalLoansRecovered: number;
    totalOtherIncome: number;
    totalRunningExpenses: number;
    extraordinaryProjectsExpenditure: number;
    conventionFees: number;
    loansIssued: number;

    constructor() {
        this.id = 0;
        this.year = 0;
        this.quarter = 0;
        this.currentAccountsTotalBalance = 0;
        this.depositAccountsTotalBalance = 0;
        this.creditUnionAccountBalance = 0;
        this.otherAccountsBalance = 0;
        this.totalConferenceContributions = 0;
        this.totalExtraordinaryExpenses = 0;
        this.buildingValuationInsuranceValue = 0;
        this.buildingValuationMarketValue = 0;
        this.otherAssetsValuation = 0;
        this.otherLongTermInvestments = 0;
        this.totalTithesAndOffering = 0;
        this.totalGiftAidReceipts = 0;
        this.totalLoansRecovered = 0;
        this.totalOtherIncome = 0;
        this.totalRunningExpenses = 0;
        this.extraordinaryProjectsExpenditure = 0;
        this.conventionFees = 0;
        this.loansIssued = 0;
    }
}

export class ManageQuarterlyReturnViewModel {
    id: number;
    year: number;
    quarter: number;
    currentAccountsTotalBalance: number;
    depositAccountsTotalBalance: number;
    creditUnionAccountBalance: number;
    otherAccountsBalance: number;
    totalConferenceContributions: number;
    totalExtraordinaryExpenses: number;
    buildingValuationInsuranceValue: number;
    buildingValuationMarketValue: number;
    otherAssetsValuation: number;
    otherLongTermInvestments: number;
    totalTithesAndOffering: number;
    totalGiftAidReceipts: number;
    totalLoansRecovered: number;
    totalOtherIncome: number;
    totalRunningExpenses: number;
    extraordinaryProjectsExpenditure: number;
    conventionFees: number;
    loansIssued: number;

    constructor() {
        this.id = 0;
        this.year = 0;
        this.quarter = 0;
        this.currentAccountsTotalBalance = 0;
        this.depositAccountsTotalBalance = 0;
        this.creditUnionAccountBalance = 0;
        this.otherAccountsBalance = 0;
        this.totalConferenceContributions = 0;
        this.totalExtraordinaryExpenses = 0;
        this.buildingValuationInsuranceValue = 0;
        this.buildingValuationMarketValue = 0;
        this.otherAssetsValuation = 0;
        this.otherLongTermInvestments = 0;
        this.totalTithesAndOffering = 0;
        this.totalGiftAidReceipts = 0;
        this.totalLoansRecovered = 0;
        this.totalOtherIncome = 0;
        this.totalRunningExpenses = 0;
        this.extraordinaryProjectsExpenditure = 0;
        this.conventionFees = 0;
        this.loansIssued = 0;
    }
}