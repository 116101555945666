var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { rounded: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "span",
                _vm._g({}, on),
                [
                  _vm.hasImageUrl
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c("v-avatar", { attrs: { rounded: "" } }, [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                transition: "scale-transition",
                                src: _vm.imageUrl,
                                alt: _vm.displayName,
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : !_vm.hasImageUrl
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "grey lighten-2" } },
                            [_vm._v(_vm._s(_vm.initials))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.menuItems, function (item) {
          return _c(
            "v-list-item",
            {
              key: item,
              attrs: { link: "" },
              on: {
                click: function ($event) {
                  return _vm.menuActionClick(item)
                },
              },
            },
            [
              _c("v-list-item-title", {
                domProps: { textContent: _vm._s(item) },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }