<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              :color="filterButtonColor"
              v-bind="attrs"
              v-on="on"
              @click="onFilterClicked"
              ><v-icon>{{ filterButtonIcon }}</v-icon></v-btn
            >
          </template>
          <span>Click here to manage your filters.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onFilterRemoveClicked"
              :disabled="!hasFilters"
              ><v-icon>mdi-filter-off</v-icon></v-btn
            >
          </template>
          <span>Click here to remove any filters that are applied.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onMessageSelected"
              :disabled="!hasSelectedAndContactableItems"
              ><v-icon>mdi-email-outline</v-icon></v-btn
            >
          </template>
          <span>Send message (email, app, sms)</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created any records yet (or no members
              match your filters). Create your first one by clicking the New
              Person.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="auto">
                <v-select
                  :items="allFilters"
                  v-model="filter"
                  placeholder="Filters"
                  label="Filters"
                  hide-details="auto"
                  item-text="title"
                  return-object
                  outlined
                  @change="onFilterChanged"
                >
                </v-select
              ></v-col>
              <v-col md="auto">
                <extended-autocomplete
                  :items="allGroups"
                  :selected.sync="selectedGroups"
                  :input="selectedGroupInput"
                  :outlined="true"
                  :invokeValidation="false"
                  label="Specific Group(s)"
                  placeholder="Select groups"
                >
                </extended-autocomplete>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="2">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>

              <v-btn color="success" @click="onShowSelectColumnsDialog"
                >Select Columns</v-btn
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items-per-page="15"
              :headers="headers"
              :items="items"
              :search="search"
              :single-select="false"
              item-key="id"
              :show-select="showMultiSelect"
              :sort-by="[]"
              :sort-desc="[]"
              multi-sort
              v-model="selectedItems"
              fixed-header
            >
              <template v-slot:[`item.imageUrl`]="{ item }">
                <div class="container">
                  <img :src="item.imageUrl" />
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <a :href="getMailLink(item.email)">{{ item.email }}</a>
              </template>
              <template v-slot:[`item.webSiteUrl`]="{ item }">
                <a :href="item.webSiteUrl" target="_blank">{{
                  item.webSiteUrl
                }}</a>
              </template>
              <template v-slot:[`item.primaryContactNumber`]="{ item }">
                <a
                  :href="getPhoneLink(item.primaryContactNumber)"
                  target="_blank"
                  >{{ item.contactNumber }}</a
                >
              </template>
              <template v-slot:[`item.contactable`]="{ item }">
                <v-simple-checkbox
                  v-model="item.contactable"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isMember`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isMember"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isVisitor`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isVisitor"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isActive"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isOnline`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isOnline"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.hasPassed`]="{ item }">
                <v-simple-checkbox
                  v-model="item.hasPassed"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.dateOfBirth`]="{ item }">
                {{ formatDateMin(item.dateOfBirth) }}
              </template>
              <template v-slot:[`item.dateOfBaptism`]="{ item }">
                {{ formatDateMin(item.dateOfBaptism) }}
              </template>
              <template v-slot:[`item.fellowshipDate`]="{ item }">
                {{ formatDateMin(item.fellowshipDate) }}
              </template>
              <template v-slot:[`item.resignationDate`]="{ item }">
                {{ formatDateMin(item.resignationDate) }}
              </template>
              <template v-slot:[`item.weddingDate`]="{ item }">
                {{ formatDateMin(item.weddingDate) }}
              </template>
              <template v-slot:[`item.datePassed`]="{ item }">
                {{ formatDateMin(item.datePassed) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <registered-user-filter-dialog
      :dialog="showFilterDialog"
      :data="dialogMessage"
      :canViewSensitiveData="canViewSensitiveData"
      :filterId="filter.id"
      @dialog-option-selected="onFilterDialogOptionSelected"
      @reset-filter="onDialogFilterResetRequest"
    ></registered-user-filter-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <multi-item-selection-dialog
      :dialog="showSelectColumnsDialog"
      :data="dialogMessage"
      :items="allColumns"
      :defaultItems="defaultColumns"
      :selectedItems="selectedColumns"
      @dialog-option-selected="onSelectColumnsDialogOptionSelected"
    >
    </multi-item-selection-dialog>
    <message-sender-dialog
      :groupId="groupId"
      :dialog="showMessageSenderDialog"
      :data="sendMessageViewModel"
      @dialog-closed="onMessageSenderDialogClosed"
    >
    </message-sender-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { IUserListItem } from "@/models/security.model";
import {
  BaseListItem,
  DialogMessage,
  DialogResponse,
  IBaseListItem,
  ISendMessageViewModel,
  LoadingType,
  MultiItemSelectionItem,
  StateKeyName,
  UserFilterName,
} from "@/models/common.model";
import {
  PeopleSearchFilter,
  UserFilterColumnName,
} from "@/models/people.model";
import { GroupService } from "@/services/group.service";
import { RegisteredUserService } from "@/services/registered-user.service";
import store from "@/store";
import RegisteredUserFilterDialog from "@/views/dialogs/registered-user-filter-dialog.vue";
import multiItemSelectionDialog from "@/components/common/multi-item-selection-dialog.vue";
import MessageSenderDialog from "@/components/common/message-sender-dialog.vue";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerNationalMinistryPeople",
  components: {
    "registered-user-filter-dialog": RegisteredUserFilterDialog,
    "multi-item-selection-dialog": multiItemSelectionDialog,
    "message-sender-dialog": MessageSenderDialog,
    "extended-autocomplete": ExtendedAutoComplete,
  },
  mixins: [CommonMixin],

  computed: {
    hasSelectedItems(): boolean {
      return this.selectedItems.length > 0;
    },
    canExportItems(): boolean {
      return this.items.length > 0;
    },
    hasFilters(): boolean {
      return this.checkHasFilter();
    },
    hasSelectedAndContactableItems(): boolean {
      let matches = this.selectedItems.filter(
        (f) =>
          f.isContactableByApp || f.isContactableByEmail || f.isContactableBySms
      );
      return matches.length > 0;
    },
  },

  watch: {
    selectedGroups: {
      immediate: true,
      handler(newVal: any) {
        var names = newVal.map((r: any) => r.name);
        if (names.length > 0) {
          this.items = this.allItems.filter((f) => names.includes(f.ownerGroupName));
        } else {
          this.items = [];
          this.items.push(...this.allItems);
        }
        this.selectedItems = [];
      },
    },
  },

  data() {
    return {
      showFilterDialog: false,
      showSelectColumnsDialog: false,
      showMessageSenderDialog: false,
      allItems: Array<IUserListItem>(),
      items: Array<IUserListItem>(),
      selectedItems: Array<IUserListItem>(),
      showMultiSelect: true,
      showGrid: false,
      showEmptyView: false,
      actionWidth: 4,
      headers: [] as any,
      search: "",
      allFilters: [] as PeopleSearchFilter[],
      allColumns: Array<MultiItemSelectionItem>(),
      defaultColumns: Array<MultiItemSelectionItem>(),
      selectedColumns: Array<MultiItemSelectionItem>(),
      filterButtonColor: "primary",
      filterButtonIcon: "mdi-filter",
      canViewSensitiveData: false,
      groupId: 0,
      filter: new PeopleSearchFilter(),
      groupIds: [] as number[],
      sendMessageViewModel: {} as ISendMessageViewModel,
      allGroups: Array<IBaseListItem>(),
      selectedGroups: Array<IBaseListItem>(),
      selectedGroupInput: "",
    };
  },

  async created() {
    this.allColumns = [];
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.groupName)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.firstName)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.lastName)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.contactable)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.email)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.dateOfBirth)
    );
    this.allColumns.push(new MultiItemSelectionItem(UserFilterColumnName.age));
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.gender)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.member)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.visitor)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.active)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.online)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.image)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.maritalStatus)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.ethnicity)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.nationality)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.profession)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.baptismDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.fellowshipDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.weddingDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.hasPassed)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.datePassed)
    );

    this.defaultColumns = [];
    this.selectedColumns = [];

    this.refreshFilterList();

    // Refresh the columns so that they reflect those of this filter
    this.getAndShowColumns(this.filter.id);
  },

  methods: {
    getItems(id: number) {
      if (this.groupId !== id) {
        this.groupId = id;
        this.refreshFilterList();
      }
      if (
        this.filter.id == 0 &&
        this.filter.id != store.state.ministryPeopleSearchFilterId
      ) {
        let match = this.allFilters.find(
          (f) => f.id == store.state.ministryPeopleSearchFilterId
        );
        if (match) {
          this.filter = match;
        } else {
          match = this.allFilters.find((f) => f.title == UserFilterName.All);
          if (match) {
            this.filter = match;
          } else {
            this.filter.id = store.state.ministryPeopleSearchFilterId;
          }
        }
      }

      this.groupId = id;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Membership, Please Wait...`
      );

      const service = new GroupService();
      service
        .getNationalMinistryMembers(
          this.groupId,
          this.filter.id,
          this.filter.groupId
        )
        .then((response) => {
          this.hideProgressIndicator();

          this.allGroups = [];
          for (let item of response.data) {
            if (!item.imageUrl) {
              item.imageUrl = require("@/assets/images/COG7-logo.png");
            }
            item.age = this.ageInYears(item.dateOfBirth);
            this.allGroups.push({
              id: item.ownerGroupId,
              name: item.ownerGroupName,
            });
          }

          this.items = response.data;
          this.generateNames(this.items);

          this.allItems = [];
          this.allItems.push(...this.items);

          this.selectedItems = [];
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = this.items.length > 0;
          this.showEmptyView = this.items.length == 0;

          // Refresh the columns so that they reflect those of this filter
          this.getAndShowColumns(this.filter.id);
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onFilterClicked() {
      this.showFilterSelectionDialog();
    },

    showFilterSelectionDialog() {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(
        "People Filter",
        "Select from one or more of the options to filter the results."
      );
      this.showFilterDialog = true;
    },

    async onFilterDialogOptionSelected(item: DialogResponse) {
      this.showFilterDialog = false;
      if (item.option) {
        this.filter = item.entity as PeopleSearchFilter;
        store.commit(
          StateKeyName.SetMinistryPeopleSearchFilterId,
          this.filter.id
        );
        this.refreshFilterList();
        this.getItems(this.groupId);
      }
    },

    onFilterChanged() {
      store.commit(
        StateKeyName.SetMinistryPeopleSearchFilterId,
        this.filter.id
      );
      this.getItems(this.groupId);
    },

    async onShowSelectColumnsDialog() {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(
        "Select Columns",
        "Select one or more columns to include in the grid."
      );
      this.showSelectColumnsDialog = true;
    },

    async onSelectColumnsDialogOptionSelected(item: DialogResponse) {
      this.showSelectColumnsDialog = false;
      if (item.option) {
        this.selectedColumns = item.entity as MultiItemSelectionItem[];

        let tmpColumns: string[] = [];
        for (let item of this.selectedColumns) {
          tmpColumns.push(item.option);
        }

        try {
          const service = new RegisteredUserService();
          const saveResponse = await service.saveColumns(
            this.filter.id,
            tmpColumns
          );
          if (saveResponse) {
            this.showSelectedColumns();
          }
        } catch (error) {
          this.showErrorDialog(error);
        }
      }
    },

    onDialogFilterResetRequest() {
      this.showFilterDialog = false;
      this.refreshFilterList();
      this.removeFilter();
    },

    onFilterRemoveClicked() {
      this.removeFilter();
    },

    removeFilter() {
      try {
        let match = this.allFilters.find((f) => f.title == UserFilterName.All);
        if (match) {
          this.filter = match;
        } else {
          this.filter = new PeopleSearchFilter();
        }
        store.commit(
          StateKeyName.SetMinistryPeopleSearchFilterId,
          this.filter.id
        );

        this.getItems(this.groupId);
      } catch (error) {
        this.showErrorDialog(error);
      }
    },

    checkHasFilter(): boolean {
      let filtersOn: boolean =
        this.filter.maritalStatus.length > 0 ||
        this.filter.ethnicity.length > 0 ||
        this.filter.profession.length > 0 ||
        this.filter.nationality.length > 0 ||
        this.filter.gender.length > 0 ||
        this.filter.contactable !== null ||
        this.filter.ageStart !== null ||
        this.filter.ageEnd !== null ||
        this.filter.dateOfBaptismStart !== null ||
        this.filter.dateOfBaptismEnd !== null ||
        this.filter.fellowshipDateStart !== null ||
        this.filter.fellowshipDateEnd !== null ||
        this.filter.resignationDateStart !== null ||
        this.filter.resignationDateEnd !== null ||
        this.filter.weddingDateStart != null ||
        this.filter.weddingDateEnd !== null ||
        this.filter.datePassedStart !== null ||
        this.filter.datePassedEnd !== null ||
        this.filter.attributes.length > 0 ||
        this.filter.currentStatus.length > 0 ||
        this.filter.groupId !== 0;

      // Do not show the filter indicator when it's set to all by default
      if (this.filter.title == UserFilterName.All) {
        filtersOn = false;
      }

      this.filterButtonColor = filtersOn ? "success" : "primary";
      this.filterButtonIcon = filtersOn
        ? "mdi-filter-check-outline"
        : "mdi-filter";
      return filtersOn;
    },

    showSelectedColumns() {
      this.headers = [];
      // No grid actions can be performed for national ministries
      /*
      this.headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        width: 120,
      });
      */
      this.headers.push({
        text: UserFilterColumnName.groupName,
        value: "ownerGroupName",
        sortable: false,
        width: 180,
      });
      this.showHideColumn(
        UserFilterColumnName.image,
        UserFilterColumnName.image,
        "imageUrl",
        false
      );
      this.showHideColumn(
        UserFilterColumnName.member,
        UserFilterColumnName.member,
        "isMember",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.visitor,
        UserFilterColumnName.visitor,
        "isVisitor",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.active,
        UserFilterColumnName.active,
        "isActive",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.online,
        UserFilterColumnName.online,
        "isOnline",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.contactable,
        UserFilterColumnName.contactable,
        "contactable",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.lastName,
        UserFilterColumnName.lastName,
        "lastName",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.firstName,
        UserFilterColumnName.firstName,
        "firstName",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.gender,
        UserFilterColumnName.gender,
        "gender",
        true,
        "120px"
      );
      this.showHideColumn(
        UserFilterColumnName.dateOfBirth,
        UserFilterColumnName.dateOfBirth,
        "dateOfBirth",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.age,
        UserFilterColumnName.age,
        "age",
        true,
        "100px"
      );
      // Email is never shown
      /*
      this.showHideColumn(
        UserFilterColumnName.email,
        UserFilterColumnName.email,
        "email",
        true,
        "180px"
      );
      */
      this.showHideColumn(
        UserFilterColumnName.maritalStatus,
        UserFilterColumnName.maritalStatus,
        "maritalStatus",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.ethnicity,
        UserFilterColumnName.ethnicity,
        "ethnicity",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.nationality,
        UserFilterColumnName.nationality,
        "nationality",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.profession,
        UserFilterColumnName.profession,
        "profession",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.baptismDate,
        UserFilterColumnName.baptismDate,
        "dateOfBaptism",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.fellowshipDate,
        UserFilterColumnName.fellowshipDate,
        "fellowshipDate",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.weddingDate,
        UserFilterColumnName.weddingDate,
        "weddingDate",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.hasPassed,
        UserFilterColumnName.hasPassed,
        "hasPassed",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.datePassed,
        UserFilterColumnName.datePassed,
        "datePassed",
        true,
        "140px"
      );
    },

    showHideColumn(
      columnName: string,
      header: string,
      value: string,
      sortable: boolean = true,
      width: string = ""
    ) {
      if (this.isSelectedColumn(columnName)) {
        this.headers.push({
          text: header,
          value: value,
          sortable: sortable,
          width: width,
        });
      }
    },

    isSelectedColumn(columnName: string): boolean {
      const index = this.selectedColumns.findIndex(
        (r) => r.option == columnName
      );
      return index != -1;
    },

    async refreshFilterList() {
      const service = new RegisteredUserService();
      service
        .getPeopleAndGroupFilters(this.groupId)
        .then((userResponse) => {
          if (userResponse.data) {
            // Capture all the available filters for the user
            this.allFilters = userResponse.data;

            // Attempt to determine which was the previous people filter used so it can be displayed
            if (store.state.ministryPeopleSearchFilterId !== 0) {
              let match = userResponse.data.find(
                (f) => f.id == store.state.ministryPeopleSearchFilterId
              );
              if (match) {
                this.filter = match;
              } else {
                this.filter = userResponse.data[0];
              }
            } else {
              this.filter = userResponse.data[0];
            }

            // Set the filter id in state so it can be set next time
            store.commit(
              StateKeyName.SetMinistryPeopleSearchFilterId,
              this.filter.id
            );
          } else {
            this.filter = new PeopleSearchFilter();
            store.commit(StateKeyName.SetMinistryPeopleSearchFilterId, 0);
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    async getAndShowColumns(currentFilterId: number) {
      try {
        const service = new RegisteredUserService();
        let selectedColumnsResponse = await service.getSelectedColumns(
          currentFilterId
        );

        this.selectedColumns = [];
        for (var item of selectedColumnsResponse.data) {
          this.selectedColumns.push(new MultiItemSelectionItem(item));
        }

        this.showSelectedColumns();
      } catch (error) {
        this.showErrorDialog(error);
      }
    },

    onMessageSenderDialogClosed() {
      this.showMessageSenderDialog = false;
    },

    onMessageSelected() {
      this.sendMessageViewModel = {
        recipients: this.selectedItems,
        subject: "",
        message: "",
        app: false,
        email: true,
        sms: false,
        attachmentUrls: [],
      };

      this.showMessageSenderDialog = true;
    },

    generateNames(users: IUserListItem[]) {
      for (let user of users) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
