<template>
  <v-dialog v-model="dialog" max-width="30%" persistent>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="4"> {{ dialogData.title }} </v-col>
          <v-spacer></v-spacer>
          <v-col md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="localItems"
          :search="search"
          :items-per-page="10"
          :footer-props="footerOptions"
          :page.sync="page"
          v-model="selected"
          item-key="option"
          item-value="option"
          show-select
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onCancel()"> Cancel </v-btn>
        <v-btn color="primary" text @click="onReset()"> Reset </v-btn>
        <v-btn
          color="primary"
          text
          @click="onSubmit()"
          :disabled="disableSubmit()"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import {
  DialogMessage,
  DialogResponse,
  MultiItemSelectionItem,
} from "@/models/common.model";
import CommonMixin from "@/mixins/common.mixin";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "MultiItemSelectionDialog",
  mixins: [CommonMixin],
  props: {
    dialog: Boolean,
    data: DialogMessage,
    items: {
      type: Array as PropType<MultiItemSelectionItem[]>,
    },
    defaultItems: {
      type: Array as PropType<MultiItemSelectionItem[]>,
    },
    selectedItems: {
      type: Array as PropType<MultiItemSelectionItem[]>,
    },
  },
  data() {
    return {
      dialogData: new DialogMessage("", ""),
      search: "",
      headers: [{ text: "Columns", value: "option", sortable: true }],
      footerOptions: {
        "items-per-page-options": [5, 10, 15, 20, 25],
        "items-per-page-text": "items per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      localItems: new Array<MultiItemSelectionItem>(),
      selected: new Array<MultiItemSelectionItem>(),
      page: 1,
    };
  },
  watch: {
    data(val: DialogMessage) {
      this.dialogData = val;
      this.populate();
    },
  },
  methods: {
    populate() {
      // Use local arrays so that we do not try to manipulate prop values
      this.selected = [];
      this.localItems = [];

      // Take ownership of sorting the list alphabetically
      let sortedItems = this.sortItems(this.items);
      let sortedSelected = this.sortItems(this.selectedItems);

      // Ensure that the selected items are at the top on the same page
      for (let item of sortedSelected) {
        this.localItems.push(item);
      }

      // Add the remaining options to the end of that list
      for (let item of sortedItems) {
        const index = this.localItems.findIndex((r) => r.option == item.option);
        if (index === -1) {
          this.localItems.push(item);
        }
      }

      // Check the selected items and ensure we display on the 1st page
      this.selected.push(...this.selectedItems);
      this.page = 1;
    },
    onSubmit() {
      this.data.entity = this.selected;
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(true, this.data.entity)
      );
    },
    onCancel() {
      this.selected = [];
      this.selected.push(...this.selectedItems);
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(false, this.data.entity)
      );
    },
    onReset() {
      this.selected = [];
      this.selected.push(...this.defaultItems);
    },
    disableSubmit(): boolean {
      return this.selected.length === 0;
    },
    onExit() {
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(false, this.data.entity)
      );
    },
    sortItems(list: MultiItemSelectionItem[]): MultiItemSelectionItem[] {
      let sortedItems = list.sort((a, b) => {
        if (a.option > b.option) {
          return 1;
        }
        if (a.option < b.option) {
          return -1;
        }
        return 0;
      });

      return sortedItems;
    },
  },
});
</script>