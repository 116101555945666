<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Basic</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide basic summary information used to identify
          the user.
        </p>
        <v-container class="fill-height" style="max-height: 256px">
          <image-selector
            :imageUrl="imageUrl"
            @uploaded-image-selected="uploadedImageSelected"
            @approved-image-selected="approvedImageSelected"
          >
          </image-selector>
        </v-container>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.ourReference"
              label="Our Reference"
              placeholder="Our Reference"
              outlined
              clearable
              hide-details="auto"
              maxlength="80"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="formData.currentStatuses"
              v-model="formData.selectedCurrentStatuses"
              outlined
              multiple
              clearable
              label="Current Status"
              placeholder="Current Status"
              menu-props="auto"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.firstName"
              label="First Name"
              placeholder="First Name"
              outlined
              clearable
              hide-details="auto"
              maxlength="80"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.lastName"
              label="Last Name"
              placeholder="Last Name"
              outlined
              clearable
              hide-details="auto"
              maxlength="80"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="formData.genders"
              v-model="formData.gender"
              outlined
              clearable
              label="Gender"
              placeholder="Gender"
              menu-props="auto"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.dateOfBirth"
              :disabled="!canViewSensitiveData"
              label="Date of Birth"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="10">
            <v-textarea
              label="Profile"
              placeholder="Provide a full profile of this person."
              auto-grow
              outlined
              counter
              clearable
              rows="3"
              hide-details="auto"
              maxlength="2000"
              v-model="formData.profile"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Demographics</h6>
        <p class="small" style="padding-right: 40px">
          Demographic information to provide a picture of your users and target
          specific indviduals
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-select
              :items="formData.maritalStatuses"
              :disabled="!canViewSensitiveData"
              v-model="formData.maritalStatus"
              outlined
              clearable
              label="Marital Status"
              placeholder="Marital Status"
              menu-props="auto"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              outlined
              clearable
              v-model="formData.skill"
              :items="formData.skills"
              label="Skills / Professions"
              placeholder="Skills / Professions"
              menu-props="auto"
              hide-details="auto"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="formData.ethnicities"
              v-model="formData.ethnicity"
              outlined
              clearable
              label="Ethnicity"
              placeholder="Ethnicity"
              menu-props="auto"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="formData.nationalities"
              v-model="formData.nationality"
              outlined
              clearable
              label="Nationality"
              placeholder="Nationality"
              menu-props="auto"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">3</span>Family</h6>
        <p class="small" style="padding-right: 40px">
          Relationship information providing us with the ability to manage
          family groups
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <member-autocomplete
              :disabled="!canViewSensitiveData"
              :member="formData.father"
              :memberBind.sync="formData.father"
              label="Father/Guardian 1"
              placeholder="Type the name"
            ></member-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <member-autocomplete
              :disabled="!canViewSensitiveData"
              :member="formData.mother"
              :memberBind.sync="formData.mother"
              label="Mother/Guardian 2"
              placeholder="Type the name"
            ></member-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">4</span>Attributes</h6>
        <p class="small" style="padding-right: 40px">
          This section is to apply attributes to this person which is not easily
          defined elsewhere. Select one or more and search on them later.
        </p>
      </v-col>
      <v-col md="3">
        <v-select
          :items="formData.attributes"
          v-model="formData.selectedAttributes"
          outlined
          multiple
          clearable
          label="Attributes"
          placeholder="Attributes"
          menu-props="auto"
          hide-details="auto"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import MemberAutoComplete from "@/components/common/member-autocomplete.vue";
import MemberCombobox from "@/components/common/member-combobox.vue";
import ImageSelector from "@/components/common/image-selector.vue";

import { ManageUserViewModel } from "@/models/security.model";
import { LoadingType } from "@/models/common.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "PeopleGeneralSection",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
    "member-autocomplete": MemberAutoComplete,
    "member-combobox": MemberCombobox,
    "image-selector": ImageSelector,
  },
  props: {
    id: Number,
    ownerId: Number,
    canViewSensitiveData: Boolean,
    formData: {
      type: Object,
      default: () => new ManageUserViewModel(),
      required: true,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val: ManageUserViewModel) {
        this.imageUrl = val.profileImageUrl;
      },
    },
  },
  data() {
    return {
      imageUrl: "",
      placeholder: require("@/assets/images/image-placeholder.png"),
    };
  },
  methods: {
    uploadedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.mainImageUrl = imageUrl;
      this.updateImage();
    },

    approvedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.mainImageUrl = imageUrl;
      this.updateImage();
    },

    updateImage() {
      if (this.formData.id) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Updating person's image, Please Wait..."
        );

        const service = new GroupService();
        service
          .updateMemberImage(this.ownerId, this.id, this.formData.mainImageUrl)
          .then(() => {
            this.hideProgressIndicator();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>