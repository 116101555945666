import Axios, { AxiosResponse } from "axios";
import { environment } from "@/environment";
import { BaseService } from "./base.service";
import { IManageUserViewModel, IUserListItem } from "@/models/security.model";
import { IContributorMediaItem, IImportViewModel, IUpdateImageCommand, IUpdateMemberImageCommand, ManagePositionViewModel, MemberSearchSuggestion, PositionListItem, UploadMediaItem } from "@/models/group.model";
import { ManagePollViewModel, PollListItem } from "@/models/group.model";
import { IPagedList } from "@/models/common.model";
import { BranchSelectionViewModel, IGroupListItem, IManageGroupViewModel, IRemoveGroupMembersCommand } from "@/models/group.model";
import { GroupType } from "@/models/common.model";
import { Contributor, ManageContributorViewModel } from "@/models/page.model";

export class GroupService extends BaseService {
    constructor() {
        super();
    }

    public getGroupsForSelection(groupType: string): Promise<AxiosResponse<BranchSelectionViewModel>> {
        const url = `${environment.apiUrl}/admin/group/${groupType}/list`;
        return Axios.get<BranchSelectionViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getAllGroups(groupType: string): Promise<AxiosResponse<IGroupListItem[]>> {

        let url = `${environment.apiUrl}/admin/group/${groupType}`;
        return Axios.get<IGroupListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getGroup(id: number, ownerId: number, groupType: string): Promise<AxiosResponse<IManageGroupViewModel>> {

        let url = ``;

        switch (groupType) {
            case GroupType.Branch:
            case GroupType.NationalMinistry:
                url = `${environment.apiUrl}/admin/group/${groupType}/${id}`;
                break;
            case GroupType.LocalMinistry:
                url = `${environment.apiUrl}/admin/group/${groupType}/${ownerId}/local/${id}`;
                break;
        }

        return Axios.get<IManageGroupViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getGroupMembers(groupId: number): Promise<AxiosResponse<IUserListItem[]>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members`;        
        return Axios.get<IUserListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getGroupMember(groupId: number, id: number): Promise<AxiosResponse<IManageUserViewModel>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/${id}`;
        return Axios.get<IManageUserViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPrimaryContactNumber(groupId: number, userId: number): Promise<AxiosResponse<string>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/${userId}/contact`;
        return Axios.get<string>(url, { headers: this.getAuthenticationHeader() });;
    }

    public getPositions(groupId: number): Promise<AxiosResponse<PositionListItem[]>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/positions`;
        return Axios.get<PositionListItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPosition(groupId: number, id: number): Promise<AxiosResponse<ManagePositionViewModel>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/positions/${id}`;
        return Axios.get<ManagePositionViewModel>(url, { headers: this.getAuthenticationHeader() });;
    }

    public getPolls(groupId: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<PollListItem>>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/polls?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<PollListItem>>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPoll(groupId: number, id: number): Promise<AxiosResponse<ManagePollViewModel>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/polls/${id}`;
        return Axios.get<ManagePollViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getMemberSuggestion(groupId: number | null, suggestion: string): Promise<AxiosResponse<MemberSearchSuggestion[]>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/suggestions?suggestion=${suggestion}`;
        return Axios.get<MemberSearchSuggestion[]>(url, { headers: this.getAuthenticationHeader() });;
    }

    public exportMembership(groupId: number): Promise<AxiosResponse<Blob>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/export`;
        return Axios.get(url, {
            responseType: 'blob', headers: this.getBlobHeader()
        });
    }

    public exportReport(groupId: number): Promise<AxiosResponse<Blob>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/report/export`;
        return Axios.get(url, {
            responseType: 'blob', headers: this.getBlobHeader()
        });
    }

    public saveGroup(viewModel: IManageGroupViewModel): Promise<AxiosResponse<IManageGroupViewModel>> {
        const url = `${environment.apiUrl}/admin/group`;
        return Axios.post<IManageGroupViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public saveGroupMember(groupId: number, viewModel: IManageUserViewModel): Promise<AxiosResponse<IManageUserViewModel>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/member`;
        return Axios.post<IManageUserViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public addLocalGroupMember(groupId: number, userId: number): Promise<AxiosResponse<IUserListItem>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/local/${userId}`;
        return Axios.post<IUserListItem>(url, null, { headers: this.getAuthenticationHeader() });
    }

    public importMembership(groupId: number, viewModel: IImportViewModel): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/import`;
        return Axios.post<boolean>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public savePosition(groupId: number, viewModel: ManagePositionViewModel): Promise<AxiosResponse<ManagePositionViewModel>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/position`;
        return Axios.post<ManagePositionViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public savePoll(groupId: number, viewModel: ManagePollViewModel): Promise<AxiosResponse<ManagePollViewModel>> {
        if (groupId === undefined || groupId == null) {
            groupId = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/poll`;
        return Axios.post<ManagePollViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public removeGroupMembers(groupId: number, memberIds: number[]): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/remove-selected`;
        let request: IRemoveGroupMembersCommand = {
            id: groupId,
            memberIds: memberIds
        };
        return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public updateImage(id: number, imageUrl: string): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${id}/image`;
        let request: IUpdateImageCommand = {
            id: id,
            imageUrl: imageUrl
        };
        return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public updateMemberImage(groupId: number, memberId: number, imageUrl: string): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/${memberId}/image`;
        let request: IUpdateMemberImageCommand = {
            groupId: groupId,
            memberId: memberId,
            imageUrl: imageUrl
        };

        return Axios.patch<boolean>(url, request, { headers: this.getAuthenticationHeader() });
    }

    public removePosition(groupId: number, id: number): Promise<AxiosResponse<boolean>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/positions/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removePoll(groupId: number, id: number): Promise<AxiosResponse<boolean>> {
        if (id === undefined || id == null) {
            id = 0;
        }
        const url = `${environment.apiUrl}/admin/group/${groupId}/polls/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeGroup(id: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeGroupMember(groupId: number, id: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeLocalGroupMember(groupId: number, userId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/members/local/${userId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveContributor(groupId: number, viewModel: ManageContributorViewModel) {
        const url = `${environment.apiUrl}/admin/group/${groupId}/contributors`;
        return Axios.post<ManageContributorViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public getContributors(groupId: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<Contributor>>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/contributors?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<Contributor>>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeContributor(groupId: number, contributorId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/group/${groupId}/contributors/${contributorId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public getContributionItems(id: number, contributorId: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<IContributorMediaItem>>> {
        const url = `${environment.apiUrl}/admin/group/${id}/contributors/${contributorId}?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<IContributorMediaItem>>(url, { headers: this.getAuthenticationHeader() });
    }
}