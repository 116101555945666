var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "4" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.onNewItem } },
                [_vm._v("New Person")]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "error",
                                    disabled: !_vm.hasSelectedItems,
                                  },
                                  on: { click: _vm.onDeleteSelected },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-delete-sweep-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Remove Selected")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.onImportButtonClicked },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-cloud-upload")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Import")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.canExportItems,
                                  },
                                  on: { click: _vm.onExportButtonClicked },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-cloud-download")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Export")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: _vm.filterButtonColor,
                                  },
                                  on: { click: _vm.onFilterClicked },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.filterButtonIcon)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Click here to manage your filters.")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.hasFilters,
                                  },
                                  on: { click: _vm.onFilterRemoveClicked },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-filter-off")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v(
                      "Click here to remove any filters that are applied."
                    ),
                  ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled:
                                      !_vm.hasSelectedAndContactableItems,
                                  },
                                  on: { click: _vm.onMessageSelected },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-email-outline")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Send message (email, app, sms)")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    icon: "",
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.canExportItems,
                                  },
                                  on: { click: _vm.onStandardReportExport },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-chart-pie")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Report Export")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmptyView,
                      expression: "showEmptyView",
                    },
                  ],
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "4" } }, [
                        _vm._v(
                          " It seems that you have not created any records yet (or no members match your filters). Create your first one by clicking the New Person. "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("lottie-player", {
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: {
                              src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                              background: "transparent",
                              speed: "1",
                              loop: "",
                              autoplay: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showGrid,
                      expression: "showGrid",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "auto" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.allFilters,
                                  placeholder: "Congregation",
                                  label: "Congregation",
                                  "hide-details": "auto",
                                  "item-text": "title",
                                  "return-object": "",
                                  outlined: "",
                                },
                                on: { change: _vm.onFilterChanged },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  clearable: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: { click: _vm.onShowSelectColumnsDialog },
                            },
                            [_vm._v("Select Columns")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: {
                          "items-per-page": 15,
                          headers: _vm.headers,
                          items: _vm.items,
                          search: _vm.search,
                          "single-select": false,
                          "item-key": "id",
                          "show-select": _vm.showMultiSelect,
                          "sort-by": [],
                          "sort-desc": [],
                          "multi-sort": "",
                          "fixed-header": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.actions",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onDeleteItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " mdi-delete "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Click here to remove"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEditItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " mdi-file-document-edit-outline "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Click here to edit"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  href: _vm.getMailLink(
                                                                    item.email
                                                                  ),
                                                                  color:
                                                                    "primary",
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.isEmptyString(
                                                                      item.email
                                                                    ),
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                " mdi-email-outline "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Click here to send an email"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.imageUrl",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("div", { staticClass: "container" }, [
                                    _c("img", {
                                      attrs: { src: item.imageUrl },
                                    }),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "item.email",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.getMailLink(item.email),
                                      },
                                    },
                                    [_vm._v(_vm._s(item.email))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.webSiteUrl",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.webSiteUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.webSiteUrl))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.primaryContactNumber",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.getPhoneLink(
                                          item.primaryContactNumber
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.contactNumber))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.contactable",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.contactable,
                                      callback: function ($$v) {
                                        _vm.$set(item, "contactable", $$v)
                                      },
                                      expression: "item.contactable",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.isMember",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.isMember,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isMember", $$v)
                                      },
                                      expression: "item.isMember",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.isVisitor",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.isVisitor,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isVisitor", $$v)
                                      },
                                      expression: "item.isVisitor",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.isActive",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.isActive,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isActive", $$v)
                                      },
                                      expression: "item.isActive",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.isOnline",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.isOnline,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isOnline", $$v)
                                      },
                                      expression: "item.isOnline",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.hasPassed",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-simple-checkbox", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.hasPassed,
                                      callback: function ($$v) {
                                        _vm.$set(item, "hasPassed", $$v)
                                      },
                                      expression: "item.hasPassed",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.dateOfBirth",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.dateOfBirth)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.dateOfBaptism",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.dateOfBaptism)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.fellowshipDate",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.fellowshipDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.resignationDate",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.resignationDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.weddingDate",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.weddingDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.datePassed",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateMin(item.datePassed)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedItems,
                          callback: function ($$v) {
                            _vm.selectedItems = $$v
                          },
                          expression: "selectedItems",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "csvReader",
        staticClass: "d-none",
        attrs: { type: "file", accept: ".csv" },
        on: { change: _vm.onImport },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteAllDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteAllOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("registered-user-filter-dialog", {
        attrs: {
          dialog: _vm.showFilterDialog,
          data: _vm.dialogMessage,
          canViewSensitiveData: _vm.canViewSensitiveData,
          filterId: _vm.filter.id,
        },
        on: {
          "dialog-option-selected": _vm.onFilterDialogOptionSelected,
          "reset-filter": _vm.onDialogFilterResetRequest,
        },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("multi-item-selection-dialog", {
        attrs: {
          dialog: _vm.showSelectColumnsDialog,
          data: _vm.dialogMessage,
          items: _vm.allColumns,
          defaultItems: _vm.defaultColumns,
          selectedItems: _vm.selectedColumns,
        },
        on: {
          "dialog-option-selected": _vm.onSelectColumnsDialogOptionSelected,
        },
      }),
      _c("message-sender-dialog", {
        attrs: {
          groupId: _vm.groupId,
          dialog: _vm.showMessageSenderDialog,
          data: _vm.sendMessageViewModel,
        },
        on: { "dialog-closed": _vm.onMessageSenderDialogClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }