<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col md="2">
          <h6><span class="number">1</span>About</h6>
          <p class="small" style="padding-right: 40px">
            This section is to provide basic summary about the conference.
          </p>
        </v-col>
        <v-col md="6" offset-lg="1">
          <v-row>
            <v-col>
              <v-text-field
                v-model="formData.name"
                label="Name"
                placeholder="Name"
                outlined
                clearable
                hide-details="auto"
                maxlength="80"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Overview"
                placeholder="Enter a short overview of the conference."
                counter="4000"
                outlined
                clearable
                v-model="formData.overview"
                rows="8"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="formData.charityNumber"
                label="Charity Number"
                placeholder="Charity Number"
                outlined
                clearable
                hide-details="auto"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <v-col lg="6">
              <date-picker
                :showHeader="false"
                :date.sync="formData.dateRegistered"
                :dateRules="validateDateRegistered"
                label="Date Registered"
              >
              </date-picker>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <h6><span class="number">2</span>Contact</h6>
          <p class="small" style="padding-right: 40px">
            This section is to provide the main contact points for the
            conference.
          </p>
        </v-col>
        <v-col md="6" offset-lg="1">
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="formData.email"
                label="Email"
                placeholder="Email"
                outlined
                clearable
                hide-details="auto"
                maxlength="256"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col lg="6">
              <v-text-field
                v-model="formData.contactNumber"
                label="Contact Number"
                placeholder="Contact Number"
                outlined
                clearable
                hide-details="auto"
                maxlength="20"
                :rules="[rules.phone]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="formData.webSiteUrl"
                label="Web Site Url"
                placeholder="Web Site Url"
                outlined
                clearable
                hide-details="auto"
                maxlength="120"
                :rules="[rules.url]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12">
          <v-btn color="primary" type="submit" :disabled="!valid"> Save </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import { ManageConferenceViewModel } from "@/models/conference.model";
import { ConferenceService } from "@/services/conference.service";
import { AlertType, LoadingType } from "@/models/common.model";
import { DateTime } from "luxon";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ConferenceManagerGeneralSection",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
  },
  computed: {
    validateDateRegistered(): string[] {
      let err: string[] = [];

      if (this.formData.dateRegistered) {
        const dr: DateTime = DateTime.fromISO(
          this.formData.dateRegistered.toString()
        );
        if (dr.diffNow("days").days > 1) {
          err.push("The date registered cannot be in the future");
        }
      }

      return err;
    },
  },
  data() {
    return {
      valid: true,
      formData: new ManageConferenceViewModel(),
      country: ["GB"],
    };
  },

  methods: {
    resetData() {
      this.formData = new ManageConferenceViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getConference() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Information, Please Wait..."
      );

      const service = new ConferenceService();
      service
        .getConference()
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Information, Please Wait..."
      );

      const service = new ConferenceService();
      service
        .saveConference(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.$emit(
            "show-alert-panel",
            "The conference data was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>