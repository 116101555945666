var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": _vm.maxWidth,
            persistent: "",
            "retain-focus": false,
            eager: "",
          },
          model: {
            value: _vm.showMyDialog,
            callback: function ($$v) {
              _vm.showMyDialog = $$v
            },
            expression: "showMyDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _c("div", [_vm._v("Permitted Users")]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOk()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("div", { staticStyle: { height: "20px" } }),
                  _c("div", [
                    _vm._v(
                      "Select the users that will be permitted to run this query."
                    ),
                  ]),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.members,
                              loading: _vm.isMatching,
                              "search-input": _vm.matching,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              clearable: "",
                              label: "Member/Visitor",
                              placeholder: "Member or Visitor",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              "return-object": "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.matching = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.matching = $event
                              },
                            },
                            model: {
                              value: _vm.selectedMember,
                              callback: function ($$v) {
                                _vm.selectedMember = $$v
                              },
                              expression: "selectedMember",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                disabled: !_vm.hasSelectedMember,
                              },
                              on: { click: _vm.onNewItem },
                            },
                            [_vm._v("Add to List")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "11" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { md: "2" } }),
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "mdi-magnify",
                                              label: "Search",
                                              "single-line": "",
                                              clearable: "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.search,
                                              callback: function ($$v) {
                                                _vm.search = $$v
                                              },
                                              expression: "search",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                attrs: {
                                  "items-per-page": 10,
                                  headers: _vm.headers,
                                  items: _vm.items,
                                  search: _vm.search,
                                  "single-select": false,
                                  "item-key": "id",
                                  "show-select": _vm.showMultiSelect,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.actions",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onDeleteItem(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(" mdi-delete "),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onOk()
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }