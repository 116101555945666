import Axios, { AxiosResponse } from "axios";

import { environment } from "@/environment";
import { DemographicsQueryViewModel, IDemographicsQueryItem, DemographicsQueryRunner, NotificationMessage, RunDemographicsQueryViewModel } from "@/models/demographics.model";
import { BaseService } from "./base.service";

export class DemographicsService extends BaseService {
    constructor() {
        super();
    }

    public getQueries(): Promise<AxiosResponse<IDemographicsQueryItem[]>> {
        const url = `${environment.apiUrl}/admin/demographics-query`;
        return Axios.get<IDemographicsQueryItem[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getQuery(id?: number): Promise<AxiosResponse<DemographicsQueryViewModel>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${id}`;
        return Axios.get<DemographicsQueryViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public getSmsMessageCost(id: number, message: NotificationMessage): Promise<AxiosResponse<number>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${id}/sms-cost`;
        return Axios.post(url, message, { headers: this.getAuthenticationHeader() });
    }

    public getAuthorizedQueryRunners(id: number): Promise<AxiosResponse<DemographicsQueryRunner[]>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${id}/runners`;
        return Axios.get(url, { headers: this.getAuthenticationHeader() });
    }

    public saveQuery(viewModel: DemographicsQueryViewModel): Promise<AxiosResponse<DemographicsQueryViewModel>> {
        const url = `${environment.apiUrl}/admin/demographics-query`;
        return Axios.post(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public runQuery(id?: number): Promise<AxiosResponse<RunDemographicsQueryViewModel>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${id}/run`;
        return Axios.get<RunDemographicsQueryViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public runDraftQuery(viewModel: DemographicsQueryViewModel): Promise<AxiosResponse<RunDemographicsQueryViewModel>> {
        const url = `${environment.apiUrl}/admin/demographics-query/run-draft`;
        return Axios.post<RunDemographicsQueryViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public addAuthorizedQueryRunner(queryId: number, userId: number): Promise<AxiosResponse<DemographicsQueryRunner>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${queryId}/runners/${userId}`;
        return Axios.post(url, null, { headers: this.getAuthenticationHeader() });
    }

    public sendMessage(message: NotificationMessage): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/demographics-query/send-message`;
        return Axios.post(url, message, { headers: this.getAuthenticationHeader() });
    }

    public removeQuery(id?: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${id}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeAuthorisedQueryRunner(queryId: number, userId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/demographics-query/${queryId}/runners/${userId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }
}