<template>
  <v-alert
    border="left"
    elevation="4"
    :value="show"
    :type="type"
    transition="slide-x-transition"
  >
    {{ message }}
  </v-alert>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ActionAlert",
  props: {
    show: Boolean,
    message: String,
    type: {
      type: String,
      default: "success",
    },
  },
  methods: {},
});
</script>

<style scoped src="@/assets/css/wg.css"></style>