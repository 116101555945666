var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-hover", {
        attrs: { "open-delay": "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    class: { "on-hover": hover },
                    attrs: { elevation: hover ? 12 : 3, outlined: hover },
                  },
                  [
                    _c("v-img", {
                      staticClass: "imageCursor",
                      attrs: {
                        height: "192",
                        width: "192",
                        src: _vm.mainImageUrl,
                        "lazy-src": _vm.placeholder,
                      },
                      on: { click: _vm.showImageSelector },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticStyle: { height: "8px" } }),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            outlined: "",
                            color: "primary",
                            "hide-details": "auto",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.file.click()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(" mdi-file-upload-outline ")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("upload image")])]
      ),
      _c("input", {
        ref: "file",
        attrs: { hidden: "", type: "file", accept: "image/*" },
        on: {
          change: function ($event) {
            return _vm.uploadImage($event)
          },
        },
      }),
      _c("approved-image-selector", {
        attrs: {
          dialog: _vm.showApprovedImageSelector,
          multiSelect: false,
          items: _vm.approvedImages,
        },
        on: { "dialog-option-selected": _vm.onApprovedImageSelection },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }