var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "split-screen" }, [
      _vm._m(0),
      _c("div", { staticClass: "right-screen" }, [
        _c("form", [
          _c(
            "section",
            [
              _c("v-img", {
                attrs: {
                  "max-height": "512",
                  "max-width": "512",
                  src: _vm.imageUrl,
                },
              }),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "social-container" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onGoogleAuth()
                    },
                  },
                },
                [_vm._v("Login with Google")]
              ),
              _c("div", { staticStyle: { height: "12px" } }),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onMicrosoftAuth()
                    },
                  },
                },
                [_vm._v("Login with Microsoft")]
              ),
            ],
            1
          ),
          _vm._m(1),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-screen" }, [
      _c("section", { staticClass: "copy" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "copy legal" }, [
      _c("p", [
        _c("span", { staticClass: "small" }, [
          _vm._v("By continuing, you agree to accept our "),
          _c("br"),
          _c("a", { attrs: { href: "#" } }, [_vm._v("Privacy Policy")]),
          _vm._v(" & "),
          _c("a", { attrs: { href: "#" } }, [_vm._v("Terms of Condition")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }