<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Exposure</h6>
        <p class="small" style="padding-right: 40px">
          State whether the event should be public on one more more platforms.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-checkbox
              v-model="formData.isOnNationalCalendar"
              label="On National Calendar"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Duration</h6>
        <p class="small" style="padding-right: 40px">
          State how long this event should be given promotional priority i.e.
          placed on banners and front pages, before it reverts back to a regular
          listing.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.promotionStart"
              :dateRules="validatePromotionStart"
              label="Promote From"
            >
            </date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :showHeader="false"
              :outlined="true"
              :date.sync="formData.promotionEnd"
              :dateRules="validatePromotionEnd"
              label="Promote To"
            >
            </date-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { EventViewModel } from "@/models/events.model";
import DatePicker from "@/components/common/date-picker.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventsPromotionSection",
  components: {
    "date-picker": DatePicker,
  },
  mixins: [CommonMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },
  computed: {
    validatePromotionStart(): string[] {
      return this.validateStartDateRange(
        this.formData.promotionStart,
        this.formData.promotionEnd
      );
    },
    validatePromotionEnd(): string[] {
      return this.validateEndDateRange(
        this.formData.promotionStart,
        this.formData.promotionEnd
      );
    },
  },
});
</script>