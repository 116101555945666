<template>
  <v-container fluid>
    <list-user-roles
      v-show="showListView"
      ref="listitems"
      @new-item-requested="onNewItem"
      @show-item="onShowItem"
    ></list-user-roles>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ListUserRoles from "@/views/lists/list-user-roles.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerUserRoles",
  mixins: [CommonMixin],
  components: {
    ListUserRoles,
  },
  data() {
    return {
      showListView: true,
      showManageView: false,
      id: 0,
    };
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems() {
      this.showListView = true;
      this.showManageView = false;

      const ref: any = this.$refs.listitems;
      if (ref) {
        ref.getItems();
      }
    },

    onNewItem() {
      /*
            this.id = 0;
            this.showListView = false;
            this.showManageView = true;
            const ref: any = this.$refs.manageitem;
            if (ref) {
                ref.getItem(this.id);
            }
            */
    },

    onShowItem(id: number) {
      /*
            this.id = id;
            this.showListView = false;
            this.showManageView = true;
            const ref: any = this.$refs.manageitem;
            if (ref) {
                ref.getItem(this.id);
            }
            */
    },

    onManageItemCancel() {
      this.getItems();
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>