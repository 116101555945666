import { render, staticRenderFns } from "./list-manager-groups-branch.vue?vue&type=template&id=7a1df740&scoped=true"
import script from "./list-manager-groups-branch.vue?vue&type=script&lang=ts"
export * from "./list-manager-groups-branch.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/wg.css?vue&type=style&index=0&id=7a1df740&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a1df740",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VForm,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a1df740')) {
      api.createRecord('7a1df740', component.options)
    } else {
      api.reload('7a1df740', component.options)
    }
    module.hot.accept("./list-manager-groups-branch.vue?vue&type=template&id=7a1df740&scoped=true", function () {
      api.rerender('7a1df740', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/list-managers/list-manager-groups-branch.vue"
export default component.exports