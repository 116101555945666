var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "40%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              attrs: { flat: "", dense: "", elevation: "1", color: "#E5E3E3" },
            },
            [
              _vm._v(" Manage Page "),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onCancel()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-close ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Close")])]
              ),
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSave.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  clearable: "",
                                  label: "Title",
                                  placeholder: "Title",
                                  counter: "80",
                                  maxlength: "80",
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.viewModel.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.viewModel, "name", $$v)
                                  },
                                  expression: "viewModel.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  clearable: "",
                                  label: "Subtitle",
                                  placeholder: "Subtitle",
                                  counter: "80",
                                  maxlength: "80",
                                },
                                model: {
                                  value: _vm.viewModel.subtitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.viewModel, "subtitle", $$v)
                                  },
                                  expression: "viewModel.subtitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  clearable: "",
                                  label: "Description",
                                  placeholder: "Description",
                                  counter: "400",
                                  outlined: "",
                                  rows: "4",
                                },
                                model: {
                                  value: _vm.viewModel.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.viewModel, "description", $$v)
                                  },
                                  expression: "viewModel.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }