export class GetPageViewModel {
    id: number;
    name: string;
    banner: ContributorContent[];
    sections: Section[];

    constructor() {
        this.id = 0;
        this.name = '';
        this.banner = [];
        this.sections = [];
    }
}

export class ManagePageViewModel {
    id: number;
    name: string;
    subtitle: string;
    description: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.subtitle = '';
        this.description = '';
    }
}

export interface Section {
    id: number;
    name: string;
    subtitle: string;
    imageUrl: string;
    isSingleContributor: boolean;
    contributors: Contributor[];
}

export class Contributor {
    id: number;
    name: string;
    subtitle: string;
    imageUrl: string;
    contentUrl: string;
    description: string;
    contentType: string;
    duration: number | null;
    credit: string;
    sampleContent: ContributorContent[];
    selected?: boolean;
    isPublished: boolean;
    show: boolean;

    constructor() {
        this.id = 0;
        this.name = '';
        this.subtitle = '';
        this.imageUrl = '';
        this.contentUrl = '';
        this.description = '';
        this.contentType = '';
        this.duration = null;
        this.credit = '';
        this.sampleContent = [];
        this.isPublished = false;
        this.show = false;
    }
}

export class ContributorContent {
    id: number;
    name: string;
    credit: string;
    imageUrl: string;
    contentUrl: string;
    description: string;
    contentType: string;
    duration: number | null;
    size: number | null;
    genres: string[];
    selected?: boolean;
    publicationStart: Date | null;
    publicationEnd: Date | null;
    tags: string[];
    updatedOn: Date | null;
    show: boolean;

    constructor() {
        this.id = 0;
        this.name = '';
        this.credit = '';
        this.imageUrl = '';
        this.contentUrl = '';
        this.description = '';
        this.contentType = '';
        this.duration = null;
        this.size = null;
        this.genres = [];
        this.publicationStart = null;
        this.publicationEnd = null;
        this.tags = [];
        this.updatedOn = null;
        this.show = false;
    }
}

export class LayoutListItem {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this.name = '';
    }
}

export class ManageContributorViewModel {
    id: number;
    name: string;
    subtitle: string;
    imageUrl: string;
    contentUrl: string;
    description: string;
    contentType: string;
    duration: number | null;
    credit: string;
    contentTypes: string[];
    tags: string[];
    allTags: string[];
    publicationStart: Date | null;
    publicationEnd: Date | null;
    updatedOn: Date | null;

    constructor() {
        this.id = 0;
        this.name = '';
        this.subtitle = '';
        this.imageUrl = '';
        this.contentUrl = '';
        this.description = '';
        this.contentType = '';
        this.duration = null;
        this.credit = '';
        this.contentTypes = [];
        this.tags = [];
        this.allTags = [];
        this.publicationStart = null;
        this.publicationEnd = null;
        this.updatedOn = null;
    }
}

export class ManageSectionViewModel {
    pageId: number;
    id: number;
    name: string;
    imageUrl: string;
    displayType: string;
    displayTypes: string[];
    contributors: Contributor[];

    constructor() {
        this.pageId = 0;
        this.id = 0;
        this.name = '';
        this.imageUrl = '';
        this.displayType = '';
        this.displayTypes = [];
        this.contributors = [];
    }
}

export interface IGetContributorFromUrlCommand {
    contentType: string;
    contentUrl: string;
}

export interface IUpdateBannerCommand {
    id: number;
    items: ContributorContent[];
}

export interface IReorderSectionCommand {
    sectionId: number;
    pageId: number;
    newPosition: number;
}

export interface IUpdateContributorImageCommand {
    id: number;
    imageUrl: string;
}

export interface IRefreshContributorMediaCommand {
    id: number;
    clearDown: boolean;
}

export interface IRemoveSelectedContributorMediaCommand {
    id: number;
    mediaIds: number[];
}

export interface ISaveContributorContentCommand {
    contributorId: number;
    id: number;
    name: string;
    description: string;
    tags: string[];
    publicationStart: Date | null;
    publicationEnd: Date | null;
    updatedOn: Date | null;
}