<template>
  <v-card class="mx-auto" max-width="400">
    <v-img
      class="white--text align-end"
      height="200px"
      :src="contributor.imageUrl"
    >
    </v-img>

    <v-card-title>
      {{ contributor.name }}
    </v-card-title>
    <v-card-subtitle class="pb-0">{{ contributor.subtitle }}</v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "ContributorCard",
  props: {
    contributor: Object,
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>