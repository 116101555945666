var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("list-user-roles", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showListView,
            expression: "showListView",
          },
        ],
        ref: "listitems",
        on: {
          "new-item-requested": _vm.onNewItem,
          "show-item": _vm.onShowItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }