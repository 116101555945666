var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "autocomplete",
    attrs: {
      id: _vm.id,
      label: _vm.placeholder,
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      rules: _vm.rules,
      clearable: _vm.clearable,
      outlined: _vm.outlined,
      "hide-details": _vm.hideDetails,
    },
    on: { "click:clear": _vm.onClear },
    model: {
      value: _vm.autocompleteText,
      callback: function ($$v) {
        _vm.autocompleteText = $$v
      },
      expression: "autocompleteText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }