var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "70%", "max-height": "70%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              ref: "uploadForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("div", { staticStyle: { height: "20px" } }),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              clearable: "",
                              "prepend-icon": "mdi-image-outline",
                              label: "Upload Image",
                              placeholder:
                                "Please select or paste an image to upload",
                              hint: "Location of the image to upload",
                              rules: _vm.validateImageUrl,
                            },
                            on: {
                              change: _vm.onSelectedImageChange,
                              "click:prepend": function ($event) {
                                return _vm.$refs.file.click()
                              },
                            },
                            model: {
                              value: _vm.image.src,
                              callback: function ($$v) {
                                _vm.$set(_vm.image, "src", $$v)
                              },
                              expression: "image.src",
                            },
                          }),
                          _c("input", {
                            ref: "file",
                            attrs: {
                              hidden: "",
                              type: "file",
                              accept: "image/*",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadImage($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "10" } },
                            [
                              _c("cropper", {
                                ref: "cropper",
                                staticClass: "cropper",
                                attrs: {
                                  src: _vm.image.src,
                                  debounce: false,
                                  "auto-zoom": false,
                                  imageRestriction: _vm.fitName,
                                  "stencil-props": {
                                    aspectRatio: 16 / 9,
                                  },
                                },
                                on: { change: _vm.onChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("preview", {
                                    attrs: {
                                      width: 375,
                                      height: 250,
                                      image: _vm.result.image,
                                      coordinates: _vm.result.coordinates,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-row", [_c("v-col")], 1),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }