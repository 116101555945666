import Axios, { AxiosResponse } from "axios";
import { environment } from "@/environment";
import { BaseService } from "./base.service";
import { IApprovedImage, IManageConferenceViewModel, IManageRegisteredUsersViewModel, IRegisteredUserDto, IReinstateRegisteredUsersCommand, IRemoveRegisteredUsersCommand, StoreAuthorizationTokenCommand } from "@/models/conference.model";
import { IPagedList } from "@/models/common.model";

export class ConferenceService extends BaseService {
    constructor() {
        super();
    }

    public getApprovedImages(platform: string, category: string): Promise<AxiosResponse<IApprovedImage[]>> {
        const url = `${environment.apiUrl}/admin/conference/approved-images?platform=${platform}&category=${category}`;
        return Axios.get<IApprovedImage[]>(url, { headers: this.getAuthenticationHeader(), });
    }

    public getConference(): Promise<AxiosResponse<IManageConferenceViewModel>> {
        const url = `${environment.apiUrl}/admin/conference`;
        return Axios.get<IManageConferenceViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveConference(viewModel: IManageConferenceViewModel): Promise<AxiosResponse<IManageConferenceViewModel>> {
        const url = `${environment.apiUrl}/admin/conference`;
        return Axios.post<IManageConferenceViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public connectToInstagram(): string {
        const appId = "344639973729708";
        const redirectUrl = `${environment.baseUrl}authredirect`;

        const scope = "user_profile,user_media";
        const url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redirectUrl}&scope=${scope}&response_type=code`;

        return url;
    }

    public storeAuthorisationToken(platform: string, authToken: string): Promise<AxiosResponse<any>> {
        const url = `${environment.apiUrl}/admin/conference/auth-token`;
        const model: StoreAuthorizationTokenCommand = {
            platform: platform,
            token: authToken,
        };
        return Axios.post(url, model, { headers: this.getAuthenticationHeader() });
    }

    public getRegisteredUsers(selectedGroup: string, showDeletedOnly: boolean, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IManageRegisteredUsersViewModel>> {
        const url = `${environment.apiUrl}/admin/conference/registered-users?selectedGroup=${selectedGroup}&showDeletedOnly=${showDeletedOnly}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IManageRegisteredUsersViewModel>(url, { headers: this.getAuthenticationHeader() });
    }

    public removeRegisteredUser(id: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/conference/registered-users/remove`;
        var model: IRemoveRegisteredUsersCommand = {
            ids: [id]
        };
        return Axios.post<boolean>(url, model, { headers: this.getAuthenticationHeader() });
    }

    public removeRegisteredUsers(ids: number[]): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/conference/registered-users/remove`;
        var model: IRemoveRegisteredUsersCommand = {
            ids: ids
        };
        return Axios.post<boolean>(url, model, { headers: this.getAuthenticationHeader() });
    }

    public reinstateRegisteredUser(id: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/conference/registered-users/re-instate`;
        var model: IReinstateRegisteredUsersCommand = {
            ids: [id]
        };
        return Axios.post(url, model, { headers: this.getAuthenticationHeader() });
    }

    public reinstateRegisteredUsers(ids: number[]): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/conference/registered-users/re-instate`;
        var model: IReinstateRegisteredUsersCommand = {
            ids: ids
        };
        return Axios.post(url, model, { headers: this.getAuthenticationHeader() });
    }
}