import { SelectableItem } from "./common.model";

export enum DemographicsQueryType {
    All = 0,
    TotalNumberOf = 1,
    Average = 2,
    Youngest = 3,
    Oldest = 4,
    RatioOf = 5,
    Spread = 6
}

export interface IDemographicsQueryItem {
    id: number;
    name: string;
    description: string;
    isNotifyable: boolean;
    isOwner: boolean;
}

export interface IDemographicsNotificationRecipient {
    id: number,
    name: string;
    isNotifyable: boolean;
}

export interface IDemographicsQueryResultViewModel {
    id: number;
    fellowshipDate: string | null;
    resignationDate: string | null;
    dateOfBaptism: string | null;
    dateOfFirstVisit: string | null;
    dateOfBaptismRequest: string | null;
    title: string;
    firstName: string;
    lastName: string;
    age: number | null;
    dateOfBirth: string | null;
    dateOfPassing: string | null;
    gender: string;
    maritalStatus: string;
    nationality: string;
    ethnicity: string;
    mobileNumber: string;
    email: string;
    imageUrl: string;
    gdprPermissionGranted: boolean | null;
    allowContactByPhone: boolean | null;
    allowContactByPost: boolean | null;
    allowContactByEmail: boolean | null;
    allowContactBySms: boolean | null;
    allowContactByApp: boolean | null;
    profession: string;
}

export class DemographicsQueryResultViewModel implements IDemographicsQueryResultViewModel {
    id: number;
    fellowshipDate: string | null;
    resignationDate: string | null;
    dateOfBaptism: string | null;
    dateOfFirstVisit: string | null;
    dateOfBaptismRequest: string | null;
    title: string;
    firstName: string;
    lastName: string;
    age: number | null;
    dateOfBirth: string | null;
    dateOfPassing: string | null;
    gender: string;
    maritalStatus: string;
    nationality: string;
    ethnicity: string;
    mobileNumber: string;
    email: string;
    imageUrl: string;
    gdprPermissionGranted: boolean | null;
    allowContactByPhone: boolean | null;
    allowContactByPost: boolean | null;
    allowContactByEmail: boolean | null;
    allowContactBySms: boolean | null;
    allowContactByApp: boolean | null;
    profession: string;

    constructor() {
        this.id = 0;
        this.fellowshipDate = null;
        this.resignationDate = null;
        this.dateOfBaptism = null;
        this.dateOfFirstVisit = null;
        this.dateOfBaptismRequest = null;
        this.title = '';
        this.firstName = '';
        this.lastName = '';
        this.age = null;
        this.dateOfBirth = null;
        this.dateOfPassing = null;
        this.gender = '';
        this.maritalStatus = '';
        this.nationality = '';
        this.ethnicity = '';
        this.mobileNumber = '';
        this.email = '';
        this.imageUrl = '';
        this.gdprPermissionGranted = null;
        this.allowContactByPhone = null;
        this.allowContactByPost = null;
        this.allowContactByEmail = null;
        this.allowContactBySms = null;
        this.allowContactByApp = null;
        this.profession = '';
    }
}

export interface IDemographicsQueryRatioResultViewModel {
    name: string;
    groupName: string;
    tooltip: string;
    value: number;
}

export class DemographicsQueryRatioResultViewModel implements IDemographicsQueryRatioResultViewModel {
    name: string;
    groupName: string;
    tooltip: string;
    value: number;

    constructor() {
        this.name = '';
        this.groupName = '';
        this.tooltip = '';
        this.value = 0;
    }
}

export interface IDemographicsQuerySpreadResultViewModel {
    groupName: string;
    ratios: IDemographicsQueryRatioResultViewModel[];
}

export class DemographicsQuerySpreadResultViewModel implements IDemographicsQuerySpreadResultViewModel {
    groupName: string;
    ratios: DemographicsQueryRatioResultViewModel[];

    constructor() {
        this.groupName = '';
        this.ratios = [];
    }
}

export interface IRunDemographicsQueryViewModel {
    id: number;
    title: string;
    description: string;
    queryType: DemographicsQueryType;
    attendees: IDemographicsQueryResultViewModel[];
    totalNumberOf: number | null;
    youngest: number | null;
    average: number | null;
    oldest: number | null;
    emailList: string;
    ratios: DemographicsQueryRatioResultViewModel[];
    spreads: DemographicsQuerySpreadResultViewModel[];
    isAllQuery: boolean;
    isTotalNumberOfQuery: boolean;
    isAverageQuery: boolean;
    isYoungestQuery: boolean;
    isOldestQuery: boolean;
    isRatioQuery: boolean;
    isSpreadQuery: boolean;
}

export class RunDemographicsQueryViewModel implements IRunDemographicsQueryViewModel {
    id: number;
    title: string;
    description: string;
    queryType: DemographicsQueryType;
    attendees: DemographicsQueryResultViewModel[];
    totalNumberOf: number | null;
    youngest: number | null;
    average: number | null;
    oldest: number | null;
    emailList: string;
    ratios: DemographicsQueryRatioResultViewModel[];
    spreads: DemographicsQuerySpreadResultViewModel[];
    isAllQuery: boolean;
    isTotalNumberOfQuery: boolean;
    isAverageQuery: boolean;
    isYoungestQuery: boolean;
    isOldestQuery: boolean;
    isRatioQuery: boolean;
    isSpreadQuery: boolean;

    constructor() {
        this.id = 0;
        this.title = '';
        this.description = '';
        this.queryType = DemographicsQueryType.All;
        this.attendees = [];
        this.totalNumberOf = null;
        this.youngest = null;
        this.average = null;
        this.oldest = null;
        this.emailList = '';
        this.ratios = [];
        this.spreads = [];
        this.isAllQuery = false;
        this.isTotalNumberOfQuery = false;
        this.isAverageQuery = false;
        this.isYoungestQuery = false;
        this.isOldestQuery = false;
        this.isRatioQuery = false;
        this.isSpreadQuery = false;
    }
}

export class DemographicsQueryViewModel {
    id: number | null;
    name: string;
    ageFrom: number | null;
    ageTo: number | null;
    dateWeddingEnd: string | null;
    dateWeddingStart: string | null;
    dateBaptisedStart: string | null;
    dateBaptisedEnd: string | null;
    dateFellowshippedStart: string | null;
    dateFellowshippedEnd: string | null;
    datePassedAwayStart: string | null;
    datePassedAwayEnd: string | null;
    dateRequestedBaptisedStart: string | null;
    dateRequestedBaptisedEnd: string | null;
    dateResignedStart: string | null;
    dateResignedEnd: string | null;
    dateOfFirstVisitStart: string | null;
    dateOfFirstVisitEnd: string | null;
    description: string;
    queryTypes: string[];
    averageTypes: string[];
    ratioTypes: string[];
    spreadTypes: string[];
    subjects: string[];
    branches: SelectableItem[];
    ministries: SelectableItem[];
    groups: SelectableItem[];
    genders: string[];
    maritalStatuses: string[];
    nationalities: string[];
    ethnicities: string[];
    positions: string[];
    regions: string[];
    cities: string[];
    professions: string[];
    currentStatuses: string[];
    attributes: string[];
    selectedQueryType: string | null | undefined;
    selectedAverageType: string | null | undefined;
    selectedRatioType: string | null | undefined;
    selectedSpreadType: string | null | undefined;
    selectedSubject: string | null | undefined;
    selectedBranches: number[];
    selectedMinistries: number[];
    selectedGroups: number[];
    selectedGenders: string[];
    selectedMaritalStatuses: string[];
    selectedNationalities: string[];
    selectedEthnicities: string[];
    selectedPositions: string[];
    selectedRegions: string[];
    selectedCities: string[];
    selectedProfessions: string[];
    selectedCurrentStatuses: string[];
    selectedAttributes: string[];    
    savedQueries: SelectableItem[];

    constructor() {
        this.id = null;
        this.name = '';
        this.ageFrom = null;
        this.ageTo = null;
        this.dateWeddingEnd = null;
        this.dateWeddingStart = null;
        this.dateBaptisedStart = null;
        this.dateBaptisedEnd = null;
        this.dateFellowshippedStart = null;
        this.dateFellowshippedEnd = null;
        this.datePassedAwayStart = null;
        this.datePassedAwayEnd = null;
        this.dateRequestedBaptisedStart = null;
        this.dateRequestedBaptisedEnd = null;
        this.dateResignedStart = null;
        this.dateResignedEnd = null;
        this.dateOfFirstVisitStart = null;
        this.dateOfFirstVisitEnd = null;
        this.description = '';
        this.queryTypes = [];
        this.averageTypes = [];
        this.ratioTypes = [];
        this.spreadTypes = [];
        this.subjects = [];
        this.branches = [];
        this.ministries = [];
        this.groups = [];
        this.genders = [];
        this.maritalStatuses = [];
        this.nationalities = [];
        this.ethnicities = [];
        this.positions = [];
        this.regions = [];
        this.cities = [];
        this.professions = [];
        this.currentStatuses = [];
        this.attributes = [];
        this.selectedQueryType = undefined;
        this.selectedAverageType = undefined;
        this.selectedRatioType = undefined;
        this.selectedSpreadType = undefined;
        this.selectedSubject = undefined;
        this.selectedBranches = [];
        this.selectedMinistries = [];
        this.selectedGroups = [];
        this.selectedGenders = [];
        this.selectedMaritalStatuses = [];
        this.selectedNationalities = [];
        this.selectedEthnicities = [];
        this.selectedPositions = [];
        this.selectedRegions = [];
        this.selectedCities = [];
        this.selectedProfessions = [];
        this.selectedCurrentStatuses = [];
        this.selectedAttributes = [];
        this.savedQueries = [];
    }
}

export class PieChartDataItem {
    x: string;
    y: number;
    text: string;
    groupName: string;

    constructor() {
        this.x = '';
        this.y = 0;
        this.text = '';
        this.groupName = '';
    }
}

export class NotificationMessage {
    queryId: number;
    category: string;
    entityId: number | null;
    subject: string;
    message: string;
    attachmentUrls: string[];
    contentUrl: string;
    email: boolean;
    sms: boolean;
    app: boolean;
    recipients: number[];

    constructor() {
        this.queryId = 0;
        this.entityId = null;
        this.category = '';
        this.subject = '';
        this.message = '';
        this.attachmentUrls = [];
        this.contentUrl = '';
        this.email = false;
        this.sms = false;
        this.app = true;
        this.recipients = [];
    }
}

export class DemographicsQueryRunner {
    id: number;
    name: string;
    isOwner: boolean;

    constructor() {
        this.id = 0;
        this.name = '';
        this.isOwner = false;
    }
}