import Axios, { AxiosResponse } from "axios";

import { of } from "rxjs";
import { BaseService } from "@/services/base.service";
import { environment } from "@/environment";

export class MediaService extends BaseService {
  constructor() {
    super();
  }

  public uploadFile(file: any, fileName: string): Promise<AxiosResponse<string>> {
    if (!file) {
      return of();
    }
    const payload = new FormData();
    payload.append("file", file, fileName);
    return Axios.post<string>(`${environment.apiUrl}/admin/media`, payload, this.getMultipartHeader());
  }

  public removeFile(fileName: string): Promise<AxiosResponse<boolean>> {
    return Axios.patch<boolean>(`${environment.apiUrl}/admin/media`, fileName, { headers: this.getAuthenticationHeader(), });
  }
}
