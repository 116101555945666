var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.maxWidth, persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.dialogData.title) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onExit()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("div", { staticStyle: { height: "20px" } }),
                  _vm._v(" " + _vm._s(_vm.dialogData.message) + " "),
                  _c("div", { staticStyle: { height: "10px" } }),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasMultipleErrors,
                        expression: "hasMultipleErrors",
                      },
                    ],
                    attrs: {
                      "fixed-header": "",
                      height: "300px",
                      headers: _vm.headers,
                      items: _vm.dialogData.errors,
                      "single-select": true,
                      "items-per-page": 15,
                      "show-select": false,
                      "item-key": "id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.isWarning",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item === "true" ? "Warning" : "Error")
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.dialogData.showCancel
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.onClick(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.showYesNo ? "NO" : "CANCEL") + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onClick(true)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.showYesNo ? "YES" : "OK") + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }