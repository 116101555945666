var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "main-container", fluid: "" } },
    [
      _c("v-row", [
        _c(
          "div",
          { staticClass: "col-md-12 mt-5" },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { lg: "4" } }, [
                  _c("section", { staticClass: "page-header" }, [
                    _c("h5", { staticClass: "wg-title" }, [
                      _vm._v("Communication Manager"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "small",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _vm._v(
                          " Manage your conference-wide communication here "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { md: "auto" } },
                  [
                    _c("action-alert", {
                      attrs: {
                        show: _vm.showAlert,
                        message: _vm.alertMessage,
                        type: _vm.alertType,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "divider" }, [_c("v-divider")], 1),
            _c("v-card", { staticClass: "wg-card-shadow" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2 no-padding" }, [
                  _c(
                    "div",
                    { staticClass: "inner_sidebar" },
                    [
                      _c(
                        "v-list",
                        { attrs: { nav: "" } },
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                "active-class":
                                  "deep-indigo--text text--accent-4",
                              },
                              model: {
                                value: _vm.selectedMenuItem,
                                callback: function ($$v) {
                                  _vm.selectedMenuItem = $$v
                                },
                                expression: "selectedMenuItem",
                              },
                            },
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.id,
                                  staticClass: "list-item",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onMenuItemClick(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-10 content-area" },
                  [
                    _c("panel-demographics-manager", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedMenuItem == 0,
                          expression: "selectedMenuItem == 0",
                        },
                      ],
                      ref: "demographicsmanager",
                      on: { "show-alert-panel": _vm.showSuccessPanel },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }