<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          {{ dialogData.title }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onExit()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <div style="height: 20px" />
          {{ dialogData.message }}
          <div style="height: 10px" />
          <v-data-table
            v-show="hasMultipleErrors"
            fixed-header
            height="300px"
            :headers="headers"
            :items="dialogData.errors"
            :single-select="true"
            :items-per-page="15"
            :show-select="false"
            item-key="id"
          >
            <template v-slot:[`item.isWarning`]="{ item }">
              <span>{{ item === "true" ? "Warning" : "Error" }}</span>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="dialogData.showCancel"
            color="primary"
            text
            @click="onClick(false)"
          >
            {{ showYesNo ? "NO" : "CANCEL" }}
          </v-btn>

          <v-btn color="primary" @click="onClick(true)">
            {{ showYesNo ? "YES" : "OK" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import { DialogMessage, DialogResponse } from "@/models/common.model";
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    dialog: Boolean,
    data: DialogMessage,
    showYesNo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Severity",
          value: "isWarning",
          sortable: true,
          width: 120,
        },
        { text: "Relates To", value: "key", sortable: true },
        { text: "Message", value: "message", sortable: true },
      ],
      dialogData: new DialogMessage("", ""),
    };
  },
  watch: {
    data(val: DialogMessage) {
      this.dialogData = val;
    },
  },
  computed: {
    hasMultipleErrors(): boolean {
      return this.hasManyErrors();
    },
    maxWidth(): string {
      return this.hasManyErrors() ? "50%" : "25%";
    },
  },
  methods: {
    hasManyErrors(): boolean {
      let hasManyErrors: boolean = false;
      if (this.data.errors) {
        if (this.data.errors.length > 0) {
          hasManyErrors = true;
        }
      }
      return hasManyErrors;
    },
    onClick(option: boolean) {
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(option, this.data.entity)
      );
    },
    onExit() {
      if (this.showYesNo) {
        this.$emit("dialog-exit");
      } else {
        this.$emit(
          "dialog-option-selected",
          new DialogResponse(false, this.data.entity)
        );
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
