import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import vuetify from './plugins/vuetify'

import { environment } from "./environment";
import "./assets/css/wg.css";
import { SecurityService } from "./services/security.service";

Vue.config.productionTip = false;

firebase.initializeApp(environment.firebaseConfig);

let app: any = null;

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const token = await user.getIdToken();
    const service = new SecurityService();
    service.getProfile(token)
      .then(async (response) => {
        if (response.data.permissions.length === 0) {
          store.commit("clearUser");
          await firebase.auth().signOut();
        } else {
          await store.dispatch("refreshUser", {
            authenticated: true,
            authenticating: false,
            profile: {
              applicationId: response.data.applicationId,
              displayName: response.data.name ? response.data.name : user.displayName,
              email: user.email,
              photoURL: response.data.imageUrl ? response.data.imageUrl : user.photoURL,
              uid: user.uid,
              token: token,
              permissions: response.data.permissions
            },
          });
        }
      })
      .catch(async () => {
        store.commit("clearUser");
        await firebase.auth().signOut();
        router.replace({ name: "login" });
      });
  }

  if (!app) {    
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount("#app");
  }

});