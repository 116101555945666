var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "main-container" } },
    [
      _c(
        "v-row",
        [
          _c("progress-indicator", {
            attrs: {
              showProgressBar: _vm.showProgressBar,
              showSpinner: _vm.showSpinner,
              message: _vm.progressMessage,
            },
          }),
        ],
        1
      ),
      !_vm.user.authenticated && !_vm.user.authenticating
        ? _c("v-row", [
            _c(
              "div",
              { staticClass: "col-md-12 mt-5" },
              [
                _c("v-row", [
                  _c("section", { staticClass: "page-header" }, [
                    _c("h5", { staticClass: "wg-title" }, [
                      _vm._v("Sorry we can't let you in!"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "small",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _vm._v(
                          " Unfortunately you have not been recognised as an administrator of the system. Please contact the system administrator for assistance. "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticStyle: { height: "40px" } }),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.onCloseClicked },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.user.authenticated && !_vm.user.authenticating
        ? _c("v-row", [
            _c(
              "div",
              { staticClass: "col-md-6 mt-5" },
              [
                _c("section", { staticClass: "page-header" }, [
                  _c("h5", { staticClass: "wg-title" }, [_vm._v("Home")]),
                  _c(
                    "p",
                    {
                      staticClass: "medium",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    [_vm._v(" Welcome to the COG7D Church Management System! ")]
                  ),
                ]),
                _c("div", { staticClass: "divider" }, [_c("v-divider")], 1),
                _c(
                  "v-card",
                  { staticClass: "wg-card-shadow" },
                  [
                    _c("v-img", {
                      attrs: {
                        "max-height": "512",
                        "max-width": "512",
                        src: _vm.imageUrl,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }