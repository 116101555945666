<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4">
        <v-btn color="success" @click="onNewItem">New Query</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created any queries. Create your first
              one by clicking the New Query button.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> My Queries </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :calculate-widths="true"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            v-model="selectedItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        icon
                        @click="onNotifyItem(item)"
                        :disabled="!item.isNotifyable"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-bullhorn-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Send a message to this group</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.isOwner"
                        color="success"
                        icon
                        @click="onEditItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit this Query</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.isOwner"
                        color="primary"
                        icon
                        @click="onRunItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-play </v-icon>
                      </v-btn>
                    </template>
                    <span>Run this Query</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.isOwner"
                        color="primary"
                        icon
                        @click="onManageUsers(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-account-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Manage access to this Query</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.isOwner"
                        color="error"
                        icon
                        @click="onDeleteItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Remove this Query</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <demographicsQuery-permissions
      :dialog="showDemographicsQueryDialog"
      @ok-clicked="demographicsDialogConfirmed"
      ref="demographicpermissionsdialog"
    >
    </demographicsQuery-permissions>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <query-result-dialog
      :dialog="showResultDialog"
      :data="queryResult"
      @dialog-closed="closeQueryResultDialog"
    >
    </query-result-dialog>
    <query-result-message-sender-dialog
      :dialog="showSendDialog"
      :data="queryResult"
      @dialog-closed="onSendDialogClosed"
    >
    </query-result-message-sender-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DemographicsMixin from "@/mixins/demographics.mixin";
import queryResultDialog from "@/components/demographics/query-result-dialog.vue";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { LoadingType } from "@/models/common.model";
import { DemographicsService } from "@/services/demographics.service";
import {
  IDemographicsQueryItem,
  DemographicsQueryRunner,
  RunDemographicsQueryViewModel,
} from "@/models/demographics.model";
import QueryResultMessageSenderDialog from "@/components/demographics/query-result-message-sender-dialog.vue";
import QueryPermissionsDialog from "@/components/demographics/query-permissions-dialog.vue";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof CommonMixin> &
      InstanceType<typeof DemographicsMixin>
  >
).extend({
  name: "ListDemographicQueries",
  mixins: [CommonMixin, DemographicsMixin],
  components: {
    queryResultDialog,
    QueryResultMessageSenderDialog,
    "demographicsQuery-permissions": QueryPermissionsDialog,
  },
  data() {
    return {
      showDeleteDialog: false,
      showSendDialog: false,
      selectedQueryId: 0,
      selectedQueryDescription: "",
      search: "",
      headers: [
        { text: "Query", value: "name", sortable: true, width: 80 },
        { text: "Description", value: "description", width: 120 },
        { text: "Can be notified", value: "isNotifyable", width: 80 },
        { text: "Actions", value: "actions", sortable: false, width: 180 },
      ],
      items: Array<IDemographicsQueryItem>(),
      selectedItems: Array<IDemographicsQueryItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      queryResult: new RunDemographicsQueryViewModel(),
      showResultDialog: false,
      showDemographicsQueryDialog: false,
    };
  },
  methods: {
    onNewItem() {
      this.$emit("new-item-requested");
    },

    onEditItem(item: IDemographicsQueryItem) {
      this.$emit("show-item", item.id);
    },

    onDeleteItem(item: IDemographicsQueryItem) {
      this.showDeleteConfirmationDialog(item);
    },

    onRunItem(item: IDemographicsQueryItem) {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Running Query, Please Wait..."
      );

      const service = new DemographicsService();
      service
        .runQuery(item.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.queryResult = response.data;
          this.showResultDialog = true;
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onNotifyItem(item: IDemographicsQueryItem) {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Running Query, Please Wait..."
      );

      const service = new DemographicsService();
      service
        .runQuery(item.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.queryResult = response.data;
          this.showSendDialog = true;
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    getItems() {
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Queries, Please Wait...`
      );

      const service = new DemographicsService();
      service
        .getQueries()
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: IDemographicsQueryItem) {
      this.dialogMessage = new DialogMessage(
        `Delete query ${item.name}?`,
        `Are you sure you want to delete the query? This item will removed from the records and no longer available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing position, Please Wait..."
        );
        const service = new DemographicsService();
        service
          .removeQuery(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    closeQueryResultDialog() {
      this.showResultDialog = false;
    },

    onSendDialogClosed() {
      this.showSendDialog = false;
    },

    onManageUsers(item: IDemographicsQueryItem) {
      this.selectedQueryId = item.id;
      const ref: any = this.$refs.demographicpermissionsdialog;
      if (ref) {
        ref.getItems(this.selectedQueryId);
        this.showDemographicsQueryDialog = true;
      }
    },

    demographicsDialogConfirmed() {
      this.showDemographicsQueryDialog = false;
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>