import { render, staticRenderFns } from "./list-events.vue?vue&type=template&id=74406ff8&scoped=true"
import script from "./list-events.vue?vue&type=script&lang=ts"
export * from "./list-events.vue?vue&type=script&lang=ts"
import style0 from "./list-events.vue?vue&type=style&index=0&id=74406ff8&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74406ff8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCalendar,VCol,VContainer,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VSheet,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74406ff8')) {
      api.createRecord('74406ff8', component.options)
    } else {
      api.reload('74406ff8', component.options)
    }
    module.hot.accept("./list-events.vue?vue&type=template&id=74406ff8&scoped=true", function () {
      api.rerender('74406ff8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lists/list-events.vue"
export default component.exports