var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("date-picker", {
            attrs: {
              date: _vm.date,
              format: _vm.format,
              clearable: _vm.clearable,
              outlined: _vm.outlined,
              disabled: _vm.disabled,
              label: _vm.dateLabel,
              dateRules: _vm.dateRules,
              showHeader: _vm.showDateHeader,
              prependIcon: _vm.datePrependIcon,
            },
            on: {
              "update:date": function ($event) {
                _vm.date = $event
              },
              "date-changed": _vm.onDateOrTimeChanged,
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "4" } },
        [
          _c("time-picker", {
            attrs: {
              time: _vm.time,
              clearable: _vm.clearable,
              outlined: _vm.outlined,
              disabled: _vm.disabled,
              label: _vm.timeLabel,
              timeRules: _vm.timeRules,
              prependIcon: _vm.timePrependIcon,
            },
            on: {
              "update:time": function ($event) {
                _vm.time = $event
              },
              "time-changed": _vm.onDateOrTimeChanged,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }