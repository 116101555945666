var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Quarterly Return"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details which quarterly return is being managed. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.years,
                          label: "Year",
                          placeholder: "Select the year",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                          outlined: "",
                        },
                        model: {
                          value: _vm.formData.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "year", $$v)
                          },
                          expression: "formData.year",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.quarters,
                          label: "Quarter",
                          placeholder: "Select the quarter",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                          outlined: "",
                        },
                        model: {
                          value: _vm.formData.quarter,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "quarter", $$v)
                          },
                          expression: "formData.quarter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Current Assets"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details the quarterly return current assets. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Balance (Current Accounts)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.currentAccountsTotalBalance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "currentAccountsTotalBalance",
                              $$v
                            )
                          },
                          expression: "formData.currentAccountsTotalBalance",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Balance (Deposit Accounts)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.depositAccountsTotalBalance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "depositAccountsTotalBalance",
                              $$v
                            )
                          },
                          expression: "formData.depositAccountsTotalBalance",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Balance (Credit Union)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.creditUnionAccountBalance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "creditUnionAccountBalance",
                              $$v
                            )
                          },
                          expression: "formData.creditUnionAccountBalance",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Balance (Other Accounts)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.otherAccountsBalance,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "otherAccountsBalance", $$v)
                          },
                          expression: "formData.otherAccountsBalance",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("div", { staticStyle: { height: "20px" } })]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Fixed Assets"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details the quarterly return fixed asset valuations. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Building (Insurance Value)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.buildingValuationInsuranceValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "buildingValuationInsuranceValue",
                              $$v
                            )
                          },
                          expression:
                            "formData.buildingValuationInsuranceValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Building (Market Value)",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.buildingValuationMarketValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "buildingValuationMarketValue",
                              $$v
                            )
                          },
                          expression: "formData.buildingValuationMarketValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Other Assets",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.otherAssetsValuation,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "otherAssetsValuation", $$v)
                          },
                          expression: "formData.otherAssetsValuation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Other Long Term Investments",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.otherLongTermInvestments,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "otherLongTermInvestments",
                              $$v
                            )
                          },
                          expression: "formData.otherLongTermInvestments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("div", { staticStyle: { height: "20px" } })]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Income"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [_vm._v(" This section details the quarterly return income. ")]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Tithes & Offering",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalTithesAndOffering,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "totalTithesAndOffering",
                              $$v
                            )
                          },
                          expression: "formData.totalTithesAndOffering",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Gift Aid Receipts",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalGiftAidReceipts,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "totalGiftAidReceipts", $$v)
                          },
                          expression: "formData.totalGiftAidReceipts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Loans Recovered",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalLoansRecovered,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "totalLoansRecovered", $$v)
                          },
                          expression: "formData.totalLoansRecovered",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Other Income",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalOtherIncome,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "totalOtherIncome", $$v)
                          },
                          expression: "formData.totalOtherIncome",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("div", { staticStyle: { height: "20px" } })]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Expenditure"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section details the quarterly return expenditure. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Normal Running Expenses",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalRunningExpenses,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "totalRunningExpenses", $$v)
                          },
                          expression: "formData.totalRunningExpenses",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Extraordinary Projects",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.extraordinaryProjectsExpenditure,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "extraordinaryProjectsExpenditure",
                              $$v
                            )
                          },
                          expression:
                            "formData.extraordinaryProjectsExpenditure",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Conference Contributions",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.totalConferenceContributions,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "totalConferenceContributions",
                              $$v
                            )
                          },
                          expression: "formData.totalConferenceContributions",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Convention Fees",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.conventionFees,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "conventionFees", $$v)
                          },
                          expression: "formData.conventionFees",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Loans Issued",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          type: "number",
                          outlined: "",
                          prefix: "£",
                        },
                        model: {
                          value: _vm.formData.loansIssued,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "loansIssued", $$v)
                          },
                          expression: "formData.loansIssued",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }