<template>
  <v-container fluid>
    <v-row>
      <v-col md="3">
        <v-btn class="ma-2" color="success" @click="onRefresh">Refresh</v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onRemoveMultipleItems"
              :disabled="!hasSelectedItems"
              ><v-icon>mdi-delete-sweep-outline</v-icon></v-btn
            >
          </template>
          <span>Remove Selected</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="onRemoveAllItems"
              :disabled="!hasItems"
              ><v-icon>mdi-delete-empty-outline</v-icon></v-btn
            >
          </template>
          <span>Remove All</span>
        </v-tooltip>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col md="8">
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you there is no media at present. Check for the
              latest entries by clicking Refresh.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-data-iterator
          v-show="showGrid"
          item-key="id"
          :items="items"
          :items-per-page.sync="itemsPerPage"
          :footer-props="footerOptions"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    max-width="400"
                    :elevation="!multiSelect && hover ? 12 : 3"
                    :outlined="!multiSelect && hover"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-img
                      max-height="240"
                      :src="item.imageUrl"
                      :lazy-src="placeholder"
                      :class="[!multiSelect ? imageCss : '']"
                      @click="!multiSelect"
                      aspect-ratio="1"
                    >
                    </v-img>

                    <v-card-title>
                      {{ limitText(item.name, 27) }}
                    </v-card-title>
                    <v-card-subtitle class="pb-0">{{
                      item.contentType
                    }}</v-card-subtitle>

                    <v-card-actions>
                      <v-checkbox
                        v-show="multiSelect"
                        v-model="item.selected"
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click.stop="onEditItem(item)"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click.stop="onRemoveItem(item)"
                      >
                        Remove
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteSingleItemDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteSingleItemSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteMultipleItemsDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteMultipleItemsSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteAllItemsDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteAllItemsSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showRefreshConfirmationDialog"
      :data="dialogMessage"
      :showYesNo="true"
      @dialog-option-selected="onRefreshOptionSelected"
      @dialog-exit="onRefreshDialogExit"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { DataOptions } from "vuetify";
import { ContributorContent } from "@/models/page.model";
import { PageService } from "@/services/page.service";
import {
  DialogMessage,
  DialogResponse,
  LoadingType,
} from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ContributionSocialMediaSection",
  mixins: [CommonMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hasItems(): boolean {
      return this.items.length > 0;
    },
    hasSelectedItems(): boolean {
      const items = this.items.filter((f) => f.selected);
      return items.length > 0;
    },
  },
  watch: {
    options: {
      handler() {
        this.onSearch();
      },
      deep: true,
    },
  },
  data() {
    return {
      showDeleteSingleItemDialog: false,
      showDeleteMultipleItemsDialog: false,
      showDeleteAllItemsDialog: false,
      showRefreshConfirmationDialog: false,
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      items: new Array<ContributorContent>(),
      multiSelect: true,
      showGrid: false,
      showEmptyView: false,
    };
  },
  methods: {
    resetData() {
      this.items = [];
      this.totalItems = 0;
      this.showDeleteSingleItemDialog = false;
      this.showDeleteMultipleItemsDialog = false;
      this.showDeleteAllItemsDialog = false;
      this.showRefreshConfirmationDialog = false;
    },

    onSearch() {
      this.resetData();

      if (this.id > 0) {
        const { page, itemsPerPage }: DataOptions = this.options;

        // If the search is being called from a dialog property watch
        // we may not have the data options. In this case take it from
        // the default values we have set up in the data section.
        let pageNumber = page ? page : 1;
        let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

        let service = new PageService();
        service
          .getContributorContent(this.id, pageNumber, perPage)
          .then((response) => {
            this.items = response.data.items;
            this.totalItems = response.data.totalCount;

            this.showGrid = response.data.items.length > 0;
            this.showEmptyView = response.data.items.length == 0;
          });
      }
    },

    onEditItem(item: ContributorContent) {
      this.$router.push({
        name: "host-media-item",
        query: {
          contributorId: this.id.toString(),
          id: item.id.toString(),
        },
      });
    },

    onRemoveItem(item: ContributorContent) {
      this.showDeleteSingleItemConfirmationDialog(item);
    },

    onRemoveMultipleItems() {
      this.showDeleteMultipleItemsConfirmationDialog();
    },

    onRemoveAllItems() {
      this.showDeleteAllItemsConfirmationDialog();
    },

    onRefresh() {
      this.showRefreshClearDownConfirmationDialog();
    },

    onRefreshDialogExit() {
      this.showRefreshConfirmationDialog = false;
    },

    showRefreshClearDownConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Remove existing items?`,
        `Do you want to remove all the existing media items before you perform the refresh?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.id;
      this.dialogMessage.errors = [];
      this.showRefreshConfirmationDialog = true;
    },

    showDeleteSingleItemConfirmationDialog(item: ContributorContent) {
      this.dialogMessage = new DialogMessage(
        `Delete item?`,
        `Are you sure you want to delete ${item.name}? This item will be removed from the media list and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteSingleItemDialog = true;
    },

    showDeleteMultipleItemsConfirmationDialog() {
      let selectedItems = this.items.filter((f) => f.selected).map((m) => m.id);
      this.dialogMessage = new DialogMessage(
        `Delete ${selectedItems.length} items?`,
        `Are you sure you want to delete the ${selectedItems.length} selected items? These items will be removed from the media list and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = selectedItems;
      this.dialogMessage.errors = [];
      this.showDeleteMultipleItemsDialog = true;
    },

    showDeleteAllItemsConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Delete ALL items?`,
        `Are you sure you want to delete all the items in this media? These items will be removed from the list and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.id;
      this.dialogMessage.errors = [];
      this.showDeleteAllItemsDialog = true;
    },

    onDeleteSingleItemSelected(item: DialogResponse) {
      this.showDeleteSingleItemDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing item, Please Wait..."
        );
        const service = new PageService();
        service
          .removeSelectedContributorMedia(this.id, [item.entity.id])
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onDeleteMultipleItemsSelected(item: DialogResponse) {
      this.showDeleteMultipleItemsDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          `Removing ${item.entity.length} selected items, Please Wait...`
        );
        const service = new PageService();
        service
          .removeSelectedContributorMedia(this.id, item.entity)
          .then(() => {
            this.hideProgressIndicator();
            this.onSearch();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onDeleteAllItemsSelected(item: DialogResponse) {
      this.showDeleteAllItemsDialog = false;

      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing all items, Please Wait..."
        );
        const service = new PageService();
        service
          .removeAllContributorMedia(item.entity)
          .then(() => {
            this.hideProgressIndicator();
            this.onSearch();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onRefreshOptionSelected(item: DialogResponse) {
      this.showRefreshConfirmationDialog = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Refreshing items, Please Wait..."
      );
      const service = new PageService();
      service
        .refreshContributorMedia(item.entity, item.option)
        .then(() => {
          this.hideProgressIndicator();
          this.onSearch();
        })
        .catch((error) => this.showErrorDialog(error));
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>