<template>
  <v-container fluid>
    <v-dialog v-model="dialog" max-width="30%" persistent>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            Event Letters
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onCancel">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <v-file-input
                  :rules="validateFile"
                  accept="application/pdf"
                  label="Letter"
                  placeholder="Click here to select a letter to upload"
                  prepend-icon=""
                  v-model="selectedFileForUpload"
                  outlined
                  clearable
                  :disabled="!allowFileSelection"
                  @change="onLetterChanged"
                  hide-details="auto"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Title"
                  placeholder="Enter a title or select a letter below"
                  :outlined="true"
                  :disabled="!canEditProperties"
                  hide-details="auto"
                  clearable
                  v-model="letter.title"
                  :rules="validateTitle"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="auto">
                <date-picker
                  :date.sync="letter.publishedOn"
                  :dateRules="validateDate"
                  :disabled="!canEditProperties"
                  label="Published On"
                ></date-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onCancel">Cancel</v-btn>
            <v-btn color="primary" text type="submit" :disabled="!valid">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { EventLetter, EventViewModel } from "@/models/events.model";
import { DateTime } from "luxon";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import { LoadingType } from "@/models/common.model";
import { MediaService } from "@/services/media.service";
import { EventsService } from "@/services/events.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventLetterEditor",
  mixins: [CommonMixin],
  components: {
    "date-picker": DatePicker,
  },

  props: {
    dialog: Boolean,
    entity: {
      type: Object,
      default: () => new EventLetter(),
    },
    mainEvent: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      letter: new EventLetter(),
      selectedFileForUpload: null as any,
      allowFileSelection: false,
    };
  },

  watch: {
    entity: {
      deep: true,
      immediate: true,
      handler(val: EventLetter) {
        this.letter = this.clone(val);
      },
    },

    dialog(val: boolean) {
      if (val === true) {
        this.selectedFileForUpload = null;
        const ref: any = this.$refs.manageForm;
        if (ref) {
          ref.validate();
        }
      }
    },
  },

  computed: {
    validateDate() {
      let rules = [];

      if (!this.letter.publishedOn) {
        rules.push("The date the letter was published has not been specified.");
      } else {
        const start: DateTime = DateTime.fromISO(
          this.letter.publishedOn?.toString()!
        );

        let ev: EventViewModel = this.mainEvent;
        const evEnd: DateTime = DateTime.fromISO(ev.endDateTime.toString());

        const evEndDiff: number = start.diff(evEnd, "days").days;
        if (evEndDiff > 0) {
          rules.push("Publication date cannot be after the end of the event.");
        }
      }

      return rules;
    },

    validateTitle() {
      let rules = [];

      if (!this.letter.title) {
        rules.push(
          "You must give this letter a title. Enter one or select a file."
        );
      } else {
        let ev: EventViewModel = this.mainEvent;
        for (let l of ev.letters) {
          if (
            l.id != this.letter.id &&
            l.title.toLowerCase() == this.letter.title.toLowerCase()
          ) {
            rules.push("Another letter already has this as their title.");
            break;
          }
        }
      }
      return rules;
    },

    validateFile() {
      let err: string[] = [];

      if (this.selectedFileForUpload) {
        let file: any = this.selectedFileForUpload;
        if (file.size > 15000000) {
          err.push("Total size cannot be more than 15 MB!");
        }
      } else {
        if (!this.letter.letterUrl) {
          err.push(
            "You must select a letter. Click in the box to select a file."
          );
        }
      }

      return err;
    },

    hasFileSelected(): boolean {
      let file: any = this.selectedFileForUpload;
      if (file) {
        return true;
      } else {
        return false;
      }
    },

    canEditProperties(): boolean {
      if (this.letter.id == 0) {
        return this.hasFileSelected;
      } else {
        return true;
      }
    },
  },

  methods: {
    submitForm() {
      let isNewLetter = this.letter.id == 0;
      if (isNewLetter) {
        this.saveNewLetter();
      } else {
        this.saveExistingLetter();
      }
    },

    saveNewLetter() {
      let ev: EventViewModel = this.mainEvent;

      let file: any = this.selectedFileForUpload;
      if (file) {
        if (file.size > 0) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Uploading letter ${file.name}, Please Wait...`
          );

          const mediaService = new MediaService();
          mediaService
            .uploadFile(file, file.name)
            .then((response) => {
              this.letter.letterUrl = response.data;

              this.showProgressIndicator(
                LoadingType.Panel,
                `Saving details, Please Wait...`
              );

              // If the process was successful then close this dialog
              // Otherwise attempt to remove the uploaded letter (if this is a new letter)
              const service = new EventsService();
              service
                .saveLetter(ev.id!, this.letter)
                .then((response) => {
                  this.allowFileSelection = false;
                  this.letter = response.data;
                  this.hideProgressIndicator();
                  this.$emit("on-dialog-save", this.letter);
                })
                .catch(async (error) => {
                  if (this.letter.letterUrl) {
                    const mediaService = new MediaService();
                    mediaService
                      .removeFile(this.letter.letterUrl)
                      .catch((error) => this.showErrorDialog(error))
                      .finally(() => this.hideProgressIndicator());
                  }
                  this.showErrorDialog(error);
                });
            })
            .catch((error) => this.showErrorDialog(error))
            .finally(() => this.hideProgressIndicator());
        }
      }
    },

    saveExistingLetter() {
      let ev: EventViewModel = this.mainEvent;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Saving details, Please Wait...`
      );

      // If the process was successful then close this dialog
      // Otherwise attempt to remove the uploaded letter (if this is a new letter)
      const service = new EventsService();
      service
        .saveLetter(ev.id!, this.letter)
        .then((response) => {
          this.allowFileSelection = false;
          this.letter = response.data;
          this.hideProgressIndicator();
          this.$emit("on-dialog-save", this.letter);
        })
        .catch(async (error) => {
          if (this.letter.letterUrl) {
            const mediaService = new MediaService();
            mediaService
              .removeFile(this.letter.letterUrl)
              .catch((error) => this.showErrorDialog(error))
              .finally(() => this.hideProgressIndicator());
          }
          this.showErrorDialog(error);
        })
        .finally(() => {
          this.hideProgressIndicator();
        });
    },

    onCancel() {
      this.selectedFileForUpload = null;
      this.$emit("on-dialog-cancel");
    },

    clone(val: EventLetter): EventLetter {
      let cloned = new EventLetter();

      this.allowFileSelection = false;

      if (val) {
        cloned.id = val.id;
        cloned.letterUrl = val.letterUrl;
        cloned.publishedOn = val.publishedOn;
        cloned.title = val.title;
        this.allowFileSelection = val.id == 0;
      }

      return cloned;
    },

    onClearLetterUrl() {
      this.selectedFileForUpload = null;
    },

    onLetterChanged(item: File) {
      if (this.entity) {
        this.entity.title = item.name;
      }
    },
  },
});
</script>
</script>
<style scoped src="@/assets/css/wg.css"></style>