var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "30%", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "manageForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        flat: "",
                        dense: "",
                        elevation: "1",
                        color: "#E5E3E3",
                      },
                    },
                    [
                      _vm._v(" Event Letters "),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          on: { click: _vm.onCancel },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Close")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-file-input", {
                                attrs: {
                                  rules: _vm.validateFile,
                                  accept: "application/pdf",
                                  label: "Letter",
                                  placeholder:
                                    "Click here to select a letter to upload",
                                  "prepend-icon": "",
                                  outlined: "",
                                  clearable: "",
                                  disabled: !_vm.allowFileSelection,
                                  "hide-details": "auto",
                                },
                                on: { change: _vm.onLetterChanged },
                                model: {
                                  value: _vm.selectedFileForUpload,
                                  callback: function ($$v) {
                                    _vm.selectedFileForUpload = $$v
                                  },
                                  expression: "selectedFileForUpload",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Title",
                                  placeholder:
                                    "Enter a title or select a letter below",
                                  outlined: true,
                                  disabled: !_vm.canEditProperties,
                                  "hide-details": "auto",
                                  clearable: "",
                                  rules: _vm.validateTitle,
                                },
                                model: {
                                  value: _vm.letter.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.letter, "title", $$v)
                                  },
                                  expression: "letter.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "auto" } },
                            [
                              _c("date-picker", {
                                attrs: {
                                  date: _vm.letter.publishedOn,
                                  dateRules: _vm.validateDate,
                                  disabled: !_vm.canEditProperties,
                                  label: "Published On",
                                },
                                on: {
                                  "update:date": function ($event) {
                                    return _vm.$set(
                                      _vm.letter,
                                      "publishedOn",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            type: "submit",
                            disabled: !_vm.valid,
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }