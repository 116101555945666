var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "prepend-icon": _vm.prependIcon,
                            label: _vm.label,
                            clearable: _vm.clearable,
                            outlined: _vm.outlined,
                            disabled: _vm.disabled,
                            rules: _vm.dateRules,
                            "hide-details": "auto",
                            readonly: "",
                          },
                          on: { "click:clear": _vm.onDateCleared },
                          model: {
                            value: _vm.textFieldDisplay,
                            callback: function ($$v) {
                              _vm.textFieldDisplay = $$v
                            },
                            expression: "textFieldDisplay",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              "year-icon": "mdi-calendar-blank",
              elevation: "8",
              "no-title": !_vm.showHeader,
            },
            on: { input: _vm.onDateSelected },
            model: {
              value: _vm.datePickerDisplay,
              callback: function ($$v) {
                _vm.datePickerDisplay = $$v
              },
              expression: "datePickerDisplay",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }