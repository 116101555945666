var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "20%", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "manageForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("div", [_vm._v("Create New User Role")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCancel()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.members,
                                  loading: _vm.isMatching,
                                  "search-input": _vm.matching,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  clearable: "",
                                  label: "Member",
                                  placeholder: "Member",
                                  "menu-props": "auto",
                                  "hide-details": "auto",
                                  "error-messages": _vm.validateMemberSelection,
                                  "return-object": "",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.matching = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.matching = $event
                                  },
                                },
                                model: {
                                  value: _vm.selectedMember,
                                  callback: function ($$v) {
                                    _vm.selectedMember = $$v
                                  },
                                  expression: "selectedMember",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.roles,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  clearable: "",
                                  label: "Role",
                                  placeholder: "Role",
                                  "menu-props": "auto",
                                  "hide-details": "auto",
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.roleId,
                                  callback: function ($$v) {
                                    _vm.roleId = $$v
                                  },
                                  expression: "roleId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onCancel()
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled: !_vm.valid,
                          },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }