var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Exposure"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " State whether the event should be public on one more more platforms. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "On National Calendar",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.formData.isOnNationalCalendar,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isOnNationalCalendar", $$v)
                          },
                          expression: "formData.isOnNationalCalendar",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Duration"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " State how long this event should be given promotional priority i.e. placed on banners and front pages, before it reverts back to a regular listing. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          showHeader: false,
                          outlined: true,
                          date: _vm.formData.promotionStart,
                          dateRules: _vm.validatePromotionStart,
                          label: "Promote From",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "promotionStart",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          showHeader: false,
                          outlined: true,
                          date: _vm.formData.promotionEnd,
                          dateRules: _vm.validatePromotionEnd,
                          label: "Promote To",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "promotionEnd",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }