export class ValidOptionListItem {
    id: number;
    category: string;
    option: string;

    constructor() {
        this.id = 0;
        this.category = '';
        this.option = '';
    }
}

export class ManageValidOptionListViewModel {
    category: string;
    options: ValidOptionListItem[];
    categories: string[];

    constructor() {
        this.category = '';
        this.options = [];
        this.categories = [];
    }
}

export class ManageValidOptionViewModel {
    id: number;
    category: string;
    option: string;

    constructor() {
        this.id = 0;
        this.category = '';
        this.option = '';
    }
}

export class PeopleFilterOptionsViewModel {
    maritalStatus: string[];
    ethnicity: string[];
    profession: string[];
    nationality: string[];
    gender: string[];
    isMember: string[];
    contactable: string[];
    attributes: string[];
    currentStatus: string[];

    constructor() {
        this.maritalStatus = [];
        this.ethnicity = [];
        this.profession = [];
        this.nationality = [];
        this.gender = [];
        this.isMember = [];
        this.contactable = [];
        this.attributes = [];
        this.currentStatus = [];
    }
}