<template>
  <v-container fluid>
    <v-form ref="manageUserForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="3">
                <v-text-field
                  outlined
                  filled
                  v-model="displayName"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      icon
                      @click="onCancel"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      icon
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshScreen"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
                <v-btn color="primary" type="submit" :disabled="!valid" large>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="5">
          <v-tabs v-model="tab">
            <v-tab>General</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <contribution-social-media-general-section
                ref="generalsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </contribution-social-media-general-section>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ContributorContent } from "@/models/page.model";
import { PageService } from "@/services/page.service";
import { AlertType, LoadingType } from "@/models/common.model";
import ContributionSocialMediaGeneralSection from "@/views/editors/editor-contribution-general.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostMediaItem",
  mixins: [CommonMixin],
  components: {
    "contribution-social-media-general-section":
      ContributionSocialMediaGeneralSection,
  },
  computed: {
    displayName(): string {
      let name: string = "";
      if (this.formData) {
        name = this.formData.name;
      }
      return name;
    },
  },

  data() {
    return {
      tab: 0,
      valid: true,
      ownerId: 0,
      id: 0,
      formData: new ContributorContent(),
    };
  },

  created() {
    if (this.$route.query.contributorId) {
      this.ownerId = parseInt(this.$route.query.contributorId.toString());
    }
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id.toString());
    }

    this.getItem();
  },

  methods: {
    refreshScreen() {
      this.getItem();
    },

    resetData() {
      this.formData = new ContributorContent();
      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem() {
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Item Details, Please Wait..."
      );

      const service = new PageService();
      service
        .getContributorContentItem(this.ownerId, this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Details, Please Wait..."
      );

      const service = new PageService();
      service
        .saveContributorMedia(
          this.ownerId,
          this.id,
          this.formData.name,
          this.formData.description,
          this.formData.tags,
          this.formData.publicationStart,
          this.formData.publicationEnd,
          this.formData.updatedOn
        )
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          this.showSuccessPanel(
            "The details were saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      this.$router.go(-1);
    },

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },
  },
});
</script>