<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn color="success" @click="onNewItem">New Position</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created a position yet. Create your
              first one by clicking the New Position.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> Position </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :items-per-page="15"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            :sort-by="[]"
            :sort-desc="[]"
            multi-sort
            v-model="selectedItems"
          >
            <template v-slot:[`item.isMainPosition`]="{ item }">
              <v-simple-checkbox
                v-model="item.isMainPosition"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.dateStarted`]="{ item }">
              {{ formatDate(item.dateStarted) }}
            </template>
            <template v-slot:[`item.dateCompleted`]="{ item }">
              {{ formatDate(item.dateCompleted) }}
            </template>
            <template v-slot:[`item.expirationDate`]="{ item }">
              <div
                :style="[
                  soonToExpire(item.expirationDate)
                    ? { color: 'red', 'font-weight': 'bold' }
                    : { color: 'black' },
                ]"
              >
                {{ formatDate(item.expirationDate) }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col>
                  <v-btn color="success" icon @click="onEditItem(item)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { PositionListItem } from "@/models/group.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { DateTime } from "luxon";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListPositions",
  mixins: [CommonMixin],
  data() {
    return {
      showDeleteDialog: false,
      search: "",
      headers: [
        { text: "Name", value: "heldBy", sortable: true },
        { text: "Position", value: "title", sortable: true },
        { text: "Main Position", value: "isMainPosition", sortable: true },
        { text: "Date Started", value: "dateStarted", sortable: true },
        { text: "Due To Expire On", value: "expirationDate", sortable: true },
        { text: "Date Completed", value: "dateCompleted", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: Array<PositionListItem>(),
      selectedItems: Array<PositionListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      ownerId: 0,
    };
  },
  methods: {
    onNewItem() {
      this.$emit("new-item-requested");
    },

    onEditItem(item: PositionListItem) {
      this.$emit("show-item", item.id);
    },

    onDeleteItem(item: PositionListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems(ownerId: number) {
      this.ownerId = ownerId;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Positions, Please Wait...`
      );

      const service = new GroupService();
      service
        .getPositions(this.ownerId)
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: PositionListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete position held by ${item.heldBy}?`,
        `Are you sure you want to delete the ${item.title} position held by ${item.heldBy}? This item will removed from the records and no longer available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing position, Please Wait..."
        );
        const service = new GroupService();
        service
          .removePosition(this.ownerId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    soonToExpire(value: Date): boolean {
      if (value) {
        const monthsRemaining = Math.round(
          DateTime.fromISO(value.toString()).diff(DateTime.now(), ["months"])
            .months
        );
        return monthsRemaining <= 6;
      } else {
        return false;
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>