var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "3", xl: "2" } },
            [
              _c("h6", [
                _c("span", { staticClass: "number" }, [_vm._v("1")]),
                _vm._v("About"),
              ]),
              _c(
                "p",
                {
                  staticClass: "small",
                  staticStyle: { "padding-right": "40px" },
                },
                [_vm._v(" This section is a detail the general information ")]
              ),
              _c("v-img", {
                staticClass: "imageCursor",
                attrs: {
                  contain: "",
                  src: _vm.imageUrl,
                  "lazy-src": _vm.placeholder,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                          label: "Title",
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                          label: "Description",
                          rows: "10",
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          clearable: "",
                          items: _vm.formData.allTags,
                          label: "Tags",
                          multiple: "",
                        },
                        model: {
                          value: _vm.formData.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "tags", $$v)
                          },
                          expression: "formData.tags",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Publication"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to determine when this content is available "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          showHeader: false,
                          outlined: true,
                          date: _vm.formData.publicationStart,
                          dateRules: _vm.validatePublicationStart,
                          label: "Publish From",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "publicationStart",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          showHeader: false,
                          outlined: true,
                          date: _vm.formData.publicationEnd,
                          dateRules: _vm.validatePublicationEnd,
                          label: "Publish Until",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "publicationEnd",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }