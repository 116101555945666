<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">3</span>Contact Details</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide contact information for notifying the user.
          Notifications are subject to the user's GDPR preferences.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <map-autocomplete
              :id="viewModel.location.localId"
              :address="viewModel.location.address"
              :ref="viewModel.location.localId"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
              placeholder="Address"
              @addressChanged="onAddressChange"
            >
            </map-autocomplete>
          </v-col>
        </v-row>
        <v-row v-for="contact of tmpContactNumbers" :key="contact.id">
          <v-col>
            <v-text-field
              v-model="contact.value"
              :label="contact.label"
              :placeholder="contact.label"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
              :rules="[rules.phone]"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addContactNumber"
                  :disabled="!canViewSensitiveData"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone </v-icon>
                </v-btn>
              </template>
              <span>add number</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!canViewSensitiveData"
                  @click="removeContactNumber(item)"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone-remove </v-icon>
                </v-btn>
              </template>
              <span>remove number</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-for="item of tmpEmails" :key="item.label">
          <v-col>
            <v-text-field
              v-model="item.value"
              :label="item.label"
              :placeholder="item.label"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addEmail"
                  :disabled="!canViewSensitiveData"
                  hide-details="auto"
                >
                  <v-icon> mdi-email-plus-outline </v-icon>
                </v-btn>
              </template>
              <span>add email</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!canViewSensitiveData"
                  @click="removeEmail(item)"
                  hide-details="auto"
                >
                  <v-icon> mdi-email-remove-outline </v-icon>
                </v-btn>
              </template>
              <span>remove email</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <member-combobox
              :name.sync="viewModel.emergencyContactName1"
              :member.sync="viewModel.emergencyContact1"
              :isMember.sync="viewModel.emergencyContact1IsMember"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
              label="Emergency Contact 1"
              placeholder="Type the name"
              @isMemberChanged="emergencyContact1TypeChanged"
              @memberChanged="emergencyContact1MemberChanged"
            ></member-combobox>
          </v-col>
          <v-col>
            <v-text-field
              v-model="viewModel.emergencyContactNumber1"
              label="Emergency Number 1"
              placeholder="Emergency Contact Number 1"
              outlined
              :clearable="!emergencyContactNumber1IsReadOnly"
              hide-details="auto"
              maxlength="200"
              :disabled="!canViewSensitiveData"
              :readonly="
                !canViewSensitiveData || emergencyContactNumber1IsReadOnly
              "
              :rules="validateEmergencyContactNumber1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <member-combobox
              :name.sync="viewModel.emergencyContactName2"
              :member.sync="viewModel.emergencyContact2"
              :isMember.sync="viewModel.emergencyContact2IsMember"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
              label="Emergency Contact 2"
              placeholder="Type the name"
              @isMemberChanged="emergencyContact2TypeChanged"
              @memberChanged="emergencyContact2MemberChanged"
            ></member-combobox>
          </v-col>
          <v-col>
            <v-text-field
              v-model="viewModel.emergencyContactNumber2"
              label="Emergency Number 2"
              placeholder="Emergency Contact Number 2"
              outlined
              :clearable="!emergencyContactNumber2IsReadOnly"
              hide-details="auto"
              maxlength="200"
              :disabled="!canViewSensitiveData"
              :readonly="
                !canViewSensitiveData || emergencyContactNumber2IsReadOnly
              "
              :rules="validateEmergencyContactNumber2"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">4</span>Socials</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide contact any social media or external
          platforms that the user can be found or contacted on.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row v-for="social of viewModel.socials" :key="social.platform">
          <v-col>
            <v-select
              :items="viewModel.externalPlatforms"
              v-model="social.platform"
              label="Platform"
              placeholder="type of social platform"
              menu-props="auto"
              hide-details="auto"
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="social.contentUrl"
              label="Platform Url"
              placeholder="The url of the platform"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
              :disabled="!canViewSensitiveData"
              :readonly="!canViewSensitiveData"
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addExternalPlatform"
                  :disabled="!canViewSensitiveData"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone </v-icon>
                </v-btn>
              </template>
              <span>add platform</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!canViewSensitiveData"
                  @click="removeExternalPlatform(social)"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone-remove </v-icon>
                </v-btn>
              </template>
              <span>remove platform</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";
import MemberAutoComplete from "@/components/common/member-autocomplete.vue";
import MapAutocomplete from "@/components/common/maps-autocomplete.vue";
import MemberCombobox from "@/components/common/member-combobox.vue";
import { EventLocation, IPlaceResultData } from "@/models/events.model";
import {
  LoadingType,
  ISocialMediaReference,
  SocialMediaReference,
} from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  ManageUserContact,
  ManageUserViewModel,
} from "@/models/security.model";
import { MemberSearchSuggestion } from "@/models/group.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "PeopleContactSection",
  mixins: [CommonMixin],
  components: {
    "map-autocomplete": MapAutocomplete,
    "date-picker": DatePicker,
    "member-autocomplete": MemberAutoComplete,
    "member-combobox": MemberCombobox,
  },
  data() {
    return {
      country: ["GB"],
      emergencyContactNumber1IsReadOnly: false,
      emergencyContactNumber2IsReadOnly: false,
      viewModel: new ManageUserViewModel(),
      tmpContactNumbers: [] as ManageUserContact[],
      tmpEmails: [] as ManageUserContact[],
    };
  },
  props: {
    id: Number,
    ownerId: Number,
    canViewSensitiveData: Boolean,
    formData: {
      type: Object,
      default: () => new ManageUserViewModel(),
      required: true,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val: ManageUserViewModel) {
        this.viewModel = val;
        this.tmpContactNumbers = this.viewModel.tmpContactNumbers;
        this.tmpEmails = this.viewModel.tmpEmails;

        if (this.viewModel.socials.length == 0) {
          this.viewModel.socials.push(new SocialMediaReference());
        }
      },
    },
  },
  computed: {
    validateEmergencyContactNumber1(): string[] {
      let err: string[] = [];
      if (
        this.viewModel.emergencyContactNumber1 &&
        !this.viewModel.emergencyContactName1 &&
        !this.viewModel.emergencyContact1
      ) {
        err.push("You must enter or select a person.");
      } else if (
        this.viewModel.emergencyContact1 &&
        !this.viewModel.emergencyContactNumber1
      ) {
        err.push("You cannot use a member who does not have a contact number.");
      } else if (
        this.viewModel.emergencyContactName1 &&
        !this.viewModel.emergencyContactNumber1
      ) {
        err.push("You must specify a value.");
      } else if (
        this.viewModel.emergencyContact1 &&
        this.viewModel.emergencyContact1.id == this.id
      ) {
        err.push("You cannot select yourself as an emergency contact.");
      } else if (
        this.viewModel.emergencyContactNumber1 &&
        isValidPhoneNumber(this.viewModel.emergencyContactNumber1, "GB") ===
          false
      ) {
        err.push("Not a valid phone number");
      }
      return err;
    },
    validateEmergencyContactNumber2(): string[] {
      let err: string[] = [];
      if (
        this.viewModel.emergencyContactNumber2 &&
        !this.viewModel.emergencyContactName2 &&
        !this.viewModel.emergencyContact2
      ) {
        err.push("You must enter or select a person.");
      } else if (
        this.viewModel.emergencyContact2 &&
        !this.viewModel.emergencyContactNumber2
      ) {
        err.push("You cannot use a member who does not have a contact number.");
      } else if (
        this.viewModel.emergencyContactName2 &&
        !this.viewModel.emergencyContactNumber2
      ) {
        err.push("You must specify a value.");
      } else if (
        this.viewModel.emergencyContact2 &&
        this.viewModel.emergencyContact2.id == this.id
      ) {
        err.push("You cannot select yourself as an emergency contact.");
      } else if (
        this.viewModel.emergencyContactNumber2 &&
        isValidPhoneNumber(this.viewModel.emergencyContactNumber2, "GB") ===
          false
      ) {
        err.push("Not a valid phone number");
      }
      return err;
    },
  },
  methods: {
    populateForm() {
      this.emergencyContactNumber1IsReadOnly =
        !this.viewModel.emergencyContactName1IsMember;
      this.emergencyContactNumber2IsReadOnly =
        !this.viewModel.emergencyContactName2IsMember;
    },

    onAddressChange(val: EventLocation) {
      let location = this.viewModel.location;
      if (location) {
        location.address = val.address;
        location.houseNameOrNumber = val.houseNameOrNumber;
        location.street = val.street;
        location.city = val.city;
        location.country = val.country;
        location.postCode = val.postCode;
        location.region = val.region;
        location.url = val.url;
        location.longitude = val.longitude;
        location.latitude = val.latitude;
      }
    },

    emergencyContact1TypeChanged() {
      this.emergencyContactNumber1IsReadOnly =
        this.viewModel.emergencyContactName1IsMember;
      this.viewModel.emergencyContactName1 = "";
      this.viewModel.emergencyContactNumber1 = "";
      this.viewModel.emergencyContact1 = null;
    },

    emergencyContact2TypeChanged() {
      this.emergencyContactNumber2IsReadOnly =
        this.viewModel.emergencyContactName2IsMember;
      this.viewModel.emergencyContactName2 = "";
      this.viewModel.emergencyContactNumber2 = "";
      this.viewModel.emergencyContact2 = null;
    },

    emergencyContact1MemberChanged(val: MemberSearchSuggestion) {
      this.viewModel.emergencyContactNumber1 = "";
      this.emergencyContactNumber1IsReadOnly = false;

      if (val) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Getting contact number, Please Wait..."
        );

        const service = new GroupService();
        service
          .getPrimaryContactNumber(this.ownerId, val.id)
          .then((response) => {
            this.hideProgressIndicator();
            this.viewModel.emergencyContactNumber1 = response.data;
            this.emergencyContactNumber1IsReadOnly = true;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    emergencyContact2MemberChanged(val: MemberSearchSuggestion) {
      this.viewModel.emergencyContactNumber2 = "";
      this.emergencyContactNumber2IsReadOnly = false;

      if (val) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Getting contact number, Please Wait..."
        );

        const service = new GroupService();
        service
          .getPrimaryContactNumber(this.ownerId, val.id)
          .then((response) => {
            this.hideProgressIndicator();
            this.viewModel.emergencyContactNumber2 = response.data;
            this.emergencyContactNumber2IsReadOnly = true;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    addEmail() {
      switch (this.viewModel.tmpEmails.length) {
        case 0:
          this.viewModel.tmpEmails.push(
            new ManageUserContact(1, "Primary E-mail", "")
          );
          break;
        case 1:
          this.viewModel.tmpEmails.push(
            new ManageUserContact(2, "Secondary E-mail", "")
          );
          break;
        case 2:
          this.viewModel.tmpEmails.push(
            new ManageUserContact(3, "Tertiary E-mail", "")
          );
          break;
      }
    },

    removeEmail(contact: ManageUserContact) {
      // Remove the email from the list
      if (this.viewModel.tmpEmails.length > 1) {
        const index = this.viewModel.tmpEmails.indexOf(contact);
        this.viewModel.tmpEmails.splice(index, 1);
      }

      // Re-assign the labels and ids
      for (let i = 0; i < this.viewModel.tmpEmails.length; i++) {
        let item = this.viewModel.tmpEmails[i];
        item.id = i;

        switch (i) {
          case 0:
            item.label = "Primary E-mail";
            break;
          case 1:
            item.label = "Secondary E-mail";
            break;
          case 2:
            item.label = "Tertiary E-mail";
            break;
        }
      }
    },

    addContactNumber() {
      switch (this.viewModel.tmpContactNumbers.length) {
        case 0:
          this.viewModel.tmpContactNumbers.push(
            new ManageUserContact(1, "Primary Contact Number", "")
          );
          break;
        case 1:
          this.viewModel.tmpContactNumbers.push(
            new ManageUserContact(2, "Secondary Contact Number", "")
          );
          break;
      }
    },

    removeContactNumber(contact: ManageUserContact) {
      // Remove the number from the list
      if (this.viewModel.tmpContactNumbers.length > 1) {
        const index = this.viewModel.tmpContactNumbers.indexOf(contact);
        this.viewModel.tmpContactNumbers.splice(index, 1);
      }

      // Re-assign the labels and ids
      for (let i = 0; i < this.viewModel.tmpContactNumbers.length; i++) {
        let item = this.viewModel.tmpContactNumbers[i];
        item.id = i;

        switch (i) {
          case 0:
            item.label = "Primary Contact Number";
            break;
          case 1:
            item.label = "Secondary Contact Number";
            break;
        }
      }
    },

    addExternalPlatform() {
      if (this.viewModel.socials.length < 5) {
        this.viewModel.socials.push(new SocialMediaReference());
      }
    },

    removeExternalPlatform(item: ISocialMediaReference) {
      if (this.viewModel.socials.length > 0) {
        const index = this.viewModel.socials.indexOf(item);
        this.viewModel.socials.splice(index, 1);
      }
    },
  },
});
</script>