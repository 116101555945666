var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "40%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("contributor-selector-dialog", {
        attrs: {
          dialog: _vm.showContributorDialog,
          multiSelect: true,
          selectedItems: _vm.viewModel.contributors,
        },
        on: {
          "dialog-closed-cancel": _vm.onContributorDialogCancelled,
          "dialog-option-selected": _vm.onContributorDialogConfirmed,
        },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("div", [_vm._v("Manage Page Section")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCancel()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSave.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Title",
                              placeholder: "Title",
                              outlined: "",
                              clearable: "",
                              "hide-details": "auto",
                              maxlength: "80",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.viewModel.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.viewModel, "name", $$v)
                              },
                              expression: "viewModel.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.viewModel.displayTypes,
                              label: "Display Type",
                              outlined: "",
                              "return-object": "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.viewModel.displayType,
                              callback: function ($$v) {
                                _vm.$set(_vm.viewModel, "displayType", $$v)
                              },
                              expression: "viewModel.displayType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", disabled: !_vm.valid },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onAddContributors()
                                },
                              },
                            },
                            [_vm._v(" Add Contributors ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "12" } },
                        [
                          _c("v-data-iterator", {
                            attrs: {
                              items: _vm.viewModel.contributors,
                              "items-per-page": _vm.itemsPerPage,
                              "footer-props": _vm.footerOptions,
                              options: _vm.options,
                            },
                            on: {
                              "update:itemsPerPage": function ($event) {
                                _vm.itemsPerPage = $event
                              },
                              "update:items-per-page": function ($event) {
                                _vm.itemsPerPage = $event
                              },
                              "update:options": function ($event) {
                                _vm.options = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "v-row",
                                      _vm._l(props.items, function (item) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: item.id,
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("v-hover", {
                                              attrs: { "open-delay": "100" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var hover = ref.hover
                                                      return [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            class: {
                                                              "on-hover": hover,
                                                            },
                                                            attrs: {
                                                              elevation:
                                                                !_vm.multiSelect &&
                                                                hover
                                                                  ? 12
                                                                  : 3,
                                                              outlined:
                                                                !_vm.multiSelect &&
                                                                hover,
                                                            },
                                                          },
                                                          [
                                                            _c("v-img", {
                                                              class: [
                                                                !_vm.multiSelect
                                                                  ? _vm.imageCss
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                src: item.imageUrl,
                                                                "lazy-src":
                                                                  _vm.placeholder,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    !_vm.multiSelect
                                                                  },
                                                              },
                                                            }),
                                                            _c("v-card-title", [
                                                              _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pb-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.subtitle
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-actions",
                                                              [
                                                                _c("v-spacer"),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.onRemove(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Remove "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.isValid,
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }