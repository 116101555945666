var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "3", xl: "2" } },
            [
              _c("h6", [
                _c("span", { staticClass: "number" }, [_vm._v("1")]),
                _vm._v("Basic"),
              ]),
              _c(
                "p",
                {
                  staticClass: "small",
                  staticStyle: { "padding-right": "40px" },
                },
                [
                  _vm._v(
                    " This section is to provide basic summary information used to identify the user. "
                  ),
                ]
              ),
              _c(
                "v-container",
                {
                  staticClass: "fill-height",
                  staticStyle: { "max-height": "256px" },
                },
                [
                  _c("image-selector", {
                    attrs: { imageUrl: _vm.imageUrl },
                    on: {
                      "uploaded-image-selected": _vm.uploadedImageSelected,
                      "approved-image-selected": _vm.approvedImageSelected,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Our Reference",
                          placeholder: "Our Reference",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                        },
                        model: {
                          value: _vm.formData.ourReference,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ourReference", $$v)
                          },
                          expression: "formData.ourReference",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.currentStatuses,
                          outlined: "",
                          multiple: "",
                          clearable: "",
                          label: "Current Status",
                          placeholder: "Current Status",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.selectedCurrentStatuses,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "selectedCurrentStatuses",
                              $$v
                            )
                          },
                          expression: "formData.selectedCurrentStatuses",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "First Name",
                          placeholder: "First Name",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "firstName", $$v)
                          },
                          expression: "formData.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Last Name",
                          placeholder: "Last Name",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "lastName", $$v)
                          },
                          expression: "formData.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.genders,
                          outlined: "",
                          clearable: "",
                          label: "Gender",
                          placeholder: "Gender",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "gender", $$v)
                          },
                          expression: "formData.gender",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfBirth,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Date of Birth",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(_vm.formData, "dateOfBirth", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "10" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Profile",
                          placeholder: "Provide a full profile of this person.",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          rows: "3",
                          "hide-details": "auto",
                          maxlength: "2000",
                        },
                        model: {
                          value: _vm.formData.profile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "profile", $$v)
                          },
                          expression: "formData.profile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Demographics"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Demographic information to provide a picture of your users and target specific indviduals "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.maritalStatuses,
                          disabled: !_vm.canViewSensitiveData,
                          outlined: "",
                          clearable: "",
                          label: "Marital Status",
                          placeholder: "Marital Status",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.maritalStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "maritalStatus", $$v)
                          },
                          expression: "formData.maritalStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          clearable: "",
                          items: _vm.formData.skills,
                          label: "Skills / Professions",
                          placeholder: "Skills / Professions",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.skill,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "skill", $$v)
                          },
                          expression: "formData.skill",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.ethnicities,
                          outlined: "",
                          clearable: "",
                          label: "Ethnicity",
                          placeholder: "Ethnicity",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.ethnicity,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ethnicity", $$v)
                          },
                          expression: "formData.ethnicity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.nationalities,
                          outlined: "",
                          clearable: "",
                          label: "Nationality",
                          placeholder: "Nationality",
                          "menu-props": "auto",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.formData.nationality,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "nationality", $$v)
                          },
                          expression: "formData.nationality",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("3")]),
              _vm._v("Family"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Relationship information providing us with the ability to manage family groups "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("member-autocomplete", {
                        attrs: {
                          disabled: !_vm.canViewSensitiveData,
                          member: _vm.formData.father,
                          memberBind: _vm.formData.father,
                          label: "Father/Guardian 1",
                          placeholder: "Type the name",
                        },
                        on: {
                          "update:memberBind": function ($event) {
                            return _vm.$set(_vm.formData, "father", $event)
                          },
                          "update:member-bind": function ($event) {
                            return _vm.$set(_vm.formData, "father", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("member-autocomplete", {
                        attrs: {
                          disabled: !_vm.canViewSensitiveData,
                          member: _vm.formData.mother,
                          memberBind: _vm.formData.mother,
                          label: "Mother/Guardian 2",
                          placeholder: "Type the name",
                        },
                        on: {
                          "update:memberBind": function ($event) {
                            return _vm.$set(_vm.formData, "mother", $event)
                          },
                          "update:member-bind": function ($event) {
                            return _vm.$set(_vm.formData, "mother", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("4")]),
              _vm._v("Attributes"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to apply attributes to this person which is not easily defined elsewhere. Select one or more and search on them later. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.formData.attributes,
                  outlined: "",
                  multiple: "",
                  clearable: "",
                  label: "Attributes",
                  placeholder: "Attributes",
                  "menu-props": "auto",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.formData.selectedAttributes,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "selectedAttributes", $$v)
                  },
                  expression: "formData.selectedAttributes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }