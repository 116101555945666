<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-row>
        <v-col md="3">
          It seems that you do not have permission to access this information.
          Please ask your administrator for access.
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_xmnyrzjz.json"
            background="transparent"
            speed="1"
            style="width: 400px; height: 400px"
            loop
            autoplay
          ></lottie-player>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "NoPermissionCard",
  mixins: [CommonMixin],
});
</script>
