var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "30%", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _vm._v(" Online Location "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.onCancel },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.mainEvent.platforms,
                              label: "Platform",
                              placeholder: "type of online platform",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              rules: [_vm.rules.required],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onPlatformChange(
                                  $event,
                                  _vm.location
                                )
                              },
                            },
                            model: {
                              value: _vm.location.platform,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "platform", $$v)
                              },
                              expression: "location.platform",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Meeting Link",
                              placeholder: "Meeting Link",
                              clearable: "",
                              "hide-details": "auto",
                              rows: "1",
                              "auto-grow": "",
                              counter: "",
                              maxlength: "400",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.location.linkUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "linkUrl", $$v)
                              },
                              expression: "location.linkUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.location.allowDialIn
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Dial-In Number",
                                  placeholder: "Dial-In Number",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "20",
                                  "error-messages": _vm.validateDialInNumber,
                                },
                                model: {
                                  value: _vm.location.dialInNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "dialInNumber", $$v)
                                  },
                                  expression: "location.dialInNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Dial-In Code",
                                  placeholder: "Dial-In Code",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "20",
                                  "error-messages": _vm.validateDialInCode,
                                },
                                model: {
                                  value: _vm.location.dialInCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "dialInCode", $$v)
                                  },
                                  expression: "location.dialInCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.allowDialIn
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Meeting Id",
                                  placeholder: "Meeting Id",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "20",
                                  "error-messages": _vm.validateMeetingId,
                                },
                                model: {
                                  value: _vm.location.meetingId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "meetingId", $$v)
                                  },
                                  expression: "location.meetingId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Pass code",
                                  placeholder: "Pass code",
                                  clearable: "",
                                  "hide-details": "auto",
                                  maxlength: "20",
                                  "error-messages": _vm.validateMeetingCode,
                                },
                                model: {
                                  value: _vm.location.meetingCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "meetingCode", $$v)
                                  },
                                  expression: "location.meetingCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "auto" } },
                        [
                          _c("date-time-picker", {
                            attrs: {
                              outlined: false,
                              clearable: false,
                              dateTime: _vm.location.startDateTime,
                              showDateHeader: false,
                              dateRules: _vm.validateDate,
                              timeRules: _vm.validateTime,
                              dateLabel: "Start Date",
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.location,
                                  "startDateTime",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.location,
                                  "startDateTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "auto" } },
                        [
                          _c("date-time-picker", {
                            attrs: {
                              outlined: false,
                              clearable: false,
                              dateTime: _vm.location.endDateTime,
                              showDateHeader: false,
                              dateLabel: "End Date",
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.location,
                                  "endDateTime",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.location,
                                  "endDateTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }