<template>
  <v-row justify="center">
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <v-dialog v-model="dialog" max-width="20%" persistent>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-card-title class="headline">
            <div>Create New User Role</div>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="onCancel()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="members"
                  :loading="isMatching"
                  :search-input.sync="matching"
                  item-text="name"
                  item-value="id"
                  v-model="selectedMember"
                  outlined
                  clearable
                  label="Member"
                  placeholder="Member"
                  menu-props="auto"
                  hide-details="auto"
                  :error-messages="validateMemberSelection"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  v-model="roleId"
                  outlined
                  clearable
                  label="Role"
                  placeholder="Role"
                  menu-props="auto"
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onCancel()">Cancel</v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { MemberSearchSuggestion } from "@/models/group.model";
import { SecurityService } from "@/services/security.service";
import { RoleListItem } from "@/models/security.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "CreateUserRoleDialog",
  mixins: [CommonMixin],
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      valid: false,
      isMatching: false,
      members: Array<MemberSearchSuggestion>(),
      selectedMember: new MemberSearchSuggestion(),
      roles: Array<RoleListItem>(),
      roleId: 0,
      matching: "",
    };
  },
  watch: {
    dialog(val: boolean) {
      if (val === true) {
        this.onLoad();
        const ref: any = this.$refs.manageForm;
        if (ref) {
          ref.validate();
        }
      }
    },
    matching(val: string) {
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(0, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  computed: {
    validateMemberSelection(): string[] {
      let err: string[] = [];
      if (this.selectedMember.id === 0) {
        err.push("You must select a member. Type a members name to find one.");
      }
      return err;
    },
  },
  methods: {
    onLoad() {
      this.selectedMember = new MemberSearchSuggestion();
      this.roles = [];
      this.roleId = 0;

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Available Roles, Please Wait..."
      );

      const service = new SecurityService();
      service
        .getRoles()
        .then((response) => {
          this.hideProgressIndicator();
          this.roles = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving User Role, Please Wait..."
      );

      const service = new SecurityService();
      service
        .saveUserRole(this.roleId, this.selectedMember.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.$emit("dialog-option-selected", response.data);
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onCancel() {
      this.$emit("dialog-option-selected");
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>