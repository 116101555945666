<template>
  <v-dialog v-model="dialog" max-width="30%" persistent>
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          Online Location
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onCancel">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                :items="mainEvent.platforms"
                v-model="location.platform"
                label="Platform"
                placeholder="type of online platform"
                menu-props="auto"
                hide-details="auto"
                :rules="[rules.required]"
                @change="onPlatformChange($event, location)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="location.linkUrl"
                label="Meeting Link"
                placeholder="Meeting Link"
                clearable
                hide-details="auto"
                rows="1"
                auto-grow
                counter
                maxlength="400"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="location.allowDialIn">
            <v-col>
              <v-text-field
                v-model="location.dialInNumber"
                label="Dial-In Number"
                placeholder="Dial-In Number"
                clearable
                hide-details="auto"
                maxlength="20"
                :error-messages="validateDialInNumber"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="location.dialInCode"
                label="Dial-In Code"
                placeholder="Dial-In Code"
                clearable
                hide-details="auto"
                maxlength="20"
                :error-messages="validateDialInCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="location.allowDialIn">
            <v-col>
              <v-text-field
                v-model="location.meetingId"
                label="Meeting Id"
                placeholder="Meeting Id"
                clearable
                hide-details="auto"
                maxlength="20"
                :error-messages="validateMeetingId"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="location.meetingCode"
                label="Pass code"
                placeholder="Pass code"
                clearable
                hide-details="auto"
                maxlength="20"
                :error-messages="validateMeetingCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="auto">
              <date-time-picker
                :outlined="false"
                :clearable="false"
                :dateTime.sync="location.startDateTime"
                :showDateHeader="false"
                :dateRules="validateDate"
                :timeRules="validateTime"
                dateLabel="Start Date"
              >
              </date-time-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="auto">
              <date-time-picker
                :outlined="false"
                :clearable="false"
                :dateTime.sync="location.endDateTime"
                :showDateHeader="false"
                dateLabel="End Date"
              >
              </date-time-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { EventOnlineLocation, EventViewModel } from "@/models/events.model";
import Vue, { VueConstructor } from "vue";
import DateTimePicker from "@/components/common/date-time-picker.vue";
import CommonMixin from "@/mixins/common.mixin";
import { DateTime } from "luxon";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventLocationOnlineEditor",
  mixins: [CommonMixin],
  components: {
    "date-time-picker": DateTimePicker,
  },
  props: {
    dialog: Boolean,
    entity: {
      type: Object,
      default: () => new EventOnlineLocation(),
      required: true,
    },
    mainEvent: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      location: new EventOnlineLocation(),
    };
  },

  watch: {
    mainEvent: {
      immediate: true,
      handler(val: EventViewModel) {
        for (let loc of val.onlineLocations) {
          this.setAllowDialinFlag(loc.platform, loc);
        }
      },
    },

    entity: {
      deep: true,
      immediate: true,
      handler(val: EventOnlineLocation) {
        this.location = this.clone(val);
      },
    },
  },

  computed: {
    validateDialInNumber(): string[] {
      let err: string[] = [];
      if (this.location.dialInNumber) {
        if (!this.location.dialInCode) {
          err.push("A dial-in number must be accomodated with a code");
        }
      }
      return err;
    },
    validateDialInCode(): string[] {
      let err: string[] = [];
      if (this.location.dialInCode) {
        if (!this.location.dialInNumber) {
          err.push("A dial-in code must be accomodated with a code");
        }
      }
      return err;
    },
    validateMeetingId(): string[] {
      let err: string[] = [];
      if (this.location.meetingId) {
        if (!this.location.meetingCode) {
          err.push("A meeting id must be accomodated with a code");
        }
      }
      return err;
    },
    validateMeetingCode(): string[] {
      let err: string[] = [];
      if (this.location.meetingCode) {
        if (!this.location.meetingId) {
          err.push("A meeting id must be accomodated with a code");
        }
      }
      return err;
    },
    validateDate() {
      let rules = [];

      const start: DateTime = DateTime.fromISO(
        this.location.startDateTime?.toString()!
      );
      const end: DateTime = DateTime.fromISO(
        this.location.endDateTime?.toString()!
      );

      const diff: number = end.diff(start, "days").days;
      if (diff < 0) {
        rules.push("Start date/time cannot be greater than the end date/time");
      }

      let ev: EventViewModel = this.mainEvent;
      const evStart: DateTime = DateTime.fromISO(ev.startDateTime.toString());
      const evEnd: DateTime = DateTime.fromISO(ev.endDateTime.toString());

      const evStartDiff: number = start.diff(evStart, "days").days;
      if (evStartDiff < 0) {
        rules.push("Start date cannot be before the start of the event.");
      }
      const evEndDiff: number = end.diff(evEnd, "days").days;
      if (evEndDiff > 0) {
        rules.push("End date cannot be after the end of the event.");
      }

      return rules;
    },
    validateTime(): string[] {
      let err: string[] = [];

      const start: DateTime = DateTime.fromISO(
        this.location.startDateTime?.toString()!
      );
      const end: DateTime = DateTime.fromISO(
        this.location.endDateTime?.toString()!
      );

      const diff: number = end.diff(start, "minutes").minutes;
      if (diff < 0) {
        err.push("The end time is before the start time.");
      }

      return err;
    },
  },

  methods: {
    submitForm() {
      this.$emit("on-dialog-save", this.location);
    },

    onCancel() {
      this.$emit("on-dialog-cancel");
    },

    clone(val: EventOnlineLocation): EventOnlineLocation {
      let cloned = new EventOnlineLocation();

      cloned.id = val.id;
      cloned.platform = val.platform;
      cloned.linkUrl = val.linkUrl;
      cloned.dialInNumber = val.dialInNumber;
      cloned.dialInCode = val.dialInCode;
      cloned.meetingCode = val.meetingCode;
      cloned.meetingId = val.meetingId;
      cloned.startDateTime = val.startDateTime;
      cloned.endDateTime = val.endDateTime;
      cloned.localId = val.localId;

      this.setAllowDialinFlag(cloned.platform, cloned);

      return cloned;
    },

    onPlatformChange(value: string, loc: EventOnlineLocation) {
      this.setAllowDialinFlag(value, loc);
    },

    setAllowDialinFlag(platform: string, loc: EventOnlineLocation) {
      switch (platform.toLowerCase()) {
        case "microsoft teams":
        case "zoom":
        case "google meet":
        case "custom":
          loc.allowDialIn = true;
          break;
        default:
          loc.allowDialIn = false;
          break;
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>