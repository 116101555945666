<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Pre-event</h6>
        <p class="small" style="padding-right: 40px">
          Specify links to pre-event activities such as registration, letters
          and documents.
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="formData.registrationUrl"
              label="Registration Link"
              placeholder="Enter the web address of the event registration form."
              clearable
              hide-details="auto"
              maxlength="400"
              outlined
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              :items="formData.letters"
              label="Letters"
              outlined
              clearable
              item-value="id"
              :item-text="displayTitle"
              v-model="selectedLetter"
              return-object
            >
            </v-select>
          </v-col>
          <v-col md="6">
            <v-btn
              color="primary"
              :disabled="hasSelectedLetter"
              large
              class="ma-1"
              @click="onNewLetter"
            >
              Add
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!hasSelectedLetter"
              large
              class="ma-1"
              @click="onEditLetter"
            >
              Edit
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!hasSelectedLetter"
              large
              class="ma-1"
              @click="onRemoveLetter"
            >
              Remove
            </v-btn>
            <v-btn
              :disabled="!hasSelectedLetter"
              color="primary"
              large
              class="ma-1"
              :href="selectedLetterUrl"
            >
              View
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Post-event</h6>
        <p class="small" style="padding-right: 40px">
          Specify links event activities after the link such as the survey and
          any downloadable souvenirs and videos.
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="formData.surveyUrl"
              label="Survey / Feedback Link"
              placeholder="Enter the web address of the survey / feedback form."
              clearable
              hide-details="auto"
              maxlength="400"
              outlined
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">3</span>Organiser</h6>
        <p class="small" style="padding-right: 40px">
          Specify who organised this event so that they can be contacted for
          additional info pre and post the event. You can select the group from
          the drop-down or type directly in the boxes below.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-select
              :items="formData.potentialOrganisers"
              label="Organised By"
              outlined
              clearable
              item-value="id"
              item-text="organisation"
              return-object
              v-model="potentialOrganiser"
              @click:clear="onPotentialOrganiserRemoved"
              @change="onPotentialOrganiserSelected"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Branch/Group Name"
              placeholder="The name of the branch or group that organised this"
              v-model="groupName"
              hide-details="auto"
              clearable
              outlined
              @click:clear="onGroupNameRemoved"
              @change="onGroupNameChanged"
              :disabled="hasSelectedOrganisation"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Contact (Name/Email/No)"
              placeholder="Enter a contact name, email or phone number"
              v-model="contactName"
              hide-details="auto"
              clearable
              outlined
              @click:clear="onContactNameRemoved"
              @change="onContactNameChanged"
              :rules="validateContactName"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <event-letter-editor
      :dialog="showLetterEditor"
      :entity="selectedLetter"
      :mainEvent="viewModel"
      @on-dialog-save="onLetterEditorSave"
      @on-dialog-cancel="onLetterEditorCancel"
    >
    </event-letter-editor>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteLetterDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteLetterConfirmation"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import EventLetterEditor from "@/components/events/event-letter-editor.vue";
import {
  EventLetter,
  EventOrganiser,
  EventViewModel,
} from "@/models/events.model";
import {
  DialogMessage,
  DialogResponse,
  LoadingType,
} from "@/models/common.model";
import { EventsService } from "@/services/events.service";
import { DateTime } from "luxon";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventsLinksSection",
  mixins: [CommonMixin],

  components: {
    "event-letter-editor": EventLetterEditor,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new EventViewModel(),
      required: true,
    },
  },

  watch: {
    formData: {
      handler(val: EventViewModel) {
        this.viewModel = val;
      },
    },
  },

  computed: {
    hasSelectedOrganisation(): boolean {
      if (this.viewModel.organisedBy) {
        return this.viewModel.organisedBy.id ? true : false;
      } else {
        return false;
      }
    },
    validateContactName() {
      let err: string[] = [];

      if (this.groupName && !this.contactName) {
        err.push(
          "You must provide a contact name, number or email if you specify the group."
        );
      }

      return err;
    },
    hasSelectedLetter(): boolean {
      return (
        this.selectedLetter != null && this.selectedLetter.letterUrl != null
      );
    },
    selectedLetterUrl(): string {
      return this.selectedLetter != null &&
        this.selectedLetter.letterUrl != null
        ? this.selectedLetter.letterUrl
        : "";
    },
  },

  data() {
    return {
      viewModel: new EventViewModel(),
      valid: false,
      potentialOrganiser: new EventOrganiser(),
      contactName: "",
      groupName: "",
      showLetterEditor: false,
      selectedLetter: null as EventLetter | null,
      showDeleteLetterDialog: false,
    };
  },

  methods: {
    initialise() {
      const val: EventViewModel = this.viewModel;
      if (val && val.organisedBy) {
        this.groupName = val.organisedBy.organisation;
        this.contactName = val.organisedBy.contactName;
        this.potentialOrganiser = val.organisedBy;
      }
    },

    onPotentialOrganiserSelected(val: EventOrganiser) {
      if (val) {
        if (this.viewModel.organisedBy) {
          this.viewModel.organisedBy.id = val.id;
          this.viewModel.organisedBy.organisation = val.organisation;
          this.viewModel.organisedBy.contactName = val.contactName;
          this.viewModel.organisedBy.imageurl = val.imageurl;
        }
        this.contactName = val.contactName;
        this.groupName = val.organisation;
      }
    },

    onPotentialOrganiserRemoved() {
      if (this.viewModel.organisedBy) {
        this.viewModel.organisedBy.id = null;
      }
    },

    onContactNameChanged(val: any) {
      if (this.viewModel.organisedBy) {
        this.viewModel.organisedBy.contactName = val;
      }
    },

    onContactNameRemoved() {
      if (this.viewModel.organisedBy) {
        this.viewModel.organisedBy.contactName = "";
      }
    },

    onGroupNameChanged(val: any) {
      if (this.viewModel.organisedBy) {
        this.viewModel.organisedBy.organisation = val;
      }
    },

    onGroupNameRemoved() {
      if (this.viewModel.organisedBy) {
        this.viewModel.organisedBy.id = null;
        this.viewModel.organisedBy.organisation = "";
      }
    },

    onLetterEditorCancel() {
      this.selectedLetter = null;
      this.showLetterEditor = false;
    },

    onLetterEditorSave(letter: EventLetter) {
      let match = this.viewModel.letters.find(
        (f: EventLetter) => f.id == letter.id
      );
      if (match) {
        match.id = letter.id;
        match.letterUrl = letter.letterUrl;
        match.publishedOn = letter.publishedOn;
        match.title = letter.title;
      } else {
        this.viewModel.letters.push(letter);

        // Ensure the new item is placed correctly in the list
        if (this.viewModel.letters.length > 0) {
          let sortedItems = this.viewModel.letters.sort((a, b) => {
            if (a.publishedOn && b.publishedOn) {
              if (a.publishedOn! > b.publishedOn!) {
                return 1;
              }
              if (a.publishedOn < b.publishedOn) return -1;
            }
            return 0;
          });

          this.viewModel.letters = [];
          for (let item of sortedItems) {
            this.viewModel.letters.push(item);
          }
        }
      }
      this.selectedLetter = null;
      this.showLetterEditor = false;
    },

    onNewLetter() {
      this.selectedLetter = new EventLetter();
      this.showLetterEditor = true;
    },

    onEditLetter() {
      this.showLetterEditor = true;
    },

    onRemoveLetter() {
      this.showDeleteLetterConfirmationDialog();
    },

    showDeleteLetterConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Remove letter?`,
        `Do you want to remove this letter from the event?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.id;
      this.dialogMessage.errors = [];
      this.showDeleteLetterDialog = true;
    },

    onDeleteLetterConfirmation(item: DialogResponse) {
      this.showDeleteLetterDialog = false;
      if (item.option && this.selectedLetter) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Letter, Please Wait..."
        );
        const service = new EventsService();
        service
          .removeLetter(this.id, this.selectedLetter.id)
          .then(() => {
            this.hideProgressIndicator();
            if (this.selectedLetter) {
              let index = this.viewModel.letters.findIndex(
                (f) => f.id == this.selectedLetter!.id
              );
              if (index !== -1) {
                this.viewModel.letters.splice(index, 1);
              }
            }
            this.selectedLetter = null;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    displayTitle(item: EventLetter): string {
      let text: string = item.title;
      if (item.publishedOn) {
        let dateString = DateTime.fromISO(
          item.publishedOn.toString()
        ).toLocaleString(DateTime.DATE_MED);
        text = `${dateString} - ${item.title}`;
      }
      return text;
    },
  },
});
</script>
