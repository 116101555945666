<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="textFieldDisplay"
          v-bind="attrs"
          v-on="on"
          :prepend-icon="prependIcon"
          :label="label"
          :clearable="clearable"
          :outlined="outlined"
          :disabled="disabled"
          :rules="dateRules"
          hide-details="auto"
          readonly
          @click:clear="onDateCleared"
        ></v-text-field>
      </template>
      <v-date-picker
        year-icon="mdi-calendar-blank"
        elevation="8"
        :no-title="!showHeader"
        v-model="datePickerDisplay"
        @input="onDateSelected"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",
  props: {
    date: String,
    format: {
      type: String,
      default: "DDD",
      required: false,
    },
    outlined: {
      type: Boolean,
      default: true,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: "Date",
      required: false,
    },
    dateRules: {
      type: Array,
      required: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
    prependIcon: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      textFieldDisplay: "",
      datePickerDisplay: "",
      menu: false,
    };
  },
  watch: {
    date: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.datePickerDisplay = DateTime.fromISO(newVal).toISODate();
          this.textFieldDisplay = DateTime.fromISO(newVal).toFormat(
            this.format
          );
        } else {
          this.datePickerDisplay = "";
          this.textFieldDisplay = "";
        }
      },
    },
  },
  methods: {
    onDateSelected(input: string) {
      let d = DateTime.fromISO(input);
      this.menu = false;
      this.$emit("update:date", d.toISO());
      this.$emit("date-changed");
    },
    onDateCleared() {
      this.$emit("update:date", undefined);
      this.$emit("date-cleared");
    },
  },
});
</script>