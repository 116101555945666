<template>
    <v-dialog v-model="dialog" :max-width="width" height="480" persistent>
        <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
            <v-card>
                <v-toolbar flat dense elevation="1" color="#E5E3E3">
                    Repeat
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <date-picker :outlined="true" :showHeader="false" :date.sync="startDate" label="Start From"
                                format="cccc d LLLL">
                            </date-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3">
                            <v-select :outlined="true" :items="repeatNumbers" v-model="repeatNumber" hide-details="auto"
                                label="Every" @change="onRepeatOrFrequencyChange"></v-select>
                        </v-col>
                        <v-col md="6">
                            <v-select :outlined="true" :items="frequencies" v-model="frequency" hide-details="auto"
                                @change="onRepeatOrFrequencyChange"></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="showSelectedDays">
                        <v-col>
                            <span class="subheading">On</span>
                            <v-chip-group v-model="selectedDays" active-class="deep-purple--text text--accent-4"
                                label="On" outline multiple mandatory>
                                <v-chip v-for="day in days" :key="day" :value="day"> {{ day
                                    }}</v-chip>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="showMonthOptions">
                        <v-radio-group v-model="monthOption" hide-details="auto">
                            <v-list-item>
                                <v-col md="auto">
                                    <v-radio label="On day" hide-details="auto" value="month-option-1">
                                    </v-radio>
                                </v-col>
                                <v-col md="2">
                                    <v-select :outlined="true" :items="dayNumbers" v-model="dayNo"
                                        hide-details="auto"></v-select>
                                </v-col>
                            </v-list-item>
                            <v-list-item>
                                <v-col md="auto">
                                    <v-radio label="On the" hide-details="auto" value="month-option-2">
                                    </v-radio>
                                </v-col>
                                <v-col>
                                    <v-select :outlined="true" :items="ordinalPatternNames" v-model="ordinalPatternName"
                                        hide-details="auto" :disabled="isMonthOption1"></v-select>
                                </v-col>
                                <v-col>
                                    <v-select :outlined="true" :items="fullDays" v-model="fullDay" hide-details="auto"
                                        :disabled="isMonthOption1"></v-select>
                                </v-col>
                            </v-list-item>
                        </v-radio-group>
                    </v-row>
                    <v-row v-if="showYearOptions">
                        <v-radio-group v-model="yearOption" hide-details="auto">
                            <v-list-item>
                                <v-col md="auto">
                                    <v-radio label="On" hide-details="auto" value="year-option-1">
                                    </v-radio>
                                </v-col>
                                <v-col md="4">
                                    <v-select :outlined="true" :items="months" v-model="month" hide-details="auto"
                                        :disabled="!isYearOption1"></v-select>
                                </v-col>
                                <v-col md="2">
                                    <v-select :outlined="true" :items="dayNumbers" v-model="dayNo"
                                        :disabled="!isYearOption1" hide-details="auto"></v-select>
                                </v-col>
                            </v-list-item>
                            <v-list-item>
                                <v-col md="auto">
                                    <v-radio label="On" hide-details="auto" value="year-option-2">
                                    </v-radio>
                                </v-col>
                                <v-col md="3">
                                    <v-select :outlined="true" :items="ordinalPatternNames" v-model="ordinalPatternName"
                                        label="The" hide-details="auto" :disabled="isYearOption1"></v-select>
                                </v-col>
                                <v-col md="3">
                                    <v-select :outlined="true" :items="fullDays" v-model="fullDay" hide-details="auto"
                                        :disabled="isYearOption1"></v-select>
                                </v-col>
                                <v-col md="5">
                                    <v-select :outlined="true" :items="months" v-model="month" hide-details="auto"
                                        label="Of" :disabled="isYearOption1"></v-select>
                                </v-col>
                            </v-list-item>
                        </v-radio-group>
                    </v-row>
                    <v-row>
                        <v-col>
                            <date-picker :outlined="true" :showHeader="false" :date.sync="endDate" label="Run until"
                                :dateRules="validateEndDate" format="cccc d LLLL">
                            </date-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
                    <v-btn color="primary" text @click="onRemove"> Remove </v-btn>
                    <v-btn color="primary" type="submit" :disabled="!valid"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import { DateTime } from "luxon";
import { IRecurrencePattern, ordinalPatternName, recFrequency } from "@/models/common.model";
import { Info } from "luxon";
import CommonMixin from "@/mixins/common.mixin";
import DatePicker from "@/components/common/date-picker.vue";

export default (
    Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
    name: "RecurrenceDialog",
    mixins: [CommonMixin],
    components: {
        "date-picker": DatePicker,
    },
    props: {
        dialog: Boolean,
        recurrence: {
            type: Object as PropType<IRecurrencePattern | null>,
            required: false,
        },
        defaultStart: {
            type: String,
            required: false
        }
    },
    watch: {
        dialog(val: boolean) {
            this.buildRecurrence();
            if (val) {
                const ref: any = this.$refs.manageForm;
                if (ref) {
                    ref.validate();
                }
            }
        },
        recurrence: {
            deep: true,
            handler(val: IRecurrencePattern) {
                this.rPattern = val;
                this.buildRecurrence();
            }
        }
    },
    computed: {
        isMonthOption1(): boolean {
            return this.monthOption == 'month-option-1';
        },
        isYearOption1(): boolean {
            return this.yearOption == 'year-option-1';
        },
        validateEndDate(): string[] {
            let err: string[] = [];
            if (
                this.startDate &&
                this.endDate &&
                DateTime.fromISO(this.startDate) > DateTime.fromISO(this.endDate)
            ) {
                err.push("Start date is greater than end date.");
            }
            return err;
        }
    },
    data() {
        return {
            valid: true,
            startDate: '01-Jan-2025',
            endDate: '',
            repeatNumbers: [] as string[],
            repeatNumber: '1',
            dayNumbers: [] as string[],
            frequencies: [recFrequency.days, recFrequency.weeks, recFrequency.months, recFrequency.years],
            frequency: recFrequency.days,
            days: Info.weekdays("short"),
            selectedDays: [] as string[],
            fullDays: Info.weekdays("long"),
            fullDay: Info.weekdays("long")[0],
            ordinalPatternNames: [ordinalPatternName.first, ordinalPatternName.second, ordinalPatternName.third, ordinalPatternName.fourth, ordinalPatternName.last],
            ordinalPatternName: ordinalPatternName.first,
            showSelectedDays: true,
            showMonthOptions: false,
            monthOption: 'month-option-1',
            dayNo: '1',
            showYearOptions: false,
            yearOption: 'year-option-1',
            months: Info.months("long"),
            month: 'January',
            width: 540,
            rPattern: {} as IRecurrencePattern
        }
    },
    created() {
        for (let i: number = 1; i < 100; i++) {
            this.repeatNumbers.push(i.toString());
        }
        for (let i: number = 1; i <= 31; i++) {
            this.dayNumbers.push(i.toString());
        }        
    },
    methods: {

        buildRecurrence() {
            if (this.rPattern && this.rPattern.start) {

                this.startDate = this.rPattern.start;

                if (this.rPattern.rule) {

                    let elements = this.rPattern.rule.split(';');
                    for (let element of elements) {
                        let parts = element.split('=');
                        switch (parts[0]) {
                            case 'FREQ':
                                switch (parts[1]) {
                                    case 'DAILY':
                                        this.frequency = recFrequency.days;
                                        break;
                                    case 'WEEKLY':
                                        this.frequency = recFrequency.weeks;
                                        break;
                                    case 'MONTHLY':
                                        this.frequency = recFrequency.months;
                                        break;
                                    case 'YEARLY':
                                        this.frequency = recFrequency.years;
                                        break;
                                }
                                break;
                            case 'INTERVAL':
                                this.repeatNumber = parts[1];
                                break;
                            case 'BYDAY':
                                if (this.frequency == recFrequency.months ||
                                    this.frequency == recFrequency.years) {
                                    let dayCode: string = parts[1].substring(parts[1].length - 2);
                                    let ordinalPattern: string = parts[1].substring(0, parts[1].length - 2);

                                    this.ordinalPatternName = this.ordinalPatternNumberToName(ordinalPattern);
                                    this.fullDay = this.twoDayNameToLongName(dayCode);

                                    if (this.frequency == recFrequency.months) {
                                        this.monthOption = 'month-option-2';
                                    } else if (this.frequency == recFrequency.years) {
                                        this.yearOption = 'year-option-2';
                                    }
                                } else {
                                    this.selectedDays = [];
                                    let dayParts = parts[1].split(',');

                                    for (let dayPart of dayParts) {
                                        this.selectedDays.push(this.twoDayNameToShortDayName(dayPart));
                                    }
                                }
                                break;
                            case 'BYMONTHDAY':
                                this.dayNo = parts[1];
                                if (this.frequency == recFrequency.months) {
                                    this.monthOption = 'month-option-1';
                                } else if (this.frequency == recFrequency.years) {
                                    this.yearOption = 'year-option-1';
                                }
                                break;
                            case 'BYMONTH':
                                this.month = this.months[parseInt(parts[1]) - 1];
                                break;
                            case 'UNTIL':
                                this.endDate = DateTime.fromISO(parts[1]).toISO();
                                break;
                        }
                    }

                }

            } else {

                this.startDate = this.defaultStart ? this.defaultStart : DateTime.now().toISO();
                this.endDate = '';
                this.dayNo = '1';
                this.repeatNumber = '1';
                this.frequency = recFrequency.days;
                this.selectedDays = Info.weekdays("short");

            }

            this.resetLayout();
        },        

        onCancel() {
            this.$emit("dialog-cancelled");
        },

        onRemove() {
            this.$emit("remove-recurrence");
        },

        submitForm() {
            let pattern: string = '';

            switch (this.frequency) {
                case recFrequency.days:
                    pattern = `FREQ=DAILY;INTERVAL=${this.repeatNumber}`;
                    if (this.selectedDays.length > 0) {
                        pattern = `${pattern};BYDAY=`;
                        for (let i = 0; i < this.selectedDays.length; i++) {
                            pattern = `${pattern}${this.shortDayNameToTwoName(this.selectedDays[i])}`;
                            if (i < this.selectedDays.length - 1) {
                                pattern = `${pattern},`;
                            }
                        }
                    }
                    break;
                case recFrequency.weeks:
                    pattern = `FREQ=WEEKLY;INTERVAL=${this.repeatNumber}`;
                    if (this.selectedDays.length > 0) {
                        pattern = `${pattern};BYDAY=`;
                        for (let i = 0; i < this.selectedDays.length; i++) {
                            pattern = `${pattern}${this.shortDayNameToTwoName(this.selectedDays[i])}`;
                            if (i < this.selectedDays.length - 1) {
                                pattern = `${pattern},`;
                            }
                        }
                    }
                    break;
                case recFrequency.months:
                    pattern = `FREQ=MONTHLY;INTERVAL=${this.repeatNumber}`;
                    if (this.isMonthOption1) {
                        pattern = `${pattern};BYMONTHDAY=${this.dayNo}`;
                    } else {
                        pattern = `${pattern};BYDAY=${this.ordinalPatternNameToNumber(this.ordinalPatternName)}${this.longDayNameToTwoName(this.fullDay)}`;
                    }
                    break;
                case recFrequency.years:
                    pattern = `FREQ=YEARLY;INTERVAL=${this.repeatNumber}`;
                    if (this.isYearOption1) {
                        pattern = `${pattern};BYMONTH=${this.months.indexOf(this.month) + 1}`;
                        pattern = `${pattern};BYMONTHDAY=${this.dayNo}`;
                    } else {
                        pattern = `${pattern};BYMONTH=${this.months.indexOf(this.month) + 1}`;
                        pattern = `${pattern};BYDAY=${this.ordinalPatternNameToNumber(this.ordinalPatternName)}${this.longDayNameToTwoName(this.fullDay)}`;
                    }
                    break;
            }

            if (this.endDate) {
                pattern = `${pattern};UNTIL=${DateTime.fromISO(this.endDate).toFormat('yyyyMMdd')}T000000Z`;
            }

            this.$emit('recurrence-updated', {
                start: this.startDate,
                rule: pattern
            });
        },

        ordinalPatternNameToNumber(name: string): string {
            let number: string = '';

            switch (name) {
                case ordinalPatternName.first:
                    number = '1';
                    break;
                case ordinalPatternName.second:
                    number = '2';
                    break;
                case ordinalPatternName.third:
                    number = '3';
                    break;
                case ordinalPatternName.fourth:
                    number = '4';
                    break;
                case ordinalPatternName.last:
                    number = '-1';
                    break;
            }

            return number;
        },

        ordinalPatternNumberToName(number: string): string {
            let name: string = '';

            switch (number) {
                case '1':
                    name = ordinalPatternName.first;
                    break;
                case '2':
                    name = ordinalPatternName.second;
                    break;
                case '3':
                    name = ordinalPatternName.third;
                    break;
                case '4':
                    name = ordinalPatternName.fourth;
                    break;
                case '-1':
                    name = ordinalPatternName.last;
                    break;
            }

            return name;
        },

        shortDayNameToTwoName(name: string): string {
            let shortDayName: string = '';

            switch (name.toLowerCase()) {
                case 'sun':
                    shortDayName = 'SU';
                    break;
                case 'mon':
                    shortDayName = 'MO';
                    break;
                case 'tue':
                    shortDayName = 'TU';
                    break;
                case 'wed':
                    shortDayName = 'WE';
                    break;
                case 'thu':
                    shortDayName = 'TH';
                    break;
                case 'fri':
                    shortDayName = 'FR';
                    break;
                case 'sat':
                    shortDayName = 'SA';
                    break;
            }

            return shortDayName;
        },

        twoDayNameToShortDayName(name: string): string {
            let shortDayName: string = '';

            switch (name.toLowerCase()) {
                case 'mo':
                    shortDayName = this.days[0];
                    break;
                case 'tu':
                    shortDayName = this.days[1];
                    break;
                case 'we':
                    shortDayName = this.days[2];
                    break;
                case 'th':
                    shortDayName = this.days[3];
                    break;
                case 'fr':
                    shortDayName = this.days[4];
                    break;
                case 'sa':
                    shortDayName = this.days[5];
                    break;
                case 'su':
                    shortDayName = this.days[6];
                    break;
            }

            return shortDayName;
        },

        longDayNameToTwoName(name: string): string {
            let shortDayName: string = '';

            switch (name.toLowerCase()) {
                case 'monday':
                    shortDayName = 'MO';
                    break;
                case 'tuesday':
                    shortDayName = 'TU';
                    break;
                case 'wednesday':
                    shortDayName = 'WE';
                    break;
                case 'thursday':
                    shortDayName = 'TH';
                    break;
                case 'friday':
                    shortDayName = 'FR';
                    break;
                case 'saturday':
                    shortDayName = 'SA';
                    break;
                case 'sunday':
                    shortDayName = 'SU';
                    break;

            }

            return shortDayName;
        },

        twoDayNameToLongName(name: string): string {
            let longDayName: string = '';

            switch (name.toLowerCase()) {
                case 'mo':
                    longDayName = this.fullDays[0];
                    break;
                case 'tu':
                    longDayName = this.fullDays[1];
                    break;
                case 'we':
                    longDayName = this.fullDays[2];
                    break;
                case 'th':
                    longDayName = this.fullDays[3];
                    break;
                case 'fr':
                    longDayName = this.fullDays[4];
                    break;
                case 'sa':
                    longDayName = this.fullDays[5];
                    break;
                case 'su':
                    longDayName = this.fullDays[6];
                    break;

            }

            return longDayName;
        },

        onRepeatOrFrequencyChange() {
            this.selectedDays = [];
            this.showSelectedDays = false;
            this.showMonthOptions = false;
            this.showYearOptions = false;
            this.width = 540;

            if (this.frequency === recFrequency.days) {
                if (this.repeatNumber === '1') {
                    this.selectedDays = Info.weekdays("short");
                    this.showSelectedDays = true;
                }
            } else if (this.frequency == recFrequency.weeks) {
                this.selectedDays = [Info.weekdays("short")[0]];
                this.showSelectedDays = true;
            } else if (this.frequency == recFrequency.months) {
                this.showMonthOptions = true;
                this.width = 720;
            } else if (this.frequency == recFrequency.years) {
                this.width = 720;
                this.showYearOptions = true;
            }
        },

        resetLayout() {
            this.showSelectedDays = false;
            this.showMonthOptions = false;
            this.showYearOptions = false;
            this.width = 540;

            if (this.frequency === recFrequency.days) {
                if (this.repeatNumber === '1') {
                    this.showSelectedDays = true;
                }
            } else if (this.frequency == recFrequency.weeks) {
                this.showSelectedDays = true;
            } else if (this.frequency == recFrequency.months) {
                this.showMonthOptions = true;
                this.width = 720;
            } else if (this.frequency == recFrequency.years) {
                this.width = 720;
                this.showYearOptions = true;
            }            
        }
    }
});

</script>