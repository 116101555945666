var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("5")]),
              _vm._v("Dates"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to specify any significant dates for the person. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfBaptism,
                          dateRules: _vm.validateDateOfBaptism,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Baptism Date",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateOfBaptism",
                              $event
                            )
                          },
                          "date-changed": _vm.baptismDateChanged,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfFellowship,
                          dateRules: _vm.validateDateOfFellowship,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Fellowship Date",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateOfFellowship",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfResignation,
                          dateRules: _vm.validateDateOfResignation,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Resignation Date",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateOfResignation",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfWedding,
                          dateRules: _vm.validateDateOfWedding,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Wedding Date",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateOfWedding",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateOfPassing,
                          dateRules: _vm.validateDateOfPassing,
                          disabled: !_vm.canViewSensitiveData,
                          label: "Date Passed",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateOfPassing",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }