<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <v-card>
      <v-card-title class="headline">
        <div>Contributors</div>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="onCancel()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-data-iterator
          :items="contributors"
          :items-per-page.sync="itemsPerPage"
          :footer-props="footerOptions"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    :elevation="!multiSelect && hover ? 12 : 3"
                    :outlined="!multiSelect && hover"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-img
                      :src="item.imageUrl"
                      :lazy-src="placeholder"
                      :class="[!multiSelect ? imageCss : '']"
                      @click="!multiSelect && onImageSelected(item)"
                    >
                    </v-img>

                    <v-card-title>
                      <div>
                        {{ item.name }}
                      </div>
                    </v-card-title>
                    <v-card-subtitle class="pb-0">{{
                      item.subtitle
                    }}</v-card-subtitle>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-checkbox
                        v-show="multiSelect"
                        v-model="item.selected"
                      ></v-checkbox>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
        <v-btn color="primary" @click="onConfirm" :disabled="!hasSelectedItems">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { Contributor } from "@/models/page.model";
import { DataOptions } from "vuetify";
import { PageService } from "@/services/page.service";

export default Vue.extend({
  name: "ContributorSelectorDialog",
  data() {
    return {
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      contributors: Array<Contributor>(),
      currentSelection: Array<Contributor>(),
    };
  },
  props: {
    dialog: Boolean,
    selectedItems: Array,
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: {
      handler(val: boolean) {
        if (val === true) {
          this.onLaunch();
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.onGridOptionsChange();
      },
      deep: true,
    },
  },
  computed: {
    hasSelectedItems(): boolean {
      const set = this.contributors as Contributor[];
      if (set) {
        const items = set.filter((f) => f.selected);
        return items.length > 0;
      } else {
        return false;
      }
    },
  },
  methods: {
    onLaunch() {
      this.onSearch();
    },

    onGridOptionsChange() {
      this.onSearch();
    },

    onCancel() {
      this.$emit("dialog-closed-cancel");
    },

    onConfirm() {
      const set = this.contributors as Contributor[];
      let items: Contributor[] = [];
      if (set) {
        items = set.filter((f) => f.selected) as Contributor[];
      }
      this.$emit("dialog-option-selected", items);
    },

    onImageSelected(item: Contributor) {
      this.$emit("dialog-option-selected", [item]);
    },

    onSearch() {
      const { page, itemsPerPage }: DataOptions = this.options;

      // If the search is being called from a dialog property watch
      // we may not have the data options. In this case take it from
      // the default values we have set up in the data section.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      var service = new PageService();

      service.getContributors(pageNumber, perPage).then((response) => {
        this.contributors = response.data.items;
        this.totalItems = response.data.totalCount;

        // Attempt to ensure that the currently selected set of banners
        // are checked so that the user knows that they have already
        // selected that contributor
        for (let i of this.selectedItems) {
          let item = i as Contributor;
          if (item) {
            let match = this.contributors.find((f) => f.id == item.id);
            if (match) {
              match.selected = true;
            }
          }
        }
      });
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
