var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.width, height: "480", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "manageForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    elevation: "1",
                    color: "#E5E3E3",
                  },
                },
                [
                  _vm._v(" Repeat "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCancel()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-close ")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("date-picker", {
                            attrs: {
                              outlined: true,
                              showHeader: false,
                              date: _vm.startDate,
                              label: "Start From",
                              format: "cccc d LLLL",
                            },
                            on: {
                              "update:date": function ($event) {
                                _vm.startDate = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: true,
                              items: _vm.repeatNumbers,
                              "hide-details": "auto",
                              label: "Every",
                            },
                            on: { change: _vm.onRepeatOrFrequencyChange },
                            model: {
                              value: _vm.repeatNumber,
                              callback: function ($$v) {
                                _vm.repeatNumber = $$v
                              },
                              expression: "repeatNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: true,
                              items: _vm.frequencies,
                              "hide-details": "auto",
                            },
                            on: { change: _vm.onRepeatOrFrequencyChange },
                            model: {
                              value: _vm.frequency,
                              callback: function ($$v) {
                                _vm.frequency = $$v
                              },
                              expression: "frequency",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showSelectedDays
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("span", { staticClass: "subheading" }, [
                                _vm._v("On"),
                              ]),
                              _c(
                                "v-chip-group",
                                {
                                  attrs: {
                                    "active-class":
                                      "deep-purple--text text--accent-4",
                                    label: "On",
                                    outline: "",
                                    multiple: "",
                                    mandatory: "",
                                  },
                                  model: {
                                    value: _vm.selectedDays,
                                    callback: function ($$v) {
                                      _vm.selectedDays = $$v
                                    },
                                    expression: "selectedDays",
                                  },
                                },
                                _vm._l(_vm.days, function (day) {
                                  return _c(
                                    "v-chip",
                                    { key: day, attrs: { value: day } },
                                    [_vm._v(" " + _vm._s(day))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showMonthOptions
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { "hide-details": "auto" },
                              model: {
                                value: _vm.monthOption,
                                callback: function ($$v) {
                                  _vm.monthOption = $$v
                                },
                                expression: "monthOption",
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "auto" } },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "On day",
                                          "hide-details": "auto",
                                          value: "month-option-1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.dayNumbers,
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.dayNo,
                                          callback: function ($$v) {
                                            _vm.dayNo = $$v
                                          },
                                          expression: "dayNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "auto" } },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "On the",
                                          "hide-details": "auto",
                                          value: "month-option-2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.ordinalPatternNames,
                                          "hide-details": "auto",
                                          disabled: _vm.isMonthOption1,
                                        },
                                        model: {
                                          value: _vm.ordinalPatternName,
                                          callback: function ($$v) {
                                            _vm.ordinalPatternName = $$v
                                          },
                                          expression: "ordinalPatternName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.fullDays,
                                          "hide-details": "auto",
                                          disabled: _vm.isMonthOption1,
                                        },
                                        model: {
                                          value: _vm.fullDay,
                                          callback: function ($$v) {
                                            _vm.fullDay = $$v
                                          },
                                          expression: "fullDay",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showYearOptions
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { "hide-details": "auto" },
                              model: {
                                value: _vm.yearOption,
                                callback: function ($$v) {
                                  _vm.yearOption = $$v
                                },
                                expression: "yearOption",
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "auto" } },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "On",
                                          "hide-details": "auto",
                                          value: "year-option-1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.months,
                                          "hide-details": "auto",
                                          disabled: !_vm.isYearOption1,
                                        },
                                        model: {
                                          value: _vm.month,
                                          callback: function ($$v) {
                                            _vm.month = $$v
                                          },
                                          expression: "month",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.dayNumbers,
                                          disabled: !_vm.isYearOption1,
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.dayNo,
                                          callback: function ($$v) {
                                            _vm.dayNo = $$v
                                          },
                                          expression: "dayNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "auto" } },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "On",
                                          "hide-details": "auto",
                                          value: "year-option-2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.ordinalPatternNames,
                                          label: "The",
                                          "hide-details": "auto",
                                          disabled: _vm.isYearOption1,
                                        },
                                        model: {
                                          value: _vm.ordinalPatternName,
                                          callback: function ($$v) {
                                            _vm.ordinalPatternName = $$v
                                          },
                                          expression: "ordinalPatternName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.fullDays,
                                          "hide-details": "auto",
                                          disabled: _vm.isYearOption1,
                                        },
                                        model: {
                                          value: _vm.fullDay,
                                          callback: function ($$v) {
                                            _vm.fullDay = $$v
                                          },
                                          expression: "fullDay",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "5" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: true,
                                          items: _vm.months,
                                          "hide-details": "auto",
                                          label: "Of",
                                          disabled: _vm.isYearOption1,
                                        },
                                        model: {
                                          value: _vm.month,
                                          callback: function ($$v) {
                                            _vm.month = $$v
                                          },
                                          expression: "month",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("date-picker", {
                            attrs: {
                              outlined: true,
                              showHeader: false,
                              date: _vm.endDate,
                              label: "Run until",
                              dateRules: _vm.validateEndDate,
                              format: "cccc d LLLL",
                            },
                            on: {
                              "update:date": function ($event) {
                                _vm.endDate = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.onRemove },
                    },
                    [_vm._v(" Remove ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        disabled: !_vm.valid,
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }