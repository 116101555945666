import { render, staticRenderFns } from "./list-people-media.vue?vue&type=template&id=36c63d51"
import script from "./list-people-media.vue?vue&type=script&lang=ts"
export * from "./list-people-media.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VCol,VContainer,VDataIterator,VForm,VHover,VImg,VRow,VSpacer,VSystemBar})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36c63d51')) {
      api.createRecord('36c63d51', component.options)
    } else {
      api.reload('36c63d51', component.options)
    }
    module.hot.accept("./list-people-media.vue?vue&type=template&id=36c63d51", function () {
      api.rerender('36c63d51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lists/list-people-media.vue"
export default component.exports