import store from "@/store/index";

export class BaseService {
  private token: string;
  constructor() {
    this.token = store.state.user.profile.token;
  }

  getAuthenticationHeader() {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getMultipartHeader() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  getBlobHeader() {
    return {
      'Set-Cookie': 'HttpOnly;Secure;SameSite=None',
      Accept: "application/json",
      Authorization: `Bearer ${this.token}`,
    }
  }
}
