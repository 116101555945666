var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "4" } },
    [
      _c("v-system-bar", { attrs: { color: _vm.systemBarColor } }),
      _c("v-card-title", [_c("div", [_vm._v(_vm._s(_vm.title))])]),
      _c("v-card-text", [
        _c("p", [_vm._v("Held at")]),
        _c("div", { staticClass: "text--primary" }, [
          _c("a", { attrs: { href: _vm.mapUrl, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.address)),
          ]),
        ]),
        _c("p"),
        _vm.isOnline
          ? _c("div", { staticClass: "text--primary" }, [
              _c("a", { attrs: { href: _vm.linkUrl, target: "_blank" } }, [
                _vm._v(_vm._s(_vm.linkUrl)),
              ]),
            ])
          : _vm._e(),
        _vm.isOnline ? _c("p") : _vm._e(),
        _vm.isOnline && _vm.dialInNumber
          ? _c("div", { staticClass: "text--primary" }, [
              _vm._v(
                " Dial In: " +
                  _vm._s(_vm.dialInNumber) +
                  " / " +
                  _vm._s(_vm.dialInCode) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.isOnline && _vm.meetingId
          ? _c("div", { staticClass: "text--primary" }, [
              _vm._v(
                " Meeting: " +
                  _vm._s(_vm.meetingId) +
                  " / " +
                  _vm._s(_vm.meetingCode) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.isOnline ? _c("p") : _vm._e(),
        _c("div", { staticClass: "text--primary" }, [
          _vm._v(" " + _vm._s(_vm.start) + " "),
        ]),
        _c("div", { staticClass: "text--primary" }, [
          _vm._v(" " + _vm._s(_vm.end) + " "),
        ]),
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.onNew()
                },
              },
            },
            [_vm._v("New")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.onRemove()
                },
              },
            },
            [_vm._v("Remove")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.onEdit()
                },
              },
            },
            [_vm._v("Edit")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }