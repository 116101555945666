<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn color="success" @click="onNewItem">New Poll</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created a poll yet. Create your first
              one by clicking the New Poll.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> Polls </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model="selectedItems"
            item-key="id"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            :show-select="showMultiSelect"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              {{ formatDate(item.startDate) }}
            </template>
            <template v-slot:[`item.endDate`]="{ item }">
              {{ formatDate(item.endDate) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col md="12">
                  <v-btn color="success" icon @click="onEditItem(item)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn color="primary" icon @click="onDeleteItem(item)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { PollListItem } from "@/models/group.model";
import { DataOptions } from "vuetify";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListPositions",
  mixins: [CommonMixin],
  data() {
    return {
      showDeleteDialog: false,
      search: "",
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      headers: [
        { text: "Name", value: "title", sortable: true },
        { text: "Publish From", value: "startDate", sortable: true },
        { text: "Publish To", value: "endDate", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      items: Array<PollListItem>(),
      selectedItems: Array<PollListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      ownerId: 0,
    };
  },
  methods: {
    onNewItem() {
      this.$emit("new-item-requested");
    },

    onEditItem(item: PollListItem) {
      this.$emit("show-item", item.id);
    },

    onDeleteItem(item: PollListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems(ownerId: number) {
      const { page, itemsPerPage }: DataOptions = this.options;

      // If the search is being called from a dialog property watch
      // we may not have the data options. In this case take it from
      // the default values we have set up in the data section.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      this.ownerId = ownerId;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Polls, Please Wait...`
      );

      const service = new GroupService();
      service
        .getPolls(this.ownerId, pageNumber, perPage)
        .then((response) => {
          this.hideProgressIndicator();
          this.items = response.data.items;
          this.totalItems = response.data.totalCount;

          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.items.length > 0;
          this.showEmptyView = response.data.items.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: PollListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete poll: ${item.title}?`,
        `Are you sure you want to delete the poll ${item.title}? This item will removed from the records and no longer available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing poll, Please Wait..."
        );
        const service = new GroupService();
        service
          .removePoll(this.ownerId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>