<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-card>
      <v-toolbar flat dense elevation="1" color="#E5E3E3">
        Manage Page
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-toolbar>
      <v-form ref="form" v-model="valid" @submit.prevent="onSave">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col md="6">
                <v-text-field
                  outlined
                  clearable
                  label="Title"
                  placeholder="Title"
                  counter="80"
                  maxlength="80"
                  v-model="viewModel.name"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-text-field
                  outlined
                  clearable
                  label="Subtitle"
                  placeholder="Subtitle"
                  counter="80"
                  maxlength="80"
                  v-model="viewModel.subtitle"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-textarea
                  clearable
                  label="Description"
                  placeholder="Description"
                  counter="400"
                  outlined
                  v-model="viewModel.description"
                  rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ManagePageViewModel } from "@/models/page.model";
import { LoadingType } from "@/models/common.model";
import { PageService } from "@/services/page.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ManagePageDialog",
  mixins: [CommonMixin],
  data() {
    return {
      valid: true,
      viewModel: new ManagePageViewModel(),
    };
  },
  props: {
    dialog: Boolean,
    pageId: Number,
  },
  watch: {
    dialog: {
      handler(val: boolean) {
        if (val === true) {
          this.onLaunch();
        }
      },
      deep: true,
    },
  },
  methods: {
    onLaunch() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Loading details, Please Wait...`
      );

      let service = new PageService();
      service.getPageManager(this.pageId).then((response) => {
        this.hideProgressIndicator();
        this.viewModel = response.data;
      });
    },

    onCancel() {
      this.$emit("dialog-closed-cancel");
    },

    onSave() {
      this.$emit("dialog-closed-confirm", this.viewModel);
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>