var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.user.authenticated
        ? _c("navbar", { on: { "nav-button-clicked": _vm.onNavButtonClick } })
        : _vm._e(),
      _vm.user.authenticated
        ? _c("flat-side-menu", {
            attrs: {
              selectedMenuItem: _vm.selectedMenuItem,
              drawerSelected: _vm.drawer,
              items: _vm.items,
            },
            on: { "item-selected": _vm.onItemSelected },
          })
        : _vm._e(),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }