<template>
  <v-row justify="center">
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <v-dialog v-model="dialog" max-width="50%" persistent>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-card-title class="headline">
            <div>Add New Group</div>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="onCancel()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <editor-section-group-main-details :formData="viewModel">
            </editor-section-group-main-details>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onCancel()">Cancel</v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import EditorSectionGroupMainDetails from "@/views/editors/editor-group-main-details.vue";
import { GroupListItem, ManageGroupViewModel } from "@/models/group.model";
import { GroupType, LoadingType } from "@/models/common.model";
import { v4 as uuidv4 } from "uuid";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "NewGroupDialog",
  mixins: [CommonMixin],
  components: {
    "editor-section-group-main-details": EditorSectionGroupMainDetails,
  },
  props: {
    dialog: Boolean,
    branchId: Number,
  },
  data() {
    return {
      valid: false,
      viewModel: new ManageGroupViewModel(),
    };
  },
  watch: {
    dialog(val: boolean) {
      if (val === true) {
        this.onLoad();
      }
    },
  },
  methods: {
    onLoad() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Group Information, Please Wait..."
      );

      this.viewModel.ownerId = this.branchId;

      const service = new GroupService();
      service
        .getGroup(0, this.branchId, GroupType.LocalMinistry)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;
          for (let item of this.viewModel.locations) {
            item.localId = uuidv4();
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Group Information, Please Wait..."
      );

      const service = new GroupService();
      service
        .saveGroup(this.viewModel)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;
          for (let item of this.viewModel.locations) {
            item.localId = uuidv4();
          }

          let newGroup = new GroupListItem();
          newGroup.id = this.viewModel.id;
          newGroup.name = this.viewModel.name;
          newGroup.imageUrl = this.viewModel.imageUrl;

          this.$emit("new-group-dialog-actioned", newGroup);
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onCancel() {
      this.$emit("new-group-dialog-actioned");
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>