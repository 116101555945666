var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Pre-event"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Specify links to pre-event activities such as registration, letters and documents. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Registration Link",
                          placeholder:
                            "Enter the web address of the event registration form.",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "400",
                          outlined: "",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: _vm.formData.registrationUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "registrationUrl", $$v)
                          },
                          expression: "formData.registrationUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.letters,
                          label: "Letters",
                          outlined: "",
                          clearable: "",
                          "item-value": "id",
                          "item-text": _vm.displayTitle,
                          "return-object": "",
                        },
                        model: {
                          value: _vm.selectedLetter,
                          callback: function ($$v) {
                            _vm.selectedLetter = $$v
                          },
                          expression: "selectedLetter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            color: "primary",
                            disabled: _vm.hasSelectedLetter,
                            large: "",
                          },
                          on: { click: _vm.onNewLetter },
                        },
                        [_vm._v(" Add ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            color: "primary",
                            disabled: !_vm.hasSelectedLetter,
                            large: "",
                          },
                          on: { click: _vm.onEditLetter },
                        },
                        [_vm._v(" Edit ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            color: "primary",
                            disabled: !_vm.hasSelectedLetter,
                            large: "",
                          },
                          on: { click: _vm.onRemoveLetter },
                        },
                        [_vm._v(" Remove ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            disabled: !_vm.hasSelectedLetter,
                            color: "primary",
                            large: "",
                            href: _vm.selectedLetterUrl,
                          },
                        },
                        [_vm._v(" View ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Post-event"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Specify links event activities after the link such as the survey and any downloadable souvenirs and videos. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Survey / Feedback Link",
                          placeholder:
                            "Enter the web address of the survey / feedback form.",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "400",
                          outlined: "",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: _vm.formData.surveyUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "surveyUrl", $$v)
                          },
                          expression: "formData.surveyUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("3")]),
              _vm._v("Organiser"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Specify who organised this event so that they can be contacted for additional info pre and post the event. You can select the group from the drop-down or type directly in the boxes below. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.potentialOrganisers,
                          label: "Organised By",
                          outlined: "",
                          clearable: "",
                          "item-value": "id",
                          "item-text": "organisation",
                          "return-object": "",
                        },
                        on: {
                          "click:clear": _vm.onPotentialOrganiserRemoved,
                          change: _vm.onPotentialOrganiserSelected,
                        },
                        model: {
                          value: _vm.potentialOrganiser,
                          callback: function ($$v) {
                            _vm.potentialOrganiser = $$v
                          },
                          expression: "potentialOrganiser",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Branch/Group Name",
                          placeholder:
                            "The name of the branch or group that organised this",
                          "hide-details": "auto",
                          clearable: "",
                          outlined: "",
                          disabled: _vm.hasSelectedOrganisation,
                        },
                        on: {
                          "click:clear": _vm.onGroupNameRemoved,
                          change: _vm.onGroupNameChanged,
                        },
                        model: {
                          value: _vm.groupName,
                          callback: function ($$v) {
                            _vm.groupName = $$v
                          },
                          expression: "groupName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Contact (Name/Email/No)",
                          placeholder:
                            "Enter a contact name, email or phone number",
                          "hide-details": "auto",
                          clearable: "",
                          outlined: "",
                          rules: _vm.validateContactName,
                        },
                        on: {
                          "click:clear": _vm.onContactNameRemoved,
                          change: _vm.onContactNameChanged,
                        },
                        model: {
                          value: _vm.contactName,
                          callback: function ($$v) {
                            _vm.contactName = $$v
                          },
                          expression: "contactName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("event-letter-editor", {
        attrs: {
          dialog: _vm.showLetterEditor,
          entity: _vm.selectedLetter,
          mainEvent: _vm.viewModel,
        },
        on: {
          "on-dialog-save": _vm.onLetterEditorSave,
          "on-dialog-cancel": _vm.onLetterEditorCancel,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteLetterDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteLetterConfirmation },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }