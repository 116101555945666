<template>
  <v-container fluid>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
      <editor-section-group-main-details :formData="formData">
      </editor-section-group-main-details>
      <v-row>
        <v-col lg="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                @click="onCancel"
                v-bind="attrs"
                v-on="on"
                large
              >
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>
            </template>
            <span>Back to list</span>
          </v-tooltip>
          <v-btn color="primary" type="submit" :disabled="!valid" large>
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import GroupMixin from "@/mixins/group.mixin";
import EditorSectionGroupMainDetails from "@/views/editors/editor-group-main-details.vue";
import { ManageGroupViewModel } from "@/models/group.model";
import { AlertType, LoadingType } from "@/models/common.model";
import { v4 as uuidv4 } from "uuid";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof CommonMixin> & InstanceType<typeof GroupMixin>
  >
).extend({
  name: "EditorSectionGroup",
  mixins: [CommonMixin, GroupMixin],
  components: {
    "editor-section-group-main-details": EditorSectionGroupMainDetails,
  },
  props: {
    groupType: {
      type: String,
      default: "branch",
    },
  },
  data() {
    return {
      tab: null,
      valid: true,
      id: 0,
      ownerId: 0,
      groupName: this.toFriendlyGroupName(this.groupType),
      formData: new ManageGroupViewModel(),
    };
  },
  methods: {
    resetData() {
      this.formData = new ManageGroupViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getGroup(id: number, ownerId: number) {
      this.id = id;
      this.ownerId = ownerId;
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Information, Please Wait..."
      );

      const service = new GroupService();
      service
        .getGroup(this.id, this.ownerId, this.groupType)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          for (let item of this.formData.locations) {
            item.localId = uuidv4();
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Information, Please Wait..."
      );

      const service = new GroupService();
      service
        .saveGroup(this.formData)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
          for (let item of this.formData.locations) {
            item.localId = uuidv4();
          }

          this.$emit(
            "show-alert-panel",
            "The data was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => {
          this.showErrorDialog(error);
        });
    },

    onCancel() {
      this.$emit("manage-group-cancel");
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>