import { render, staticRenderFns } from "./editor-contribution-general.vue?vue&type=template&id=a6dfd910&scoped=true"
import script from "./editor-contribution-general.vue?vue&type=script&lang=ts"
export * from "./editor-contribution-general.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/wg.css?vue&type=style&index=0&id=a6dfd910&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6dfd910",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VCombobox,VContainer,VImg,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6dfd910')) {
      api.createRecord('a6dfd910', component.options)
    } else {
      api.reload('a6dfd910', component.options)
    }
    module.hot.accept("./editor-contribution-general.vue?vue&type=template&id=a6dfd910&scoped=true", function () {
      api.rerender('a6dfd910', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/editors/editor-contribution-general.vue"
export default component.exports