var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.maxWidth, persistent: "", eager: "" },
          model: {
            value: _vm.showSenderDialog,
            callback: function ($$v) {
              _vm.showSenderDialog = $$v
            },
            expression: "showSenderDialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "manageForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        flat: "",
                        dense: "",
                        elevation: "1",
                        color: "#E5E3E3",
                      },
                    },
                    [
                      _c("div", [_vm._v("Message to " + _vm._s(_vm.heading))]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCancel()
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Close")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticStyle: { height: "20px" } }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Subject",
                                  placeholder: "Subject",
                                  clearable: "",
                                  rules: _vm.validateSubject,
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.notification.subject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.notification, "subject", $$v)
                                  },
                                  expression: "notification.subject",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.queryOptions,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                  label: "Send to this group",
                                  placeholder:
                                    "Choose a group of people that you want to notify",
                                  "menu-props": "auto",
                                  "hide-details": "auto",
                                  clearable: "",
                                  rules: _vm.validateSelectedQuery,
                                },
                                on: { change: _vm.onSelectedQueryChange },
                                model: {
                                  value: _vm.selectedQuery,
                                  callback: function ($$v) {
                                    _vm.selectedQuery = $$v
                                  },
                                  expression: "selectedQuery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("extended-autocomplete", {
                                attrs: {
                                  items: _vm.allRecipients,
                                  selected: _vm.selectedRecipients,
                                  input: _vm.searchRecipientsInput,
                                  label: "And/Or specifically",
                                  placeholder:
                                    "Only recipients who permit contact will be listed",
                                },
                                on: {
                                  "update:selected": function ($event) {
                                    _vm.selectedRecipients = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    label: "Message",
                                    placeholder: "Enter your message here.",
                                    counter: "4000",
                                    outlined: "",
                                    clearable: "",
                                    rows: "8",
                                    rules: [_vm.rules.required],
                                  },
                                  model: {
                                    value: _vm.notification.message,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.notification, "message", $$v)
                                    },
                                    expression: "notification.message",
                                  },
                                }),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "8" } },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  "show-size": "",
                                  counter: "",
                                  multiple: "",
                                  "truncate-length": "5",
                                  label: "Attachments",
                                  placeholder: "Add file attachments here",
                                  rules: [_vm.rules.attachmentSize],
                                  accept:
                                    ".txt,.mp4,.mp3,.gif,.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx",
                                },
                                model: {
                                  value: _vm.attachments,
                                  callback: function ($$v) {
                                    _vm.attachments = $$v
                                  },
                                  expression: "attachments",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "4", rowspan: "2" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "primary" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-progress-circular",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    rotate: -90,
                                                    size: 100,
                                                    width: 15,
                                                    value: _vm.reach,
                                                    color: "primary",
                                                  },
                                                },
                                                "v-progress-circular",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.reach) + " % "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "This is the percentage of your audience that will be reached."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "This number may not reach 100% if some have opted out of being contacted"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " or have not provided contact information."
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "mdi-link",
                                  label: "Content Url",
                                  placeholder:
                                    "A link to additional content like a web site or online form.",
                                  clearable: "",
                                  "hide-details": "auto",
                                  rules: _vm.validateContentUrl,
                                },
                                model: {
                                  value: _vm.notification.contentUrl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.notification,
                                      "contentUrl",
                                      $$v
                                    )
                                  },
                                  expression: "notification.contentUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Send by mobile app",
                                  "hide-details": "auto",
                                  rules: _vm.validateSendBy,
                                },
                                model: {
                                  value: _vm.notification.app,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.notification, "app", $$v)
                                  },
                                  expression: "notification.app",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Send by email",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.notification.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.notification, "email", $$v)
                                  },
                                  expression: "notification.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Send by text",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.notification.sms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.notification, "sms", $$v)
                                  },
                                  expression: "notification.sms",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.notification.sms === true &&
                                    _vm.incursCost,
                                  expression:
                                    "notification.sms === true && incursCost",
                                },
                              ],
                              attrs: { md: "6" },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  "Texts are charged. Please check the cost before sending."
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.incursCost,
                          },
                          on: { click: _vm.calculateCost },
                        },
                        [_vm._v(" Get Cost ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled: !_vm.valid,
                          },
                        },
                        [_vm._v(" Send Message ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }