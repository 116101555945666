<template>
  <v-dialog v-model="dialog" max-width="40%" persistent>
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat dense elevation="1" color="#E5E3E3">
          {{ dialogData.title }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="onExit()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row class="top-buffer">
            <v-col md="4">
              <v-autocomplete
                v-model="selection.currentStatus"
                :items="filters.currentStatus"
                label="Current Status"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.currentStatus.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.gender"
                :items="filters.gender"
                label="Gender"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.gender.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2">
              <v-text-field
                v-model.number="selection.ageStart"
                :rules="validateAgeStart"
                type="number"
                outlined
                label="Age (from)"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col md="2">
              <v-text-field
                v-model.number="selection.ageEnd"
                :rules="validateAgeEnd"
                type="number"
                outlined
                label="Age (to)"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <v-select
                :items="filters.contactable"
                v-model="selection.contactable"
                label="Contactable Only"
                placeholder="Select an option"
                menu-props="auto"
                hide-details="auto"
                outlined
              ></v-select>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.attributes"
                :items="filters.attributes"
                label="Attributes"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.attributes.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.maritalStatus"
                :items="filters.maritalStatus"
                label="Marital Status"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.maritalStatus.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.profession"
                :items="filters.profession"
                label="Profession"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.profession.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.ethnicity"
                :items="filters.ethnicity"
                label="Ethnicity"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.ethnicity.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                v-model="selection.nationality"
                :items="filters.nationality"
                label="Nationality"
                placeholder="Select one or more options"
                multiple
                clearable
                auto-select-first
                outlined
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>{{ item }} </span>
                  </div>
                  <div style="width: 4px"></div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selection.nationality.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <date-picker
                :date.sync="selection.dateOfBaptismStart"
                :dateRules="validateDateOfBaptism"
                :disabled="!canViewSensitiveData"
                label="Baptism Date (Start)"
              ></date-picker>
            </v-col>
            <v-col md="4">
              <date-picker
                :date.sync="selection.dateOfBaptismEnd"
                :dateRules="validateDateOfBaptism"
                :disabled="!canViewSensitiveData"
                label="Baptism Date (End)"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <date-picker
                :date.sync="selection.fellowshipDateStart"
                :dateRules="validateDateOfFellowship"
                :disabled="!canViewSensitiveData"
                label="Fellowship Date (Start)"
              ></date-picker>
            </v-col>
            <v-col md="4">
              <date-picker
                :date.sync="selection.fellowshipDateEnd"
                :dateRules="validateDateOfFellowship"
                :disabled="!canViewSensitiveData"
                label="Fellowship Date (End)"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <date-picker
                :date.sync="selection.resignationDateStart"
                :dateRules="validateDateOfResignation"
                :disabled="!canViewSensitiveData"
                label="Resignation Date (Start)"
              ></date-picker>
            </v-col>
            <v-col md="4">
              <date-picker
                :date.sync="selection.resignationDateEnd"
                :dateRules="validateDateOfResignation"
                :disabled="!canViewSensitiveData"
                label="Resignation Date (End)"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <date-picker
                :date.sync="selection.datePassedStart"
                :dateRules="validateDateOfPassing"
                :disabled="!canViewSensitiveData"
                label="Date Passed (Start)"
              ></date-picker>
            </v-col>
            <v-col md="4">
              <date-picker
                :date.sync="selection.datePassedEnd"
                :dateRules="validateDateOfPassing"
                :disabled="!canViewSensitiveData"
                label="Date Passed (End)"
              ></date-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onCancel()"> Cancel </v-btn>
          <v-btn color="primary" text @click="onReset()"> Reset </v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import { ValidOptionService } from "@/services/valid-option.service";
import { PeopleFilterOptionsViewModel } from "@/models/valid-option.model";
import DatePicker from "@/components/common/date-picker.vue";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";
import { PeopleSearchFilter } from "@/models/people.model";
import { RegisteredUserService } from "@/services/registered-user.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "RegisteredUserFilterDialog",
  mixins: [CommonMixin],
  components: {
    "extended-autocomplete": ExtendedAutoComplete,
    "date-picker": DatePicker,
  },
  props: {
    dialog: Boolean,
    data: DialogMessage,
    canViewSensitiveData: Boolean,
  },
  data() {
    return {
      valid: false,
      dialogData: new DialogMessage("", ""),
      filters: new PeopleFilterOptionsViewModel(),
      selection: new PeopleSearchFilter(),
      isMemberIndeterminate: true,
    };
  },
  computed: {
    validateAgeStart(): string[] {
      let err: string[] = [];
      if (this.selection.ageStart) {
        if (!this.isNumber(this.selection.ageStart?.toString()))
          err.push("This is not a number");
        else if (this.selection.ageStart < 0) err.push("Cannot be less than 0");
        else if (this.selection.ageStart > 130)
          err.push("Cannot be greater than 130");
        else if (
          this.selection.ageEnd &&
          this.isNumber(this.selection.ageEnd?.toString())
        ) {
          if (this.selection.ageStart > this.selection.ageEnd)
            err.push("Invalid age range.");
        }
      }
      return err;
    },
    validateAgeEnd(): string[] {
      let err: string[] = [];
      if (this.selection.ageEnd) {
        if (!this.isNumber(this.selection.ageEnd?.toString()))
          err.push("This is not a number");
        else if (this.selection.ageEnd < 0) err.push("Cannot be less than 0");
        else if (this.selection.ageEnd > 130)
          err.push("Cannot be greater than 130");
        else if (
          this.selection.ageStart &&
          this.isNumber(this.selection.ageStart?.toString())
        ) {
          if (this.selection.ageStart > this.selection.ageEnd)
            err.push("Invalid age range.");
        }
      }
      return err;
    },
    validateDateOfBaptism(): string[] {
      let err: string[] = [];
      if (
        this.selection.dateOfBaptismStart &&
        this.selection.dateOfBaptismEnd &&
        this.selection.dateOfBaptismStart > this.selection.dateOfBaptismEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfFellowship(): string[] {
      let err: string[] = [];
      if (
        this.selection.fellowshipDateStart &&
        this.selection.fellowshipDateEnd &&
        this.selection.fellowshipDateStart > this.selection.fellowshipDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfResignation(): string[] {
      let err: string[] = [];
      if (
        this.selection.resignationDateStart &&
        this.selection.resignationDateEnd &&
        this.selection.resignationDateStart > this.selection.resignationDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfWedding(): string[] {
      let err: string[] = [];
      if (
        this.selection.weddingDateStart &&
        this.selection.weddingDateEnd &&
        this.selection.weddingDateStart > this.selection.weddingDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfPassing(): string[] {
      let err: string[] = [];
      if (
        this.selection.datePassedStart &&
        this.selection.datePassedEnd &&
        this.selection.datePassedStart > this.selection.datePassedEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
  },
  watch: {
    data(val: DialogMessage) {
      this.dialogData = val;
      this.populate();
    },
  },
  methods: {
    submitForm() {
      this.data.entity = this.selection;
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(true, this.data.entity)
      );
    },
    onCancel() {
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(false, this.data.entity)
      );
    },
    onReset() {
      this.isMemberIndeterminate = true;
      this.selection = new PeopleSearchFilter();
    },
    onExit() {
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(false, this.data.entity)
      );
    },
    async populate() {
      const validOptionService = new ValidOptionService();
      let response = await validOptionService.getPeopleFilterOptions();
      if (response.data) {
        this.filters = response.data;
      }

      const userService = new RegisteredUserService();
      let userResponse = await userService.getPeopleFilter();
      if (userResponse.data) {
        this.selection = userResponse.data;
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>